<div mat-dialog-title class="has-action p-3" *ngIf="!loading && video">
  <span class="pr-3">{{video.title}}</span>
  <ng-container *csHasPermission="RolePermission.CONTENT_VIDEOS_INGEST">
    <button mat-mini-fab class="mat-elevation-z0" color="accent" (click)="editMode = true" *ngIf="!editMode">
      <i class="fas fa-edit"></i>
    </button>
    <button mat-mini-fab class="mat-elevation-z0" color="accent" (click)="updateVideo()" *ngIf="editMode">
      <i class="fas fa-save"></i>
    </button>
  </ng-container>
</div>
<div mat-dialog-content class="px-0 mb-3" *ngIf="!loading && video">
  <mat-divider></mat-divider>
  <mat-tab-group mat-stretch-tabs animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Info</span>
      </ng-template>

      <div class="col-12 p-3">
        <dl class="data-list data-list--inline">
          <dt>Duration:</dt>
          <dd>{{getTimeFromSeconds(video?.duration)}}</dd>
        </dl>
        <mat-divider></mat-divider>
        <dl class="data-list data-list--inline mt-3">
          <dt>Tags:</dt>
          <dd *ngIf="!editMode">{{ video.tags }}</dd>
          <dd *ngIf="editMode">

            <mat-chip-list #chipList>
              <mat-form-field class="w-100" floatLabel="">
                <input matInput
                       placeholder="New tag..."
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [formControl]="tagCtrl"
                       (matChipInputTokenEnd)="addTag($event)">
              </mat-form-field>
              <mat-basic-chip
                *ngFor="let tag of video.tags"
                [removable]="true"
                (removed)="removeTag(tag)">
                {{tag}}
                <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
              </mat-basic-chip>
            </mat-chip-list>
          </dd>
        </dl>
        <mat-divider></mat-divider>
        <dl class="data-list data-list--inline mt-3">
          <dt>Description:</dt>
          <dd *ngIf="!editMode">{{ video.description }}</dd>
          <dd *ngIf="editMode">
            <mat-form-field floatLabel="never" class="w-100 mt-n2 mb-n5">
              <textarea matInput class="textarea-note" name="description" [(ngModel)]="video.description" style="min-height: 200px"
                        placeholder="Video description"></textarea>
            </mat-form-field>
          </dd>
        </dl>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span>Preview</span>
      </ng-template>

      <div class="container">
        <div class="row mb-3">
          <div class="col-12 mt-3">
            <small class="text-muted mb-2 d-block">Preview</small>
            <app-vjs-player [options]="options"></app-vjs-player>
          </div>
        </div>
        <div class="row mb-3 justify-content-center">
          <div class="col-6">
            <mt-image-upload-preview label="Upload thumbnail" (fileSelected)="thumbnailSelected($event)"
              (fileCanceled)="removeThumbnail()"
              [fileName]="false"
              [allowedFileTypes]="'image/png, image/jpeg'"
              [imagePreview]="video.posters[0]"></mt-image-upload-preview>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <mat-divider *ngIf="!loading"></mat-divider>
</div>

<div mat-dialog-actions class="" *ngIf="!loading">
  <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
  <button mat-flat-button class="iconed" color="primary" [disabled]="!thumbnail" *csHasPermission="RolePermission.CONTENT_VIDEOS_INGEST"
          (click)="saveThumbnail()">
    <i class="fas fa-save"></i>
    <span>Save thumbnail</span>
  </button>
</div>

<div class="data-loader" [hidden]="!loading">
  <mat-spinner [color]="'primary'"
               [mode]="'indeterminate'"
               [diameter]="30">
  </mat-spinner>
</div>
