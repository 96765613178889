import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {Domain, DomainCreateRequest, DomainUpdateRequest} from '../domain/domain.model';
import {first, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DomainsService {
    constructor(private httpClient: HttpClient) {
    }

    getDomainById(id: string): Observable<Domain> {
        return this.httpClient.get<Domain>(`${environment.serviceUrl}/api/domains/${id}`);
    }

    getAllDomains(filter: any): Observable<Page<Domain>> {
        return this.httpClient.get<Page<Domain>>(`${environment.serviceUrl}/api/domains`, {
            params: HttpUtils.getHttpParams(filter)
        });
    }

    exportAllDomainsCSV(publisherId: string): Observable<string> {
        return this.httpClient
            .get<string>(
                `${environment.serviceUrl}/api/publishers/${publisherId}/domains/csv/export`,
                {
                    responseType: 'blob',
                    header: {
                        Accept: 'text/csv',
                    },
                } as any
            )
            .pipe(
                map((response: any) => {
                    const blob = new Blob([response], {
                        type: 'text/csv',
                    });
                    return URL.createObjectURL(blob);
                })
            );
    }

    updateDomainsCSV(publisherId: string, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.httpClient
            .put<any>(
                `${environment.serviceUrl}/api/publishers/${publisherId}/domains/csv/reimport`,
                formData
            )
            .pipe(first());
    }

    addDomainsCSV(publisherId: string, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.httpClient
            .post<any>(
                `${environment.serviceUrl}/api/publishers/${publisherId}/domains/csv/import`,
                formData
            )
            .pipe(first());
    }

    createDomain(request: DomainCreateRequest) {
        console.log('createDomains:::', request);
        return this.httpClient.post(`${environment.serviceUrl}/api/domains`, request);
    }

    updateDomain(request: DomainUpdateRequest) {
        console.log('updateDomain:::', request);
        return this.httpClient.put<Domain>(`${environment.serviceUrl}/api/domains/${request.domain.id}`, request);
    }

    downloadTemplateCsv(publisherId: string): Observable<string> {
        return this.httpClient
            .get<string>(
                `${environment.serviceUrl}/api/publishers/${publisherId}/domains/download/domains-csv-template`,
                {
                    responseType: 'blob',
                    header: {
                        Accept: 'text/csv',
                    },
                } as any
            )
            .pipe(
                map((response: any) => {
                    const blob = new Blob([response], {
                        type: 'text/csv',
                    });
                    return URL.createObjectURL(blob);
                })
            );
    }

    countByStatus(): Observable<any> {
        return this.httpClient.get<any>(
            `${environment.serviceUrl}/api/domains/statuses`
        );
    }
}