import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { User } from 'src/app/core/auth/user.model';
import { UsersService } from '../../services/users.service';
import _ from 'lodash';
import { SortUtils } from '../../utils/sort-utils';
import { CampaignsService } from '../../services/campaigns.service';
import { CreativeLibrary } from '../../domain/creative-library.model';
import { CreativeType, LineItemCreative } from '../../domain/campaigns.model';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-creatives-autocomplete',
  template: ` <mat-form-field
    [floatLabel]="floatLabel || 'always'"
    class="w-100"
    [ngClass]="formFieldClass"
    [appearance]="formFieldAppearance ? 'fill' : undefined"
  >
    <mat-label >{{
      label ? label : 'Choose creative'
    }}</mat-label>
    <input
      matInput
      #name
      aria-label="User"
      [matAutocomplete]="auto"
      [formControl]="filterCtrl"
      [value]="this.selectedCreative?.name"
      [required]= "this.required"
      placeholder="Choose creative"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="display"
    >
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-autocomplete>
    <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
  </mat-form-field>`,
})

export class CreativesAutocompleteComponent implements OnInit {
  @ViewChild('name', { static: true }) nameFilter: ElementRef;

  filter = {
    activeStatus: 'ACTIVE'
  } as any;

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public required: boolean;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;
  @Input()
  public formFieldAppearance: string;

  @Input()
  public creativesId: string;

  @Input()
  public creativeType: CreativeType;

  @Input()
  public creativesToIgnore: LineItemCreative[];

  @Input()
  items: User[];

  @Input()
  brandSecondaryId: string; // Brand linked to the order on line item

  @Output()
  creativeSelected = new EventEmitter<CreativeLibrary>();

  serverSearching = false;

  filteredItems: Subject<CreativeLibrary[]>;
  filterCtrl = new UntypedFormControl();
  selectedCreative;
  allCreatives: CreativeLibrary[] = [];
  filteredCreativeIds: string[] = [];

  constructor(private service: CampaignsService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((next) => {
        if (!next && this.selectedCreative) {
          this.selectedCreative = undefined;
          this.emit();
        }

        if (next && typeof next === 'string') {
          this.filterCtrl.disable();
          this.searchCreatives();
        }

        if (next == ''){
          this.searchCreatives();
        }
      });
  }

  resetInput() {
    this.selectedCreative = null;
    this.filterCtrl.setValue('');
  }

  ngOnInit(): void {
    if (this.creativeType) {
      this.getSingleTypeCreatives();
    }
    else {this.getAllCreatives();}
  }

  display(e?: User) {
    return e ? e.name : undefined;
  }

  optionSelected($event) {
    this.selectedCreative = $event.option.value;
    this.filteredItems.next(this.allCreatives.slice(0, 20));
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.creativeSelected.emit(this.selectedCreative);
  }

  getAllCreatives() {
    this.serverSearching = true;
    forkJoin([
      this.service.getCreativeLibraryList(this.filter),
    ])
      .subscribe(
        ([resp]) => {
          this.allCreatives = [...resp.content];
          //Dont show creatives that are already choosen for association
          if (this.creativesToIgnore){
            this.creativesToIgnore.forEach(element => {
              this.allCreatives = this.allCreatives.filter(item => item.id !== element.creative.id);
            });
          }
          if (!this.filterCtrl.value?.length) {
            this.filteredItems.next(
              this.allCreatives.filter(
                (item) => !this.filteredCreativeIds.includes(item.name)
              )
            );
          }
          if (this.creativesId) {
            this.selectedCreative =  this.allCreatives.find(x => x.id == this.creativesId);
          }
          this.serverSearching = false;
        },
        () => {
          this.filterCtrl.enable();
          this.nameFilter.nativeElement.focus();
        }
      );
  }

  getSingleTypeCreatives() {
      this.serverSearching = true;
      this.httpCall().subscribe(
        (resp) => {
          // Note added additional filter because we only want to return creatives linked with brand that can be found on order in line item 9/11/2023
          this.allCreatives = resp.content.filter(item => (item.brand.secondaryId === this.brandSecondaryId));
          //Dont show creatives that are already choosen for association
          if (this.creativesToIgnore){
            this.creativesToIgnore.forEach(element => {
              this.allCreatives = this.allCreatives.filter(item => (item.id !== element.creative.id));
            });
          }
          if (!this.filterCtrl.value?.length) {
            this.filteredItems.next(
              this.allCreatives.filter(
                (item) => !this.filteredCreativeIds.includes(item.name)
              )
            );
          }
          if (this.creativesId) {
            this.selectedCreative =  this.allCreatives.find(x => x.id == this.creativesId);
          }
          this.serverSearching = false;
        },
        () => {
          this.filterCtrl.enable();
          this.nameFilter.nativeElement.focus();
        }
      );
  }

  private httpCall(): any{
    return this.service.getCreativeLibraryList({type: this.creativeType});
  }


  updateFilteredUsers(items) {
    this.filteredCreativeIds = items.map((item) => item.name);
  }

  searchCreatives() {
    this.serverSearching = true;
    //treba izbrisati creative gdje je ime null pa cemo maknut ovaj kod, trenutno zajebava jer ima creative
    //sa null imenima
    const temp = [];
    this.allCreatives.forEach(element => {
      if (element.name != null) {
        temp.push(element);
      }
    });
    if (temp.length > 0) {
      const search = temp.filter((e) =>
        e.name.toLowerCase().includes(this.filterCtrl.value.toLowerCase())
      );
      this.filteredItems.next(
        search
      );
    }
    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }
}
