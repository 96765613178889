import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Targeting } from '../domain/targeting.model';
import { urlJoin } from 'url-join-ts';
import { DeviceType } from '../domain/device-type.model';
import { Location } from '../interfaces/location.model';
import { Page } from '../domain/page.model';
import { HttpUtils } from '../utils/http-utils';
import { IFilters } from '../interfaces/filters.model';
import { Placements } from '../domain/placements.model';

@Injectable({
  providedIn: 'root'
})
export class GeolocationsService {
  private readonly endpoint = 'api/targetings';
  private readonly targetingGeoEndpoint = 'geolocations';

  constructor(private httpClient: HttpClient) {
  }

  public getTargetingGeoContinents(): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'continents'));
  }

  public getTargetingGeoCountries_NA_EU(): Observable<Array<Location>> {
      return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'na-eu-countries'));
  }

  public getTargetingGeoCountries(filter: any): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'countries'),{
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getTargetingGeoRegions(filter: any): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'regions'),{
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getTargetingGeoSubregions(filter: any): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'subregions'),{
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getTargetingGeoCities(filter: any): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'cities'),{
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public searchGeolocations(filter: any): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'search'),{
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public searchGeolocationsCountries(filter: any): Observable<Array<Location>> {
    return this.httpClient.get<Array<Location>>(urlJoin(environment.serviceUrl, this.endpoint, this.targetingGeoEndpoint, 'countries/search'),{
      params: HttpUtils.getHttpParams(filter)
    });
  }


}

export class GeoRequestFilter {
  continent?: string;
}