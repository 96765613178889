import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { NetworkAutocompleteComponent } from './network-autocomplete.component';
import { NetworkService } from '../../services/network.service';
import { Network } from '../../domain/network.model';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-network-autocomplete-chips',
  template: `
    <div class="cs-network-autocomplete-chips-wrapper">
      <mt-network-autocomplete #pc (networkSelected)="onItemSelected($event)"
                                [disabled]="disabled"
                                [floatLabel]="floatLabel"
                                [formFieldClass]="formFieldClass"
                                [formFieldAppearance]="formFieldAppearance">
      </mt-network-autocomplete>
      <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="!disabled"
                        (removed)="remove(item)">
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class NetworkAutocompleteChipsComponent implements OnInit, OnChanges {

  @ViewChild(NetworkAutocompleteComponent, {static: true}) pc: NetworkAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public networkIds: string[];

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  disabled: boolean;

  @Output()
  networkSelectionChange = new EventEmitter<Network[]>();

  items: Network[] = [];

  constructor(private service: NetworkService) {
  }

  ngOnInit(): void {
    //Preselect
    if (this.networkIds && this.networkIds.length) {
      if (this.networkIds && typeof this.networkIds === 'string') {
        this.networkIds = [this.networkIds];
      }
      this.service.listNetworks().subscribe(networks => {
        this.pc.updateFilteredNetworks(networks.filter(x => this.networkIds.some(y => x.id.toString() == y)));
        networks.filter(x => this.networkIds.some(y => x.id.toString() == y)).map(network => this.items.push(network));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled?.currentValue == true && !changes.disabled?.firstChange) {
        this.networkSelectionChange.emit([]);
        this.pc.updateFilteredNetworks([]);
        this.items = [];
    }
  }

  onItemSelected($event: Network) {
    if ($event && !this.items.find(x => x.id === $event.id)) {
      this.items.push($event);
      this.networkSelectionChange.emit(this.items);
    }
    this.pc.resetInput();
    this.pc.updateFilteredNetworks(this.items);
  }

  remove(p: Network) {
    this.items.splice(this.items.indexOf(p), 1);
    this.networkSelectionChange.emit(this.items);
    this.pc.updateFilteredNetworks(this.items);
  }

  /** Clear all items from filter. */
  clearFilter(){
    this.items = [];
    this.pc.updateFilteredNetworks(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable == true) {
      this.pc.disableSearch(true);
    }
    else {
      this.pc.disableSearch(false);
    }
  }
}
