import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {Principal} from '../../../core/auth/principal.service';
import {Playlist, PlaylistListRequest} from '../../domain/playlist.model';
import {PlaylistsService} from '../../services/playlists.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-playlist-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass">
      <mat-label >{{label ? label : 'Collection'}}</mat-label>
      <input matInput
             #name
             aria-label="Publisher"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="Type to search">
      <i class="fas fa-spinner fa-spin mr-2" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.title}}
        </mat-option>
      </mat-autocomplete>
      <i class="far fa-search" matSuffix></i>
    </mat-form-field>`,
})
export class PlaylistAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public playlistId: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  items: Playlist[];

  @Output()
  playlistSelected = new EventEmitter<Playlist>();

  serverSearching = false;
  filteredPlaylistIds = [];
  playlists: Playlist[] = [];
  filteredItems: Subject<Playlist[]>;
  filterCtrl = new UntypedFormControl();
  selectedPlaylist;

  constructor(private service: PlaylistsService, private principal: Principal) {
    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedPlaylist) {
          this.selectedPlaylist = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          service.list({
            page: 0,
            size: 100,
            title: next
          } as PlaylistListRequest).subscribe(resp => {
            this.playlists = resp.content;
            this.filteredItems.next(this.playlists.sort(SortUtils.propertyComparatorString('title')));
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
            this.filteredItems.next(this.playlists.filter(x => !this.filteredPlaylistIds.includes(x.id)));
          }
          , () => {
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          });
        }
      });
  }

  
  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredPublisherIds. */
  public updateFilteredPlaylists(items) {
    this.filteredPlaylistIds = items.map((item) => item.id);
  }

  resetInput() {
    this.selectedPlaylist = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.playlistId) {
      this.service.getPlaylistById(this.playlistId).subscribe(next => {
        if (next) {
          this.selectedPlaylist = next;
          this.filterCtrl.setValue(next);
          this.emit();
        }
      });
    }
  }

  display(p?: Playlist) {
    return p ? p.title : undefined;
  }

  optionSelected($event) {
    this.selectedPlaylist = $event.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  private emit() {
    this.playlistSelected.emit(this.selectedPlaylist);
  }
}
