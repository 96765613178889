<div class="container-fluid">
  <div class="row">
    <div class="col p-0 m-0">
      <mat-card>
        <mat-card-header>

          <mat-card-title *ngIf="selection.selected.length > 0">
            <button mat-flat-button *csHasPermission="RolePermission.CONTENT_VIDEOS_ADD_TO_COLLECTION" color="primary" (click)="addToPlaylist()" class="ml-0">
              <span>Add to collection</span>
            </button>
            <ng-container *csHasPermission="RolePermission.CONTENT_VIDEOS_EDIT">
              <button mat-flat-button color="primary" (click)="editSelectedVideos()" class="ml-2" *ngIf="!videoType">
                <span>Edit selected videos</span>
              </button>
            </ng-container>
            <button mat-flat-button *csHasPermission="RolePermission.CONTENT_VIDEOS_REMOVE_OWNER" color="warn" (click)="removeOwner()" class="ml-2">
              <span>Remove owner</span>
            </button>
          </mat-card-title>
          <div class="mat-card-header-search">
            <mat-form-field class="header-form-field mr-3" floatLabel="never">
              <input matInput [value]="filter.title || ''" [formControl]="titleControl"
                     placeholder="search">
              <i class="far fa-search" matSuffix></i>
            </mat-form-field>
          </div>
          <div class="mat-card-header-actions">
            <button mat-flat-button color="primary" class="iconed" (click)="ingestVideo()"  *ngIf="videoType">
              <i class="fal fa-cloud-upload" aria-hidden="true"></i>
              <span>Ingest video</span>
            </button>
            <button mat-mini-fab color="accent" matTooltip="Filters" (click)="filters.toggle()"
            class="mat-elevation-z0 mx-1">
            <i class="fas fa-filter" aria-hidden="true"></i>
            </button>
          </div>
        </mat-card-header>
        <mat-card-content class="mat-card-content--full">

          <mt-card-filters #filters [formFieldClass]="'align-items-start'" >
            <div class="col mt-3 mb-1">
              <mt-flags-autocomplete-chips #autoFlag
                [activeFlags]="flags"
                (flagSelectionChange)="filterFlagsChange($event)">
              </mt-flags-autocomplete-chips>
            </div>
            <div class="col mt-3 mb-1">
              <mt-tags-autocomplete-chips #autoTag
                [activeTags]="tags"
                (tagSelectionChange)="filterTagsChange($event)">
              </mt-tags-autocomplete-chips>
            </div>
            <div class="col mt-3 mb-1">
              <mt-genres-autocomplete-chips #autoGenre
                [activeGenres]="genres"
                (genreSelectionChange)="filterGenresChange($event)">
              </mt-genres-autocomplete-chips>
            </div>
            <div class="col mt-3 mb-1">
              <mt-artists-autocomplete-chips #autoArtist
                [activeArtists]="artists"
                (artistSelectionChange)="filterArtistChange($event)">
              </mt-artists-autocomplete-chips>
            </div>
            <div class="col mt-3 mb-1">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Owner Type</mat-label>
                <mat-select name="ownerType" [(ngModel)]="filter.ownerType" (ngModelChange)="ownerTypeFilterChanged($event)">
                  <mat-option value="">ALL</mat-option>
                  <mat-option *ngFor="let kl of ownerTypeKL" [value]="kl.key">{{kl.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- <div class="col mt-3">
              <mt-content-owner-autocomplete-chips
                (contentOwnerSelectionChange)="filterContentOwnerChange($event)"
                *ngIf="filter.ownerType === ownerType.CONTENT_OWNER">
              </mt-content-owner-autocomplete-chips>
            </div> -->
            <div class="col mt-3 mb-1">
              <mat-form-field floatLabel="always" class="w-100" appearance="outline">
                <mat-label>Thumb filter</mat-label>
                <mat-select name="missing" (selectionChange)="filterThumb($event)">
                  <mat-option value="">ALL</mat-option>
                  <mat-option value="true">Missing thumb</mat-option>
                  <mat-option value="false">Present thumb</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mt-card-filters>
          <mat-divider></mat-divider>

          <div class="data-loader" [hidden]="!loading">
            <mat-spinner [color]="'primary'"
                         [mode]="'indeterminate'"
                         [diameter]="30">
            </mat-spinner>
          </div>
          <div class="data-alert" [hidden]="loading || (dataSource.data && dataSource.data.length)">
            <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
          </div>
          <div class="table-scroll-wrapper" [hidden]="loading || !dataSource.data || !dataSource.data.length">
            <mat-table #table [dataSource]="dataSource" matSort matSortActive="title" matSortDirection="asc">

              <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef class="d-none d-md-flex">
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                color="primary">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="d-none d-md-flex">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggle(row) : null"
                                [checked]="selection.isSelected(row)" color="primary">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <img src="{{element.posters[0] ? element.posters[0] : '/assets/img/no_img.png'}}" alt="" width="35px"/>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span>{{element.title}}</span>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="preview">
                <mat-header-cell *matHeaderCellDef>Preview</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button *csHasPermission="RolePermission.CONTENT_VIDEOS_METADATA_READ" mat-icon-button color="primary" (click)="previewVideo(element.id)">
                    <i class="fas fa-play-circle"></i>
                  </button>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="duration">
                <mat-header-cell *matHeaderCellDef>Length</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <div class="data-loader justify-content-start pt-0 pb-0" *ngIf="!element?.duration">
                    <mat-spinner [color]="'primary'"
                                 [mode]="'indeterminate'"
                                 [diameter]="20">
                    </mat-spinner>
                  </div>
                  <span *ngIf="element?.duration">{{getTimeFromSeconds(element?.duration)}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="playlist">
                <mat-header-cell *matHeaderCellDef>Collection</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span>{{element?.numberOfPlaylists | number}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tags">
                <mat-header-cell *matHeaderCellDef>Tags</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.tags.length === 1">{{element.tags}}</span>
                  <div *ngIf="element.tags.length > 1" [matMenuTriggerFor]="tags">

                    <span *ngIf="element?.tags.length">
                      <button mat-icon-button color="primary" disableRipple="true" (click)="$event.stopPropagation()"
                              [matMenuTriggerFor]="tags">
                        <i class="fal fa-info-circle"></i>
                      </button>
                    </span>
                    <mat-menu #tags="matMenu" overlapTrigger class="data-list-menu">
                      <ul class="menu-info-list menu-info-list-400px" *ngIf="element?.tags.length">
                        <li *ngFor="let tag of element?.tags">
                          {{tag}}
                        </li>
                      </ul>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="genres">
                <mat-header-cell *matHeaderCellDef>Genres</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.genres.length === 1">{{element.genres}}</span>
                  <div *ngIf="element.genres.length > 1" [matMenuTriggerFor]="genres">

                    <span *ngIf="element?.genres.length">
                      <button mat-icon-button color="primary" disableRipple="true" (click)="$event.stopPropagation()"
                              [matMenuTriggerFor]="genres">
                        <i class="fal fa-info-circle"></i>
                      </button>
                    </span>
                    <mat-menu #genres="matMenu" overlapTrigger class="data-list-menu">
                      <ul class="menu-info-list menu-info-list-400px" *ngIf="element?.genres.length">
                        <li *ngFor="let genre of element?.genres">
                          {{genre}}
                        </li>
                      </ul>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="flags">
                <mat-header-cell *matHeaderCellDef>Flags</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.flags.length === 1" [matTooltip]="element.flags">{{element.flags}}</span>
                  <div *ngIf="element.flags.length > 1" [matMenuTriggerFor]="flags">

                    <span *ngIf="element?.flags.length">
                      <button mat-icon-button color="primary" disableRipple="true" (click)="$event.stopPropagation()"
                              [matMenuTriggerFor]="flags">
                        <i class="fal fa-info-circle"></i>
                      </button>
                    </span>
                    <mat-menu #flags="matMenu" overlapTrigger class="data-list-menu">
                      <ul class="menu-info-list menu-info-list-400px" *ngIf="element?.flags.length">
                        <li *ngFor="let flag of element?.flags">
                          {{flag}}
                        </li>
                      </ul>
                    </mat-menu>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="owner">
                <mat-header-cell *matHeaderCellDef>Owner</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.ownerType === ownerType.CONTENT_OWNER" [matTooltip]="element?.coVideo?.name">{{element?.coVideo?.name}}</span>
                  <span *ngIf="element.ownerType === ownerType.KM" [matTooltip]="platformTitle">{{ platformTitle }}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="artist">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Artist</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span [matTooltip]="element.artist">{{element.artist}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="companyOwner">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Company owner</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span>{{element.companyOwner}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="publishedAt">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="white-space:nowrap">Published at</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span [matTooltip]="element.publishedAt | date: 'HH:mm' : ''">{{element.publishedAt | date:'MM/dd/yyyy'}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="info">
                <mat-header-cell *matHeaderCellDef>Info</mat-header-cell>
                <mat-cell *matCellDef="let element" [matMenuTriggerFor]="info">

                  <span>
                      <button mat-icon-button color="primary" disableRipple="true" (click)="$event.stopPropagation()"
                              [matMenuTriggerFor]="info">
                        <i class="fal fa-info-circle"></i>
                      </button>
                    </span>
                  <mat-menu #info="matMenu" overlapTrigger class="data-list-menu">
                    <ul class="menu-info-list" (click)="$event.stopPropagation()">
                      <li>
                        <small>Tags:</small>
                        {{element?.tags}}
                      </li>
                      <li>
                        <small>Duration:</small>
                        {{getTimeFromSeconds(element?.duration)}}</li>
                      <li>
                        <small>Caption:</small>
                        {{element.caption}}</li>
                      <li>
                        <small>Quality:</small>
                        {{element?.quality}}</li>
                      <li>
                        <small>Views:</small>
                        {{element?.views}}</li>
                      <li>
                        <small>Comments:</small>
                        {{element?.comments}}</li>
                      <li>
                        <small>Rating:</small>
                        {{element?.rating}}</li>
                      <li>
                        <small>Genre:</small>
                        {{element?.genre}}</li>
                    </ul>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span [matTooltip]="element.createdOn | date: 'HH:mm' : ''">{{element.createdOn | date:'MM/dd/yyyy'}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="updatedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Updated</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span [matTooltip]="element.updatedOn | date: 'HH:mm' : ''">{{element.updatedOn | date:'MM/dd/yyyy'}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <button mat-icon-button disableRipple="true" *csHasPermission="RolePermission.CONTENT_VIDEOS_METADATA_EDIT" (click)="$event.stopPropagation()"
                          [matMenuTriggerFor]="menu">
                    <i class="far fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item matTooltip="Set metadata"
                      (click)="openMetadataDialog(element)">
                      <i class="far fa-info"></i> Metadata
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
          </div>
        </mat-card-content>
        <mat-card-footer [hidden]="loading || !dataSource.data || !dataSource.filteredData.length">
          <mat-paginator #paginator [pageSize]="filter.size" [pageSizeOptions]="[5, 10, 25, 50, 100]">
          </mat-paginator>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>

</div>
