import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
import {MainComponent} from './main.component';
import {appRoutes} from './app.route';
import {MaskSelectComponent} from './mask-select.component';
import {CoreModule} from './core/core.module';
import {Principal} from './core/auth/principal.service';
import {AuthInterceptor} from './core/auth/auth.interceptor';
import {ServicesModule} from './shared/services/services.module';
import {SharedComponentsModule} from './shared/components/components.module';
import {RouterExtService} from './core/services/router-ext.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MaskSelectComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    ServicesModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      appRoutes,
      {
    useHash: true, enableTracing: true
}
    )
  ],
  providers: [
    Principal,
    RouterExtService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
