import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../domain/page.model';
import { HttpUtils } from '../utils/http-utils';
import { Domain } from '../domain/domain.model';
import {
  DomainDpComplaince,
  DpBundleCompliance,
  DpBundleComplianceRequest,
  DpComplianceStatus,
  DPDomainCompliance,
} from '../domain/demand-partner-compliance.model';
import { first, map } from 'rxjs/operators';
import { PublisherDomainNew } from '../domain/publisher-domain-new.model';
import {PublisherDomain} from "../domain/publisher-domain.model";

export interface CreateUpdatePublisherDomainInput {
  publisherId: string;
  domainId?: string;
  publisherDomain: {
    url: PublisherDomainNew['domain']['url'];
  };
}

@Injectable({
  providedIn: 'root',
})
export class PublisherDomainsService {
  constructor(private httpClient: HttpClient) {}

  bundleCompliance(
    filter: DpBundleComplianceRequest
  ): Observable<Page<DpBundleCompliance>> {
    return this.httpClient.get<Page<DpBundleCompliance>>(
      `${environment.serviceUrl}/api/dps/compliances`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  domainsCompliance(filter: any): Observable<Page<DPDomainCompliance>> {
    return this.httpClient.get<Page<DPDomainCompliance>>(
      `${environment.serviceUrl}/api/dps/domain-compliances`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  countByStatus() {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/domain/dp/approval/count-by-status`
    );
  }

  updateDpCompliance(dpCompliance: DpBundleCompliance) {
    return this.httpClient.put(
      `${environment.serviceUrl}/api/domain/dp/approval/${dpCompliance.id}`,
      dpCompliance
    );
  }

  complianceDpStatuses(id: string): Observable<DomainDpComplaince[]> {
    return this.httpClient.get<DomainDpComplaince[]>(
      `${environment.serviceUrl}/api/dp/compliance/${id}`
    );
  }

  complianceDomainDpStatuses(id: string): Observable<DomainDpComplaince[]> {
    return this.httpClient.get<DomainDpComplaince[]>(
      `${environment.serviceUrl}/api/dps/domain-approvals/${id}`
    );
  }

  updateDpComplianceStatus(id: string, status: DpComplianceStatus) {
    return this.httpClient.put(
      `${environment.serviceUrl}/api/domain/dp/approval/${id}`,
      { status }
    );
  }

  updateDemandPartners(dp: DomainDpComplaince[], approvalId: string) {
    return this.httpClient.post(
      `${environment.serviceUrl}/api/domain/dp/approval/${approvalId}/status/batch`,
      dp
    );
  }

  updateDpApproval(dp: DomainDpComplaince[]) {
    return this.httpClient.put(`${environment.serviceUrl}/api/dp/approvals`, {
      approvals: dp,
    });
  }

  updateDpDomainApproval(dp: DomainDpComplaince[]) {
    return this.httpClient.put(
      `${environment.serviceUrl}/api/dps/domain-approvals/bulk`,
      { approvals: dp }
    );
  }

  updateAdsTxtSent(adsTxtSent: boolean, id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/domain/${id}`, {
      adsTxtSent,
    });
  }

  getAllPubDomains(filter: any): Observable<Page<Domain>> {
    return this.httpClient.get<Page<Domain>>(`${environment.serviceUrl}/api/publishers/report/domains`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  exportAllPublisherDomainsCSV(publisherId: string): Observable<string> {
    return this.httpClient
      .get<string>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/domains/csv/export`,
        {
          responseType: 'blob',
          header: {
            Accept: 'text/csv',
          },
        } as any
      )
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], {
            type: 'text/csv',
          });
          return URL.createObjectURL(blob);
        })
      );
  }

  updatePublisherDomainsCSV(publisherId: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient
      .put<any>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/domains/csv/reimport`,
        formData
      )
      .pipe(first());
  }

  addPublisherDomainsCSV(publisherId: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient
      .post<any>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/domains/csv/import`,
        formData
      )
      .pipe(first());
  }

  createPublisherDomain(publisherDomain: PublisherDomain) {
    console.log('createPublisherDomain:::', publisherDomain);
    return this.httpClient.post(`${environment.serviceUrl}/api/publishers/${publisherDomain.id.publisherId}/domains`, publisherDomain);
  }

  updatePublisherDomain(publisherDomain: PublisherDomain) {
    console.log('updatePublisherDomain:::', publisherDomain);
    return this.httpClient.put(`${environment.serviceUrl}/api/publishers/${publisherDomain.id.publisherId}/domains/${publisherDomain.id.domainId}`, publisherDomain);
  }

  downloadTemplateCsv(publisherId: string): Observable<string> {
    return this.httpClient
      .get<string>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/domains/download/domains-csv-template`,
        {
          responseType: 'blob',
          header: {
            Accept: 'text/csv',
          },
        } as any
      )
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], {
            type: 'text/csv',
          });
          return URL.createObjectURL(blob);
        })
      );
  }
}