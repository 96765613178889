export class SortUtils {
  public static propertyComparatorNumber(key: string, descending?: boolean) {
    return (a, b) => {
      if (!a[key] && !b[key]) {
        return 0;
      } else {
        if (!a[key]) {
          return descending ? 1 : -1;
        }
        if (!b[key]) {
          return descending ? -1 : 1;
        }
      }
      if (a[key] < b[key]) {
        return descending ? 1: -1;
      }
      if (a[key] > b[key]) {
        return descending ? -1 : 1;
      }
      return 0;
    };
  }
  public static propertyComparatorString(key: string, reverse?: boolean) {
    return (a, b) => {
      if (!a[key] && !b[key]) {
        return 0;
      } else {
        if (!a[key]) {
          return reverse ? 1 : -1;
        }
        if (!b[key]) {
          return reverse ? -1 : 1;
        }
      }
      if (a[key].toLowerCase() < b[key].toLowerCase()) {
        return reverse ? 1 : -1;
      }
      if (a[key].toLowerCase() > b[key].toLowerCase()) {
        return reverse ? -1 : 1;
      }
      return 0;
    };
  }
}
