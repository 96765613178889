<mat-sidenav-container>

  <mat-sidenav-content [ngClass]="sidenav.opened ? 'sidenav-opened':'sidenav-closed mx-0'">
    <div class="mobile-top-bar d-flex align-items-center" (scroll)="onScrollEvent($event)"
      [ngClass]=" {'fixed' : (scrollOffset > 0)} ">
      <div class="col p-0 d-flex">
        <button mat-icon-button (click)="sidenav.toggle()"><i class="fas fa-bars"></i></button>
        <a routerLink="/">
          <img src="{{ logoLightUrl }}" alt="logo" height="42">
        </a>
      </div>
      <div class="col p-0 text-right">
        <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>person</mat-icon><span class="ml-2">{{user.name}}</span><mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <div *csHasPermission="RolePermission.PUBLISHER_DASHBOARD_READ">
            <button mat-menu-item *ngFor="let pub of publishers" (click)="openDashboard('publisher', pub.id)">
              <i class="far fa-fw fa-house-user"></i>
              <span class="ml-2">{{pub.name}}</span>
            </button>
          </div>
          <div *csHasPermission="RolePermission.ADVERTISER_READ">
            <button mat-menu-item *ngIf="user?.advertiserId" (click)="openDashboard('advertiser', advertiser?.id)">
              <i class="fa fa-users"></i>
              <span class="ml-2">{{advertiser?.name}}</span>
            </button>
          </div>
          <button mat-menu-item (click)="logout()" class="sign-out-button">
            <i class="far fa-fw fa-sign-out text-danger"></i>
            <span class="ml-2 text-danger">Sign out</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <main class="main">
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>

  <mat-sidenav [mode]="(screenWidth > 992) ? 'side' : 'over'" [opened]="!screenWidth || screenWidth > 992"
    [class.sideOpened]="sidenav.opened" [class.sideClosed]="!sidenav.opened" #sidenav>

    <div class="sidenav-menu">
      <div class="mt-md-5 pt-md-3"></div>
      <!-- ADMIN USER-->
      <mat-accordion class="app-nav-accordion" #accordion="matAccordion" [multi]="false"
        *ngIf="UI.isAdmin || UI.isUser || UI.isReadOnlyAdmin || UI.isReadOnlyUser">
        <div *csHasPermission="RolePermission.HOME_READ">
          <div *csHasNetworkPermission (click)="closeSidenavMobile(sidenav); closeAllExpansionsPanels();">
            <a class="sidenav-menu-item" routerLink="/dashboard" routerLinkActive="active">
              <i class="far fa-house"></i>
              <span>Home</span>
            </a>
          </div>
        </div>
        <div *csHasPermission="RolePermission.SUPPLY_READ">
          <mat-expansion-panel *csHasNetworkPermission class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-boxes"></i>
                <span>Supply</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.SUPPLY_PUBLISHERS_READ">
                <a routerLink="/publishers" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Publishers"></i>
                  <span>Publishers</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.SUPPLY_PLACEMENTS_READ">
                <a routerLink="/placements" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Placements"></i>
                  <span>Placements</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.SUPPLY_APPS_READ">
                <a routerLink="/apps" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Apps"></i>
                  <span>Apps</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.DEMAND_COMPLIANCE_READ">
                <a routerLinkActive="active" routerLink="/demand/compliance">
                  <i class="fas fa-chevron-right" matTooltip="Domains"></i>
                  <span>Domains</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.TARGETING_READ">
                <a routerLink="/tailored-supply" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Tailored Supply"></i>
                  <span>Tailored Supply</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.SUPPLY_ADS_TXT_READ">
                <a routerLinkActive="active" routerLink="/ads-txt">
                  <i class="fas fa-chevron-right" matTooltip="Ads.txt"></i>
                  <span>Ads.txt</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.SUPPLY_ENDPOINT_GENERATOR_READ">
                <a routerLinkActive="active" routerLink="/endpoint-generator">
                  <i class="fas fa-chevron-right" matTooltip="Endpoint generator"></i>
                  <span>Endpoint generator</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </div>
        <div *csHasPermission="RolePermission.DEMAND_READ">
          <mat-expansion-panel *csHasNetworkPermission class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-user-visor"></i>
                Demand
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.DEMAND_PARTNERS_READ">
                <a routerLinkActive="active" routerLink="/demand/list">
                  <i class="fas fa-chevron-right" matTooltip="Partners"></i>
                  <span>Partners</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.DEMAND_CONNECTIONS_READ">
                <a routerLinkActive="active" routerLink="/integrations">
                  <i class="fas fa-chevron-right" matTooltip="Demand Integrations"></i>
                  <span>Integrations</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.DEMAND_DEALS_READ">
                <a routerLinkActive="active" routerLink="/deals">
                  <i class="fas fa-chevron-right" matTooltip="Deals"></i>
                  <span>Deals</span>
                </a>
              </li>
              <!-- <li *csHasPermission="RolePermission.DEMAND_DISCREPANCIES_READ">
              <a routerLinkActive="active" routerLink="/demand/discrepancies">
                <i class="fas fa-chevron-right" matTooltip="Discrepancies"></i>
                <span>Discrepancies</span>
              </a>
            </li> -->
            </ul>
          </mat-expansion-panel>
        </div>
        <div *csHasPermission="RolePermission.CAMPAIGNS_READ">
          <div *ngIf="isFeatureAllowed(NetworkAvailableFeatures.CAMPAIGN)">
          <mat-expansion-panel class="mat-elevation-z0" *csHasNetworkPermission [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-chart-network"></i>
                Campaigns
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li>
                <a routerLink="/advertiser" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Advertisers"></i>
                  <span>Advertisers</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.CAMPAIGNS_ADVERTISERS_READ">
                <a routerLink="/brand" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Brands"></i>
                  <span>Brands</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.CAMPAIGNS_ORDERS_READ">
                <a routerLink="/orders" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Orders"></i>
                  <span>Orders</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.CAMPAIGNS_LINE_ITEMS_READ">
                <a routerLinkActive="active" routerLink="/line-items/table">
                  <i class="fas fa-chevron-right" matTooltip="Line Items"></i>
                  <span>Line Items</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.CAMPAIGNS_CREATIVE_READ">
                <a [routerLink]="['/', AppRoutePath.CAMPAIGNS, AppRoutePath.CAMPAIGNS_CREATIVE_LIBRARY]"
                  routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Creatives"></i>
                  <span>Creatives</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
          </div>
        </div>
        <div *csHasPermission="RolePermission.REPORTING_READ">
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="false" *csHasNetworkPermission>
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-chart-bar"></i>
                Reporting
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.REPORTING_REPORT_BUILDER_READ">
                <a routerLink="/reports" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Report Builder"></i>
                  <span>Report Builder</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.REPORTING_PERFORMANCE_READ">
                <a routerLink="/performance" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Performance"></i>
                  <span>Performance</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.REPORTING_SCHEDULED_REPORTS_READ">
                <a routerLink="/scheduled-reports" routerLinkActive="active"
                  *csHasPermission="RolePermission.RB_SCHEDULE_CREATE">
                  <i class="fas fa-chevron-right" matTooltip="Saved reports"></i>
                  <span>Saved reports</span>
                </a>
              </li>
              <!-- Section was removed during the refactor 5.1.2024. module errors removed, path to module removed -->
              <!-- <li *csHasPermission="RolePermission.REPORTING_ERROR_REPORTS_READ">
              <a [routerLink]="['/', AppRoutePath.ERRORS]" routerLinkActive="active">
                <i class="fas fa-circle" matTooltip="Errors"></i>
                <span>Error Reports</span>
              </a>
            </li> -->
            </ul>
          </mat-expansion-panel>
        </div>
        <div *csHasPermission="RolePermission.A2V_READ">
          <div *ngIf="isFeatureAllowed(NetworkAvailableFeatures.A2V)">
          <mat-expansion-panel *csHasNetworkPermission class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="fal fa-tv-music" matTooltip="Audio to Video"></i>
                <span>A2V</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.A2V_ASSETS_READ">
                <a routerLink="/assets" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Assets"></i>
                  <span>Assets</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.A2V_BRANDS_READ" [hidden]="true">
                <a routerLink="/brands" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Brands"></i>
                  <span>Brands</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.A2V_NETWORK_TEMPLATE_READ">
                <a routerLink="/network-template" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Network template"></i>
                  <span>Network template</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
          </div>
        </div>
        <div *csHasPermission="RolePermission.TARGETING_READ">
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-bullseye" matTooltip="Targeting"></i>
                Targeting
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.TARGETING_READ">
                <a [routerLink]="['/', AppRoutePath.TARGETING]" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Collections"></i>
                  <span>Targeting Builder</span>
                </a>
              </li>
              <li *csHasPermission="RolePermission.FORECAST_READ">
                <a routerLink="/forecast" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Forecast"></i>
                  <span>Forecast</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </div>
        <!-- <div *csHasPermission="RolePermission.TARGETING_READ"
          (click)="closeSidenavMobile(sidenav); closeAllExpansionsPanels();">
          <a class="sidenav-menu-item" [routerLink]="['/', AppRoutePath.TARGETING]" routerLinkActive="active"
            (click)="closeSidenavMobile(sidenav); closeAllNetworkExpansionsPanels();">
            <i class="far fa-bullseye" matTooltip="Targeting"></i>
            <span>Targeting</span>
          </a>
        </div>
        <div *csHasPermission="RolePermission.FORECAST_READ"
          (click)="closeSidenavMobile(sidenav); closeAllExpansionsPanels();">
          <a class="sidenav-menu-item" routerLink="/forecast" routerLinkActive="active"
            (click)="closeSidenavMobile(sidenav); closeAllNetworkExpansionsPanels();">
            <i class="fa-regular fa-chart-simple" matTooltip="Forecast"></i>
            <span>Forecast</span>
          </a>
        </div> -->
        <div *csHasPermission="RolePermission.CONTENT_READ">
          <div *ngIf="isFeatureAllowed(NetworkAvailableFeatures.CONTENT)">
            <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title class="sidenav-menu-item">
                  <i class="far fa-books"></i>
                  Content
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
                <li *csHasPermission="RolePermission.CONTENT_COLLECTIONS_READ">
                  <a routerLink="/collections" routerLinkActive="active">
                    <i class="fas fa-chevron-right" matTooltip="Collections"></i>
                    <span>Collections</span>
                  </a>
                </li>
                <li *csHasPermission="RolePermission.CONTENT_VIDEOS_READ">
                  <a routerLink="/videos" routerLinkActive="active">
                    <i class="fas fa-chevron-right" matTooltip="Videos"></i>
                    <span>Videos</span>
                  </a>
                </li>
                <li *csHasPermission="RolePermission.CONTENT_CONTENT_OWNERS_READ">
                  <a routerLinkActive="active" routerLink="/content-owner">
                    <i class="fas fa-chevron-right" matTooltip="Content owners"></i>
                    <span>Content owners</span>
                  </a>
                </li>
              </ul>
            </mat-expansion-panel>
          </div>
        </div>
        <div *ngIf="isFeatureAllowed(NetworkAvailableFeatures.CAMPAIGN)">
        <ng-container *csHasPermission="RolePermission.WIDGET_TYPES_READ">
          <div (click)="closeSidenavMobile(sidenav); closeAllExpansionsPanels();">
            <a class="sidenav-menu-item " routerLink="/widget-types" routerLinkActive="active"
              (click)="closeSidenavMobile(sidenav); closeAllNetworkExpansionsPanels();">
              <i class="fal fa-magic" matTooltip="Widget types"></i>
              <span>Widget types</span>
            </a>
          </div>
        </ng-container>
        </div>
        <!-- <div *csHasPermission="RolePermission.AI_WEB_PLAYER_READ">
        <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="sidenav-menu-item">
              <i class="far fa-robot"></i>
              AI Web Player
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
            <li *csHasPermission="RolePermission.AI_WEB_PLAYER_DOMAINS_READ">
              <a routerLink="/ai-web-player" routerLinkActive="active">
                <i class="fas fa-chevron-right" matTooltip="Domains"></i>
                <span>Domains</span>
              </a>
            </li>
          </ul>
        </mat-expansion-panel>
      </div> -->
        <div *csHasPermission="RolePermission.ADMIN_READ">
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-tools"></i>
                Admin
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.USERS_USERS_READ">
                <a routerLink="/users" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Users"></i>
                  <span>Users</span>
                </a>
              </li>
              <!-- <li *csHasPermission="RolePermission.USERS_ROLES_READ">
              <a routerLink="/roles" routerLinkActive="active">
                <i class="fas fa-chevron-right" matTooltip="Roles"></i>
                <span>Roles</span>
              </a>
            </li> -->
              <li *csHasPermission="RolePermission.CHANGELOG_READ">
                <a routerLink="/changelog" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Changelog"></i>
                  <span>Changelog</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
      <!-- ADMIN USER END-->

      <!--SUPER ADMIN USER START-->
      <mat-accordion class="app-nav-accordion" #accordion="matAccordion" [multi]="false" *ngIf="UI.isSuperAdmin">
        <div *csHasPermission="RolePermission.HOME_READ">
          <div (click)="closeSidenavMobile(sidenav); closeAllExpansionsPanels();">
            <a class="sidenav-menu-item" routerLink="/dashboard" routerLinkActive="active">
              <i class="far fa-house"></i>
              <span>Home</span>
            </a>
          </div>
        </div>
        <div *csHasPermission="RolePermission.HOME_READ">
          <div (click)="closeSidenavMobile(sidenav); closeAllExpansionsPanels();">
            <a class="sidenav-menu-item" routerLink="/networks" routerLinkActive="active">
              <i class="fa-regular fa-network-wired"></i>
              <span>Networks</span>
            </a>
          </div>
        </div>
        <div *csHasPermission="RolePermission.AI_WEB_PLAYER_READ">
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-robot"></i>
                AI Web Player
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.AI_WEB_PLAYER_DOMAINS_READ">
                <a routerLink="/ai-web-player" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Domains"></i>
                  <span>Domains</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </div>
        <div *csHasPermission="RolePermission.REPORTING_READ">
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-chart-bar"></i>
                Reporting
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.REPORTING_REPORT_BUILDER_READ">
                <a routerLink="/reports" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Report Builder"></i>
                  <span>Report Builder</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </div>
        <div *csHasPermission="RolePermission.ADMIN_READ">
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-menu-item">
                <i class="far fa-tools"></i>
                Admin
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sidenav-submenu" (click)="closeSidenavMobile(sidenav)">
              <li *csHasPermission="RolePermission.USERS_USERS_READ">
                <a routerLink="/users" routerLinkActive="active">
                  <i class="fas fa-chevron-right" matTooltip="Users"></i>
                  <span>Users</span>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </div>
      </mat-accordion>

    </div>

  </mat-sidenav>
</mat-sidenav-container>