import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {Embed} from '../../domain/embed.model';
import {EmbedsService} from '../../services/embeds.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-embed-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass">
      <mat-label >{{label ? label : 'Choose embed'}}</mat-label>
      <input matInput
             #name
             aria-label="Embed"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="'Choose embed'">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)" 
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class EmbedAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public name: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  items: Embed[];

  @Output()
  embedSelected = new EventEmitter<Embed>();

  serverSearching = false;

  filteredItems: Subject<Embed[]>;
  filterCtrl = new UntypedFormControl();
  selectedEmbed;
  allEmbeds: Embed[] = [];

  constructor(private service: EmbedsService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedEmbed) {
          this.selectedEmbed = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.allEmbeds.length === 0 ? this.getAllEmbeds() : this.searchEmbed();
        }
      });
  }

  resetInput() {
    this.selectedEmbed = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.name) {
      const e = Embed.of(this.name);
      this.selectedEmbed = e;
      this.filterCtrl.setValue(e);
      this.emit();
    }
  }

  display(e?: Embed) {
    return e ? e.name : undefined;
  }

  optionSelected($event) {
    this.selectedEmbed = $event.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  private emit() {
    this.embedSelected.emit(this.selectedEmbed);
  }

  getAllEmbeds() {
    this.service.getAllEmbeds().subscribe(
      response => {
        this.allEmbeds = response;
        this.searchEmbed();
      },
      error => {
        console.log(error);
      }
    );
  }

  searchEmbed() {
    if (this.allEmbeds.length > 0) {
      const search = this.allEmbeds.filter(e => e.name.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(search.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }
}
