import {PageRequest} from './page-request';
import {User} from '../../core/auth/user.model';
import {Playlist} from './playlist.model';
import {KeyLabel} from './key-label.model';

export class Channel {
  id?: string;
  title?: string;
  description?: string;
  createdOn: string;
  updatedOn: string;
  createdBy: User;
  imageUrl?: string;
  adsConfiguration: AdsConfiguration;
  status: ChannelStatus;
  channelPlaylists: Playlist[];
  active: boolean;
  numberOfEmbeds: number;
  categories?: string[];
  duration?: number;
  channelId?: string;
  shortDescription?: string;
  longDescription?: string;
}

export class ChannelListRequest extends PageRequest {
  title: string;
  active: boolean;
  status: ChannelStatus | '';
  categories?: string[];
  ids?: string[];
}

export class AdsConfiguration  {
  adFrequency: number;
  adsDuration: number;
}

export enum ChannelStatus {
  QUEUED = 'QUEUED',
  LIVE = 'LIVE',
}

export const ChannelStatusLabels = {
  QUEUED: 'Queued',
  LIVE: 'Live',
};

export const ChannelStatusLabelKL = () =>
  (Object.keys(ChannelStatus).map(key => new KeyLabel(<ChannelStatus> key, ChannelStatusLabels[key])));
