import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ResetPasswordRequest, User} from '../../core/auth/user.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {

    resetPasswordUrl: string = environment.serviceUrl + '/api/forgot-password'
    constructor(private httpClient: HttpClient) {
    }

    sendEmailToResetPassword(email): Observable<any> {
        return this.httpClient.post<any>(this.resetPasswordUrl,{
            "email": email
        });
    }

    resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<any> {
        return this.httpClient.post<ResetPasswordRequest>(this.resetPasswordUrl + '/confirm-password-reset',{
            "token": resetPasswordRequest.token,
            "newPassword": resetPasswordRequest.newPassword,
            "key": resetPasswordRequest.key
        });
    }

}
