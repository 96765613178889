import { Injectable } from '@angular/core';
import { Principal } from '../principal.service';
import { UserRole, UserRoleNew } from '../user-role.model';

export enum RolePermission {
  //old permissions
  PLACEMENT_CREATE = 'PLACEMENT_CREATE',
  PLACEMENT_EDIT = 'PLACEMENT_EDIT',
  PLACEMENT_ARCHIVE = 'PLACEMENT_ARCHIVE',
  APP_CSV_IMPORT = 'APP_CSV_IMPORT',
  APP_CREATE = 'APP_CREATE',
  APP_EDIT = 'APP_EDIT',
  APP_ARCHIVE = 'APP_ARCHIVE',
  APP_REQUEST_CREATE = 'APP_REQUEST_CREATE',
  APP_REQUEST_EDIT = 'APP_REQUEST_EDIT',
  APP_REQUEST_DISCONNECT = 'APP_REQUEST_DISCONNECT',
  APP_DP_IDS_CREATE = 'APP_DP_IDS_CREATE',
  ADSTXT_RECORDS_CREATE = 'ADSTXT_RECORDS_CREATE',
  ADSTXT_RECORDS_EDIT = 'ADSTXT_RECORDS_EDIT',
  ADSTXT_RECORDS_ARCHIVE = 'ADSTXT_RECORDS_ARCHIVE',
  ADSTXT_APPS_CRAWL = 'ADSTXT_APPS_CRAWL',
  ADSTXT_DOMAINS_CRAWL = 'ADSTXT_DOMAINS_CRAWL',
  PUBLISHER_CREATE = 'PUBLISHER_CREATE',
  PUBLISHER_EDIT = 'PUBLISHER_EDIT',
  PUBLISHER_ADSTXT_CRAWL = 'PUBLISHER_ADSTXT_CRAWL',
  PUBLISHER_A2V_CREATE = 'PUBLISHER_A2V_CREATE',
  PUBLISHER_APPROVALS_CREATE = 'PUBLISHER_APPROVALS_CREATE',
  DOMAIN_CREATE = 'DOMAIN_CREATE',
  DOMAIN_EDIT = 'DOMAIN_EDIT',
  DOMAIN_ARCHIVE = 'DOMAIN_ARCHIVE',
  NETWORK_CREATE = 'NETWORK_CREATE',
  NETWORK_EDIT = 'NETWORK_EDIT',
  NETWORK_ARCHIVE = 'NETWORK_ARCHIVE',
  DP_CREATE = 'DP_CREATE',
  DP_EDIT = 'DP_EDIT',
  DP_ARCHIVE = 'DP_ARCHIVE',
  DP_IMPORT_CSV = 'DP_IMPORT_CSV',
  CONNECTION_CREATE = 'CONNECTION_CREATE',
  CONNECTION_EDIT = 'CONNECTION_EDIT',
  CONNECTION_DUPLICATE = 'CONNECTION_DUPLICATE',
  COMPLIANCE_DOMAIN_CREATE = 'COMPLIANCE_DOMAIN_CREATE',
  COMPLIANCE_DOMAIN_EDIT = 'COMPLIANCE_DOMAIN_EDIT',
  SOURCES_CREATE = 'SOURCES_CREATE',
  SOURCES_EDIT = 'SOURCES_EDIT',
  SOURCES_ARCHIVE = 'SOURCES_ARCHIVE',
  SOURCES_TEMPLATE_CREATE = 'SOURCES_TEMPLATE_CREATE',
  SOURCES_TEMPLATE_EDIT = 'SOURCES_TEMPLATE_EDIT',
  SOURCES_TEMPLATE_ARCHIVE = 'SOURCES_TEMPLATE_ARCHIVE',
  IMAGE_CREATIVES_CREATE = 'IMAGE_CREATIVES_CREATE',
  IMAGE_CREATIVES_EDIT = 'IMAGE_CREATIVES_EDIT',
  IMAGE_CREATIVES_ARCHIVE = 'IMAGE_CREATIVES_ARCHIVE',
  ASSETS_CREATE = 'ASSETS_CREATE',
  ASSETS_EDIT = 'ASSETS_EDIT',
  GLOBAL_BRAND_TEMPLATE = 'GLOBAL_BRAND_TEMPLATE',
  BRAND_CREATE = 'BRAND_CREATE',
  BRAND_EDIT = 'BRAND_EDIT',
  NETWORK_TEMPLATE_CREATE = 'NETWORK_TEMPLATE_CREATE',
  COLLECTION_CREATE = 'COLLECTION_CREATE',
  COLLECTION_EDIT = 'COLLECTION_EDIT',
  VIDEOS_CREATE = 'VIDEOS_CREATE',
  VIDEOS_EDIT = 'VIDEOS_EDIT',
  VIDEOS_METADATA_CREATE = 'VIDEOS_METADATA_CREATE',
  CONTENT_OWNER_CREATE = 'CONTENT_OWNER_CREATE',
  CONTENT_OWNER_EDIT = 'CONTENT_OWNER_EDIT',
  USERS_CREATE = 'USERS_CREATE',
  USERS_EDIT = 'USERS_EDIT',
  RB_SCHEDULE_CREATE = 'RB_SCHEDULE_CREATE',
  CUSTOM_REPORTS_CREATE = 'CUSTOM_REPORTS_CREATE',
  ENDPOINT_GENERATOR_CREATE = 'ENDPOINT_GENERATOR_CREATE',
  OANDO_APPS_CREATE = 'OANDO_APPS_CREATE',
  OANDO_APPS_EDIT = 'OANDO_APPS_EDIT',
  CHANGELOG_EXPORT = 'CHANGELOG_EXPORT',
  ADVERTISER_CREATE = 'ADVERTISER_CREATE',
  ADVERTISER_EDIT = 'ADVERTISER_EDIT',
  ADVERTISER_ARCHIVE = 'ADVERTISER_ARCHIVE',
  MASTER_TABLE_ACTIONS = 'MASTER_TABLE_ACTIONS',
  FORECAST_READ = "FORECAST_READ",

  //new permissions
  ALL = '*:*',
  ADMIN_READ = "admin:read",
  HOME_READ = "home:read",
  HOME_PERFORMANCE_ANALYZER_READ = "home.performance-analyzer:read",
  SUPPLY_READ = "supply:read",
  SUPPLY_PLACEMENTS_READ = "supply.placements:read",
  SUPPLY_PLACEMENTS_CREATE = "supply.placements:create",
  SUPPLY_PLACEMENTS_UPDATE = "supply.placements:update",
  SUPPLY_PLACEMENTS_ARCHIVE = "supply.placements:archive",
  SUPPLY_PLACEMENTS_ENDPOINT_GENERATOR_GENERATE = "supply.placements.endpoint-generator:generate",
  SUPPLY_PLACEMENTS_GENERAL_GENERATE = "supply.placements.general:generate",
  SUPPLY_PUBLISHERS_OVERVIEW_READ = "supply.publishers.overview:read",
  SUPPLY_PUBLISHERS_APP_REQUESTS_READ = "supply.publishers.app-requests:read",
  SUPPLY_PUBLISHERS_CHANGELOG_READ = "supply.publishers.changelog:read",
  SUPPLY_PUBLISHERS_APPS_ADD = "supply.publishers.apps:add",
  SUPPLY_PUBLISHERS_APPS_MANAGE = "supply.publishers.apps:manage",
  SUPPLY_PUBLISHERS_APPS_EDIT = "supply.publishers.apps:edit",
  SUPPLY_PUBLISHERS_APPS_ARCHIVE = "supply.publishers.apps:archived",
  SUPPLY_PUBLISHERS_DOMAINS_ADD = "supply.publishers.domains:add",
  SUPPLY_PUBLISHERS_DOMAINS_MANAGE = "supply.publishers.domains:manage",
  SUPPLY_PUBLISHERS_DOMAINS_EDIT = "supply.publishers.domains:edit",
  SUPPLY_PUBLISHERS_DOMAINS_ARCHIVE = "supply.publishers.domains:archive",
  SUPPLY_PUBLISHERS_A2V_READ = 'supply.publishers.a2v:read',
  SUPPLY_PUBLISHERS_A2V_SAVE = 'supply.publishers.a2v:save',
  SUPPLY_APPS_APPS_READ = "supply.apps.apps:read",
  SUPPLY_APPS_APPS_EDIT = "supply.apps.apps:edit",
  SUPPLY_APPS_APPS_ARCHIVE = "supply.apps.apps:archive",
  SUPPLY_APPS_APPS_IMPORT = "supply.apps.apps:import",
  SUPPLY_APPS_APPS_EXPORT = "supply.apps.apps:export",
  SUPPLY_APPS_APP_REQUESTS_EXPORT = "supply.apps.app-requests:export",
  SUPPLY_APPS_APP_REQUESTS_READ = "supply.apps.app-requests:read",
  SUPPLY_APPS_COMPLIANCE_READ = "supply.apps.compliance:read",
  SUPPLY_APPS_COMPLIANCE_EDIT = "supply.apps.compliance:edit",
  SUPPLY_APPS_READ = "supply.apps:read",
  SUPPLY_APPS_UPDATE = "supply.apps:update",
  SUPPLY_APPS_IMPORT = "supply.apps:import",
  SUPPLY_APPS_EXPORT = "supply.apps:export",
  SUPPLY_APP_REQUESTS_READ = "supply.app-requests:read",
  SUPPLY_COMPLIANCE_READ = "supply.compliance:read",
  SUPPLY_TARGETING_READ = "supply.targeting:read",
  SUPPLY_TARGETING_CREATE = "supply.targeting:create",
  SUPPLY_TARGETING_NAME_UPDATE = "supply.targeting.name:update",
  SUPPLY_ADS_TXT_READ = "supply.ads-txt:read",
  SUPPLY_ADS_TXT_RECORDS_READ = "supply.ads-txt.records:read",
  SUPPLY_ADS_TXT_RECORDS_CREATE = "supply.ads-txt.records:create",
  SUPPLY_ADS_TXT_RECORDS_UPDATE = "supply.ads-txt.records:update",
  SUPPLY_ADS_TXT_RECORDS_DELETE = "supply.ads-txt.records:delete",
  SUPPLY_ADS_TXT_RECORDS_EXPORT = "supply.ads-txt.records:export",
  SUPPLY_ADS_TXT_APPS_READ = "supply.ads-txt.apps:read",
  SUPPLY_ADS_TXT_DOMAINS_READ = "supply.ads-txt.domains:read",
  SUPPLY_ADS_TXT_RECORDS_EDIT = "supply.ads-txt.records:edit",
  SUPPLY_ADS_TXT_RECORDS_COPY = "supply.ads-txt.records:copy",
  SUPPLY_ADS_TXT_APPS_CRAWL = "supply.ads-txt.apps:crawl",
  SUPPLY_ADS_TXT_DOMAINS_CRAWL = "supply.ads-txt.domains:crawl",
  SUPPLY_PUBLISHERS_READ = "supply.publishers:read",
  SUPPLY_PUBLISHERS_CREATE = "supply.publishers:create",
  SUPPLY_PUBLISHERS_EDIT = "supply.publishers:edit",
  SUPPLY_PUBLISHERS_DELETE = "supply.publishers:delete",
  SUPPLY_PUBLISHERS_APPS_READ = "supply.publishers.apps:read",
  SUPPLY_PUBLISHERS_APPS_CREATE = "supply.publishers.apps:create",
  SUPPLY_PUBLISHERS_APPS_UPDATE = "supply.publishers.apps:update",
  SUPPLY_PUBLISHERS_DOMAINS_READ = "supply.publishers.domains:read",
  SUPPLY_PUBLISHERS_DOMAINS_CREATE = "supply.publishers.domains:create",
  SUPPLY_PUBLISHERS_DOMAINS_UPDATE = "supply.publishers.domains:update",
  SUPPLY_PUBLISHERS_DOMAINS_EXPORT = "supply.publishers.domains:export",
  SUPPLY_PUBLISHERS_PLACEMENTS_READ = "supply.publishers.placements:read",
  SUPPLY_PUBLISHERS_PLACEMENTS_CREATE = "supply.publishers.placements:create",
  SUPPLY_PUBLISHERS_PLACEMENTS_EDIT = "supply.publishers.placements:edit",
  SUPPLY_PUBLISHERS_PLACEMENTS_ARCHIVE = "supply.publishers.placements:archive",
  SUPPLY_PUBLISHERS_PLACEMENTS_ENDPOINT_GENERATE = "supply.publishers.placements.enpoint:generate",
  SUPPLY_PUBLISHERS_GENERAL_GENERATE = "supply.publishers.general:generate",
  SUPPLY_PUBLISHERS_ENDPOINT_GENERATOR_GENERATE = "supply.publishers.endpoint-generator:generate",
  SUPPLY_PLACEMENTS_EDIT = "supply.placements:edit",
  SUPPLY_PLACEMENTS_ENDPOINT_GENERATE = "supply.placements.endpoint:generate",
  SUPPLY_ENDPOINT_GENERATOR_READ = "supply.endpoint-generator:read",
  SUPPLY_ENDPOINT_GENERATOR_GENERATE = "supply.endpoint-generator:generate",
  DEMAND_READ = "demand:read",
  DEMAND_PARTNERS_READ = "demand.partners:read",
  DEMAND_PARTNERS_CREATE = "demand.partners:create",
  DEMAND_PARTNERS_EDIT = "demand.partners:edit",
  DEMAND_PARTNERS_EXPORT = "demand.partners:export",
  DEMAND_PARTNERS_IMPORT = "demand.partners:import",
  DEMAND_CONNECTIONS_READ = "demand.connections:read",
  DEMAND_CONNECTIONS_CREATE = "demand.connections:create",
  DEMAND_CONNECTIONS_EDIT = "demand.connections:edit",
  DEMAND_CONNECTIONS_DUPLICATE = "demand.connections:duplicate",
  DEMAND_CONNECTIONS_CHANGELOG_READ = "demand.connections.changelog:read",
  DEMAND_CONNECTIONS_OVERVIEW_READ = "demand.connections.overview:read",
  DEMAND_CONNECTIONS_PLACEMENTS_READ = "demand.connctions.placements:read",
  DEMAND_CONNECTIONS_PLACEMENTS_EDIT = "demand.connctions.placements:edit",
  DEMAND_CONNECTIONS_PLACEMENTS_ARCHIVE = "demand.connections.placements:archive",
  DEMAND_CONNECTIONS_PLACEMENTS_ENDPOINT_GENERATE = "demand.connections.placements.endpoint:generate",
  DEMAND_COMPLIANCE_DPS_EDIT = "demand.compliance.dps:edit",
  DEMAND_COMPLIANCE_DPS_READ = "demand.compliance.dps:read",
  DEMAND_DEALS_CREATE = "demand.deals:create",
  DEMAND_DEALS_EDIT = "demand.deals:edit",
  DEMAND_DEALS_ARCHIVE = "demand.deals:archive",
  DEMAND_COMPLIANCE_READ = "demand.compliance:read",
  DEMAND_COMPLIANCE_EDIT = "demand.compliance:edit",
  DEMAND_DISCREPANCIES_READ = "demand.discrepancies:read",
  DEMAND_DEALS_READ = "demand.deals:read",
  CAMPAIGNS_READ = "campaigns:read",
  CAMPAIGNS_ORDERS_READ = "campaigns.orders:read",
  CAMPAIGNS_ORDERS_CREATE = "campaigns.orders:create",
  CAMPAIGNS_ORDERS_EDIT = "campaigns.orders:edit",
  CAMPAIGNS_ORDERS_OVERVIEW_READ = "campaigns.orders.overview:read",
  CAMPAIGNS_ORDERS_OVERVIEW_EDIT = "campaigns.orders.overview:edit",
  CAMPAIGNS_ORDERS_OVERVIEW_ARCHIVE = "campaigns.orders.overview:archive",
  CAMPAIGNS_ORDERS_OVERVIEW_DUPLICATE = "campaigns.orders.overview:duplicate",
  CAMPAIGNS_ORDERS_OVERVIEW_EXTEND = "campaigns.orders.overview:extend",
  CAMPAIGNS_ORDERS_OVERVIEW_LINE_ITEM_CREATE = "campaigns.orders.overview.line-item:create",
  CAMPAIGNS_ORDERS_DISCUSSION_CREATE = "campaigns.orders.discussion:create",
  CAMPAIGNS_ORDERS_DISCUSSION_READ = "campaigns.orders.discussion:read",
  CAMPAIGNS_ORDERS_DISCUSSION_SEND = "campaigns.orders.discussion:send",
  CAMPAIGNS_LINE_ITEMS_CREATE = "campaigns.line-items:create",
  CAMPAIGNS_LINE_ITEMS_READ = "campaigns.line-items:read",
  CAMPAIGNS_LINE_ITEMS_EDIT = "campaigns.line-items:edit",
  CAMPAIGNS_LINE_ITEMS_EXTEND = "campaigns.line-items:extend",
  CAMPAIGNS_LINE_ITEMS_DUPLICATE = "campaigns.line-items:duplicate",
  CAMPAIGNS_LINE_ITEMS_ARCHIVE = "campaigns.line-items:archive",
  CAMPAIGNS_LINE_ITEMS_CHANGELOG_READ = "campaigns.line-items.changelog:read",
  CAMPAIGNS_CREATIVE_VIDEO_CREATE = "campaigns.creative.video:create",
  CAMPAIGNS_CREATIVE_VIDEO_READ = "campaigns.creative.video:read",
  CAMPAIGNS_CREATIVES_VIDEO_EDIT = "campaigns.creative.video:edit",
  CAMPAIGNS_CREATIVES_VIDEO_ARCHIVE = "campaigns.creative.video:archive",
  CAMPAIGNS_CREATIVE_DISPLAY_CREATE = "campaigns.creative.display:create",
  CAMPAIGNS_CREATIVE_DISPLAY_READ = "campaigns.creative.display:read",
  CAMPAIGNS_CREATIVES_DISPLAY_EDIT = "campaigns.creative.display:edit",
  CAMPAIGNS_CREATIVES_DISPLAY_ARCHIVE = "campaigns.creative.display:archive",
  CAMPAIGNS_CREATIVE_READ = "campaigns.creative:read",
  CAMPAIGNS_CREATIVE_ADD = "campaigns.creative:add",
  CAMPAIGNS_CREATIVE_UPDATE = "campaigns.creative:update",
  CAMPAIGNS_CREATIVE_ARCHIVE = "campaigns.creative:archive",
  CAMPAIGNS_ADVERTISERS_READ = "campaigns.advertisers:read",
  CAMPAIGNS_ADVERTISERS_CREATE = "campaigns.advertisers:create",
  CAMPAIGNS_ADVERTISERS_EDIT = "campaigns.advertisers:edit",
  REPORTING_READ = "reporting:read",
  REPORTING_REPORT_BUILDER_READ = "reporting.report-builder:read",
  REPORTING_REPORT_BUILDER_RUN = "reporting.report-builder:run",
  REPORTING_PERFORMANCE_READ = "reporting.performance:read",
  REPORTING_PERFORMANCE_GO = "reporting.performance:go",
  REPORTING_SCHEDULED_REPORTS_READ = "reporting.scheduled-reports:read",
  REPORTING_SCHEDULED_REPORTS_CREATE = "reporting.scheduled-reports:create",
  REPORTING_SCHEDULED_REPORTS_EDIT = "reporting.scheduled-reports:edit",
  REPORTING_SCHEDULED_REPORTS_DELETE = "reporting.scheduled-reports:delete",
  REPORTING_ERROR_REPORTS_READ = "reporting.error-reports:read",
  TARGETING_READ = "targeting:read",
  TARGETING_CREATE = "targeting:create",
  TARGETING_EDIT = "targeting:edit",
  TARGETING_ARCHIVE = "targeting:archive",
  CHANGELOG_READ = "admin.changelog:read",
  CONTENT_READ = "content:read",
  CONTENT_COLLECTIONS_READ = "content.collections:read",
  CONTENT_COLLECTIONS_CREATE = "content.collections:create",
  CONTENT_COLLECTIONS_UPDATE = "content.collections:update",
  CONTENT_COLLECTIONS_EDIT = "content.collections:edit",
  CONTENT_COLLECTIONS_DELETE = "content.collections:delete",
  CONTENT_COLLECTIONS_COLLECTION_VIDEOS_ADD = "content.collections.collection-videos:add",
  CONTENT_COLLECTIONS_COLLECTION_VIDEOS_DELETE = "content.collections.collection-videos:delete",
  CONTENT_COLLECTIONS_COLLECTION_VIDEOS_MRSS_EXPORT = "content.collections.collection-videos.mrss:export",
  CONTENT_VIDEOS_ADD_TO_COLLECTION = "content.videos:add-to-collection",
  CONTENT_VIDEOS_REMOVE_OWNER = "content.videos:remove-owner",
  CONTENT_VIDEOS_METADATA_READ = "content.videos.metadata:read",
  CONTENT_VIDEOS_METADATA_EDIT = "content.videos.metadata:edit",
  CONTENT_CONTENT_OWNERS_EDIT = "content.content-owners:edit",
  CONTENT_VIDEOS_EDIT = "content.videos:edit",
  CONTENT_VIDEOS_READ = "content.videos:read",
  CONTENT_VIDEOS_LIST = "content.videos:list",
  CONTENT_VIDEOS_INGEST = "content.videos:ingest",
  CONTENT_VIDEOS_UPDATE = "content.videos:update",
  CONTENT_CONTENT_OWNERS_READ = "content.content-owners:read",
  CONTENT_CONTENT_OWNERS_CREATE = "content.content-owners:create",
  CONTENT_CONTENT_OWNERS_UPDATE = "content.content-owners:update",
  USERS_USERS_READ = "admin.users:read",
  USERS_USERS_WRITE = "admin.users:write",
  USERS_USERS_EDIT = "admin.users:edit",
  USERS_USERS_DELETE = "admin.users:delete",
  USERS_ROLES_READ = "admin.roles:read",
  USERS_ROLES_WRITE = "admin.roles:write",
  USERS_ROLES_EDIT = "admin.roles:edit",
  USERS_ROLES_DELETE = "admin.roles:delete",
  CAMPAIGNS_ORDERS_ORDER_CREATE = 'campaigns.orders.order:create',
  CAMPAIGNS_ORDERS_ORDER_UPDATE = 'campaigns.orders.order:update',
  CAMPAIGNS_ORDERS_ORDER_ARCHIVE = 'campaigns.orders.order:archive',
  CAMPAIGNS_ORDERS_LINEITEM_READ = 'campaigns.orders.lineitem:read',
  CAMPAIGNS_ORDERS_LINEITEM_CREATE = 'campaigns.orders.lineitem:create',
  CAMPAIGNS_ORDERS_LINEITEM_UPDATE = 'campaigns.orders.lineitem:update',
  CAMPAIGNS_ORDERS_LINEITEM_DUPLICATE = 'campaigns.orders.lineitem:duplicate',
  CAMPAIGNS_ORDERS_LINEITEM_ARCHIVE = 'campaigns.orders.lineitem:archive',
  CAMPAIGNS_CREATIVES_READ = 'campaigns.creatives:read',
  CAMPAIGNS_CREATIVES_CREATE = 'campaigns.creatives:create',
  CAMPAIGNS_CREATIVES_UPDATE = 'campaigns.creatives:update',
  CAMPAIGNS_CREATIVES_ARCHIVE = 'campaigns.creatives:archive',
  PMP_CREATE = 'PMP_CREATE',
  PMP_EDIT = 'PMP_EDIT',
  PMP_ARCHIVE = 'PMP_ARCHIVE',
  PUBLISHER_DASHBOARD_READ = 'publisher-dashboard:read',
  PUBLISHER_DASHBOARD_HOME_READ = 'publisher-dashboard.home:read',
  PUBLISHER_DASHBOARD_REPORTING_READ = 'publisher-dashboard.reporting:read',
  WIDGET_TYPES_READ = 'widget-types:read',
  WIDGET_TYPES_CREATE = 'widget-types:create',
  WIDGET_TYPES_EDIT = 'widget-types:edit',
  WIDGET_TYPES_EXPORT = 'widget-types:export',
  AI_WEB_PLAYER_READ = 'ai-web-player:read',
  AI_WEB_PLAYER_DOMAINS_READ = 'ai-web-player.domains:read',
  AI_WEB_PLAYER_DOMAINS_CREATE = 'ai-web-player.domains:create',
  AI_WEB_PLAYER_DOMAINS_EDIT = 'ai-web-player.domains:edit',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_READ = 'ai-web-player.domains.single-domain:read',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_CREATE = 'ai-web-player.domains.single-domain:create',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_EDIT = 'ai-web-player.domains.single-domain:edit',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_SINGLE_PAGE_READ = 'ai-web-player.domains.single-domain.single-page:read',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_SINGLE_PAGE_SCRAPE = 'ai-web-player.domains.single-domain.single-page:scrape',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_SINGLE_PAGE_DELETE = 'ai-web-player.domains.single-domain.single-page:delete',
  AI_WEB_PLAYER_DOMAINS_SINGLE_DOMAIN_SINGLE_PAGE_SCRAPE_DATA_READ = 'ai-web-player.domains.single-domain.single-page.scrape-data:read',
  A2V_READ = 'a2v:read',
  A2V_ASSETS_READ = 'a2v.assets:read',
  A2V_ASSETS_UPLOAD = 'a2v.assets:upload',
  A2V_ASSETS_EDIT = 'a2v.assets:edit',
  A2V_BRANDS_READ = 'a2v.brands:read',
  A2V_BRANDS_CREATE = 'a2v.brands:create',
  A2V_BRANDS_GLOBAL_BRAND_TEMPLATE_READ = 'a2v.brands.global-brand-template:read',
  A2V_BRANDS_GLOBAL_BRAND_TEMPLATE_EDIT = 'a2v.brands.global-brand-template:edit',
  A2V_BRANDS_EDIT = 'a2v.brands.edit',
  A2V_NETWORK_TEMPLATE_READ = 'a2v.network-template:read',
  A2V_NETWORK_TEMPLATE_SAVE = 'a2v.network-template:save',
  ADVERTISER_READ = 'advertiser-dashboard:read'
}

const RolesWithPermissions = {

  [UserRoleNew.SUPER_ADMIN]: Object.values(RolePermission),
  [UserRoleNew.ADMIN]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    return !(rolePermission.includes('ai-web-player') || rolePermission.includes('advertiser-dashboard:read'))
  }),
  [UserRoleNew.ADMIN_READ_ONLY]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    // Exclude any 'ai_webplayer' related permissions
    if (rolePermission.includes('ai-web-player') || rolePermission.includes('advertiser-dashboard:read')) return false;
    return rolePermission.includes('READ') || rolePermission.includes('EXPORT') || rolePermission.includes('export') || rolePermission.includes('read') || rolePermission.includes('run');
  }),
  [UserRoleNew.USER]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    return !(rolePermission.includes('admin.users') || rolePermission.includes('ai-web-player') || rolePermission.includes('advertiser-dashboard:read'));
  }),
  [UserRoleNew.USER_READ_ONLY]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    // Exclude any 'ai_webplayer' related permissions
    if (rolePermission.includes('ai-web-player') || rolePermission.includes('admin') || rolePermission.includes('advertiser-dashboard:read')) return false;
    return rolePermission.includes('READ') || rolePermission.includes('EXPORT') || rolePermission.includes('export') || rolePermission.includes('read') || rolePermission.includes('run');
  }),
  [UserRoleNew.PUBLISHER]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    return rolePermission.includes('publisher-dashboard') || rolePermission.includes('run');
  }),
  [UserRoleNew.ADVERTISER]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    return rolePermission.includes('advertiser-dashboard') || rolePermission.includes('campaigns') || rolePermission.includes('targeting') || rolePermission.includes('run') || rolePermission.includes('FORECAST_READ');
  }),
  [UserRoleNew.ADVERTISER_READ_ONLY]: Object.values(RolePermission).filter((rolePermission: RolePermission) => {
    return rolePermission.includes('advertiser-dashboard:read') || rolePermission.includes('campaigns.orders.lineitem:read') || rolePermission.includes("targeting:read") || rolePermission.includes('campaigns.orders.overview:read') || rolePermission.includes('campaigns.orders.discussion:read') || rolePermission.includes('campaigns.creatives:read') || rolePermission.includes('run') || rolePermission.includes('FORECAST_READ');
  })
};

@Injectable()
export class RolePermissionService {

  constructor(private principal: Principal) {
  }

  hasPermissions(permissions: RolePermission[]) {
    const roles = this.principal.getUser().roles;
    if (permissions && permissions.length) {
      if (roles) {
        return roles.some(role => {
          const permissionsForRole = RolesWithPermissions[role] as RolePermission[];
          return permissionsForRole && permissionsForRole.some(p => permissions.includes(p));
        });
      }
    }
    return false;
  }

  hasPermissionsNew(permissions: RolePermission[]) {
    const userRoles = this.principal.getUser().userRoles;

    for (let role of userRoles) {

      if (role.permissions.includes('*:*')) {
        return true;
      }

      for (let permission of role.permissions) {
        if (permissions.some(p => p === permission)) {
          return true;
        }
      }
    }
    return false;
  }
}

