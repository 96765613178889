import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Utils} from '../../utils/utils';
@Component({
  selector: 'mt-custom-upload-menu-button',
  template: `
    <button mat-menu-item (click)="fileInput.click()" [matTooltip]="tooltip"  >
      <i class="fas fa-upload"></i> {{label}}
    </button>
    <!--<button mat-flat-button color="primary" (click)="fileInput.click()" [matTooltip]="tooltip" class="large iconed">
      <i class="fas fa-upload"></i>
      <span>{{label}}</span>
    </button>-->
    <span class="d-none">
			<input type="file"
             #fileInput
             [id]="id"
             (change)="handleFileInput($event.target.files)">
    </span>
  `,
})
export class CustomUploadMenuButtonComponent {

  @Input()
  label: string;

  @Input()
  tooltip: string;

  @Output()
  public fileSelected: EventEmitter<File> = new EventEmitter();

  @Output()
  public fileCanceled: EventEmitter<string> = new EventEmitter();

  @ViewChild('fileInput', {static: true})
  myInputVariable: ElementRef;

  private file: File;

  public id = Utils.generateGuid();

  handleFileInput(file: FileList): void {
    if (file.length) {
      this.file = file.item(0);
      this.fileSelected.emit(file.item(0));
      this.myInputVariable.nativeElement.value = '';
    } else {
      const fileName = this.file.name;
      this.file = null;
      this.fileCanceled.emit(fileName);
    }
  }

  getFile() {
    return this.file;
  }

}
