import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import { NetworkService } from '../../services/network.service';
import { Network } from '../../domain/network.model';
import { Location } from '../../interfaces/location.model';
import { GeolocationsService } from '../../services/geolocations.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-geolocations-country-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'"
                    class="w-100"
                    [ngClass]="formFieldClass"
                    [appearance]="formFieldAppearance ? formFieldAppearance : undefined"
    >
      <mat-label >{{label ? label : 'Choose country'}}</mat-label>
      <input matInput
             #name
             aria-label="Network"
             [matAutocomplete]="auto"
             [value]="selectedCountry?.countryName"
             [formControl]="filterCtrl"
             placeholder="{{placeholder ? placeholder : 'Search'}}">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.countryName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class GeolocationsCountryAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public locationId: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  items: Location[];

  @Input()
  disabled: boolean;

  @Output()
  countrySelected = new EventEmitter<Location>();

  serverSearching = false;

  filteredItems: Subject<Location[]>;
  filterCtrl = new UntypedFormControl();
  selectedCountry: Location;
  filteredlocationIds: string[] = [];
  countryList: Location[];

  constructor(
    private service: GeolocationsService,
    private snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedCountry) {
          this.selectedCountry = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.searchCountries(next.trim());
        }
      });
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.filterCtrl.disable({onlySelf: true, emitEvent: true});
    }
  }

  resetInput() {
    this.selectedCountry = null;
    this.filterCtrl.setValue(null);
  }

  searchCountries(searchTerm: string) {
    this.serverSearching = true;
    this.service.searchGeolocationsCountries({searchTerm}).subscribe(resp => {
      this.countryList = resp;
      this.filteredItems.next(this.countryList.filter(item => !this.filteredlocationIds.includes(item.countryName)));
    },
    error => {
      const messages = ServerErrorUtils.getValidationMessages(error);
      if (messages) {
        messages.forEach(m => this.snackBarService.error(m));
      } else {
        this.snackBarService.error('Error while fetching country data');
    }},
    () => {
      this.filterCtrl.enable();
      this.nameFilter.nativeElement.focus();
      this.serverSearching = false;
    });
  }

  display(p?: Network) {
    return p ? p.name : undefined;
  }

  optionSelected($event) {
    this.selectedCountry = $event.option.value;
    this.filteredItems.next([]);
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.countrySelected.emit(this.selectedCountry);
  }

  updateFilteredCountries(items) {
    this.filteredlocationIds = items.map(item => item.id);
  }

  disableSearch(disable: true) {
    disable ? this.filterCtrl.disable() : this.filterCtrl.enable();
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }
}
