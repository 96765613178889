import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomainAutocompleteComponent} from './domain-autocomplete.component';
import {Domain} from '../../domain/domain.model';
import {PublisherDomainsService} from "../../services/publisher-domains.service";
import {DomainsService} from "../../services/domains.service";
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-domain-autocomplete-chips',
  template: `
    <div class="mt-domain-autocomplete-chips-wrapper">
      <mt-domain-autocomplete [pub]="pub" [publisherIds]="publisherIds" #dc (domainSelected)="onItemSelected($event)"
                               [formFieldClass]="formFieldClass"
                               [formFieldAppearance]="formFieldAppearance"
                               [floatLabel]="floatLabel">
      </mt-domain-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="!disabled"
                        (removed)="remove(item)">
          {{item.url}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class DomainAutocompleteChipsComponent implements OnInit {

  @ViewChild(DomainAutocompleteComponent, {static: true}) dc: DomainAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public domainIds: string[];

  @Input()
  public formFieldClass: string;
  @Input()
  public formFieldAppearance: string;
  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public publisherIds: string[];

  @Input()
  public disabled: boolean;

  @Input()
  public pub: boolean;

  @Output()
  domainSelectionChange = new EventEmitter<Domain[]>();

  items: Domain[] = [];

  constructor(private publisherDomainsService: PublisherDomainsService, private domainsService: DomainsService) {
  }

  ngOnInit(): void {
    if (this.domainIds && this.domainIds.length) {
      this.httpCall({publisherIds: this.publisherIds}).subscribe(domains => {
        domains.content.forEach(dom => {
          if (this.domainIds.includes(dom.url)) {
            this.onItemSelected(dom);
          }
        });
      });
    }
  }

  private httpCall(filter: any): any {
    if (this.pub) {
      return this.getPubDomainsList(filter);
    } else {
      return this.getDomainsList(filter);
    }
  }

  private getDomainsList(filter: any): any {
    return this.domainsService.getAllDomains(filter);
  }

  private getPubDomainsList(filter: any): any {
    return this.publisherDomainsService.getAllPubDomains(filter);
  }

  onItemSelected($event: Domain) {
    if ($event && !this.items.find(x => x.url === $event.url)) {
      this.items.push($event);
      this.domainSelectionChange.emit(this.items);
    }
    this.dc.resetInput();
    this.dc.updateFilteredDomains(this.items);
  }

  remove(d: Domain) {
    this.items.splice(this.items.indexOf(d), 1);
    this.domainSelectionChange.emit(this.items);
    this.dc.updateFilteredDomains(this.items);
  }

  disableSearch(disable: true) {
    this.dc.disableSearch(disable);
    if (disable) {
      this.items = [];
      this.domainSelectionChange.emit(this.items);
    }
  }
}
