import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'mt-page-not-found',
  template: `Page not found`,
})
export class PageNotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
