<div mat-dialog-title>
  Placements
</div>

<div mat-dialog-content class="p-0 border-top border-bottom">

  <div class="data-loader" [hidden]="!loading">
    <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
    </mat-spinner>
  </div>
  <div class="data-alert" [hidden]="loading || (dataSource.data && dataSource.data.length)">
    <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
  </div>
  <div class="table-scroll-wrapper" [hidden]="loading || !dataSource.data || !dataSource.data.length">
    <mat-table #table [dataSource]="dataSource" matSort matSortActive="displayName" matSortDirection="asc">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <a *csHasPermission="RolePermission.PLACEMENT_EDIT" class="link" [routerLink]="'/placements/edit/' + element.id">
            {{element.name}}
          </a>
          <span *csHasNoPermission="RolePermission.PLACEMENT_EDIT">
            {{element.name}}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Delivery type</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <span>{{DeliveryTypeLabels[element.deliveryType]}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="bidFloor">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Floor</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <span *ngIf="element.bidFlorr">${{element.bidFloor}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cpm">
        <mat-header-cell *matHeaderCellDef mat-sort-header>CPM</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <span *ngIf="element.cpm">${{element.cpm}}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columns"></mat-row>
    </mat-table>
    <div [hidden]="loading || !dataSource.data || !dataSource.filteredData.length">
      <mat-paginator #paginator [pageSize]="filter.size" [pageSizeOptions]="[5, 10, 25, 50, 100]">
      </mat-paginator>
    </div>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-stroked-button (click)="closeDialog()">Close</button>
</div>
