import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DpAutocompleteComponent} from './dp-autocomplete.component';
import {DemandPartner, DemandPartnersRequest, DemandPartnerStatusColorMap} from '../../domain/demand-partner.model';
import {Observable, Subscription} from 'rxjs';
import {DemandPartnersService} from '../../services/demand-partners.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-dp-autocomplete-chips',
  template: `
    <mt-dp-autocomplete #pc
                         (demandPartnerSelected)="onItemSelected($event)"
                         [formFieldClass]="formFieldClass"
                         [formFieldAppearance]="formFieldAppearance"
                         [required]="required"
                         [label]="placeholder"
                         [floatLabel]="floatLabel">
    </mt-dp-autocomplete>
    <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
      <mat-basic-chip *ngFor="let item of items"
                      (removed)="remove(item)">
        <i class="fas fa-circle mr-1 ml-0" ngClass="text-{{DemandPartnerStatusColorMap[item.status]}}"></i>
        {{item.displayName}}
<!--        <span *csHasNetworkPermission>-->
<!--            <span *ngIf="item.network" class="badge badge-primary ml-3">N</span>-->
<!--          </span>-->
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  `,
})
export class DpAutocompleteChipsComponent implements OnInit {
  @ViewChild(DpAutocompleteComponent, {static: true}) pc: DpAutocompleteComponent;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter dps for that given network*/
  @Input() networkIdsChanged: Observable<string[]>;

  /** Pass clearFilterItems = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public demandPartnerIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  /** Hold list of networkIds to filter dps only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  required = false;

  @Output()
  demandPartnerSelectionChange = new EventEmitter<DemandPartner[]>();

  items: DemandPartner[] = [];
  DemandPartnerStatusColorMap = DemandPartnerStatusColorMap;

  constructor(private service: DemandPartnersService) {
  }

  ngOnInit(): void {
    if (this.demandPartnerIds && this.demandPartnerIds.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.demandPartnerIds === 'string') {
        this.demandPartnerIds = [this.demandPartnerIds];
      }
      //Fetch only preselected demandPartners
      this.service.list({ids: this.demandPartnerIds} as any).subscribe(demandPartners => {
        demandPartners.content.map(dp => {
          this.items.push(dp);
        });
        this.pc.updateFilteredDemandPartners(this.items);
      });
    }

    this.networkIdsChanged?.subscribe((result) => this.updateDemandPartnersIdsChips(result));
    this.clearItems?.subscribe(() => this.clearFilter());
  }

  /** Remove items that are not part of given network/s. */
  updateDemandPartnersIdsChips(networks: string[]) {
    this.items = this.items.filter(x => networks.includes(x.network?.id));
    this.pc.updateFilteredDemandPartners(this.items);
  }

  onItemSelected($event: DemandPartner) {
    if ($event) {
      this.items.push($event);
      this.demandPartnerSelectionChange.emit(this.items);
      this.pc.resetInput();
      this.pc.updateFilteredDemandPartners(this.items);
    }
  }

  remove(p: DemandPartner) {
    this.items.splice(this.items.indexOf(p), 1);
    this.demandPartnerSelectionChange.emit(this.items);
    this.pc.updateFilteredDemandPartners(this.items);
  }

  /** Clear all items from filter. */
  clearFilter(disable?: boolean) {
    this.items = [];
    this.pc.updateFilteredDemandPartners(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.pc.disable();
    }
    else {
      this.pc.enable();
    }
  }
}
