import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {Principal} from '../auth/principal.service';
import {UserRole} from '../auth/user-role.model';
import {Observable} from 'rxjs';

@Injectable()
export class PublisherGuard  {

  constructor(private authService: AuthService,
              private router: Router,
              private principal: Principal) {
  }

 canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable(subscriber => {
      this.authService.identity().subscribe(() => {
        if (!this.principal.hasAuthority(UserRole.PUBLISHER)) {
          this.router.navigateByUrl('/');
          subscriber.next(false);
        } else {
          subscriber.next(true);
        }
        subscriber.complete();
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    return new Observable(subscriber => {
      this.authService.identity()
        .subscribe(
          user => {
            subscriber.next(this.principal.canAccess('publisher'));
            subscriber.complete();
            return true;
          },
          (error) => {
            console.error(error);
            subscriber.next(false);
            subscriber.complete();
          }
        );
    });
  }
}
