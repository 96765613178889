import {PageRequest} from './page-request';
import {User} from '../../core/auth/user.model';
import {PageSortRequest} from "./page-sort-request.model";
import {KeyLabel} from "./key-label.model";
import {OrderStatus, OrderStatusLabels} from "./campaigns.model";
import { Publisher } from './publisher.model';

export class Domain {
  id: string;
  url: string;
  description?: string;
  createdOn: string;
  updatedOn: string;
  createdBy: User;
  status: string;
  adsTxtSent: boolean;
  version?:number;
  webVideoStatus: string;
  publisher?: Publisher;

  iabCategories: string[];

  static of(url: string) {
    const dom = new Domain();
    dom.url = url;
    return dom;
  }
}

export class DomainCreateRequest {
  urls: string[];
  user?:string;
}

export class DomainUpdateRequest {
  domain: Domain
}

export class AiWebPlayerDomainUpdateRequest {
  id: string;
  url: string;
  status: string;
}

export class DomainListRequest extends PageRequest {
  url?: any;
  status?: DomainStatus[];
}

export class DomainListFilter extends PageSortRequest {
  page?: number;
  size?: number;
  url?: string;
  searchTerm?: string;
  status: DomainStatus[];
  ids?: string[];
  webVideoStatus: DomainWebVideoStatus[];
  immuContentType: DomainImmuContentType[];
  publisherIds: string[];
}

export class DomainStatusBox {
  count: number;
  status: string;
}

export enum DomainStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum DomainWebVideoStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED'
}

export enum DomainImmuContentType {
  AI_VIDEO = 'AI_VIDEO',
  COLLECTIONS = 'COLLECTIONS',
  N_A = 'N/A',
  null = 'N/A'
}

export class DomainImmuConntentTypeListing {
  AI_VIDEO: number;
  COLLECTIONS: number;
  N_A: number;
}

export enum DomainImmuContentTypeColorMap {
  AI_VIDEO = 'tealikegreen',
  COLLECTIONS = 'orange',
  N_A = 'gray',
  null = 'green'
}

export class DomainWebVideoStatusListing {
  APPROVED: number;
  UNAPPROVED: number;
}

export enum DomainWebVideoStatusColorMap {
  APPROVED = 'blue',
  UNAPPROVED = 'red'
}

export const DomainWebVideoStatusLabels = {
  APPROVED: 'Approved',
  UNAPPROVED: 'Unapproved'
};

export const DomainImmuContentTypeLabels = {
  AI_VIDEO: 'AI video',
  COLLECTIONS: 'Collections',
  N_A: 'N/A',
  null: 'N/A'
};

export class DomainStatusListing {
  ACTIVE: number;
  ARCHIVED: number;
}

export enum DomainStatusColorMap {
  ACTIVE = 'green',
  ARCHIVED = 'gray'
}

export const DomainStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
};

export const DomainStatusKL = () =>
    (Object.keys(OrderStatus).map(key => new KeyLabel(<OrderStatus>key, DomainStatusLabels[key])));

export const AiWebPlayerDomainStatusKL = () =>
    (Object.keys(DomainStatus).map(key => new KeyLabel(<DomainStatus>key, DomainStatusLabels[key])));

export const DomainWebVideoStatusKL = () =>
    (Object.keys(OrderStatus).map(key => new KeyLabel(<DomainWebVideoStatus>key,DomainWebVideoStatusLabels[key])));

export const DomainImmuContentTypeKL = () =>
    (Object.keys(DomainImmuContentType).map(key => new KeyLabel(<DomainImmuContentType>key,DomainImmuContentTypeLabels[key])));