import {debounceTime, distinctUntilChanged, finalize, takeUntil} from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import { AppListRequest } from '../../domain/apps.model';
import { AppsService } from '../../services/apps.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-bundle-id-autocomplete',
  template: ` <mat-form-field
      [floatLabel]="floatLabel || 'always'"
      class="w-100"
      [ngClass]="formFieldClass"
      [appearance]="formFieldAppearance ? formFieldAppearance : undefined"
    >
      <mat-label>{{label ? label : 'Choose Bundle'}}</mat-label>
      <input
        matInput
        #search
        aria-label="Bundle"
        [matAutocomplete]="auto"
        [formControl]="filterCtrl"
        [placeholder]="placeholder ? placeholder : 'Search'"
        [required]="required"
      />
      <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="optionSelected($event)"
        [displayWith]="display"
      >
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{ item }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class BundleIdAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: FloatLabelType;

  /** Hold list of networkIds to filter pubs only by given networks.*/
  @Input() public networkIds: string[];

  /** Hold list of publisherIds to filter pubs only by given networks.*/
  @Input() public publisherIds: string[];

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  required: boolean;

  @Input()
  preselectedBundle: string;

  @Output()
  bundleSelected = new EventEmitter<string>();

  filteredItems: Subject<string[]>; //List of Bundles to show in dropdown filtered with 'filteredBundles'
  filterCtrl = new UntypedFormControl();
  bundlesList: string[] = []; //stores currently fetched list of bundles
  selectedBundle: string; //currently selected bundle
  filteredBundles: string[] = []; //It stores all selected bundles
  public loading = false;

  constructor(
    private readonly service: AppsService,
    private readonly snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getBundles(searchTerm);
          }
          else {this.filteredItems.next(undefined);} //triggers when user deletes everything from input
        }
      );
  }


  ngOnInit(): void {
    if (this.preselectedBundle) {
      this.getBundles('', this.preselectedBundle);
    }
  }

  private getBundleIdList(filter: any): any {
    return this.service.bundleIdList(filter as AppListRequest);
  }

  private getPubBundleIdList(filter: any): any {
    return this.service.bundleIdPubList(filter as AppListRequest);
  }

  getBundles(searchTerm?: string, preselectedBundle?: string) {
    this.loading = true;
    this.filterCtrl.disable();
    this.getBundleIdList({
      page: 0,
      size: 1000,
      publisherIds: this.publisherIds,
      networkIds: this.networkIds,
      bundleId: searchTerm,
      ids: [preselectedBundle]
    } as AppListRequest)
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedBundle ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected publisher
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.bundlesList = resp.sort();
          if (preselectedBundle) {
            this.selectedBundle = this.bundlesList[0]; //Naturally since the api call is filtered with one ID, selectedBundle will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedBundle);
            this.updateFilteredBundles([this.selectedBundle]);
            this.emit();
          }
          this.filteredItems.next(this.bundlesList.filter(x => !this.filteredBundles.includes(x)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching bundles autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedBundle = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.bundleSelected.emit(this.selectedBundle);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredBundles. */
  public updateFilteredBundles(items) {
    this.filteredBundles = items.map((item) => item);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(p?: string) {
    return p ? p : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
