<div class="container">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Users
          </mat-card-title>
          <div class="mat-card-header-actions">
            <mat-form-field floatLabel="never" class="header-form-field mr-3 d-none d-lg-block" >
              <input matInput (keyup)="searchUser($event)"
                     placeholder="search">
              <i class="far fa-search" matSuffix></i>
            </mat-form-field>
            <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="addNewUser()">
              <i class="fas fa-user-plus"></i>
            </button>
<!--            <button mat-mini-fab color="primary" class="mat-elevation-z0 ml-2 d-lg-none" matTooltip="Filters" (click)="filters.toggle()">-->
<!--              <i class="fas fa-filter" aria-hidden="true"></i>-->
<!--            </button>-->
          </div>
        </mat-card-header>
        <mat-card-content class="mat-card-content--full">
          <div class="data-loader" [hidden]="!loading">
            <mat-spinner [color]="'primary'"
                         [mode]="'indeterminate'"
                         [diameter]="30">
            </mat-spinner>
          </div>
          <mt-card-filters #filters>
            <div class="col-12 col-lg-auto d-lg-none">
              <mat-form-field floatLabel="always" class="w-100 mb-n3">
                <input matInput (keyup)="searchUser($event)"
                       placeholder="search">
                <i class="far fa-search" matSuffix></i>
              </mat-form-field>
            </div>
          </mt-card-filters>
          <div class="data-alert" [hidden]="loading || (dataSource.data && dataSource.data.length) && dataSource.filteredData.length > 0">
            <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
          </div>
          <div class="table-scroll-wrapper" [hidden]="loading || !dataSource.data || !dataSource.data.length || dataSource.filteredData.length < 1">
            <mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span class="text-truncate">{{element.name}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="username">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Username</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span class="text-truncate">{{element.username}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="roles">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Roles</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span class="text-truncate">{{setUserRolesLabel(element.roles)}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="enabled">
                <mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span>
                      <mat-slide-toggle
                        [color]="'primary'"
                        [(ngModel)]="element.enabled"
                        (ngModelChange)="updateUserStatus(element.username, element.enabled)">
                      </mat-slide-toggle>
                    </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="lastLoginTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Last login</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span
                    [matTooltip]="element?.lastLoginTime | date: 'HH:mm' : ''">{{element?.lastLoginTime | date:'MM/dd/yyyy'}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <span
                    [matTooltip]="element?.createdOn | date: 'HH:mm' : ''">{{element?.createdOn | date:'MM/dd/yyyy'}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">

                  <button mat-icon-button disableRipple="true" (click)="$event.stopPropagation()"
                          [matMenuTriggerFor]="menu">
                    <i class="far fa-ellipsis-v"></i>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item matTooltip="Edit"
                            (click)="editUser(element)">
                      <i class="far fa-edit"></i> Edit
                    </button>
                  </mat-menu>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
          </div>
        </mat-card-content>
        <mat-card-footer [hidden]="loading || !dataSource.data || !dataSource.filteredData.length">
          <mat-paginator #paginator [pageSize]="filter.size" [pageSizeOptions]="[5, 10, 25, 50, 100]">
          </mat-paginator>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>

</div>
