import {User} from 'src/app/core/auth/user.model';
import {CreativeLibraryVariant} from './creative-library-variant.model';
import {CreativeLibrary} from './creative-library.model';
import {KeyLabel} from './key-label.model';
import {Targeting} from './targeting.model';
import {ActiveInactiveArchived} from "../enums/active-inactive-archived.enum";
import {Network} from "./network.model";

export class LineItem {
  public id: string;
  public name: string;
  public lineItemAdType?: string;
  public active?: boolean;
  public cpm?: number;
  public agencyFee?: number;
  public createdBy?: User;
  public disableLowPacing?: boolean;
  public domain?: string;
  public endDate?: string;
  public grossBudget?: string;
  public frequencyCapping?: FrequencyCapping;
  public iabCategory?: string;
  public impressionsGoal?: number;
  public lineItemCreatives?: Array<LineItemCreative>;
  public lineItemDisplayCreatives?: Array<LineItemImageCreative>;
  public lineItemType?: string;
  public numericId?: number;
  public order?: Order;
  public pacing?: string;
  public pacingPerHour?: string;
  public startDate?: string;
  public status?: string;
  public targeting?: Targeting;
  public testMode?: boolean;
  public updatedBy?: User;
  public deliveredImpressions?: number;
  public impressionTrackers?: Array<string>;
  public dayTimeActivities?: Array<DayTimeActivities>;
  public iabCategories?: Array<string>;
  public trackerStart: string;
  public trackerFirstQuartile: string;
  public trackerMidpoint: string;
  public trackerThirdQuartile: string;
  public trackerComplete: string;
  public deliveryStatus?: LineItemDeliveryStatus;
}

export class DayTimeActivities {
  public day:	string; //MONDAY,..,SUNDAY
  public restrictedHours: boolean; //If hour restriction is on
  public startHour: number; //0-23
  public endHour: number; //0-23
}

export class FrequencyCapping {
  public fcActive: boolean;
  public fcDuration: number;
  public fcLimit: number;
  public fcTimeUnit: string;
}

export class LineItemCreative {
  public endDate: string;
  public startDate: string;
  public variants: CreativeLibraryVariant;
  public creative: CreativeLibrary;
  public weight: number;
  public id?: string;
}

export class LineItemImageCreative {
  public endDate: string;
  public startDate: string;
  public creative: CreativeLibrary;
  public weight: number;
  public id?: string;
}

// ex Advertiser
export class Brand  {
  id: string;
  name: string;
  status: ActiveInactiveArchived;
  secondaryId: string;
  companyName: string;
  contactName: string;
  phone: string;
  email: string;
  websiteUrl: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  orders: Order[];
  advertiser:any;
}

export class BrandRequestFilter {
  name: string;
  status: ActiveInactiveArchived[];
  networkId: number;
  advertiserId: number;
}

export class Order {
  public brand: Brand;
  public altId: string;
  public name: string;
  public trafficker: string;
  public createdBy?: User;
  public lineItemsCnt?: number;
  public startDate?: string;

  public endDate?: string;
  public notes?: string;
  public poNumber?: string;
  public id?: string;
  public agency?: string;
  public salesPerson?: string;
  public status?: string;
  public owners?: any;
  public statusesCounts?: [];

  public ioFiles: any;

  public discussions: Discussion[];
  public network: Network;
}

export class Discussion {
  id: string;
  title: string;
  postsCount: number;
  unreadPosts: number;
  lastPost: DiscussionPost;
  posts?: DiscussionPost[];
  discussionParticipants: User[];
  createdBy: User;
  updatedBy: User;
  createdOn: Date;
  updatedOn: Date;
}

export class DiscussionPost {
  id: any;
  text: string;
  attachments?: any;
  createdBy: User;
  createdOn?: any;
}

export enum CreativeType {
  MEDIAFILE = 'MEDIAFILE',
  VAST = 'VAST',
  DISPLAY = 'DISPLAY',
}

export const CreativeTypeLabels = {
  MEDIAFILE: 'MediaFile',
  VAST: 'VAST',
  DISPLAY: 'Display',
};

export const CreativeTypeKL = () =>
    (Object.keys(CreativeType).map(key => new KeyLabel(<CreativeType>key, CreativeTypeLabels[key])));

export enum LineItemType {
  DIRECT = 'DIRECT',
  HOUSE = 'HOUSE',
}

export const LineItemTypeLabels = {
  DIRECT: 'Direct Campaign',
  HOUSE: 'House',
};

export const LineItemTypeKL = () =>
    (Object.keys(LineItemType).map(key => new KeyLabel(<LineItemType>key, LineItemTypeLabels[key])));

export enum PacingType {
  EVENLY = 'EVENLY',
  FRONT_LOADED = 'FRONT_LOADED',
  ASAP = 'ASAP',
}

export const PacingTypeLabels = {
  EVENLY: 'Evenly',
  FRONT_LOADED: 'Frontloaded',
  ASAP: 'Asap',
};

export const PacingTypeKL = () =>
    (Object.keys(PacingType).map(key => new KeyLabel(<PacingType>key, PacingTypeLabels[key])));

export class SchedulerModel {
  public day: string;
  public hour: number[];
}

export class OrdersStatusBox {
  count: number;
  status: string;
}

export enum OrderStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const OrderStatusLabels = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ARCHIVED: 'Archived',
};

export enum OrderStatusColorMap {
  ACTIVE = 'green',
  INACTIVE = 'red',
  ARCHIVED = 'gray',
}

export const OrderStatusKL = () =>
    (Object.keys(OrderStatus).map(key => new KeyLabel(<OrderStatus>key, OrderStatusLabels[key])));

export class LineItemStatusBox {
  count: number;
  status: string;
}

export enum LineItemDeliveryStatus {
  DELIVERING = 'DELIVERING',
  GOAL_MET = 'GOAL_MET',
  COMPLETED = 'COMPLETED',
  READY = 'READY',
}

export const LineItemDeliveryStatusLabels = {
  DELIVERING: 'Delivering',
  GOAL_MET: 'Goal met',
  COMPLETED: 'Completed',
  READY: 'Ready',
};

export enum LineItemDeliveryStatusColorMap {
  DELIVERING = 'ds-delivering',
  GOAL_MET = 'ds-goal-met',
  COMPLETED = 'ds-completed',
  READY = 'ds-ready',
}

export enum LineItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const LineItemStatusLabels = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ARCHIVED: 'Archived',
};

export enum LineItemStatusColorMap {
  ACTIVE = 'green',
  INACTIVE = 'red',
  ARCHIVED = 'gray',
}

export enum LineItemAdType {
  VIDEO = 'VIDEO',
  DISPLAY = 'DISPLAY',
}

export const LineItemAdTypeLabels = {
  VIDEO: 'Video',
  DISPLAY: 'Display',
};

export const LineItemAdTypeKL = () =>
    (Object.keys(LineItemAdType).map(key => new KeyLabel(<LineItemAdType>key, LineItemAdTypeLabels[key])));

export const LineItemsKL = () =>
    (Object.keys(LineItemStatus).map(key => new KeyLabel(<LineItemStatus>key, LineItemStatusLabels[key])));

export const LineItemDeliveryStatusKL = () =>
  (Object.keys(LineItemDeliveryStatus).map(key => new KeyLabel(<LineItemDeliveryStatus>key, LineItemDeliveryStatusLabels[key])));
