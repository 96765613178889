import {Component, Input, TemplateRef} from '@angular/core';
import { LoginService } from '../../../core/login/login.service';
import { Router } from '@angular/router';
import { RouterExtService } from '../../../core/services/router-ext.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'mt-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input()
  public pageTitle: string;
  @Input()
  public titleDescription: string;
  @Input()
  public statusColor: string;
  @Input()
  public titleNext: string;
  @Input()
  public backBtnRouteUrl: string;
  @Input()
  public smartBackButton: boolean; //back button based on history, it simply goes back to page before in browser history
  @Input()
  public routerBackOr: string; // provide default url if router cannot go back (when we refresh browser so there is no history)
  @Input()
  toolbarSecondRow: TemplateRef<any>;

  constructor(
    private loginService: LoginService,
    private routerExt: RouterExtService,
    private router: Router,
    private navigation: NavigationService
  ) {}

  public logout() {
    this.loginService.logout(true);
  }

  navigateBack() {
    if (this.backBtnRouteUrl)
      {return this.navigation.back(this.backBtnRouteUrl);}

    if (this.routerBackOr)
      {return this.navigation.back(this.routerBackOr);}

    if (this.smartBackButton)
      {return this.navigation.back('');}
  }
}
