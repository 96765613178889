import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {CategoriesService} from '../../services/categories.service';
import {ServerErrorUtils} from '../../utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {map, startWith} from 'rxjs/operators';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-categories-autocomplete-chips-input',
  template: `
    <mat-form-field class="w-100" [floatLabel]="floatLabel || 'always'" *ngIf="!loading">
      <mat-chip-list #chipList required class="form-field-chip-list">
        <mat-basic-chip
          *ngFor="let category of categories"
          [selectable]="true"
          [removable]="true"
          (removed)="remove(category)">
          {{category}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
        <input
          placeholder="{{placeholder ? placeholder : ''}}" required
          #categoryInput
          [formControl]="categoriesCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category" [hidden]="!categoriesCtrl.value">
          {{category}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Start typing (autocomplete)</mat-hint>
    </mat-form-field>`
})
export class CategoriesAutocompleteChipsInputComponent implements OnInit {

  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input()
  categories: string[];

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Output()
  categorySelectionChange = new EventEmitter<string[]>();

  loading: boolean;
  allCategories: string[] = [];
  categoriesCtrl = new UntypedFormControl();
  filteredCategories: Observable<string[]>;

  constructor(private service: CategoriesService,
              private sbs: SnackBarService) {
    this.filteredCategories = this.categoriesCtrl.valueChanges.pipe(
      startWith(null),
      map((category: string | null) => category ? this._filter(category) : this.allCategories.slice().sort().splice(0, 10)));
  }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories() {
    this.loading = true;
    this.service.list().subscribe(response => {
      this.loading = false;
      this.allCategories = response;
      if (!this.categories) {
        this.categories = [];
      } else {
        this.categories.map(c => {
          if (this.allCategories.includes(c)) {
            this.allCategories.splice(this.allCategories.indexOf(c), 1);
          }
        });
      }
    }, error => {
      ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error occurred while fetching categories');
    });
  }

  remove(category: string): void {
    this.categories.splice(this.categories.indexOf(category), 1);
    this.allCategories.push(category);
    this.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.categories.push(event.option.viewValue);
    this.allCategories.splice(this.allCategories.indexOf(event.option.viewValue), 1);

    this.categoryInput.nativeElement.value = '';
    this.categoriesCtrl.setValue(null);
    this.emit();
  }

  emit() {
    this.categorySelectionChange.emit(this.categories);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCategories.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0).sort().splice(0, 10);
  }
}
