import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../../core/auth/user.model';
import {Observable} from 'rxjs';
import {HttpUtils} from '../utils/http-utils';
import {PermissionsResponse, Role} from "../domain/roles-permissions.model";

@Injectable({
  providedIn: 'root',
})
export class RolesService {

  rolesUrl: string = environment.serviceUrl + '/api/roles'
  constructor(private httpClient: HttpClient) {
  }

  listRoles(filter): Observable<Role[]> {
    return this.httpClient.get<Role[]>(this.rolesUrl, {
      params: filter ? HttpUtils.getHttpParams(filter) : {}
    });
  }

  getAllRoles(): Observable<Role[]>{
    return this.httpClient.get<Role[]>(this.rolesUrl);
  }

  getPermissions(): Observable<PermissionsResponse[]>{
    return this.httpClient.get<PermissionsResponse[]>(this.rolesUrl + '/permissions');
  }

  createRole(roleName: string, permissions): Observable<any> {
    return this.httpClient.post<User>(this.rolesUrl, {
      "name": roleName,
      "permissions": permissions
    });
  }

  updateRole(roleId: number, roleName: string, permissions: string[]): Observable<any> {
    return this.httpClient.put<any>(this.rolesUrl + '/' + roleId, {
      "name": roleName,
      "permissions": permissions
    });
  }

  deleteRole(roleId: number) {
    return this.httpClient.delete(this.rolesUrl  + '/' + roleId);
  }

}