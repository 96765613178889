<div mat-dialog-title>
  <span>Document upload</span>
</div>
<div mat-dialog-content>
  <form #f="ngForm" class="d-flex flex-column">

    <mt-upload-button [label]="'Document'" (fileSelected)="file = $event" [filePreview]="file" [fileName]="true" [isEdit]="isEdit"></mt-upload-button>

    <mat-form-field floatLabel="always" color="primary" class="mt-3">
      <mat-label>Description</mat-label>
      <textarea matInput name="description" required [(ngModel)]="documentDescription" style="min-height: 110px "
                ></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="mr-3" [mat-dialog-close]="false">
    Close
  </button>
  <button mat-flat-button class="iconed" color="primary"
          [disabled]="!f.valid"
          (click)="uploadDocument()">
    <i class="fas fa-save"></i>
    <span>Save</span>
  </button>
</div>
