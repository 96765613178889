import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {updatedDaysAgoValues, Video, VideoListRequest} from '../../../shared/domain/video.model';
import {Subscription} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {FeedCategoriesYT, OwnerType, OwnerTypeKL} from '../../../shared/domain/feed.model';
import {VideosService} from '../../../shared/services/videos.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {Location} from '@angular/common';
import {PlaylistsService} from '../../../shared/services/playlists.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ServerErrorUtils} from '../../../shared/utils/server-error-utils';
import {Utils} from '../../../shared/utils/utils';
import {AddVideoToPlaylistDialogComponent} from '../video-playlist-dialog/add-video-to-playlist-dialog.component';
import { EditVideosDialogComponent} from '../edit-videos-dialog/edit-videos-dialog..component';
import {PlaylistVideoPreviewComponent} from '../../playlists/playlist-video-preview/playlist-video-preview.component';
import {ContentOwner} from '../../../shared/domain/content-owner.model';
import {ContentOwnerService} from '../../../shared/services/content-owner.service';
import {PublishersService} from '../../../shared/services/publishers.service';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {GlobalSpinnerService} from '../../../core/services/global-spinner.service';
import {PlaylistIngestFeedDialogComponent} from '../../playlists/playlist-ingest-feed-dialog/playlist-ingest-feed-dialog.component';
import { E } from '@angular/cdk/keycodes';
import { VideoMetadataDialog } from '../video-metadata-dialog/video-metadata-dialog.component';
import { FlagAutocompleteChipsComponent } from 'src/app/shared/components/utils/flags-autocomplete-chips';
import { GenresAutocompleteChipsComponent } from 'src/app/shared/components/utils/genres-autocomplete-chips';
import { TagsAutocompleteChipsComponent } from 'src/app/shared/components/utils/tags-autocomplete-chips';
import { ArtistsAutocompleteChipsComponent } from 'src/app/shared/components/utils/artists-autocomplete-chips';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mt-videos-table',
  templateUrl: './videos-table.component.html',
  styleUrls: ['./videos-table.component.scss']
})
export class VideosTableComponent implements OnInit, AfterViewInit {
  @ViewChild(FlagAutocompleteChipsComponent) autoFlag;
  @ViewChild(TagsAutocompleteChipsComponent) autoTag;
  @ViewChild(GenresAutocompleteChipsComponent) autoGenre;
  @ViewChild(ArtistsAutocompleteChipsComponent) autoArtist;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  public readonly RolePermission = RolePermission;

  @Input()
  videoType: {id: string; type: OwnerType};

  columns = ['select', 'image', 'title', 'preview', 'duration', 'playlist', 'tags', 'genres', 'flags', 'artist', 'owner', 'publishedAt','createdOn', 'updatedOn', 'actions'];
  loading = false;
  dataSource = new MatTableDataSource<Video>();
  flags: string[] = [];
  tags: string[] = [];
  genres: string[] = [];
  artists: string[] = [];
  filter = {
    page: 0,
    size: 10,
    sortProperty: ['createdOn'],
    sortDirection: 'DESC',
    title: '',
    contentOwnerId: null,
    updatedDaysAgo: null,
    flags: '',
    tags: '',
    genres: '',
    artists: '',
    ownerType: '',
    coId: '',
    coIds: [],
    missingThumb: null,
  } as VideoListRequest;
  sbs: Subscription;
  titleControl = new UntypedFormControl();
  selection = new SelectionModel<Video>(true, []);
  updatedDaysAgoValues = updatedDaysAgoValues;
  ownerType = OwnerType;
  ownerTypeKL = OwnerTypeKL();
  platformTitle: string = environment.platformTitle;

  constructor(private service: VideosService,
              private route: ActivatedRoute,
              private snackBarService: SnackBarService,
              private router: Router,
              private location: Location,
              private playlistsService: PlaylistsService,
              private matDialog: MatDialog,
              private coService: ContentOwnerService,
              private publishersService: PublishersService,
              private gss: GlobalSpinnerService) {
  }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        this.filter.page = params.page || 0;
        this.filter.size = params.size || 10;
        this.filter.sortDirection = params.sortDirection || this.filter.sortDirection;
        this.filter.sortProperty = params.sortProperty || this.filter.sortProperty;
        this.filter.title = params.title || this.filter.title;
        this.filter.contentOwnerId = params.contentOwnerId || this.filter.contentOwnerId;
        this.filter.updatedDaysAgo = params.dateFrom || this.filter.updatedDaysAgo;
        this.filter.flags = params.flags || this.filter.flags;
        this.filter.tags = params.tags || this.filter.tags;
        this.filter.genres = params.genres || this.filter.genres;
        this.filter.artists = params.artists || this.filter.artists;
        this.filter.ownerType = params.ownerType || this.filter.ownerType;
        this.filter.missingThumb = params.missing || this.filter.missingThumb;
        this.refreshData();
      });
  }

  ngAfterViewInit(): void {
    if (this.videoType) {
      this.columns.splice(this.columns.indexOf('owner'), 1);
    }
    this.sort.sortChange.subscribe(next => {
      this.filter.sortProperty = [next.active];
      this.filter.sortDirection = next.direction.toUpperCase();
      this.refreshDataFirstPage();
    });

    this.paginator.page.subscribe((p: PageEvent) => {
      this.filter.size = p.pageSize;
      this.filter.page = p.pageIndex;
      this.refreshData();
    });
    this.titleControl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(data => {
        this.filter.title = data;
        this.refreshDataFirstPage();
      });
  }

  refreshDataFirstPage() {
    this.paginator.pageIndex = 0;
    this.filter.page = 0;
    this.refreshData();
  }

  refreshData() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: Object.assign({}, this.filter),
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
    this.location.replaceState(urlTree.toString());
    if (this.sbs) {
      this.sbs.unsubscribe();
    }
    this.getVideos();
  }

  getVideos() {
    let serv;
    if (this.videoType && this.videoType.type === OwnerType.CONTENT_OWNER) {
      serv = this.coService;
    } else {
      serv = this.service;
    }
    this.loading = true;
    this.sbs = serv.videosList(this.filter, this.videoType ? this.videoType.id : null).subscribe(
      response => {
        this.loading = false;
        response?.content.forEach(element => {
          if (element.posters[0]) {
            element.posters[0] = `${element.posters[0]}?${Date.now()}`;
          }
        });
        this.paginator.length = response?.totalElements;
        this.dataSource.data = response?.content;
      },
      error => {
        this.loading = false;
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error during while fetching videos');
        }
      }
    );
  }

  setOwnerId(owner) {
    this.filter.coId = owner?.id;
    this.refreshDataFirstPage();
  }

  ownerTypeFilterChanged(event) {
    this.filter.ownerType = event;
    this.filter.coId = '';
    this.filter.coIds = [];
    this.refreshDataFirstPage();
  }

  filterThumb(event) {
    this.filter.missingThumb = event.value;
    this.refreshDataFirstPage();
  }

  filterPublisherChange(event) {
    this.filter.coIds = event.map(item => item.id);
    this.refreshDataFirstPage();
  }

  filterContentOwnerChange(event) {
    this.filter.coIds = event;
    this.refreshDataFirstPage();
  }

  filterFlagsChange(event) {
    this.filter.flags = event.toString();
    this.refreshDataFirstPage();
  }

  filterTagsChange(event) {
    this.filter.tags = event.toString();
    this.refreshDataFirstPage();
  }

  filterGenresChange(event) {
    this.filter.genres = event.toString();
    this.refreshDataFirstPage();
  }

  filterArtistChange(event) {
    this.filter.artists = event.toString();
    this.refreshDataFirstPage();
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  toggle(row) {
    this.selection.toggle(row);
  }

  getTimeFromSeconds(durationInSeconds: number) {
    return Utils.getTimeFromSeconds(durationInSeconds);
  }

  addToPlaylist() {
    this.matDialog.open(AddVideoToPlaylistDialogComponent, {data: {videos: this.selection.selected.map(s => s.id)}} as MatDialogConfig)
      .afterClosed().subscribe(next => {
      if (next) {
        this.selection.clear();
        this.refreshData();
      }
    });
  }

  editSelectedVideos() {
    this.matDialog.open(EditVideosDialogComponent, {data: {videos: this.selection.selected.map(s => s.id)}} as MatDialogConfig)
      .afterClosed().subscribe(next => {
      if (next) {
        this.selection.clear();
        this.refreshData();
      }
    });
  }

  previewVideo(id: string) {
    this.matDialog.open(PlaylistVideoPreviewComponent, {data: {id}} as MatDialogConfig)
    .afterClosed().subscribe(next => {
      if (next) {
        this.refreshDataFirstPage();
      }
    });
  }

  contentOwnerSelected(contentOwner: ContentOwner) {
    this.filter.contentOwnerId = contentOwner ? contentOwner.id : null;
    this.refreshDataFirstPage();
  }

  openMetadataDialog(element) {
    this.matDialog.open(VideoMetadataDialog, { data: { element} } as MatDialogConfig)
      .afterClosed().subscribe(next => {
      if (next) {
        this.refreshDataFirstPage();
      }
    });
  }

  removeOwner(id?: string) {
    this.matDialog.open(ConfirmDialogComponent,
      {data: {
          subtitle: `Are you sure you want to remove owner from
            ${id || this.selection.selected.map(s => s.id).length === 1 ? 'video' : 'videos'}?`}
      } as MatDialogConfig)
      .afterClosed().subscribe(next => {
      if (next) {
        this.gss.showLoader();
        this.service.removeOwner(this.selection.selected.map(s => s.id)).subscribe(
          response => {
            this.gss.hideLoader();
            this.snackBarService.success(
              `You have successfully removed owner from ${id || this.selection.selected.map(s => s.id).length === 1 ? 'video' : 'videos'}`);
            this.refreshData();
          },
          error => {
            this.gss.hideLoader();
            ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService,
              `Error while removing owner from ${id || this.selection.selected.map(s => s.id).length === 1 ? 'video' : 'videos'}`);
          }
        );
      }
    });
  }

  ingestVideo() {
    this.matDialog.open(PlaylistIngestFeedDialogComponent, {data: {videoType: this.videoType}} as MatDialogConfig)
      .afterClosed().subscribe(next => {
      if (next) {
        this.refreshDataFirstPage();
      }
    });
  }
}
