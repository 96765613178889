import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpUtils} from '../utils/http-utils';
import {Principal} from '../../core/auth/principal.service';
import {DashboardResponse} from './dashboard.service';
import {PubDashResponse} from '../domain/pub-dashboard.model';
import {DateRange} from '../domain/rb/date-range.model';
import {ChangePasswordRequest, UpdateProfileRequest} from '../../core/auth/user.model';

@Injectable()
export class PubDashService {

  constructor(private httpClient: HttpClient,
              private principal: Principal) {
  }

  dashboard(dateFrom: string, dateTo: string): Observable<DashboardResponse> {
    return this.httpClient.get<DashboardResponse>(`${environment.serviceUrl}/api/pub-dash/dashboard`, {
      params: HttpUtils.getHttpParams({
        dateFrom,
        dateTo,
        publisherId: this.principal.publisher.id
      })
    });
  }

  updateProfile(userId: string, request: UpdateProfileRequest): Observable<any> {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/pub-dash/${userId}/users`, request);
  }

  changePassword(userId: string, request: ChangePasswordRequest): Observable<any> {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/pub-dash/${userId}/users/change-password`, request);
  }

  dashboardDomains(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/${pubId}/dashboard/domains`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  dashboardVideos(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/${pubId}/dashboard/videos`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  dashboardAudio(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/dashboard-av/${pubId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  dashboardTotal(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/dashboard/${pubId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  dashboardVideo(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/dashboard-v/${pubId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  impressionsAudio(pubId: string, filter: DateRange): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/pub-dash/dashboard-av/${pubId}/impressions`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  impressionsTotal(pubId: string, filter: DateRange): Observable<DashboardResponse> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/pub-dash/dashboard/${pubId}/impressions`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  impressionsVideo(pubId: string, filter: DateRange): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/pub-dash/dashboard-v/${pubId}/impressions`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  cost(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/${pubId}/cost`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  watchDuration(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/${pubId}/watch-duration`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  revenue(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/${pubId}/revenue`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  domains(pubId: string, filter: DateRange): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/pub-dash/${pubId}/domains`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  videos(pubId: string, filter: DateRange): Observable<PubDashResponse> {
    return this.httpClient.get<PubDashResponse>(`${environment.serviceUrl}/api/pub-dash/${pubId}/videos`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

}