import { KeyLabel } from './key-label.model';
import { SaasCost } from './publisher.model';

export class Network {
    createdOn: string;
    createdBy: string;
    id: string;
    name: string;
    updatedOn: string;
    version: number;
    saasCost: SaasCost;
    status: string;
    networkSettingsDto: NetworkSettingsDto;
    networkSettings: any;
  }

export class NetworkStatusBox {
    count: number;
    status: string;
}

export const NetworkStatusKL = () =>
  Object.keys(NetworkStatus).map(
    (key) => new KeyLabel(key as NetworkStatus, NetworkStatusLabels[key])
);

export enum NetworkStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const NetworkStatusLabels = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export enum NetworkStatusColorMap {
  ACTIVE = 'green',
  ARCHIVED = 'gray',
}

export enum NetworkAvailableFeatures {
  CAMPAIGN = 'CAMPAIGN',
  AD_UNIT = 'AD_UNIT',
  A2V = 'A2V',
  CONTENT = 'CONTENT',
  IMMU = 'IMMU',
  LIVE_RAMP = 'LIVE_RAMP',
  EMAIL = 'EMAIL',
  STITCHER = 'STITCHER',
  BID_CACHE = 'BID_CACHE'
}

export const NetworkAvailableFeaturesLabels = {
  CAMPAIGN: 'Campaigns',
  AD_UNIT: 'Ad Units',
  A2V: 'A2V',
  CONTENT: 'Content',
  IMMU: 'IMMU',
  LIVE_RAMP: 'LiveRamp',
  EMAIL: 'Mail',
  STITCHER: 'Stitcher',
  BID_CACHE: 'Bid Cache'
};

export const NetworkAvailableFeaturesKL = () =>
  (Object.keys(NetworkAvailableFeatures).map(key => new KeyLabel(<NetworkAvailableFeatures> key, NetworkAvailableFeaturesLabels[key])));

export class NetworkSettingsDto {
  platformName: string;
  rootDomain: string;
  dashboardDomain?: string;
  backendDomain?: string;
  adstxtDomain?: string;
  cdn?: string;
  a2v?: string;
  exchangeBidderName: string;
  tagId?: number;
  logoUrl?: string;
  logoLightUrl?: string;
  faviconUrl?: string;
  supportEmail?: string;
  features?: NetworkAvailableFeatures[] = [];
  exchangeDomain: string;
  trackersDomain: string;
}