import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatLegacyAutocomplete as MatAutocomplete} from '@angular/material/legacy-autocomplete';
import {ServerErrorUtils} from '../../utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {debounceTime, distinctUntilChanged, finalize} from 'rxjs/operators';
import {A2vService} from '../../services/audio-to-video.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-a2v-tags-autocomplete-chips-input',
  template: `
    <mat-form-field class="w-100" [ngClass]="formFieldClass"
                    [floatLabel]="floatLabel ? floatLabel : 'always'"
                    [appearance]="formFieldAppearance ? formFieldAppearance : undefined"
    >
      <mat-label *ngIf="title">{{title}}</mat-label>
      <mat-chip-list #chipList [disabled]='loading' class="form-field-chip-list">
        <mat-basic-chip
          [hidden]="classicChips"
          *ngFor='let tag of selectedTags'
          [selectable]='true'
          [removable]='true'
          (removed)='remove(tag)'>
          {{tag}}
          <i class='fas fa-times-circle text-danger hand' matChipRemove></i>
        </mat-basic-chip>
        <input matInput
               [placeholder]="placeholder ? placeholder : ''"
               required
               #tagsFilter
               [formControl]='tagsCtrl'
               [matAutocomplete]='auto'
               [matChipInputFor]='chipList'
               (keyup.enter)="arbitraryTagInput()">
      </mat-chip-list>
      <span matSuffix class="double-suffix-actions">
        <!--<button mat-mini-fab color="accent" *ngIf="arbitraryInput && tagsFilter.value"
                [hidden]="loading" (click)="arbitraryTagInput()" matTooltip="Create tag" class="mr-2">
            <i class="far fa-plus"></i>
        </button>-->
        <i class="far fa-spin fa-spinner text-primary mr-3" [hidden]="!loading"></i>
      </span>
      <mat-autocomplete #auto='matAutocomplete' (optionSelected)='optionSelected($event)'>
        <mat-option *ngFor='let tag of filteredTags | async' [value]='tag'>
          {{tag}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint [hidden]="classicChips">Search (autocomplete) or press Enter to add a new tag</mat-hint>
    </mat-form-field>

    <mat-chip-list #chipList [ngClass]="selectedTags.length > 0 ? 'mb-3':''" [hidden]="!classicChips || selectedTags.length === 0">
      <mat-basic-chip *ngFor='let tag of selectedTags'
                      (removed)="remove(tag)">
        {{tag}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>`
})
export class A2vTagsAutocompleteChipsInputComponent implements OnInit {
  @ViewChild('tagsFilter') tagsFilter: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() preselectedTags: string[];
  @Input() classicChips: boolean; //if true, we stack chips under input instead of inside input
  @Input() placeholder: string;
  @Input() title: string;
  @Input() class: string;
  @Input() floatLabel: FloatLabelType;
  @Input() formFieldClass: string;
  @Input() formFieldAppearance: string;
  @Input() arbitraryInput: boolean; //if true, save button will be shown and user will be able to add custom tags
  @Output() tagSelectionChange = new EventEmitter<string[]>();

  public selectedTags: string[] = [];
  public allTags: string[] = [];

  loading: boolean;
  tagsCtrl = new UntypedFormControl();
  filteredTags: Subject<string[]> = new Subject();

  constructor(
    private readonly a2vService: A2vService,
    private snackBarService: SnackBarService) {
    this.tagsCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getAllTags(searchTerm);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedTags) {
      this.selectedTags = this.preselectedTags;
    }
  }

  public getAllTags(searchTerm: string) {
    this.loading = true;
    this.tagsCtrl.disable();
    this.a2vService.getTags({searchTerm})
      .pipe(
        finalize(() => {
          this.tagsCtrl.enable();
          this.tagsFilter.nativeElement.focus();
          this.loading = false;
        })
      )
      .subscribe(
        (response) => {
          this.allTags = response;
          this.filteredTags.next(this.allTags?.filter(x => !this.selectedTags.includes(x)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching tags');
          }
        }
      );
  }

  public optionSelected($event) {
    this.selectedTags.push($event.option.value);
    this.emit();
    this.refreshInputAndList();
  }

  public arbitraryTagInput() {
    this.selectedTags.push(this.tagsCtrl.value);
    this.refreshInputAndList();
    this.emit();
  }

  private refreshInputAndList() {
    this.tagsFilter.nativeElement.value = '';
    this.tagsCtrl.setValue('');
    this.filteredTags.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
    this.tagsFilter.nativeElement.focus();
  }


  /** Emits value back to parent component. */
  private emit() {
    this.tagSelectionChange.emit(this.selectedTags);
  }

  public remove(tag: string) {
    this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
    this.tagSelectionChange.emit(this.selectedTags);
  }

}
