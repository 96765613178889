import { Component, Input } from '@angular/core';

@Component({
  selector: 'mt-removable-message-card',
  template: `
    <div *ngIf="isVisible" class="alert alert-primary ">
      {{text}}
      <span class="close-alert" (click)="isVisible = false">
        <i class="fas fa-times"></i>
      </span>
    </div>`,
  styleUrls: ['./removable-message-card.component.scss']
})
export class RemovableMessageCardComponent {
  @Input() text: string;

  isVisible = true;

  constructor() { }

}
