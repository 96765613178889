import {UserRoleNew} from './user-role.model';

export class PrincipalMask {

  role: UserRoleNew;
  publisherId: string;
  contentOwnerId: string;
  advertiserId: string;

}
