import {KeyLabel} from '../../shared/domain/key-label.model';

export enum UserRole {
  ADMIN = 'ADMIN',
  READ_ONLY_ADMIN = 'READ_ONLY_ADMIN',
  PUBLISHER = 'PUBLISHER',
  CONTENT_OWNER = 'CONTENT_OWNER',
  SALES_REPRESENTATIVE = 'SALES_REPRESENTATIVE',
  CAMPAIGN_MANAGER = 'CAMPAIGN_MANAGER',
  CLIENT_SERVICE_MANAGER = 'CLIENT_SERVICE_MANAGER',
}

export const UserRoleLabels = {
  ADMIN: 'Admin',
  READ_ONLY_ADMIN: 'Read only Admin',
  PUBLISHER: 'Publisher',
  CONTENT_OWNER: 'Content owner',
  SALES_REPRESENTATIVE: 'Sales representative',
  CAMPAIGN_MANAGER: 'Campaign manager',
  CLIENT_SERVICE_MANAGER: 'Client service manager',
};

//TODO go through the system and remove old user role lables

export enum UserRoleNew {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  ADMIN_READ_ONLY = 'ADMIN_READ_ONLY',
  USER = 'USER',
  USER_READ_ONLY = 'USER_READ_ONLY',
  PUBLISHER = 'PUBLISHER',
  ADVERTISER = 'ADVERTISER',
  ADVERTISER_READ_ONLY = 'ADVERTISER_READ_ONLY',
}

export const UserRoleLabelsNew = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  ADMIN_READ_ONLY: 'Read only Admin',
  PUBLISHER: 'Publisher',
  USER: 'User',
  USER_READ_ONLY: 'Read only User',
  ADVERTISER: 'Advertiser',
  ADVERTISER_READ_ONLY: 'Read only advertiser',
};

export const UserRoleKLNew = () =>
  (Object.keys(UserRoleNew).filter(key => key != UserRoleNew.SUPER_ADMIN && key !== UserRoleNew.ADVERTISER && key !== UserRoleNew.ADVERTISER_READ_ONLY).map(key => new KeyLabel(<UserRoleNew> key, UserRoleLabelsNew[key])));



export const UserRoleKL = () =>
    // TODO remove sales representative in future if we want to include this role as well
  (Object.keys(UserRole).filter(key => key != UserRole.SALES_REPRESENTATIVE).map(key => new KeyLabel(<UserRole> key, UserRoleLabels[key])));

  