import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {CategoriesService} from '../../services/categories.service';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-categories-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass">
      <mat-label >{{label ? label : 'Category'}}</mat-label>
      <input matInput
             #name
             aria-label="Category"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="{{placeholder ? placeholder : ''}}">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class CategoriesAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public publisherId: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public categories: string[];

  @Output()
  categorySelected = new EventEmitter<string>();

  serverSearching = false;

  filteredItems: Subject<string[]>;
  filterCtrl = new UntypedFormControl();
  selectedCategory;
  filteredCategories: string[] = [];
  allCategories: string[] = [];

  constructor(private service: CategoriesService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedCategory) {
          this.selectedCategory = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.allCategories.length === 0 ? this.getAllCategories() : this.searchCategories();
        }
      });
  }

  resetInput() {
    this.selectedCategory = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.categories && this.categories.length) {
      this.filteredCategories = this.categories;
    }
  }

  display(category?: string) {
    return category ? category : undefined;
  }

  optionSelected($event) {
    this.selectedCategory = $event.option.value;
    if (!this.filteredCategories.includes(this.selectedCategory)) {
      this.filteredCategories.push(this.selectedCategory);
    }
    this.filteredItems.next([]);
    this.emit();
  }

  private emit() {
    this.categorySelected.emit(this.selectedCategory);
  }

  disableSearch(disable: true) {
    disable ? this.filterCtrl.disable() : this.filterCtrl.enable();
  }

  searchCategories() {
    if (this.allCategories.length > 0) {
      const categories = this.allCategories.filter(c => !this.filteredCategories.includes(c));
      const search = categories.filter(c => c.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(search.sort().splice(0, 10));
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }

  getAllCategories() {
    this.service.list().subscribe(response => {
      this.allCategories = response;
      this.searchCategories();
    }, () => {
      this.filterCtrl.enable();
      this.nameFilter.nativeElement.focus();
      this.serverSearching = false;
    });
  }

  remove(category: string) {
    this.filteredCategories.splice(this.filteredCategories.indexOf(category), 1);
  }
}
