<div class="auth login d-flex">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">


        <div class="login-content">
          <mat-card class="p-5 mb-5">
            <div class="d-block text-center login-logo mb-4">
              <img src="{{ logoUrl }}" alt="logo" height="80">
            </div>
            <mat-card-title class="mb-4 pb-3 pt-4 text-center">
              <strong>Sign in to your account</strong>
            </mat-card-title>
            <mat-card-content>
              <div>
                <form #f="ngForm" class="d-flex flex-column">
                  <mat-form-field floatLabel="" class="mb-2" appearance="fill">
                    <mat-label>Email address</mat-label>
                    <input #email
                           matInput
                           name="email" value=""
                           required
                           email
                           [(ngModel)]="user.email"
                           (keyup.enter)="f.valid &&  login()">
                  </mat-form-field>
                  <mat-form-field floatLabel=""appearance="fill" >
                    <mat-label>Password</mat-label>
                    <input type="password"
                           matInput
                           name="password"
                           value=""
                           required
                           [(ngModel)]="user.password" (keyup.enter)=" f.valid && login()">
                    <mat-hint align="end">Forgot password? <a [routerLink]="'/forgot-password'">Click here</a></mat-hint>
                  </mat-form-field>
                </form>
              </div>
              <div class="text-center mt-4">
                <button mat-raised-button type="button" color="primary" class="large login-button" (click)="login()"
                        [disabled]="!f.valid">
                  <span>Continue</span>
                </button>
              </div>
            </mat-card-content>
          </mat-card>

          <small class="d-block text-center text-muted mt-n3">
            © 2024 {{ platformTitle }}
          </small>

          <small class="d-block text-center text-white mt-n3" [hidden]="!registerLinkVisible">
            Not a member yet? <a class="text-info" [routerLink]="'/register'">Register here</a>
          </small>

        </div>

      </div>
    </div>
  </div>
</div>
