import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import _ from 'lodash';
import { RolePermission, RolePermissionService } from './role-permission.service';

@Directive({
  selector: '[csHasPermissionNew]'
})
export class HasPermissionDirectiveNew {
  private permissions: RolePermission[];

  constructor(
    private service: RolePermissionService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set csHasPermissionNew(value: string | string[]) {
    this.permissions = typeof value === 'string' ? [<RolePermission>value] : <RolePermission[]>value;
    this.updateViewNew();
  }

  private updateViewNew(): void {
    this.viewContainerRef.clear();
    if (this.service.hasPermissionsNew(this.permissions)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}

