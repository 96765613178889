import {PageRequest} from './page-request';
import {KeyLabel} from './key-label.model';
import { AdsTxtRecord } from './ads-txt.model';

export class DemandPartner {
  aEnabled  = false;
  approvedCnt: number;
  notAvailableCnt: number;
  avEnabled = false;
  bidderCode: string;
  biddingGrossNet: string;
  cbEnabled = false;
  createdBy: string;
  createdOn: string;
  defaultOnBundleCreation: boolean;
  demandPartnerParameters: DpParameters[];
  name: string;
  displayName: string;
  id?: string;
  notSubmittedCnt: number;
  pendingCnt: number;
  pricingType: string;
  revShareGrossNet: string;
  revShareValue: number;
  rejectedCnt: number;
  status: string;
  totalSites: number;
  updatedBy: string;
  updatedOn: string;
  vEnabled = false;
  vavEnabled = false;
  // adsTxtRecord: any;
  demandIntegrationsCnt: number;
  adsTxtRecords: AdsTxtRecord[];
  network: {
    id: string;
    name: string;
  };
}

export class DemandPartnerCreate {
  displayName: string;
  id: string;
  bidderCode: string;
  address: string;
  adsTxtRecord: any;
  status: string;
  pricingType: CostType;
  ioCpmCostValue: number;
  ioCpmCostMetric: IoCpmCostMetric;
  revShareValue: number;
  revShareGrossNet: GrossNet;
  approvalsRequired: boolean;
  biddingTmax: number;
  biddingBidTTL: number;
  biddingGrossNet: GrossNet;
  userSyncIframe: string;
  userSyncRedirect: string;
  includeUserIdInCacheKey: boolean = false;
  qps: number;
}

export enum IoCpmCostMetric {
  AD_REQUEST = 'AD_REQUEST',
  FILLED_IMPRESSION = 'FILLED_IMPRESSION',
  AD_IMPRESSION = 'AD_IMPRESSION'
}

export enum CostType {
  REVSHARE = 'REVSHARE',
  CPM = 'CPM'
}

export enum GrossNet {
   GROSS = 'GROSS',
   NET ='NET'
}

export class DemandPartnersRequest extends PageRequest {
  displayName: string;
  status?: DemandPartnerStatus[];
  networkIds?: string[];
}

export enum DemandPartnerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export const DemandPartnerStatusLabel = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const DemandPartnerStatusKL = () =>
  (Object.keys(DemandPartnerStatus).map(key => new KeyLabel(<DemandPartnerStatus> key, DemandPartnerStatusLabel[key])));

export class DemandPartnerStatusBox {
  count: number;
  status: string;
}

export enum DemandPartnerStatusColorMap {
  ACTIVE = 'green',
  INACTIVE = 'red'
}

export class DpParameters {
  name: string;
  nameCamelCase: string;
  uiTitle: string;
  defaultValue: string;
  required: boolean;
}
