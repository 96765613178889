import { KeyValue } from '../key-value.model';

export class DateRange {
  dateFrom: string;
  dateTo: string;
}

export enum CompareType {
  PREVIOUS = 'PREVIOUS',
  CUSTOM = 'CUSTOM'
}

export const CompareTypeLabels = {
  PREVIOUS: 'Previous',
  CUSTOM: 'Custom'
};

export const CompareTypeLabelsKV = () =>
  (Object.keys(CompareTypeLabels).map(key => new KeyValue(<CompareType>key, CompareTypeLabels[key])));
