import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CampaignsService } from '../../services/campaigns.service';
import { LineItemsAutocompleteComponent } from './line-items-autocomplete.component';

@Component({
  selector: 'mt-line-items-autocomplete-chips',
  template: `
    <div class="cs-line-items-autocomplete-chips-wrapper">
      <mt-line-items-autocomplete #pc [lineItemIds]="lineItemIds" (lineItemIdSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass" [disabled]="disabled">
      </mt-line-items-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="!disabled"
                        (removed)="remove(item)">
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class LineItemsAutocompleteChipsComponent implements OnInit, OnDestroy {

  @ViewChild(LineItemsAutocompleteComponent, {static: true}) pc: LineItemsAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public lineItemIds: string[];

  @Input()
  public formFieldClass: string;

  @Input()
  disabled: boolean;

  @Input()
  public pub: boolean;

  @Output()
  lineItemSelectionChange = new EventEmitter<string[]>();

  items: string[] = [];
  pubs: string[] = [];
  networks: string[] = [];

  private eventsSubscription: Subscription;


  constructor(private service: CampaignsService) {
  }

  ngOnInit(): void {
    if (this.lineItemIds && this.lineItemIds.length) {
      if (this.lineItemIds && typeof this.lineItemIds === 'string') {
        this.lineItemIds = [ this.lineItemIds ];
      }
      this.service.getLineItemsListForOrderId({}).subscribe(lineItems => {
        this.pc.updateFilteredLineItems(lineItems.content.filter(x => this.lineItemIds.some(y => x.id.toString() == y)));
        lineItems.content.filter(x => this.lineItemIds.some(y => x.id.toString() == y)).map(ln => this.items.push(ln));
      });
    }

  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }

  updateLineItemsChips(result: string[], type) {
    this[type] = result;
    if (!this[type].length) {
      this.items = [];
      this.pc.updateFilteredLineItems(this.items);
      this.lineItemSelectionChange.emit(this.items);
      return false;
    }
  }

  onItemSelected($event: string) {
    if ($event && !this.items.find(x => x === $event)) {
      this.items.push($event);
      this.lineItemSelectionChange.emit(this.items);
    }
    this.pc.resetInput();
    this.pc.updateFilteredLineItems(this.items);
  }

  remove(p: string) {
    this.items.splice(this.items.indexOf(p), 1);
    this.lineItemSelectionChange.emit(this.items);
    this.pc.updateFilteredLineItems(this.items);
  }

  disableSearch(disable: true) {
    this.pc.disableSearch(disable);
    if (disable) {
      this.items = [];
      this.lineItemSelectionChange.emit(this.items);
    }
  }
}
