import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {Utils} from '../../../utils/utils';

@Component({
  selector: 'mt-image-upload-preview',
  templateUrl: './image-upload-preview.component.html',
  styleUrls: ['./image-upload-preview.component.scss']
})
export class ImageUploadPreviewComponent {

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  @Output()
  public fileSelected: EventEmitter<File> = new EventEmitter();

  @Output()
  public fileCanceled: EventEmitter<string> = new EventEmitter();

  @Input()
  public label: string;

  @Input()
  public imageSize: number;

  @Input()
  public allowedFileTypes: string;

  @Input()
  public resolutonGreater: boolean;

  @Input()
  public tooltip: string;

  @Input()
  public resolutionWidth: string;

  @Input()
  public resolutionHeight: string;

  @Input()
  public fileName = true;

  @Input()
  public enableRemove = true;

  file: File;

  public id = Utils.generateGuid();

  @Input()
  public imagePreview;

  constructor(private sbs: SnackBarService,){}

  handleFileInput(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (this.resolutionWidth && this.resolutionHeight) {
          if (this.resolutonGreater) {
            this.checkImageResolutionGreater(file, reader.result);
            return;
          }
          this.checkImageResolutionEqual(file, reader.result);
        } else {
          this.imagePreview = reader.result;
        }
      };

      reader.readAsDataURL(file);

      this.file = event.target.files[0];
      this.fileSelected.emit(this.file);
    }
  }

  open() {
    this.fileInput.nativeElement.click();
  }

  checkImageResolutionEqual(file, result) {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = (e: any) => {
      const height = e.target.height;
      const width = e.target.width;

      if (this.resolutionWidth != width || this.resolutionHeight != height) {
        this.removeImage();
        this.sbs.error(`Image resolution must be ${this.resolutionWidth}x${this.resolutionHeight}`);
        return false;
      } else {
        this.imagePreview = result;
      }
    };
  }

  checkImageResolutionGreater(file, result) {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = (e: any) => {
      const height = e.target.height;
      const width = e.target.width;

      if (this.resolutionWidth >= width || this.resolutionHeight >= height) {
        this.removeImage();
        this.sbs.error(`Image resolution must be greater than ${this.resolutionWidth}x${this.resolutionHeight}`);
        return false;
      } else {
        this.imagePreview = result;
      }
    };
  }

  getFile() {
    return this.file;
  }

  removeImage() {
    const fileName = this.file ? this.file.name : null;
    this.file = null;
    this.imagePreview = null;
    this.fileCanceled.emit(fileName);
  }
}
