import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { GlobalSpinnerService } from 'src/app/core/services/global-spinner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { Publisher } from 'src/app/shared/domain/publisher.model';
import { ServerErrorUtils } from 'src/app/shared/utils/server-error-utils';
import { VideosService } from 'src/app/shared/services/videos.service';
import { Video } from 'src/app/shared/domain/video.model';
import { FlagAutocompleteChipsComponent } from 'src/app/shared/components/utils/flags-autocomplete-chips';
import { MomentDateUtils } from 'src/app/shared/utils/moment-date-utils';
import { OwnerType, OwnerTypeKL } from 'src/app/shared/domain/feed.model';

@Component({
  selector: 'mt-video-metadata-dialog',
  templateUrl: './video-metadata-dialog.component.html',
  styleUrls: ['./video-metadata-dialog.component.scss']
})
export class VideoMetadataDialog implements OnInit, AfterViewInit {
  @ViewChild(FlagAutocompleteChipsComponent) autoFlag;

  today = MomentDateUtils.today();
  video: Video;
  metaTag: string;
  metaGenre: string;
  publishedAt: string;
  flags: string[];
  ownerTypeKL = OwnerTypeKL();
  ownerType = OwnerType;

  constructor(public dialogRef: MatDialogRef<VideoMetadataDialog>,
              private service: VideosService,
              private gss: GlobalSpinnerService,
              private clipboardService: ClipboardService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.video = JSON.parse(JSON.stringify(data.element));
    }
  }

  ngOnInit(): void {
    this.gss.showLoader();
    this.service.flagsList().subscribe((response) => {
      this.flags = response;
      this.gss.hideLoader();
    }, e => {
      this.snackBarService.error('Error occurred during getting flags');
      this.gss.hideLoader();
    });
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('700px');
  }

  removeTag(item) {
    this.video.tags.splice(this.video.tags.indexOf(item), 1);
  }

  addTag(value) {
    this.video.tags.push(value);
    this.metaTag = '';
  }

  removeGenre(item) {
    this.video.genres.splice(this.video.genres.indexOf(item), 1);
  }

  addGenre(value) {
    this.video.genres.push(value);
    this.metaGenre = '';
  }

  filterFlagsChange(items: string[]) {
    this.video.flags = items;
  }

  dateChange(event) {
    const offsetMs = event.value.getTimezoneOffset() * 60000;
    this.video.publishedAt = new Date(event.value.getTime() - offsetMs);
  }


  saveMetadata() {
    const request = {
      id: this.video.id,
      title: this.video.title,
      description: this.video.description,
      artist: this.video.artist,
      publishedAt: this.video.publishedAt,
      imageUrls: this.video.imageUrls,
      posterUrls: this.video.posterUrls,
      category: this.video.category,
      tags: this.video.tags,
      genres: this.video.genres,
      flags: this.video.flags,
      ownerType: this.video.ownerType,
      coId: this.video.coId
    };
    this.gss.showLoader();
    this.service.updateVideo(request).subscribe(() => {
      this.gss.hideLoader();
      this.dialogRef.close(true);
      this.snackBarService.success('Metadata updated successfully');
    }, e => {
      ServerErrorUtils.serverValidationOrMsg(e, this.snackBarService, 'Error occurred during publisher updating');
      this.gss.hideLoader();
    });
  }
}
