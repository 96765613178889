import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {GlobalSpinnerService} from '../../../core/services/global-spinner.service';
import {Playlist} from '../../../shared/domain/playlist.model';
import {VideosService} from '../../../shared/services/videos.service';
import {ServerErrorUtils} from '../../../shared/utils/server-error-utils';
import {PlaylistEditorDialogComponent} from '../../playlists/playlist-editor-dialog.component';

@Component({
  selector: 'mt-add-video-to-playlist-dialog',
  templateUrl: './add-video-to-playlist-dialog.component.html',
  styleUrls: ['./add-video-to-playlist-dialog.component.scss']
})
export class AddVideoToPlaylistDialogComponent implements AfterViewInit {

  videos: string[];
  playlists: string[] = [];

  constructor(public dialogRef: MatDialogRef<AddVideoToPlaylistDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { videos: string[] },
              private snackBarService: SnackBarService,
              private gss: GlobalSpinnerService,
              private service: VideosService,
              private matDialog: MatDialog) {
    this.videos = data.videos;
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('500px');
  }

  playlistSelectionChange(playlists: Playlist[]) {
    this.playlists = [];
    playlists.map(p => this.playlists.push(p.id));
  }

  addVideosToPlaylists() {
    this.gss.showLoader();
    this.service.addVideosToPlaylists(this.playlists, this.videos).subscribe(
      response => {
        this.gss.hideLoader();
        this.dialogRef.close(true);
        this.snackBarService.success('You have successfully added videos to collections');
      },
      error => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(error));
        } else {
          this.snackBarService.error('Error occurred during add videos to collections');
        }
        this.gss.hideLoader();
      }
    );
  }

  createNewPlaylist() {
    this.matDialog.open(PlaylistEditorDialogComponent);
  }
}
