<h1 mat-dialog-title>
  {{data && data.title || 'Please confirm your action'}}
</h1>
<div mat-dialog-content>
  <p style="white-space: pre-line">{{data?.subtitle || 'Are you sure?'}}</p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end mb-n2">
  <button mat-flat-button mat-dialog-close cdkFocusInitial *ngIf="data?.confirmButtonText !== 'Ok'">Cancel</button>
  <button mat-flat-button
          [color]="data?.confirmButtonColor ? data?.confirmButtonColor : 'primary'"
          [mat-dialog-close]="true"
  >
    {{ data?.confirmButtonText || 'Yes!' }}
  </button>
</div>
