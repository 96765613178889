<div mat-dialog-title>Changelog {{data.title}}</div>

<div mat-dialog-content class="mat-card-content--full p-0">

  <div class="page-loader" [hidden]="!loading">
    <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
    </mat-spinner>
  </div>

  <div class="data-alert" [hidden]="loading || (dataSource.data && dataSource.data.length)">
    <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
  </div>

  <div class="table-scroll-wrapper d-block border-top" [hidden]="!dataSource.data || !dataSource.data.length">
    <mat-table #table [dataSource]="dataSource" matSort matSortActive="createdOn"
      matSortDirection="desc">

      <ng-container matColumnDef="createdOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong>{{element.createdOn | date: 'MM/dd/yyyy'}}</strong>
          <small>{{element.createdOn | date: 'HH:mm'}}</small>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong>{{element.type}}</strong>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fieldName">
        <mat-header-cell *matHeaderCellDef>Field</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong matTooltip="{{element.fieldName}}" class="field-value">{{element.fieldName}}</strong>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="oldValue">
        <mat-header-cell *matHeaderCellDef>Old value</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong matTooltip="{{element.oldValue}}" class="changelog-value">{{(element.oldValue !== null  && element.oldValue !== "") ? element.oldValue : 'N/A'}}</strong>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="arrow">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong>&rarr;</strong>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="newValue">
        <mat-header-cell *matHeaderCellDef>New value</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong matTooltip="{{element.newValue}}" class="changelog-value">{{(element.newValue !== null && element.newValue !== "") ? element.newValue : 'N/A'}}</strong>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef>User</mat-header-cell>
        <mat-cell *matCellDef="let element">

          <strong>{{element.createdBy}}</strong>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columns"></mat-row>

    </mat-table>
  </div>
</div>

<div class="border-bottom mx-n4" [hidden]="!dataSource.data || !dataSource.filteredData.length">
  <mat-paginator #paginator [pageSize]="filter.size" [pageSizeOptions]="[5, 10, 25, 50, 100]">
  </mat-paginator>
</div>

<div mat-dialog-actions class="">
  <button mat-stroked-button [mat-dialog-close]="true">Close</button>
</div>
