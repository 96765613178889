<mat-toolbar>
  <mat-toolbar-row>
    <div class="row align-items-center no-gutters w-100 py-3 py-md-0">
      <div class="col-6 col-md">
        <div class="toolbar-logo">
        </div>
      </div>
      <div class="col-12 col-md-auto order-2 order-md-0">
        <nav mat-tab-nav-bar mat-align-tabs="start">
          <a mat-tab-link
             *ngFor="let link of navLinks"
             [routerLink]="link.path"
             routerLinkActive #rla="routerLinkActive"
             [active]="rla.isActive"
             [disabled]="link.disabled"
             matTooltip="{{link.tooltip}}">
            {{link.label}}
          </a>
        </nav>
      </div>
      <div class="col-6 col-md">
        <div class="d-flex justify-content-end align-items-center">
          <div class="toolbar-user-info text-muted">
            <span class="toolbar-user-name">{{principal.user?.name}}</span>
            <small class="toolbar-user-company">{{principal.publisher?.name}}</small>
          </div>
          <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="mat-elevation-z0">
            <i class="fal fa-user" aria-hidden="true"></i>
          </button>
        </div>
        <mat-menu #menu="matMenu" [overlapTrigger]="true">
          <button mat-menu-item (click)="logout()">
            <i class="far fa-sign-out"></i>
            <span>Sign out</span>
          </button>
          <mat-divider *ngIf="principal.user?.roles.includes(userRole.ADMIN)"></mat-divider>
          <button mat-menu-item *ngIf="principal.user?.roles.includes(userRole.ADMIN)" (click)="changeUrl('main')">
            <span>Main</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item *ngFor="let publisher of publishers" (click)="changeUrl('publisher', publisher.id)">
            <span>{{publisher.name}}</span>
          </button>

          <mat-divider *ngIf="contentOwners"></mat-divider>
          <button mat-menu-item disabled *ngIf="contentOwners">
            <span>Content owner dashboard</span>
          </button>
          <div>
            <span *ngFor="let co of contentOwners">
              <button mat-menu-item (click)="changeUrl('co')">
                <span class="ml-2">{{co.name}}</span>
              </button>
            </span>
          </div>

        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>

  <mat-toolbar-row class="">
    <ng-content></ng-content>
  </mat-toolbar-row>
</mat-toolbar>
