import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { KeyLabel } from './key-label.model';
import {Targeting} from './targeting.model';

export class Placements implements Podding, Exclusions {
  type: string;
  adUnit?: AdUnit;
  placementDemandIntegrations?:PlacementDpIntegration[];
  position: string;
  bidFloor: number;
  cpm: number;
  qpsLimit: number;
  shareOfVoice: number;
  createdBy: string;
  createdOn: Date;
  description: string;
  demandIntegrationSyncDtos: any;
  demandIntegrationsCnt: number;
  gamBackfillAdUnit: string;
  gamEnabled: boolean;
  debug: boolean;
  vwAdUnit: string;
  id: string;
  name: string;
  networkId: string;
  productType: ProductTypePlacement;
  deliveryType: DeliveryType;
  updatedBy: string;
  updatedOn: Date;
  status: PlacementStatus;
  secondaryId: string;
  poddingEnabled: boolean;
  ignoreCompliance: boolean;
  dvExclusionsEnabled: boolean;
  // multi slot vast
  maxPodSlotCount: number;
  lineItemMargin: number;
  podFloor: number;
  minPodDuration: number;
  maxPodDuration: number;
  deduplicateUniqueMediaFile: boolean;
  deduplicateUniqueAdvertiserDomain: boolean;
  deduplicateUniqueIABCategory: boolean;
  exclusionsEnabled: boolean;
  advertiserDomains: string[];
  advertiserDomainsValue: string;
  iabCategoriesExcluded: string[];
  targetingCnt: number;
  targetingList: Array<Targeting>;
  cache: boolean;
  impressionTrackers: Array<string>;
  publishersIds: string[]; //pubId I guess
  notes: string;
  xpParametersMappings: { value: string; xpParameter: string }[];
  overridePublisherXpParametersMapping: boolean;
  publishers: {
    id: string;
    xpParametersMappings: { value: string; xpParameter: string }[];
    name: string;
    network: {
      id: string;
      name: string;
      status: string;
    };
    secondaryId: string;
    status: string;
    allProductsCost: {
      value;
      costType;
    };
  }[];
  stats: {
    global7daysRevenuePct: number;
    network7daysRevenuePct: number;
  };
  maxAdDuration: number;
  ssai: boolean;
  passToDemandAppData: boolean;
  passToDemandAppStoreIds: boolean;
  blockBidsWithoutCatOrAdomain: boolean;
  connectionType: string;
  ivtEnabled: boolean;
  ivtType: IvtType;
  ivtIntegrationType: string;
  rewriteVastEnabled: boolean;
  rewriteVastVersion: number;

  bcAvEnabled: boolean;
  bcVideoEnabled: boolean;
  bcAlwaysUseCache: boolean;

  stitcherEnabled: boolean;
  stAcceptPercentageOfFloor: number;
  stMaxBids: number;
  stMaxAvBids: number;
  stMaxVideoBids: number;
  stMaxBidsFromCache: number;
  stIgnoreAdDuration: boolean;
  stPoddingFloor: number;
  stFeeCpm: number;
  stDedupeEnabled: boolean;
  stDedupeUniqueMediaFile: boolean;
  stDedupeUniqueAdvertiserDomain: boolean;
  stDedupeUniqueIABCategory: boolean;

  cpmCapEnabled: boolean;
  cpmCapMin: number;
  cpmCapMax: number;
  cpmCapIncrement: number;

  upstreamCpmCapEnabled: boolean;
  upstreamCpmCapMinCpm: number;
  upstreamCpmCapMaxCpm: number;

  dmEnabled: boolean;
  dmShortTimeoutMs: number;
  dmLongTimeoutMs: number;
  pricingType: string;
  pricing: number;
}

export class AdUnit{
  name?: string;
  widgetName: string;
  publisherDomains: PublisherDomain[] = [];
  video: boolean;
  adWidth:number;
  adHeight: number;
  devicePlacement: string;
  s2s: boolean;
  bidder: boolean;
  unitConfig: UnitConfig;
  widgetId?: string;
}

export class PlacementDpIntegration{
  placementId?: string;
  demandIntegrationId?: string;
  siteId?: string;
  bidAdjustment?: number;
  bidFloor?: number;
  approvalStatus?: string;
  unitActivated?: any;
  bidType?: any;
  duplication?: boolean;
  revenueLast7Days?: any;
  adRequestsLast7Days?: any;
  publisherId?: string;
  app_id?: string;
  tag_id?: string;
  zone_id?: string;
  display_manager?: string;
  triplelift_inventory_code?: string;
  openx_del_domain?: string;
  unruly_targeting_uuid?: string;
  openx_unit_id?: string;
  smart_ad_server_page_id?: string;
  smart_ad_server_network_id?: string;
  smart_ad_server_format_id?: string;
  stats?: any
}

export class UnitConfig {
  viewIn: string;
  viewOut: string;
  adLoadDelay: number;
  closeButton: boolean;
  adhesion:boolean;
  noFillHideUnit: boolean;
  idSystemIdentityLink: boolean;
  ibv: boolean;
  refreshRate: number;
  maxRefresh: number;
  weMakeSticky: boolean;
  placementId?: string;
}

export class unitBuilderConfig {
  placementId?: string;
  adWidth?: number;
  adHeight?: number;
  widgetWidth?: number;
  widgetHeight?: number;
  widgetType?: string;
  cdnUrl?: string | null;
  iframeBreakout?: boolean;
  noFillHideUnit?: boolean;
  bidCache?: boolean;
  ibv?: boolean;
  confiantWrapperId?: string | null;
  gptName?: string | null;
  gptDiv?: string;
  pixelUrl?: string | null;
  prebidSyncPerBidder?: string | null;
  refreshRate?: number;
  maxRefresh?: number;
  closeButton?: boolean;
  adLoadDelay?: number;
  bidTimeout?: number;
  idSystemIdentityLink?: boolean;
  cssContent?: string;
  publisherId?: string;
  pageMarginBottom?: number | null;
  adhesion?: boolean;
  customCssApplied?: boolean;
  weMakeSticky?: boolean;
  s2s?: boolean;
  overbidEnabled?: boolean;
  overbidPercentage?: number | null;
  position?: string | null;
  bidderParams?: {
    placementId?: string;
    kulturemediaId?: string | null;
  };
  viewIn?: string | null;
  viewOut?: string | null;
  bidderMaxIntervals?: object;
  bidderCaps?: object;
  bidderFloors?: object;
  bidderAdjustments?: string;
  createdOn?: string | null;
  updatedOn?: string | null;
}

export class PublisherDomain {
    publisherId: string;
    domainId: string;
}

export enum PlacementType {
  ADUNIT = "ADUNIT",
  STANDARD = "STANDARD"
}

export interface Podding {
  poddingEnabled: boolean;
  // multi slot vast
  maxPodSlotCount: number;
  podFloor: number;
  minPodDuration: number;
  maxPodDuration: number;
  deduplicateUniqueMediaFile: boolean;
  deduplicateUniqueAdvertiserDomain: boolean;
  deduplicateUniqueIABCategory: boolean;
}

export class AdServerTypeMacros {
  adServerType: string;
  name: string;
  value: string;
  description: string;
}

export class PublisherCellData {
  id: string;
  name: string;
  network: {
    id: string;
    name: string;
    status: string;
  };
  status: string;
}

export interface Exclusions {
  exclusionsEnabled: boolean;
  /**
   * this is used for backed communication
   */
  advertiserDomains: string[];
  /**
   * This is used to bind with textarea
   */
  advertiserDomainsValue: string;

  iabCategoriesExcluded: string[];
}

export enum PlacementPricingType {
  REVSHARE = 'REVSHARE',
  CPM = 'CPM',
  NONE = 'NONE',
}

export interface ConnectionPlacement {
  name: string;
  status: string;
  bidFloor: number;
  productType: string;
}

export class PlacementStatusBox {
  count: number;
  status: string;
}

export class PlacementStatusListing {
  ACTIVE: number;
  INACTIVE: number;
  ARCHIVED: number;
}

export enum PlacementStatusColorMap {
  ACTIVE = 'green',
  INACTIVE = 'red',
  ARCHIVED = 'gray',
}

export enum ApprovalStatus{
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
  N_A = 'N_A'
}

export const ApprovalStatusLabels = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  SUBMITTED: 'Submitted',
  N_A: 'N/A'
};

export enum ProductTypePlacement {
  VIDEO = 'VIDEO',
  AUDIO_BACKFILL = 'AUDIO_BACKFILL',
  CONTENT_BYTE = 'CONTENT_BYTE',
  VIDEO_AUDIO_VIDEO = 'VIDEO_AUDIO_VIDEO',
  BACKFILL = 'BACKFILL',
  VAST_WEB = 'VAST_WEB',
}

export enum MediaType {
  BANNER = 'BANNER',
  NATIVE = 'NATIVE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export enum MediaTypeLabels {
  BANNER = 'Banner',
  NATIVE = 'Native',
  VIDEO = 'Video',
  AUDIO = 'Audio'
}

export enum DeliveryType {
  APP = 'APP',
  CTV = 'CTV',
  WEB = 'WEB',
}

export enum DeliveryTypeLabels {
  APP = 'App',
  CTV = 'CTV',
  WEB = 'Web',
}

export enum IvtType {
  DOUBLE_VERIFY = 'DOUBLE_VERIFY',
  MOAT = 'MOAT',
  HUMAN = 'HUMAN',
}

export enum IvtTypeLabels {
  DOUBLE_VERIFY = 'Double Verify',
  MOAT = 'Moat',
  HUMAN = 'Human',
}

export const ProductTypePlacementLabels = {
  VIDEO: 'Video',
  AUDIO_BACKFILL: 'Audio to Video',
  VIDEO_AUDIO_VIDEO: 'Video + Audio to Video',
  BACKFILL: 'Backfill',
  VAST_WEB: 'Vast Web',
};

export const ApprovalStatusKL = () =>
  Object.keys(ApprovalStatus).map(
    (key) =>
      new KeyLabel(<ApprovalStatus>key, ApprovalStatusLabels[key])
  );

export const ProductTypePlacementKL = () =>
  Object.keys(ProductTypePlacement).map(
    (key) =>
      new KeyLabel(<ProductTypePlacement>key, ProductTypePlacementLabels[key])
  );
export const DeliveryTypeKL = () =>
  Object.keys(DeliveryType).map(
    (key) =>
      new KeyLabel(<DeliveryType>key, DeliveryTypeLabels[key])
  );
export const MediaTypeKL = () =>
    Object.keys(MediaType).map(
        (key) =>
            new KeyLabel(<MediaType>key, MediaTypeLabels[key])
    );
export const IvtTypeKL = () =>
  Object.keys(IvtType).map(
    (key) =>
      new KeyLabel(<IvtType>key, IvtTypeLabels[key])
  );

export enum PlacementStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const PlacementStatusLabels = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ARCHIVED: 'Archived',
};

export const PlacementStatusKL = () =>
  Object.keys(PlacementStatus).map(
    (key) => new KeyLabel(<PlacementStatus>key, PlacementStatusLabels[key])
  );
  export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return !!(control && control.invalid && (control.dirty || control.touched));
    }
  }

