import { CurrencyPipe, formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  MasterTableColumn,
  MasterTableDataType,
} from 'src/app/shared/domain/master-table/master-table-column';
import {ActiveInactiveArchived, ActiveInactiveArchivedLabels} from "../../../enums/active-inactive-archived.enum";

@Pipe({
  name: 'masterTablePipe',
})
export class MasterTablePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(object: Object, column: MasterTableColumn): any {
    const data = this.getValue(object, column.dataKey);
    switch (column.dataType) {
      case MasterTableDataType.DATE:
        return formatDate(data, 'MM/dd/yyyy', 'en-US');
      case MasterTableDataType.CURRENCY:
        return this.currencyPipe.transform(data || 0);
      case MasterTableDataType.STATUS:
        return ActiveInactiveArchivedLabels[data];
      default:
        return data;
    }
  }

  private getValue(obj: Object, path: string) {
    if (!obj || !path) {return obj;}
    const properties = path.split('.');
    return this.getValue(obj[properties.shift()], properties.join('.'));
  }
}


