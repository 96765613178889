import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Utils} from '../../../utils/utils';

@Component({
  selector: 'mt-video-upload-preview',
  templateUrl: './video-upload-preview.component.html',
  styleUrls: ['./video-upload-preview.component.scss']
})
export class VideoUploadPreviewComponent {

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  @Output()
  public fileSelected: EventEmitter<File> = new EventEmitter();

  @Output()
  public fileCanceled: EventEmitter<string> = new EventEmitter();

  @Input()
  public label: string;

  @Input()
  public fileName = true;

  file: File;

  public id = Utils.generateGuid();

  @Input()
  public videoPreview;

  handleFileInput(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.videoPreview = reader.result;

      reader.readAsDataURL(file);

      this.file = event.target.files[0];
      this.fileSelected.emit(this.file);
    }
  }

  open() {
    this.fileInput.nativeElement.click();
  }

  getFile() {
    return this.file;
  }

  removeVideo() {
    const fileName = this.file ? this.file.name : null;
    this.file = null;
    this.videoPreview = null;
    this.fileCanceled.emit(fileName);
  }
}
