<label [for]="id" *ngIf="label" class="mt-upload-button-label">
  {{label}} <i class="fas fal fa-info-circle" [matTooltip]="tooltip" *ngIf="tooltip"></i>
  <span class="ml-3" *ngIf="imageSize || imageSize === 0"><strong>{{imageSize}}MB</strong></span>
</label>
<label [for]="id" class="mt-upload-button" *ngIf="!imagePreview && !file">
  <span class="fal fa-upload text-primary mr-3"></span>
  <span>Upload</span>
  <input type="file" class="d-none"
         [id]="id" #fileInput
         (change)="handleFileInput($event)"
         [accept]="allowedFileTypes">
</label>
<div *ngIf="imagePreview" class="mt-upload-preview">
    <div class="mt-upload-preview-image" ngClass="{{fileName ? 'col-4' : 'col-12'}}">
      <img [src]="imagePreview" alt="" width="100%">
      <div *ngIf="enableRemove" class="mt-upload-preview-delete">
        <button type="button" mat-mini-fab color="warn" class="mat-elevation-z0" (click)="removeImage()" matTooltip="Delete">
          <i class="fal fa-trash-alt" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="col mt-upload-preview-name" *ngIf="fileName">
      <p class="mb-0">
        <small>File name:</small> <br>
        <span>{{file.name}}</span>
      </p>
    </div>
</div>
