import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Platform } from '../domain/platform.model';

@Injectable({
  providedIn: 'root',
})
export class PlatformsService {
  constructor(private httpClient: HttpClient) {
  }

  listActivePlatforms(): Observable<Platform[]> {
    return this.httpClient.get<Platform[]>(`${environment.serviceUrl}/api/apps/platforms`);
  }

}
