import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterExtService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  navigateToPreviousUrlOr(alternativeRoute?: string) {
    if (!this.previousUrl || this.isPreviousRoot()) {
      this.navigateByUrl(alternativeRoute || '/');
    } else {
      this.navigateByUrl(this.previousUrl);
    }
  }

  navigateByUrl(param: string) {
    this.router.navigateByUrl(param);
  }

  isPreviousRoot() {
    return this.previousUrl === '/';
  }
}
