import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PublisherAutocompleteComponent} from './publisher-autocomplete.component';
import {Publisher, PublisherStatusColorMap} from '../../domain/publisher.model';
import {PublisherListFilter, PublishersService} from '../../services/publishers.service';
import { Observable } from 'rxjs';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-publisher-autocomplete-chips',
  template: `
      <mt-publisher-autocomplete #pc
                                  [networkIds]="networkIds"
                                  (publisherSelected)="onItemSelected($event)"
                                  [formFieldClass]="formFieldClass"
                                  [required]="required"
                                  [label]="placeholder"
                                  [exchangeOnly]="exchangeOnly"
                                  [floatLabel]="floatLabel"
                                  [formFieldAppearance]="formFieldAppearance ? formFieldAppearance : ''">
      </mt-publisher-autocomplete>
      <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        (removed)="remove(item)">
        <small class="fas fa-circle ml-n2 mr-2" ngClass="text-{{PublisherStatusColorMap[item.status]}}"></small>
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
  `,
})
export class PublisherAutocompleteChipsComponent implements OnInit {
  @ViewChild(PublisherAutocompleteComponent, {static: true}) pc: PublisherAutocompleteComponent;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter pubs for that given network*/
  @Input() networkIdsChanged: Observable<string[]>;

  /** Pass clearFilterItems = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  exchangeOnly = false;

  @Input()
  public publisherIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  /** Hold list of networkIds to filter pubs only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  required = false;

  @Output()
  publisherSelectionChange = new EventEmitter<Publisher[]>();

  items: Publisher[] = [];

  PublisherStatusColorMap = PublisherStatusColorMap;

  constructor(private service: PublishersService) {
  }

  ngOnInit(): void {
    if (this.publisherIds && this.publisherIds.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.publisherIds === 'string') {
        this.publisherIds = [this.publisherIds];
      }
      //Fetch only preselected publishers
      this.service.listPublishers({ids: this.publisherIds} as PublisherListFilter).subscribe(publishers => {
        publishers.content.map(pub => {this.items.push(pub);});
        this.pc.updateFilteredPublishers(this.items);
      });
    }

    this.networkIdsChanged?.subscribe((result) => this.updatePublisherIdsChips(result));
    this.clearItems?.subscribe(() => this.clearFilter());
  }

  /** Remove items that are not part of given network/s. */
  updatePublisherIdsChips(networks: string[]) {
    this.items = this.items.filter(x => networks.includes(x.network?.id));
    this.pc.updateFilteredPublishers(this.items);
  }

  onItemSelected($event: Publisher) {
    if ($event) {
      this.items.push($event);
      this.publisherSelectionChange.emit(this.items);
      this.pc.resetInput();
      this.pc.updateFilteredPublishers(this.items);
    }
  }

  remove(p: Publisher) {
    this.items.splice(this.items.indexOf(p), 1);
    this.publisherSelectionChange.emit(this.items);
    this.pc.updateFilteredPublishers(this.items);
  }

  /** Clear all items from filter. */
  clearFilter(){
    this.items = [];
    this.pc.updateFilteredPublishers(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.pc.disable();
    }
    else {
      this.pc.enable();
    }
  }
}
