import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    return value ? this.fancyTimeFormat(value) : '00:00';
  }

  fancyTimeFormat(time: number): string {
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor(time % 60);
    let ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':';
    }
    ret += '' + (mins < 10 ? '0' + mins : mins) + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  }

}
