import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {ConnectionStatusColorMap, DemandPartnerConnection, DemandPartnersConnectionRequest} from '../../domain/demand-partner-connection.model';
import {DemandPartnersService} from '../../services/demand-partners.service';
import {ConnectionsAutocompleteComponent} from './dp-connections-autocomplete.component';
import {FloatLabelType} from "@angular/material/form-field";

interface ConnectionGroup {
  name: string;
  connection: DemandPartnerConnection[];
}

@Component({
  selector: 'mt-dp-connections-autocomplete-chips',
  template: `
    <mt-dp-connections-autocomplete #pc
                                     [networkIds]="networkIds"
                                     (connectionSelected)="onItemSelected($event)"
                                     [formFieldClass]="formFieldClass"
                                     [formFieldAppearance]="formFieldAppearance"
                                     [required]="required"
                                     [label]="placeholder"
                                     [floatLabel]="floatLabel">
    </mt-dp-connections-autocomplete>
    <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
      <mat-basic-chip *ngFor="let item of items"
                      (removed)="remove(item)">
        <i class="fas fa-circle mr-1 ml-0" ngClass="text-{{ConnectionStatusColorMap[item.status]}}"></i>
        {{item.name}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  `,
})
export class ConnectionsAutocompleteChipsComponent implements OnInit {
  @ViewChild(ConnectionsAutocompleteComponent, {static: true}) pc: ConnectionsAutocompleteComponent;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter pubs for that given network*/
  //@Input() networkIdsChanged: Observable<string[]>;

  /** Pass clearFilterItems = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public integrationIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  /** Hold list of networkIds to filter connections only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  required = false;

  @Output()
  connectionSelectionChange = new EventEmitter<DemandPartnerConnection[]>();

  items: DemandPartnerConnection[] = [];
  public ConnectionStatusColorMap = ConnectionStatusColorMap;

  constructor(private service: DemandPartnersService) {
  }

  ngOnInit(): void {
    if (this.integrationIds && this.integrationIds.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.integrationIds === 'string') {
        this.integrationIds = [this.integrationIds];
      }
      //Fetch only preselected publishers
      this.service.getAllIntegrations({ids: this.integrationIds} as DemandPartnersConnectionRequest).subscribe(publishers => {
        publishers.content.map(pub => {
          this.items.push(pub);
        });
        this.pc.updateFilteredConnections(this.items);
      });
    }
    this.clearItems?.subscribe(() => this.clearFilter());
  }

  onItemSelected($event: DemandPartnerConnection) {
    if ($event) {
      this.items.push($event);
      this.connectionSelectionChange.emit(this.items);
      this.pc.resetInput();
      this.pc.updateFilteredConnections(this.items);
    }
  }

  remove(p: DemandPartnerConnection) {
    this.items.splice(this.items.indexOf(p), 1);
    this.connectionSelectionChange.emit(this.items);
    this.pc.updateFilteredConnections(this.items);
  }

  /** Clear all items from filter. */
  clearFilter() {
    this.items = [];
    this.pc.updateFilteredConnections(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.pc.disable();
    }
    else {
      this.pc.enable();
    }
  }
}
