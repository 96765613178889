import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CategoriesAutocompleteComponent} from './categories-autocomplete.component';

@Component({
  selector: 'mt-categories-autocomplete-chips',
  template: `
    <div class="cs-publisher-autocomplete-chips-wrapper">
      <mt-categories-autocomplete #cat [categories]="categories"
                                  (categorySelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </mt-categories-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class CategoriesAutocompleteChipsComponent implements OnInit {

  @ViewChild(CategoriesAutocompleteComponent, {static: true}) cat: CategoriesAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public categories: string[];

  @Input()
  public formFieldClass: string;

  @Output()
  categorySelectionChange = new EventEmitter<string[]>();

  items: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.categories && this.categories.length) {
      // this.items = this.categories;
      this.categories.map(c => this.onItemSelected(c));
    }
  }

  onItemSelected(category: string) {
    if (category && !this.items.includes(category)) {
      this.items.push(category);
      this.categorySelectionChange.emit(this.items);
    }
    this.cat.resetInput();
  }

  remove(category: string) {
    this.items.splice(this.items.indexOf(category), 1);
    this.cat.remove(category);
    this.categorySelectionChange.emit(this.items);
  }

  disableSearch(disable: true) {
    this.cat.disableSearch(disable);
    if (disable) {
      this.items = [];
      this.categorySelectionChange.emit(this.items);
    }
  }
}
