import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {CategoriesService} from '../../services/categories.service';
import {ServerErrorUtils} from '../../utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {FloatLabelType} from "@angular/material/form-field";

export class CategoriesGroup {
  name: string;
  categories: string[];
}

@Component({
  selector: 'mt-categories-multi-select',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label >{{label ? label : 'Category'}}</mat-label>
      <mat-select [required]="required" [multiple]="multiple" [(ngModel)]="selectedCategory" (selectionChange)="optionSelected()">
        <mat-optgroup *ngFor="let group of categories" [label]="group.name">
          <mat-option *ngFor="let category of group.categories" [value]="category">
            {{category}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  `
})
export class CategoriesMultiSelectComponent implements OnInit, OnDestroy {

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public required: boolean;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public selectedCategory;

  @Input()
  public multiple: boolean;

  @Output()
  categorySelected = new EventEmitter();

  topCategories: string[] = ['Movie', 'Music', 'Food', 'Sports', 'Travel', 'Gaming', 'Science', 'Autos', 'Entertainment', 'News'];
  sbs: Subscription;
  loading: boolean;
  categories: CategoriesGroup[] = [];

  constructor(private service: CategoriesService,
              private snackBarService: SnackBarService) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    if (this.sbs) {
      this.sbs.unsubscribe();
    }
    this.loading = true;
    this.sbs = this.service.list().subscribe(
      response => {
        const top: string[] = [];
        const other: string[] = [];
        response.map(c => this.topCategories.includes(c) ? top.push(c) : other.push(c));
        this.categories = [
          {name: 'Top categories', categories: top},
          {name: 'Other categories', categories: other},
        ];
        this.loading = false;
      },
      error => {
        this.loading = false;
        ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error occurred while fetching categories');
      }
    );
  }

  optionSelected() {
    this.categorySelected.emit(this.selectedCategory);
  }

  ngOnDestroy() {
    this.sbs.unsubscribe();
  }
}
