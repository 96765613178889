import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DpAutocompleteComponent } from './dp-autocomplete.component';
import { DemandPartner, DemandPartnersRequest } from '../../domain/demand-partner.model';
import { Observable, Subscription } from 'rxjs';
import { DemandPartnersService } from '../../services/demand-partners.service';
import { User } from 'src/app/core/auth/user.model';
import { UsersService } from '../../services/users.service';
import { UsersAutocompleteComponent } from './users-autocomplete.component';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';
import { UserRole } from 'src/app/core/auth/user-role.model';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-users-autocomplete-chips',
  template: `
  <mt-users-autocomplete #pc
                               (userSelected)="onItemSelected($event)"
                               [formFieldClass]="formFieldClass"
                               [formFieldAppearance]="formFieldAppearance"
                               [required]="required"
                               [label]="placeholder"
                               [floatLabel]="floatLabel"
                               [filterByRoles]="filterByRoles">
  </mt-users-autocomplete>
  <mat-chip-list class="mt-n3" #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
    <mat-basic-chip *ngFor="let item of items" (removed)="remove(item)">
      {{item.name}}
      <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
    </mat-basic-chip>
  </mat-chip-list>`,
})
export class UsersAutocompleteChipsComponent implements OnInit {
  @ViewChild(UsersAutocompleteComponent, { static: true }) pc: UsersAutocompleteComponent;

  /** Pass clearFilterItems: Subject<void> = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;
  @Input() public placeholder: string;
  @Input() public stacked: false;
  @Input() public userIds: string[];
  @Input() public floatLabel: FloatLabelType;
  @Input() public formFieldClass: string;
  @Input() public formFieldAppearance: string;
  @Input() required = false;
  @Input() public filterByRoles: UserRole[];
  @Output() userSelectionChange = new EventEmitter<User[]>();

  items: User[] = [];

  constructor(private service: UsersService) {
  }

  ngOnInit(): void {
    if (this.userIds && this.userIds.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.userIds === 'string') {
        this.userIds = [this.userIds];
      }
      //Fetch only preselected publishers
      this.service.list({ ids: this.userIds }).subscribe(users => {
        users.map(user => {
          this.items.push(user)
        });
        this.pc.updateFilteredUsers(this.items);
      });
    }

    this.clearItems?.subscribe(() => this.clearFilter());
  }

  onItemSelected($event: User) {
    if ($event) {
      this.items.push($event);
      this.userSelectionChange.emit(this.items);
      this.pc.resetInput();
      this.pc.updateFilteredUsers(this.items);
    }
  }

  remove(user: User) {
    this.items.splice(this.items.indexOf(user), 1);
    this.userSelectionChange.emit(this.items);
    this.pc.updateFilteredUsers(this.items);
  }

  /** Clear all items from filter. */
  clearFilter() {
    this.items = [];
    this.pc.updateFilteredUsers(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.pc.disable();
    }
    else {
      this.pc.enable();
    }
  }
}
