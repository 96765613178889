import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { forkJoin, interval, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { AdsTxtStatusDialogComponent } from 'src/app/modules/ads-txt/ads-txt-status-dialog/ads-txt-status-dialog.component';
import { AdsTxtUrlStatusModel } from '../../domain/ads-txt.model';
import { DemandPartner, DemandPartnerStatus, DemandPartnersRequest } from '../../domain/demand-partner.model';
import { AdsTxtService } from '../../services/ads-txt.service';
import { DemandPartnersService } from '../../services/demand-partners.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { ComponentWithLoadingState } from '../component-with-loading-state/component-with-loading-state';

@Component({
  selector: 'mt-ads-txt-url-status-table',
  templateUrl: './ads-txt-url-status-table.component.html',
  styleUrls: ['./ads-txt-url-status-table.component.scss']
})
export class AdsTxtUrlStatusTableComponent extends ComponentWithLoadingState implements OnInit, OnChanges {
  private readonly onDestroy = new Subject<void>();
  public readonly RolePermission = RolePermission;

  @Input() publisherId: string;

  public demandPartnerList: DemandPartner[];
  public latestCrawlDate: string;

  private sbs: Subscription;
  private oldCrawlValues: AdsTxtUrlStatusModel[];

  columns: string[] = [
    'url',
    'appsConnected',
    'found',
    'lastCrawl'];

  public dataSource = new MatTableDataSource<AdsTxtUrlStatusModel>();
  //sbs: Subscription;
  filter = {
    publisherId: '',
    demandPartnerIds: ''
  };

  constructor(
    private readonly adsTxtService: AdsTxtService,
    private readonly demandPartnerService: DemandPartnersService,
    private readonly snackBarService: SnackBarService,
    private readonly matDialog: MatDialog
  ) {
    super();
   }

  ngOnInit(): void {
    this.filter.publisherId = this.publisherId;
    if (this.publisherId) {
      this.getData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.publisherId && !changes.publisherId.isFirstChange()) {
      this.filter.publisherId = this.publisherId;
      this.getData();
    }
  }

  private getData() {
    this._loading$.next('Fetching data');
    forkJoin([
      this.adsTxtService.getAdsTxt(this.filter),
      this.demandPartnerService.listAll({
        status: [DemandPartnerStatus.ACTIVE]
      } as unknown as DemandPartnersRequest),
    ])
    .pipe(
      finalize(() => this._loading$.next(false)),
      takeUntil(this.onDestroy)
    )
    .subscribe(
      ([adsTxtResponse, dpResponse]) => {
        this.dataSource.data = adsTxtResponse;
        if (adsTxtResponse.length) {
          this.latestCrawlDate = adsTxtResponse.reduce((a, b) => (a.adstxtCrawledOn > b.adstxtCrawledOn ? a : b)).adstxtCrawledOn;
        }
        this.demandPartnerList = dpResponse;
      },
      (error) => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while fetching Ads.txt Data');
        }
      }
    );
  }

  public openFoundDialog(adsTxtLocation: string) {
    this.matDialog.open(AdsTxtStatusDialogComponent, <MatDialogConfig>{
      data: {
        adsTxtUrl: adsTxtLocation,
        dpId: this.filter.demandPartnerIds
      },
      autoFocus: false,
      width: '1400px'});
  }

  public onDemandPartnerFilterChange(){
    this._loading$.next('Fetching data');
    this.adsTxtService.getAdsTxt(this.filter)
    .pipe(
      takeUntil(this.onDestroy),
      finalize(() => this._loading$.next(false))
    )
    .subscribe(
      (adsTxtResponse) => {
        this.dataSource.data = adsTxtResponse;
      },
      (error) => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while fetching Ads.txt Data');
        }
      }
    );
  }

  public startCrawlAll() {
    this.oldCrawlValues = this.dataSource.data;
    this._loading$.next('Crawling...');
    this.sbs = this.adsTxtService.crawlAllAdsTxtForPublisher({publisherId: this.publisherId}).subscribe((response) => {
      this.checkIfCrawling();
    },
      error => {
        this.oldCrawlValues = [];
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while crawling Ads.txt.');
        }
      });
  }

  private checkIfCrawling() {
    this.sbs = interval(3000).subscribe(x => {
      this.adsTxtService.getAdsTxtInfo(this.filter).subscribe((response) => {
        //slice(0,-10), we have to slice hundredths from time because there can be apps with same adsTxtLocation and they wont have the completely same time on adstxtCrawledOn when crawling because they enter database sequentially
        //and there can be slight differences in time
        if (response.some(x => this.oldCrawlValues.find(y => y.adsTxtLocation == x.adsTxtLocation).adstxtCrawledOn != x.adstxtCrawledOn)) {
          this.sbs.unsubscribe();
          this.oldCrawlValues = [];
          this._loading$.next(false);
          console.log('Crawling finished');
          //Get data
          this._loading$.next('Fetching data');
          this.adsTxtService.getAdsTxt(this.filter)
          .pipe(
            finalize(() => this._loading$.next(false)),
            takeUntil(this.onDestroy)
          )
          .subscribe(
            (adsTxtResponse) => {
              this.dataSource.data = adsTxtResponse;
              if (adsTxtResponse.length) {
                this.latestCrawlDate = adsTxtResponse.reduce((a, b) => (a.adstxtCrawledOn > b.adstxtCrawledOn ? a : b)).adstxtCrawledOn;
              }
            },
            (error) => {
              const messages = ServerErrorUtils.getValidationMessages(error);
              if (messages) {
                messages.forEach(m => this.snackBarService.error(m));
              } else {
                this.snackBarService.error('Error while fetching Ads.txt Data');
              }
            }
          );
        }
      });
    });
  }

}
