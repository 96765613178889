import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Principal } from '../auth/principal.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminGuard {

  constructor(private authService: AuthService,
    private router: Router,
    private principal: Principal) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    console.log('admin guard :: can activate');
    return new Observable(subscriber => {
      this.authService.identity()
        .subscribe(
          user => {
            const value = this.principal.canAccess('admin');
            console.log('can access admin %s', value);
            subscriber.next(value);
            subscriber.complete();
            return true;
          },
          (error) => {
            console.error(error);
            subscriber.next(false);
            subscriber.complete();
          }
        );
    });
  }
}
