import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import { NetworkService } from '../../services/network.service';
import { Network } from '../../domain/network.model';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-network-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" [appearance]="formFieldAppearance ? formFieldAppearance : undefined">
      <mat-label >{{label ? label : 'Choose network'}}</mat-label>
      <input matInput
             #name
             (focus)="onFocusEvent()"
             aria-label="Network"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="{{placeholder ? placeholder : 'Choose network'}}">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class NetworkAutocompleteComponent implements OnInit, OnChanges {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public networkId: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  items: Network[];

  @Input()
  disabled: boolean;

  @Output()
  networkSelected = new EventEmitter<Network>();

  serverSearching = false;

  filteredItems: Subject<Network[]>;
  filterCtrl = new UntypedFormControl();
  selectedNetwork;
  filteredNetworkIds: string[] = [];
  networkList: Network[];

  constructor(private service: NetworkService) {
    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedNetwork) {
          this.selectedNetwork = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object' && this.networkList.length > 0) {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.searchNetwork();
        }
        else {
          this.onFocusEvent();
        }
      });
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.filterCtrl.disable({onlySelf: true, emitEvent: true});
    }

    this.service.listNetworks().subscribe(resp => {
      this.networkList = resp;
    }, () => {
      this.filterCtrl.enable();
      this.nameFilter.nativeElement.focus();
      this.serverSearching = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) {
      this.filterCtrl.disable({ onlySelf: true, emitEvent: false });
      this.filteredNetworkIds = [];
    }
    else {
      this.filterCtrl.enable({ onlySelf: true, emitEvent: false });
    }
  }

  resetInput() {
    this.selectedNetwork = null;
    this.filterCtrl.setValue(null);
  }

  searchNetwork() {
    if (this.networkList.length > 0) {
      const search = this.networkList.filter(net => net.name.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(
        search.filter(
          (item) => !this.filteredNetworkIds.includes(item.id)
        )
      );
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }

  onFocusEvent() {
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(this.networkList?.filter(item => !this.filteredNetworkIds.includes(item.id)));
    }
  }

  display(p?: Network) {
    return p ? p.name : undefined;
  }

  optionSelected($event) {
    this.selectedNetwork = $event.option.value;
    this.filteredItems.next([]);
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.networkSelected.emit(this.selectedNetwork);
  }

  updateFilteredNetworks(items) {
    this.filteredNetworkIds = items.map(item => item.id);
  }

  disableSearch(disable: boolean) {
    disable == true ? this.filterCtrl.disable() : this.filterCtrl.enable();
  }
}
