import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DemandPartner } from './demand-partner.model';
import { KeyLabel } from './key-label.model';
import { PageRequest } from './page-request';
import { Targeting, TargetingModel } from './targeting.model';
import {Deals} from "./pmp-deals.model";

export class DemandPartnerConnection {
  createdBy: Date;
  createdOn: Date;
  demandPartner: DemandPartner;
  endpointUriApac: string;
  endpointUriEU: string;
  endpointUriUsEast: string;
  endpointUriUsWest: string;
  gzip: boolean;
  id: string;
  integrationType: IntegrationType;
  inventoryType: InventoryType;
  name: string;
  mediaTypes: string;
  schainAsi: string;
  status: string;
  updatedBy: Date;
  updatedOn: Date;
  userSyncIframe: string;
  targeting: Targeting;
  displayManager: string;
  notes: string;
}

export class DemandPartnerConnectionCreate {
  demandPartner: {
    id: string;
    aEnabled: boolean;
    address: string;
    adsTxtRecord: string;
    approvedCnt: number;
    avEnabled: boolean;
    bidderCode: string;
    biddingBidTTL: number;
    biddingGrossNet: string;
    biddingTmax: number;
    cbEnabled: boolean;
    defaultOnBundleCreation: boolean;
    displayName: string;
    demandIntegrationsCnt: number;
    ioCpmCostMetric: string;
    ioCpmCostValue: number;
    notAvailableCnt: number;
    notSubmittedCnt: number;
    pendingCnt: number;
    pricingType: string;
    rejectedCnt: number;
    revShareGrossNet: string;
    revShareValue: number;
    status: string;
    submittedCnt: number;
    vEnabled: boolean;
    vavEnabled: true;
  };
  id: string;
  privateAuction: boolean;
  endpointUri: string;
  endpointUriApac: string;
  endpointUriEu: string;
  endpointUriUsEast: string;
  endpointUriUsWest: string;
  gzip: boolean;
  multipleImpressionsEnabled: boolean;
  multipleImpressionsCount: number;
  integrationType: IntegrationType;
  displayManager: string;
  name: string;
  mediaTypes: string;
  sellerId: string;
  schainAsi: string;
  userSyncIframe: string;
  userSyncRedirect: string;
  publisherId: string;
  siteId: string;
  appId: string;
  placementId: string;
  tagId: string;
  zoneId: string;
  tripleliftInventoryCode: string;
  openxDelDomain: string;
  unrulyTargetingUuid: string;
  openxUnitId: string;
  targetCpm: number;
  bidFloor: number;
  status: string;
  shareOfVoice: number;
  placementCnt: number;
  qps: number;
  targeting: Targeting;
  requestRewrite: IRewriteObjectModel[];
  pmpDeals: PmpDeals[];
  notes: string;
  smartAdServerFormatId: string;
  smartAdServerNetworkId: string;
  smartAdServerPageId: string;
  dupCount: number;
  dupIncrement: number;
  dupMaxFloor: number;
  dupMinFloor: number;
  dupUseDefaultFloor: boolean;
  duplicateDemandIntegration: boolean;
  stats: {
    global7daysRevenuePct: number;
    network7daysRevenuePct: number;
  };
  blockBidsWithoutCat: boolean;
  blockBidsWithoutAdomain: boolean;
  deals: Deals[];
}

export interface IDPConnectionsCustomParametersModel {
  params: string[];
  //value can be string or string[]
  bidders: {
    name: string;
    requiredFields: string[];
  }[];
}

export class PmpDeals {
  initialId: string;
  dealId: string;
  wseat: any; //should be string[], but for the sake of simplicity we use "any" because at same time it is string (on HTML) and string[] (when sending to BE)
  auctionType?: number;
}

export class ConnectionStatusBox {
  count: number;
  status: string;
}

export class ConnectionStatusListing {
  ACTIVE: number;
  INACTIVE: number;
  ARCHIVED: number;
}

export enum ConnectionStatusColorMap {
  ACTIVE = 'green',
  INACTIVE = 'red',
  ARCHIVED = 'gray',
}

export enum IntegrationType {
  ORTB = 'ORTB',
  VAST = 'VAST',
  PBS = 'PBS',
  CUSTOM = 'CUSTOM',
}

export const IntegrationTypeLabels = {
  ORTB: 'oRTB',
  VAST: 'VAST',
  PBS: 'PreBid server',
  CUSTOM: 'Custom',
};

export enum InventoryType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export const InventoryTypeLabels = {
  VIDEO: 'Video',
  AUDIO: 'Audio',
};

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

export class DemandPartnersConnectionRequest extends PageRequest {
  name: string;
  status: string[];
  ids: string[];
}

export const IntegrationTypeKL = () =>
  Object.keys(IntegrationType).map(
    (key) => new KeyLabel(<IntegrationType>key, IntegrationTypeLabels[key])
  );

export const InventoryTypeKL = () =>
  Object.keys(InventoryType).map(
    (key) => new KeyLabel(<InventoryType>key, InventoryTypeLabels[key])
  );

export enum ConnectionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const ConnectionStatusLabels = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ARCHIVED: 'Archived',
};

export const ConnectionStatusKL = () =>
  Object.keys(ConnectionStatus).map(
    (key) => new KeyLabel(<ConnectionStatus>key, ConnectionStatusLabels[key])
  );

export interface IRewriteObjectModel {
  field: string;
  //value can be string or string[]
  value: any;
  overwrite: boolean;
  nullify: boolean;
}

export enum DeviceTypeEnum {
  DeviceMobile = '1',
  DevicePC = '2',
  DeviceTV = '3',
  DevicePhone = '4',
  DeviceTablet = '5',
  DeviceConnected = '6',
  DeviceSetTopBox = '7',
  DeviceOOH = '8',
}

export const DeviceTypeLabels = {
  1: 'Mobile/Tablet - General',
  2: 'Personal Computer',
  3: 'Connected TV',
  4: 'Phone',
  5: 'Tablet',
  6: 'Connected Device',
  7: 'Set Top Box',
  8: 'OOH Device',
};

export const DeviceTypeKL = () =>
  Object.values(DeviceTypeEnum).map(
    (key) => new KeyLabel(<DeviceTypeEnum>key, DeviceTypeLabels[key])
);

export enum AuctionType {
  FIRST_PRICE = 'FIRST_PRICE',
  SECOND_PRICE = 'SECOND_PRICE',
  BID_FLOOR_PRICE = 'BID_FLOOR_PRICE',
}

export const AuctionTypeLabels = {
  FIRST_PRICE: 'First price',
  SECOND_PRICE: 'Second price',
  BID_FLOOR_PRICE: 'Bid floor price',
};
export const AuctionTypeKL = () =>
    Object.keys(AuctionType).map(
        (key) => new KeyLabel(<AuctionType>key, AuctionTypeLabels[key])
    );
