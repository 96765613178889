import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

interface SuccessRedirectDialogComponentInput {
  title: string;
  descriptions: string[];
  redirectButtons: {
    label: string;
    icon: string;
    path: string;
  }[];
}

@Component({
  selector: 'mt-success-redirect-dialog',
  templateUrl: './success-redirect-dialog.component.html',
  styleUrls: ['./success-redirect-dialog.component.scss'],
})
export class SuccessRedirectDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SuccessRedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuccessRedirectDialogComponentInput,
    private router: Router
  ) {}

  ngOnInit(): void {
  }

  redirect(path: string) {
    this.router.navigateByUrl(path);
    this.dialogRef.close();
  }
}
