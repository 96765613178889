<div class="auth login d-flex">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">


                <div class="login-content">
                    <mat-card class="p-5 mb-5">
                        <mat-card-title class="mb-4 pb-3 text-center">
                            <div class="d-block text-center login-logo mb-4">
                                <img src="{{ logoUrl }}" alt="logo" height="80">
                            </div>
                            <strong>Reset password</strong>
                        </mat-card-title>
                        <mat-card-content>
                            <div>
                                <form #f="ngForm" class="d-flex flex-column">
                                    <mat-form-field floatLabel="" class="mb-2" appearance="outline">
                                        <mat-label>Secret key</mat-label>
                                        <input #key
                                               matInput
                                               placeholder="Insert secret key"
                                               name="key" value=""
                                               required
                                               [(ngModel)]="resetPasswordRequest.key">
                                    </mat-form-field>
                                    <mat-form-field floatLabel="" class="mb-2" appearance="outline">
                                        <mat-label>New password</mat-label>
                                        <input #newPassword
                                               type="password" 
                                               matInput
                                               placeholder="Insert new password"
                                               name="newPassword" value=""
                                               required
                                               [(ngModel)]="resetPasswordRequest.newPassword">
                                    </mat-form-field>
                                </form>
                            </div>
                        </mat-card-content>
                        <mat-card-footer class="text-center">
                            <button type="button" mat-flat-button color="primary" class="iconed large" (click)="resetPassword()">
                                <i class="far"></i>
                                <span>Reset</span>
                            </button>
                        </mat-card-footer>
                    </mat-card>

                </div>
            </div>
        </div>
    </div>
</div>