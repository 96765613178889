import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DateRangeType} from '../../domain/rb/date-range-type.enum';
import {DateRangeHolder} from '../../domain/rb/date-range-holder.model';
import { CompareDateRangeHolder } from 'src/app/shared/domain/rb/date-range-holder.model';
import { DateRangeChooserComponent } from './date-range-chooser.component';

@Component({
  selector: 'mt-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
  @ViewChild('chooser') dateChooser: DateRangeChooserComponent;

  @Input()
  public dateFrom;
  @Input()
  public dateTo;
  @Input()
  public maxDate;
  @Input()
  public minDate;
  @Input()
  public allowedDateRangeTypes: DateRangeType[] = <DateRangeType[]>Object.keys(DateRangeType);
  @Input()
  public maxRangeBetweenDates: number;
  @Input()
  public weeklyType: boolean;
  @Input()
  public arrowsDisabled: boolean;
  @Input()
  public compareOption: boolean;

  @Output()
  dateRangeChanged = new EventEmitter<DateRangeHolder>();
  dateRangeType: DateRangeType;

  @Output()
  compareDateRangeChanged = new EventEmitter<CompareDateRangeHolder>();

  public compareActive = false;


  public UI = {
    dateChooserOpen: false
  };

  constructor(private myElement: ElementRef) {
  }

  ngOnInit(): void {
  }

  onDateRangeChanged(event: DateRangeHolder) {
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.dateRangeChanged.emit(event);
    this.hide();
  }

  onCompareDateRangeChanged(event: CompareDateRangeHolder) {
    this.compareDateRangeChanged.emit(event);
  }

  isPrevDayEnabled() {
    return !this.minDate || this.minDate < this.dateFrom;
  }

  isNextDayEnabled() {
    return !this.maxDate || this.maxDate > this.dateTo;
  }

  private hide() {
    this.UI.dateChooserOpen = false;
  }

  public reset(dateRange: DateRangeHolder, compareActive?: boolean){
    this.dateChooser.reset(dateRange, compareActive);
    this.hide();
  }

  @HostListener('document:click', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const overlayElements = document.getElementsByClassName('cdk-overlay-container');
    const matCalendar = document.getElementsByClassName('mat-calendar');
    const datepickerCell = (<Element>e.target).className === 'mat-calendar-body-cell-content';

    const insideOverlay = overlayElements.length && overlayElements[0].contains(<Node>e.target);
    const insidePopup = matCalendar.length && matCalendar[0].contains(<Node>e.target);
    const insideWidget = this.myElement.nativeElement.contains(e.target);

    if (!insideWidget && !insideOverlay && !insidePopup && !datepickerCell) {
      this.hide();
    }
  }
}
