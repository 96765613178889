import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Principal } from '../auth/principal.service';
import { RolePermission } from '../auth/rbac/role-permission.service';

@Injectable()
export class HomeScreenGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private principal: Principal
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(subscriber => {
      this.authService.identity().subscribe(() => {
        if (!this.principal.hasPermission(RolePermission.HOME_READ)) {
            this.navigateToFirstAllowedRoute();
            subscriber.next(false);
        } else {
          subscriber.next(true);
        }
        subscriber.complete();
      });
    });
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(subscriber => {
      this.authService.identity().subscribe(
        () => {
          if (!this.principal.hasPermission(RolePermission.HOME_READ)) {
            this.navigateToFirstAllowedRoute();
            subscriber.next(false);
          } else {
            subscriber.next(true);
          }
          subscriber.complete();
        },
        error => {
          console.error(error);
          subscriber.next(false);
          subscriber.complete();
        }
      );
    });
  }

  private navigateToFirstAllowedRoute(): void {
    const allowedRoutes = ['/publishers', '/placements', '/apps', '/ads-txt', '/tailored-supply', '/endpoint-generator', '/demand','/advertiser',
                           '/integrations', '/deals', '/demand/compliance', '/demand/discrepancies', '/campaigns',
                           '/brand', '/orders', '/line-items', '/reports', '/performance', '/scheduled-reports', '/errors', '/targeting',
                           '/collections', '/videos', '/content-owner', '/users', '/roles', '/changelog', '/ai-web-player'];
    const firstAllowedRoute = allowedRoutes.find(route => this.principal.hasPermission(this.getPermissionForRoute(route)));

    if (firstAllowedRoute) {
      this.router.navigateByUrl(firstAllowedRoute);
    } 
  }

   getPermissionForRoute(route: string): RolePermission | null {
    switch (route) {
      case '/publishers':
        return RolePermission.SUPPLY_PUBLISHERS_READ;
      case '/placements':
        return RolePermission.SUPPLY_PLACEMENTS_READ;
      case '/apps':
        return RolePermission.SUPPLY_APPS_READ;
      case '/tailored-supply':
          return RolePermission.SUPPLY_APPS_READ;
      case '/ads-txt':
        return RolePermission.TARGETING_READ;                          
      case '/endpoint-generator':
        return RolePermission.SUPPLY_ENDPOINT_GENERATOR_READ;
      case '/demand':
        return RolePermission.DEMAND_PARTNERS_READ;
      case '/integrations':
        return RolePermission.DEMAND_CONNECTIONS_READ;
      case '/deals':
        return RolePermission.DEMAND_DEALS_READ;
      case '/demand/compliance':
        return RolePermission.DEMAND_COMPLIANCE_READ;
      case '/demand/discrepancies':
        return RolePermission.DEMAND_DISCREPANCIES_READ;
      case '/campaigns':
        return RolePermission.CAMPAIGNS_CREATIVES_READ;
      case '/advertiser':
        return RolePermission.CAMPAIGNS_ADVERTISERS_READ;
      case '/brand':
        return RolePermission.CAMPAIGNS_ADVERTISERS_READ;
      case '/orders':
        return RolePermission.CAMPAIGNS_ORDERS_READ;
      case '/line-items':
        return RolePermission.CAMPAIGNS_LINE_ITEMS_READ;
      case '/reports':
        return RolePermission.REPORTING_REPORT_BUILDER_READ;
      case '/performance':
        return RolePermission.REPORTING_PERFORMANCE_READ;
      case '/scheduled-reports':
        return RolePermission.REPORTING_SCHEDULED_REPORTS_READ;
      case '/errors':
        return RolePermission.REPORTING_ERROR_REPORTS_READ; 
      case '/targeting':
        return RolePermission.TARGETING_READ;
      case '/collections':
        return RolePermission.CONTENT_COLLECTIONS_READ;
      case '/video':
        return RolePermission.CONTENT_VIDEOS_READ;
      case '/content-owner':
        return RolePermission.CONTENT_CONTENT_OWNERS_READ;
      case '/users':
        return RolePermission.USERS_USERS_READ;
      case '/roles':
        return RolePermission.USERS_ROLES_READ;
      case '/changelog':
        return RolePermission.CHANGELOG_READ;
      case '/ai-web-player':
        return RolePermission.AI_WEB_PLAYER_READ;      
      default:
        return null;
    }
  }
}

