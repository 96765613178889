import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SortUtils } from '../../utils/sort-utils';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { FloatLabelType } from '@angular/material/form-field';
import {Order, OrderStatus} from "../../domain/campaigns.model";
import {CampaignsService} from "../../services/campaigns.service";

@Component({
  selector: 'mt-orders-autocomplete',
  template: `
      <mat-form-field
              [floatLabel]="floatLabel || floatLabelAlways"
              class="w-100"
              [ngClass]="formFieldClass"
              [appearance]="formFieldAppearance ? formFieldAppearance : undefined"
      >
          <mat-label>{{label ? label : 'Choose order'}}</mat-label>
          <input
                  matInput
                  #search
                  [disabled]="disabled"
                  aria-label="Order"
                  [matAutocomplete]="auto"
                  [formControl]="filterCtrl"
                  [required]="required"
                  (keydown)="!disabled"
                  (click)="!disabled"
          />
          <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
          <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="optionSelected($event)"
                  [displayWith]="display"
          >
              <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                  {{ item.name }}
              </mat-option>
          </mat-autocomplete>
          <mat-hint *ngIf="formFieldHint">{{formFieldHint ? formFieldHint : ''}}</mat-hint>
      </mat-form-field>`,
})
export class OrdersAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();
  @Input() public placeholder: string;
  @Input() public floatLabel: FloatLabelType;
  /** Hold networkId to filter orders only by given network.*/
  @Input() public networkIds: string[];
  @Input() public label: string;
  @Input() public formFieldClass: string;
  @Input() public formFieldAppearance: string;
  @Input() public formFieldHint: string;
  @Input() required: boolean;
  @Input() preselectedOrderId: Order['id'];
  @Output() orderSelected = new EventEmitter<Order>();
  floatLabelAlways: FloatLabelType = 'always';
  @Input() filteredItems: Subject<Order[]>  = new Subject<Order[]>(); //List of Orders to show in dropdown filtered with 'filteredOrderIds'
  filterCtrl = new UntypedFormControl();
  orderList: Order[] = []; //stores currently fetched list of orders
  selectedOrder: Order; //currently selected order
  filteredOrderIds: string[] = []; //It stores all the IDs of selected orders
  public loading = false;
  @Input() disabled:boolean;

  constructor(
    private service: CampaignsService,
    private snackBarService: SnackBarService) {
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getOrders(searchTerm);
          }
          else if (searchTerm == '') { //triggers when user deletes everything from input
            this.filteredItems.next(undefined);
            this.orderSelected.emit(null);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedOrderId) {
      this.getOrders('', this.preselectedOrderId);
    }
  }

  /** SearchTerm used for filtering by pub name. preselectedOrderId used to get only one order (preselected) at the component initialization. */
  private getOrders(searchTerm?: string, preselectedOrderId?: string) {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.getOrdersList({
      page: 0,
      size: 50,
      networkIds: this.networkIds,
      name: searchTerm,
      status: [OrderStatus.ACTIVE],
      ids: preselectedOrderId ? [preselectedOrderId] : []
    } )
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedOrderId ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected order
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe({
        next: (resp) => {
          this.orderList = resp.content.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedOrderId) {
            this.selectedOrder = this.orderList[0]; //Naturally since the api call is filtered with one ID, selectedPlacement will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedOrder);
            this.updateFilteredOrders([this.selectedOrder]);
            this.emit();
          }
          this.filteredItems.next(this.orderList.filter(x => !this.filteredOrderIds.includes(x.id)));
        },
        error: (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching orders autocomplete data');
          }
        },
       });
  }

  public optionSelected($event) {
    this.selectedOrder = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.orderSelected.emit(this.selectedOrder);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredPlacementIds. */
  public updateFilteredOrders(items) {
    this.filteredOrderIds = items.map((item) => item.id);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(o?: Order) {
    return o ? o.name : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
