export class AdsTxtExportUtils {

    public static createRecordRows(records): string {
        let data = '';
        records.forEach(record  => {
            data = data + this.createRecordRow(record);
        });
        return data;
    }

    public static createRecordRow(record): string {
        let row = this.checkIfUndefined(record.domain) + ',' + this.checkIfUndefined(record.publisherAccountId) + ','
            + this.checkIfUndefined(record.accountType) + ',' + this.checkIfUndefined(record.certAuthority);
        return row.replace(/,+$/, '') + '\n';
    }

    public static checkIfUndefined(data: string): string {
        return (data != undefined) ? data : '';
    }

}
