import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginService} from './login/login.service';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './login/logout.component';
import {MaterialModule} from './material.module';
import {PageNotFoundComponent} from './components/page-not-found.component';
import {ForbiddenComponent} from './components/forbidden.component';
import {SnackBarService} from './services/snackbar.service';
import {GlobalSpinnerService} from './services/global-spinner.service';
import {GlobalSpinnerComponent} from './services/global-spinner.component';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {AdminGuard} from './guards/admin-guard.service';
import {PublisherGuard} from './guards/publisher-guard.service';
import {PublisherResolver} from './resolvers/publisher.resolver';
import {NetworkGuard} from './guards/network-guard.service';
import { TwoDigitDecimaNumberDirective } from '../shared/directive/two-digit-decimal-number.directive';
import { HasNetworkPermissionDirective } from '../shared/directive/has-network-permission.directive';
import { NetworkGuardPublisher } from './guards/network-guard-publisher.service';
import { FourDigitDecimaNumberDirective } from '../shared/directive/four-digit-decimal-number.directive';
import { CommifiedNumberInputDirective } from '../shared/directive/commified-number-input.directive';
import { HasSalesRepPermissionDirective } from '../shared/directive/has-sales-rep-permission.directive';
import { HasPermissionDirective } from './auth/rbac/has-permission.directive';
import { RolePermissionService } from './auth/rbac/role-permission.service';
import { HasNoPermissionDirective } from './auth/rbac/has-no-permission.directive';
import {HasPermissionDirectiveNew} from "./auth/rbac/has-permission-new.directive";
import {HasNoPermissionDirectiveNew} from "./auth/rbac/has-no-permission-new.directive";
import {UserManagementGuard} from "./guards/user-management-guard";
import {ForgotPasswordComponent} from "./reset-password/forgot-password.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import { HomeScreenGuard } from './guards/home-screen-guard.service';
import { AdvertiserGuard } from './guards/advertiser-guard.service';
import { AdvertiserResolver } from './resolvers/advertiser.resolver';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        HttpClientModule,
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        PageNotFoundComponent,
        ForbiddenComponent,
        GlobalSpinnerComponent,
        TwoDigitDecimaNumberDirective,
        FourDigitDecimaNumberDirective,
        HasNetworkPermissionDirective,
        CommifiedNumberInputDirective,
        HasSalesRepPermissionDirective,
        HasPermissionDirective,
        HasPermissionDirectiveNew,
        HasNoPermissionDirectiveNew,
        HasNoPermissionDirective
    ],
    providers: [
        LoginService,
        SnackBarService,
        NetworkGuard,
        NetworkGuardPublisher,
        PublisherResolver,
        AdvertiserResolver,
        AdminGuard,
        PublisherGuard,
        AdvertiserGuard,
        GlobalSpinnerService,
        RolePermissionService,
        UserManagementGuard,
        HomeScreenGuard
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        LoginComponent,
        LogoutComponent,
        PageNotFoundComponent,
        ForbiddenComponent,
        GlobalSpinnerComponent,
        MaterialModule,
        TwoDigitDecimaNumberDirective,
        FourDigitDecimaNumberDirective,
        HasNetworkPermissionDirective,
        CommifiedNumberInputDirective,
        HasSalesRepPermissionDirective,
        HasPermissionDirective,
        HasPermissionDirectiveNew,
        HasNoPermissionDirectiveNew,
        HasNoPermissionDirective
    ]
})
export class CoreModule {
}
