import { Injectable } from '@angular/core';
import { User } from './user.model';
import { Observable, Observer, of, Subject, throwError } from 'rxjs';
import { Principal } from './principal.service';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UsersService } from '../../shared/services/users.service';
import { Publisher } from '../../shared/domain/publisher.model';
import { PublishersService } from '../../shared/services/publishers.service';
import { UserRole, UserRoleNew } from './user-role.model';
import { GlobalSpinnerService } from '../services/global-spinner.service';
import { Advertiser } from 'src/app/shared/domain/advertiser.model';
import { AdvertiserService } from 'src/app/shared/services/advertiser.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  updateUserHeaderData: Subject<{ updateUserHeaderData: boolean, user?: User }> = new Subject<{ updateUserHeaderData: boolean, user?: User }>();

  constructor(private router: Router,
    private httpClient: HttpClient,
    private usersService: UsersService,
    private publishersService: PublishersService,
    private principal: Principal,
    private advertiserService: AdvertiserService,
    private globalSpinnerService: GlobalSpinnerService) {
    const token = localStorage.getItem('authenticationToken');
    console.log(`authentication token from local storage: [${token}]`);
    if (token) {
      principal.setToken(token);
    }
  }

  getToken() {
    return this.principal.getToken();
  }

  isAuthenticated() {
    return this.principal.isAuthenticated();
  }

  login(credentials: User): Observable<User> {
    const self = this;

    return new Observable((observer: Observer<User>) => {
      this.usersService.login(credentials).subscribe(
        (resp: HttpResponse<string>) => {
          try {
            const user = <User>JSON.parse(resp.body);
            const jwt = user.accessToken;
            if (jwt) {
              localStorage.setItem('authenticationToken', jwt);
              this.principal.setToken(jwt);

              this.identity(true).subscribe(
                (u: User) => {
                  observer.next(u);
                },
                () => {
                  observer.error('Error during identity establishment');
                },
                () => observer.complete());
            }
          } catch (e) {
            observer.error('Invalid token');
            observer.complete();
          }
        },
        error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      console.log('clearing authentication token');
      localStorage.removeItem('authenticationToken');
      this.principal.cleanup();
      observer.complete();
    });
  }

  identity(force?: boolean): Observable<User> {
    return new Observable(subscriber => {
      const user = this.principal.getUser();
      if (!force && user) {
        subscriber.next(user);
        subscriber.complete();
      } else {
        this.principal.setUser(null);
        this.usersService.me().subscribe({
          next: (newUser) => {
            if (newUser.roles.includes(UserRoleNew.ADMIN)) {
              this.principal.setMask(UserRoleNew.ADMIN);
            }
            if (newUser.roles.includes(UserRoleNew.SUPER_ADMIN)) {
              this.principal.setMask(UserRoleNew.SUPER_ADMIN);
            }
            if (newUser.roles.includes(UserRoleNew.USER)) {
              this.principal.setMask(UserRoleNew.USER);
            }
            if (newUser.roles.includes(UserRoleNew.USER_READ_ONLY)) {
              this.principal.setMask(UserRoleNew.USER_READ_ONLY);
            }
            if (newUser.roles.includes(UserRoleNew.ADMIN_READ_ONLY)) {
              this.principal.setMask(UserRoleNew.ADMIN_READ_ONLY);
            }
            this.principal.setUser(newUser);
            console.log(newUser)
            subscriber.next(newUser);
            subscriber.complete();
          },
          error: (error) => {
            console.log(error)
            this.router.navigate(['/login'])
            subscriber.error(error)
          },
          complete: () => subscriber.complete()
        });
      }
    });
  }

  publisherIdentity(force?: boolean): Observable<Publisher> {
    if (!force && this.principal.publisher) {
      // publisher exists and we didnt force identity - ok resolve it
      return of(this.principal.publisher);
    } else {
      const user = this.principal.getUser();
      if (user) {
        const publisherId = this.principal.mask.publisherId;
        if (!publisherId) {
          this.router.navigate(['login']);
          throw throwError('publisher id does not exist for user !');
        }
        return new Observable(subscriber => {
          this.globalSpinnerService.showLoader();
          this.publishersService.getPublisherById(publisherId)
            .subscribe({
              next: (publisher: Publisher) => {
                this.globalSpinnerService.hideLoader();
                this.principal.publisher = publisher;
                subscriber.next(publisher);
              },
              error: () => {
                this.globalSpinnerService.hideLoader();
                subscriber.error(false)
              },
              complete: () => {
                this.globalSpinnerService.hideLoader();
                subscriber.complete()
              }
            });
        });
      } else {
        // ok resolve user and then try getting publisher identity again
        return new Observable(subscriber => {
          this.identity(true).subscribe(u => {
            this.publisherIdentity(true).subscribe(publisher => {
              subscriber.next(publisher);
            },
              error => subscriber.error(error),
              () => subscriber.complete());
          },
            error => subscriber.error(error));
        });
      }
    }
  }

  advertiserIdentity(force?: boolean): Observable<Advertiser> {
    if (!force && this.principal.advertiser) {
      // advertiser exists and we didnt force identity - ok resolve it
      return of(this.principal.advertiser);
    } else {
      const user = this.principal.getUser();
      if (user) {
        const advertiserId = this.principal.mask.advertiserId;
        if (!advertiserId) {
          this.router.navigate(['login']);
          throw throwError(() => 'Advertiser ID does not exist for user !');
        }
        return new Observable(subscriber => {
          this.globalSpinnerService.showLoader();
          this.advertiserService.getAdvertiserById(advertiserId)
            .subscribe({
              next: (advertiser: Advertiser) => {
                this.globalSpinnerService.hideLoader();
                this.principal.advertiser = advertiser;
                subscriber.next(advertiser);
              },
              error: () => {
                this.globalSpinnerService.hideLoader();
                subscriber.error(false)
              },
              complete: () => {
                this.globalSpinnerService.hideLoader();
                subscriber.complete()
              }
            });
        });
      } else {
        // ok resolve user and then try getting publisher identity again
        return new Observable(subscriber => {
          this.identity(true).subscribe({
            next: (u) => {
              this.advertiserIdentity(true).subscribe({
                next: (publisher) => {
                  subscriber.next(publisher);
                },
                error: (error) => subscriber.error(error),
                complete: () => subscriber.complete()
              });
            },
            error: (error) => subscriber.error(error)
          });
        });
      }
    }
  }
}
