import {PageRequest} from './page-request';
import {User} from '../../core/auth/user.model';
import {OwnerType} from './feed.model';
import { Publisher } from './publisher.model';
import { ContentOwner } from './content-owner.model';

export class Video {
  id?: string;
  title?: string;
  description?: string;
  createdOn: string;
  updatedOn: string;
  createdBy?: User;
  tags?: string[];
  genres?: string[];
  flags?: string[];
  mp4?: string;
  mp4_480p?: string;
  mp4_720p?: string;
  hls_720p?: string;
  posterUrls?: string;
  posters?: string[];
  imageUrls?: string[];
  duration: number;
  category: string;
  caption: string;
  numberOfChannels?: number;
  thumbnail: string;
  artist: string;
  companyOwner: string;
  publishedAt: Date;
  coId?: string;
  ownerType: OwnerType;
  publisherVideo?: Publisher;
  coVideo?: ContentOwner;
}

export class VideoListRequest extends PageRequest {
  title: any;
  playlistId?: string;
  category?: string;
  contentOwnerId?: string;
  updatedDaysAgo?: number;
  publisherId?: string;
  ownerType?: string;
  flags?: string;
  tags?: string;
  genres?: string;
  artists?: string;
  coId?: string;
  coIds?: string[];
  missingThumb: boolean;
  excludedIds?: string[];
}

export class VideoPendingJobs {
  pending: boolean;
}

export const updatedDaysAgoValues = [
  {label: '1 days ago', value: 1},
  {label: '7 days ago', value: 7},
  {label: '1 month', value: 30},
  {label: 'More than month ago', value: 31}
];
