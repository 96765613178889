import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {UsersService} from './users.service';
import {PublishersService} from './publishers.service';
import {PlaylistsService} from './playlists.service';
import {ChannelsService} from './channels.service';
import {VideosService} from './videos.service';
import {DashboardService} from './dashboard.service';
import {DomainsService} from './domains.service';
import {CategoriesService} from './categories.service';
import {KitchenService} from './kitchen.service';
import { DemandPartnersService } from './demand-partners.service';
import { PubDashService } from './pub-dash.service';
import { AppsService } from './apps.service';
import { WidgetTypeService } from './widget-types.service';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  providers: [
    UsersService,
    PublishersService,
    PlaylistsService,
    ChannelsService,
    VideosService,
    DemandPartnersService,
    DashboardService,
    DomainsService,
    PubDashService,
    CategoriesService,
    KitchenService,
    AppsService,
    WidgetTypeService
  ],
  exports: []
})
export class ServicesModule {
}
