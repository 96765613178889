<div class="date-selector">
  <div class="d-flex justify-content-center">

    <button [disabled]="!isPrevDayEnabled() || arrowsDisabled"
            (click)="chooser.goToPrevDay(weeklyType)"
            mat-icon-button color="primary" class="date-selector-arrow">
      <i class="fas fa-chevron-left"></i>
    </button>

    <div class="date-preview">
      <button (click)="UI.dateChooserOpen = !UI.dateChooserOpen"
              mat-button color="primary">
        <span class="date-range-dates">
        <span [hidden]="dateFrom !== dateTo">
	          {{dateFrom | date:'MM/dd/yyyy'}}
        </span>
        <span [hidden]="dateFrom === dateTo">
	          {{dateFrom | date:'MM/dd/yyyy'}} - {{dateTo | date:'MM/dd/yyyy'}}
        </span>
      </span>
        <span class="date-range-icon">
        <i class="far fa-calendar-range"></i>
      </span>
      </button>
    </div>

    <button [disabled]="!isNextDayEnabled() || arrowsDisabled"
            (click)="chooser?.goToNextDay(weeklyType)"
            mat-icon-button color="primary" class="date-selector-arrow">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <mt-date-range-chooser
    class=""
    #chooser
    [dateFrom]="dateFrom"
    [dateTo]="dateTo"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [allowedDateRangeTypes]="allowedDateRangeTypes"
    [maxRangeBetweenDates]="maxRangeBetweenDates"
    (dateRangeChanged)="onDateRangeChanged($event)"
    (compareDateRangeChanged)="onCompareDateRangeChanged($event)"
    [hidden]="!UI.dateChooserOpen"
    [compareOption]="compareOption"
    [compareActive]="compareActive"
    regularButtons="true"></mt-date-range-chooser>

</div>
