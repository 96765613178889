<div class="app-select entity-selector">
  <div class="container">

    <div class="row">
      <div class="col-12 text-center">
        <div class="login-header text-center pt-5">
          <img src="{{ logoUrl }}" alt="logo" height="80">
        </div>
      </div>
    </div>
    <div class="row justify-content-center" [hidden]="loading">
      <div class="col-md-12 text-center mb-5">
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 mx-auto">
        <div class="entity-wrapper">
          <div class="row">
            <div class="col-12 mb-5 col-md-12 col-lg-12" [hidden]="!principal.user?.roles.includes(userRolesNew.SUPER_ADMIN) && 
      !principal.user?.roles.includes(userRolesNew.ADMIN) && 
      !principal.user?.roles.includes(userRolesNew.ADMIN_READ_ONLY) && 
      !principal.user?.roles.includes(userRolesNew.USER) && 
      !principal.user?.roles.includes(userRolesNew.USER_READ_ONLY)">
              <div class="app-select-group">
                <h3 class="app-select-group-title">Main Dashboard</h3>
                <mat-card class="mb-3"
                  *ngIf="principal.user?.roles.includes(userRolesNew.SUPER_ADMIN) || principal.user?.roles.includes(userRolesNew.ADMIN) || principal.user?.roles.includes(userRolesNew.ADMIN_READ_ONLY) || principal.user?.roles.includes(userRolesNew.USER) || principal.user?.roles.includes(userRolesNew.USER_READ_ONLY)">
                  <mat-card-header (click)="mainDashboard()">
                    <div mat-card-avatar class="">
                      <i class="fal fa-user-tie"></i>
                    </div>
                    <mat-card-title class="text-uppercase">Main dashboard</mat-card-title>
                    <div class="mat-card-header-action">
                      <i class="fas fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </mat-card-header>
                </mat-card>
                <mat-card class="mb-3"
                  *ngIf="principal.user?.roles.includes(userRoles.CAMPAIGN_MANAGER) || principal.user?.roles.includes(userRoles.CLIENT_SERVICE_MANAGER)">
                  <mat-card-header>
                    <div mat-card-avatar class="">
                      <i class="fal fa-user-tie"></i>
                    </div>
                    <mat-card-title class="text-uppercase">Campaigns dashboard</mat-card-title>
                    <div class="mat-card-header-action">
                      <i class="fas fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </mat-card-header>
                </mat-card>
              </div>

              <div class="app-select-group" *ngIf="principal.user?.roles.includes('CONTENT_OWNER')"
                [ngClass]="{'mt-5': principal.user?.roles.includes('ADMIN')}">
                <h3 class="app-select-group-title">Content owner dashboard</h3>
                <div class="data-loader" *ngIf="coLoading">
                  <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
                  </mat-spinner>
                </div>
                <div *ngIf="contentOwners?.length && !coLoading">
                  <mat-card class="mb-3" *ngFor="let co of contentOwners">
                    <mat-card-header>
                      <div mat-card-avatar class="">
                        <i class="fal fa-user-tie"></i>
                      </div>
                      <mat-card-title class="text-uppercase">{{co.name}}</mat-card-title>
                      <div class="mat-card-header-action">
                        <i class="fas fa-chevron-right" aria-hidden="true"></i>
                      </div>
                    </mat-card-header>
                  </mat-card>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4 col-md-12 col-lg-12"
              [hidden]="!principal.user?.publisherIds || !principal.user?.publisherIds.length">
              <div class="data-loader" *ngIf="pubLoading">
                <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
                </mat-spinner>
              </div>
              <div class="app-select-group" *ngIf="!pubLoading">
                <h3 class="app-select-group-title">Publisher Dashboards</h3>
                <div *ngIf="publishers?.length">
                  <mat-card *ngFor="let p of publishers" class="mb-3">
                    <mat-card-header (click)="selectPublisher(p.id)">
                      <div mat-card-avatar class="">
                        <i class="fal fa-user"></i>
                      </div>
                      <mat-card-title class="text-uppercase">{{p.name}}</mat-card-title>
                      <div class="mat-card-header-action">
                        <i class="fas fa-chevron-right" aria-hidden="true"></i>
                      </div>
                    </mat-card-header>
                  </mat-card>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4 col-md-12 col-lg-12"
            [hidden]="!principal.user?.advertiserId">
            <div class="data-loader" *ngIf="advertiserLoading">
              <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
              </mat-spinner>
            </div>
            <div class="app-select-group" *ngIf="!advertiserLoading">
              <h3 class="app-select-group-title">Advertiser Dashboards</h3>
              <div *ngIf="advertiser.name !== ''">
                <mat-card class="mb-3">
                  <mat-card-header (click)="selectAdvertiser(advertiser.id)">
                    <div mat-card-avatar class="">
                      <i class="fal fa-user"></i>
                    </div>
                    <mat-card-title class="text-uppercase">{{advertiser.name}}</mat-card-title>
                    <div class="mat-card-header-action">
                      <i class="fas fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </mat-card-header>
                </mat-card>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-5 mx-auto">
        <small class="d-block text-center text-muted mt-5 entity-bottom3">
          © 2024 {{ platformTitle }}
        </small>
      </div>
    </div>
  </div>

</div>