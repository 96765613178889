import { Component, OnDestroy, OnInit } from '@angular/core';
import { Publisher } from './shared/domain/publisher.model';
import { Principal } from './core/auth/principal.service';
import { Router } from '@angular/router';
import { SnackBarService } from './core/services/snackbar.service';
import { AuthService } from './core/auth/auth.service';
import { PublisherListFilter, PublishersService } from './shared/services/publishers.service';
import { UserRole, UserRoleNew } from './core/auth/user-role.model';
import { Subscription } from 'rxjs';
import { ContentOwner } from './shared/domain/content-owner.model';
import { ContentOwnerService } from './shared/services/content-owner.service';
import { ServerErrorUtils } from './shared/utils/server-error-utils';
import { User } from './core/auth/user.model';
import { AdvertiserService } from './shared/services/advertiser.service';
import { Advertiser } from './shared/domain/advertiser.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mt-mask-select',
  templateUrl: './mask-select.component.html',
  styleUrls: ['./mask-select.component.scss']
})
export class MaskSelectComponent implements OnInit, OnDestroy {

  publishers: Publisher[] = [];
  contentOwners: ContentOwner[] = [];
  subscription = new Subscription();
  readonly userRoles = UserRole;
  readonly userRolesNew = UserRoleNew;
  public advertiser: Advertiser = new Advertiser();
  loading: boolean = true;
  coLoading: boolean = false;
  pubLoading: boolean = false;
  advertiserLoading: boolean = false;
  allowAdminDashboard: boolean = false;
  platformTitle: string = environment.platformTitle;
  logoUrl: string = environment.logoUrl;

  constructor(public principal: Principal,
    private router: Router,
    private sbs: SnackBarService,
    private authService: AuthService,
    private service: PublishersService,
    private advertiserService: AdvertiserService,
    private contentOwnerService: ContentOwnerService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscription.add(
      this.authService.identity().subscribe(() => {
        this.getUserPublishers();
        this.getUserCo();
        this.getAdvertiser();
        this.loading = false;
      })
    );
  }

  getUserPublishers() {
    if (this.principal.user.publisherIds && this.principal.user.publisherIds.length) {
      this.pubLoading = true;
      this.subscription.add(
        this.service.listPublishers({ ids: this.principal.user.publisherIds } as PublisherListFilter).subscribe(publishers => {
          this.publishers = publishers.content;
          this.pubLoading = false;
        }, (error) => {
          this.pubLoading = false;
          ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error during while fetching publishers');
        })
      );
    }
  }

  public getAdvertiser(): void {
    if (this.principal.user.advertiserId) {
      this.advertiserLoading = true;
      this.subscription.add(
        this.advertiserService.getAdvertiserById(this.principal.user.advertiserId).subscribe({
          next: (advertiser) => {
            this.advertiser = advertiser;
            this.advertiserLoading = false;
          },
          error: (error) => {
            this.advertiserLoading = true;
            ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error during while fetching advertisers');
          }
        }
        )
      );
    }
  }

  getUserCo() {
    if (this.principal.user.contentOwnerIds && this.principal.user.contentOwnerIds.length) {
      this.coLoading = true;
      this.subscription.add(
        this.contentOwnerService.list({ coIds: this.principal.user.contentOwnerIds }).subscribe(response => {
          this.coLoading = false;
          this.contentOwners = response.filter(co => this.principal.user.contentOwnerIds.includes(co.id));
        }, (error) => {
          this.coLoading = false;
          ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error during while fetching content owners');
        })
      );
    }
  }

  selectPublisher(publisherId: string) {
    this.principal.setMask(UserRoleNew.PUBLISHER, publisherId);
    this.router.navigateByUrl(`/p/${publisherId}`);
  }

  selectAdvertiser(advertiserId: string) {
    const isAdvertiser = this.principal.user.roles.includes("ADVERTISER");
    const isAdvertiserReadOnly = this.principal.user.roles.includes("ADVERTISER_READ_ONLY");
  
    if (isAdvertiser || isAdvertiserReadOnly) {
      const roleToSet = isAdvertiser ? UserRoleNew.ADVERTISER : UserRoleNew.ADVERTISER_READ_ONLY;
      this.principal.setMask(roleToSet, advertiserId);
      this.router.navigateByUrl(`/adv/${advertiserId}`);
    }
  }

  mainDashboard() {
    this.principal.setMask(UserRoleNew.ADMIN, null);
    this.router.navigateByUrl('/');
  }

  // campaignsDashboard() {
  //   this.principal.setMask(UserRole.CAMPAIGN_MANAGER, null);
  //   this.router.navigateByUrl('/orders');
  // }

  // coDashboard(coId: string) {
  //   this.principal.setMask(UserRole.CONTENT_OWNER, coId);
  //   this.router.navigateByUrl(`/co/${coId}`);
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

