import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { TagsAutocompleteComponent } from './tags-autocomplete.component';

@Component({
  selector: 'mt-tags-autocomplete-chips',
  template: `
    <div class="mt-tags-autocomplete-chips-wrapper">
      <mt-tags-autocomplete #ec [availableTags]="availableTags" [activeTags]="activeTags" (tagSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </mt-tags-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class TagsAutocompleteChipsComponent implements OnInit {

  @ViewChild(TagsAutocompleteComponent, {static: true}) ec: TagsAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public activeTags: string[];

  @Input()
  public availableTags: string[];

  @Input()
  public availableTagsFlag: boolean;

  @Input()
  public formFieldClass: string;

  @Output()
  tagSelectionChange = new EventEmitter<string[]>();

  items: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.items = this.activeTags;
    if (this.availableTagsFlag) {
      this.ec.availableTagsFlag = this.availableTagsFlag;
    }
  }

  onItemSelected($event: string) {
    if ($event && !this.items.find(x => x === $event)) {
      this.items.push($event);
      this.tagSelectionChange.emit(this.items);
    }
    this.ec.resetInput();
    this.ec.updateFilteredTags(this.items);
  }

  remove(e: string) {
    this.items.splice(this.items.indexOf(e), 1);
    this.tagSelectionChange.emit(this.items);
    this.ec.updateFilteredTags(this.items);
  }
}
