import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Principal } from './core/auth/principal.service';
import { LoginService } from './core/login/login.service';
import { PublisherListFilter, PublishersService } from './shared/services/publishers.service';
import { AuthService } from './core/auth/auth.service';
import { Publisher } from './shared/domain/publisher.model';
import { SnackBarService } from './core/services/snackbar.service';
import { User } from './core/auth/user.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlaylistIngestFeedDialogComponent } from './modules/playlists/playlist-ingest-feed-dialog/playlist-ingest-feed-dialog.component';
import { PlaylistEditorDialogComponent } from './modules/playlists/playlist-editor-dialog.component';
import { ChannelEditorDialogComponent } from './modules/channels/channel-editor-dialog.component';
import { Router } from '@angular/router';
import { UserEditorDialogComponent } from './modules/users/user-editor/user-editor-dialog.component';
import { Subscription } from 'rxjs';
import { ContentOwnerService } from './shared/services/content-owner.service';
import { ContentOwner } from './shared/domain/content-owner.model';
import { AppRoutePath } from './app-route-path.enum';
import { UserRole, UserRoleNew } from './core/auth/user-role.model';
import { RolePermission } from './core/auth/rbac/role-permission.service';
import { MatAccordion } from '@angular/material/expansion';
import { AdvertiserService } from './shared/services/advertiser.service';
import { Advertiser } from './shared/domain/advertiser.model';
import { NetworkAvailableFeatures } from './shared/domain/network.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mt-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('accordion') accordion: MatAccordion;
  @ViewChild('accordionNetwork') accordionNetwork: MatAccordion;

  public readonly AppRoutePath = AppRoutePath;
  public readonly RolePermission = RolePermission;
  public readonly NetworkAvailableFeatures = NetworkAvailableFeatures;

  screenWidth: number;
  mobileTopBar = document.getElementsByClassName('mobile-top-bar');
  scrollOffset: number;
  publishers: Publisher[];
  contentOwners: ContentOwner[];
  user: User;
  subscription = new Subscription();
  advertiser: Advertiser = new Advertiser();
  UserRoleNew = UserRoleNew;
  allowedFeatures: NetworkAvailableFeatures[];
  logoLightUrl: string = environment.logoLightUrl;

  UI: {
    isNetworkUser?: boolean
    isSalesRep?: boolean
    isCampaignManager?: boolean
    isClientServiceManager?: boolean
    isAdmin?: boolean
    isUser?: boolean
    isReadOnlyAdmin?: boolean
    isReadOnlyUser?: boolean
    isSuperAdmin?: boolean
  } = {};

  constructor(public principal: Principal,
    private httpClient: HttpClient,
    private cdRef: ChangeDetectorRef,
    private loginService: LoginService,
    private authService: AuthService,
    private publisherService: PublishersService,
    private contentOwnerService: ContentOwnerService,
    private advertiserService: AdvertiserService,
    private sbs: SnackBarService,
    private matDialog: MatDialog,
    private router: Router) {

    this.screenWidth = window.innerWidth;
    this.authService.updateUserHeaderData.subscribe(({ updateUserHeaderData, user }) => {
      // Reset publishers and contentOwners arrays
      this.publishers = [];
      this.contentOwners = [];

      // Call getUserPublishers which might use the user object or related info
      this.getUserPublishers();

      // Set current user object as the principal.user that comes from subject had to do this because this component was not getting updated principal
      this.user = user

      // getAdvertiser uses information from the subscription - the newest user object 
      this.getAdvertiser(user?.advertiserId)
    });
  }


  ngOnInit(): void {
    this.user = this.principal.user;
    this.UI.isNetworkUser = !!this.user.network;
    this.UI.isSalesRep = !!this.user.roles.includes(UserRole.SALES_REPRESENTATIVE);
    this.UI.isClientServiceManager = !!this.user.roles.includes(UserRole.CLIENT_SERVICE_MANAGER);
    this.UI.isCampaignManager = !!this.user.roles.includes(UserRole.CAMPAIGN_MANAGER);
    this.UI.isAdmin = !!this.user.roles.includes(UserRole.ADMIN);
    this.UI.isUser = !!this.user.roles.includes(UserRoleNew.USER);
    this.UI.isReadOnlyUser = !!this.user.roles.includes(UserRoleNew.USER_READ_ONLY);
    this.UI.isReadOnlyAdmin = !!this.user.roles.includes(UserRoleNew.ADMIN_READ_ONLY);
    this.UI.isSuperAdmin = !!this.user.roles.includes(UserRoleNew.SUPER_ADMIN);
    this.getUserPublishers();
    this.getUserCo();
    this.getAdvertiser(this.principal.user.advertiserId);
    this.allowedFeatures = this.principal.user.network?.networkSettings?.features;
  }

  public getUserPublishers(): void {
    if (this.principal.user.publisherIds && this.principal.user.publisherIds.length) {
      this.subscription.add(
        this.publisherService.listPublishers({ ids: this.principal.user.publisherIds } as PublisherListFilter).subscribe(publishers => {
          this.publishers = publishers.content;
        }, () => {
          this.sbs.error('Error during while fetching publishers');
        })
      );
    }
  }

  public getAdvertiser(advertiserId?: string): void {
    if (advertiserId) {
      this.subscription.add(
        this.advertiserService.getAdvertiserById(advertiserId).subscribe({
          next: (advertiser) => {
            this.advertiser = advertiser;
          },
          error: () => {
            this.sbs.error('Error during while fetching advertiser');
          }
        }
        )
      );
    } else {
      // This is used to remove advertiser from toolbar menu if there is no advertiser linked to the user
      this.advertiser = null;
    }
  }

  public getUserCo(): void {
    if (this.principal.user.contentOwnerIds && this.principal.user.contentOwnerIds.length) {
      this.subscription.add(
        this.contentOwnerService.list({ coIds: this.principal.user.contentOwnerIds }).subscribe(response => {
          this.contentOwners = response.filter(co => this.principal.user.contentOwnerIds.includes(co.id));
        }, () => {
          this.sbs.error('Error during while fetching content owners');
        })
      );
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }


  public logout() {
    this.loginService.logout(true);
  }

  @HostListener('window:resize')
  resizeScreen() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if ($event.path && $event.path.length) {
      this.scrollOffset = $event.path[1].scrollY;
    } else {
      this.scrollOffset = 0;
    }
  }

  openDashboard(type: 'advertiser' | 'publisher', id?: string) {
    if (type === 'publisher') {
      window.open(`/#/p/${id}`, '_blank').focus();
    } else {
      window.open(`/#/adv/${id}`, '_blank').focus();
    }
  }

  openDialog(type: string) {
    let modalComponent;
    switch (type) {
      case 'feed':
        modalComponent = PlaylistIngestFeedDialogComponent;
        break;
      case 'playlist':
        modalComponent = PlaylistEditorDialogComponent;
        break;
      case 'channel':
        modalComponent = ChannelEditorDialogComponent;
        break;
      case 'user':
        modalComponent = UserEditorDialogComponent;
        break;
      default:
        break;
    }
    // todo add publisher created

    if (modalComponent) {
      this.matDialog.open(modalComponent);
    }

    if (type === 'content-owner') {
      this.router.navigate(['content-owner/create']);
    }
  }

  closeSidenavMobile(sidenav: any) {
    if (this.screenWidth < 992) {
      sidenav.toggle();
    }
  }

  closeAllExpansionsPanels() {
    if (this.accordion) {
      this.accordion.multi = true;
      this.accordion.closeAll();
      this.accordion.multi = false;
    }
  }

  closeAllNetworkExpansionsPanels() {
    if (this.accordionNetwork) {
      this.accordionNetwork.multi = true;
      this.accordionNetwork.closeAll();
      this.accordionNetwork.multi = false;
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isFeatureAllowed(feature: any): boolean {
    return this.allowedFeatures.includes(feature);
  }
}

