<div mat-dialog-title>
  <span>{{isEdit ? 'Edit user' : 'Create new user'}}</span>
</div>
<div>
  <form #f="ngForm" class="d-flex flex-column mt-1">
    <!--toni I added mt- class because it was being choped off on top-->
    <mat-form-field floatLabel="always" color="primary" appearance="outline">
      <mat-label>Email</mat-label>
      <input [disabled]="isEdit" name="username" email matInput required [(ngModel)]="user.email" />
    </mat-form-field>
    <mat-form-field floatLabel="always" color="primary" appearance="outline">
      <mat-label>Name</mat-label>
      <input name="name" matInput required [(ngModel)]="user.name" />
    </mat-form-field>
    <mat-form-field floatLabel="always" color="primary" appearance="outline" *ngIf="!isEdit">
      <mat-label>Password</mat-label>
      <div class="input-container">
        <input name="password" [type]="showPassword ? 'text' : 'password'" matInput required
          [(ngModel)]="user.password" />
        <mat-icon (click)="togglePasswordVisibility()" class="center-icon">
          {{ showPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </div>
    </mat-form-field>


    <mat-form-field floatLabel="always" color="primary" appearance="outline">
      <mat-label>Roles</mat-label>
      <mat-select name="roles" [multiple]="true" [(ngModel)]="user.roles" [disabled]="disabled" required>
        <mat-option *ngFor="let role of userRoleKLNew" [value]="role.key">{{role.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100" floatLabel="always" appearance="outline" *ngIf="isSA()">
      <mat-label>Network</mat-label>
      <mat-select name="networkId" [(ngModel)]="userNetwork" [class.hide-arrow]="isNetworkListLoading"
        [disabled]="isNetworkDropdownDisabled">
        <mat-option *ngFor="let network of networkList" [value]="network.id">{{network.name}}</mat-option>
      </mat-select>
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!isNetworkListLoading"></i>
    </mat-form-field>

    <mat-form-field class="w-100" floatLabel="always" appearance="outline" *ngIf="isAdvertiser()">
      <mat-label>Advertiser</mat-label>
      <mat-select name="advertiser" [(ngModel)]="user.advertiserId" [class.hide-arrow]="isAdvertiserListLoading"
        [disabled]="isAdvertiserDropdownDisabled">
        <mat-option *ngFor="let advertiser of advertiserList" [value]="advertiser.id">{{advertiser.name}}</mat-option>
      </mat-select>
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!isAdvertiserListLoading"></i>
    </mat-form-field>

    <div *ngIf="isPublisher()">
      <mt-publisher-autocomplete-chips [publisherIds]="user.publisherIds"
        (publisherSelectionChange)="onPublisherSelection($event)" [networkIds]="[networkId]"
        [networkIdsChanged]="eventsSubjectNetwork.asObservable()" [clearItems]="clearFilterItems.asObservable()"
        formFieldAppearance="outline">
      </mt-publisher-autocomplete-chips>
    </div>
    <div class=""
      *ngIf="(user.roles && user.roles.includes(userRole.CONTENT_OWNER) && !userType) || (userType && user.contentOwners)">
      <mt-content-owner-autocomplete-chips [contentOwners]="user.contentOwners"
        (contentOwnerSelectionChange)="onContentOwnerSelection($event)" [disabled]="disabled"
        formFieldAppearance="outline"></mt-content-owner-autocomplete-chips>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button class="mr-2" [mat-dialog-close]="false">
    Close
  </button>

  <button mat-flat-button class="iconed" color="accent" (click)="generateSecurePassword()" *ngIf="!isEdit">
    <i class="fa-sharp fa-solid fa-lock"></i>
    <span>Generate password</span>
  </button>

  <ng-container *ngIf="!isSA(); else superAdminTemplate">
    <button mat-flat-button *csHasPermission=[RolePermission.USERS_USERS_WRITE,RolePermission.USERS_USERS_EDIT]
      class="iconed" color="primary" [disabled]="!f.valid || (user && user.roles && (user.roles.includes(userRoleNew.ADVERTISER) || user.roles.includes(userRoleNew.ADVERTISER_READ_ONLY)) && !user.advertiserId) || (user && user.roles && user.roles.includes(userRole.PUBLISHER)
                  && (!user.publisherIds || !user.publisherIds.length))" (click)="createUser()">
      <!-- TODO: ? || moreThanOneMainRoleSelected() -->
      <i class="fas fa-save"></i>
      <span>Save</span>
    </button>
  </ng-container>
  <ng-template #superAdminTemplate>
    <button mat-flat-button *csHasPermission=[RolePermission.USERS_USERS_WRITE,RolePermission.USERS_USERS_EDIT]
      class="iconed" color="primary" [disabled]="!f.valid || userNetwork === '' || (user && user.roles && (user.roles.includes(userRoleNew.ADVERTISER) || user.roles.includes(userRoleNew.ADVERTISER_READ_ONLY)) && !user.advertiserId) || (user && user.roles && user.roles.includes(userRole.PUBLISHER)
                && (!user.publisherIds || !user.publisherIds.length))" (click)="createUser()">
      <!-- TODO: ? || moreThanOneMainRoleSelected() -->
      <i class="fas fa-save"></i>
      <span>Save</span>
    </button>
  </ng-template>
</div>