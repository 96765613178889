<div class="metric-summary-card-container">
  <!-- SPINNER -->
  <div *ngIf="loading" class="w-100 d-flex justify-content-center">
    <mat-progress-spinner diameter="40" color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
  <!-- CONTENT -->
  <div *ngIf="!loading" class="d-flex">
    <!-- Icon -->
    <div class="metric-icon metric-icon--{{icon}}">
      <i class="fas {{icon}}"></i>
    </div>
    <!-- Text -->
    <h1 [ngSwitch]="valueType">
      <ng-container *ngSwitchCase="ValueType.Number">{{value | number}}</ng-container>
      <ng-container *ngSwitchCase="ValueType.Percentage">{{value | percent:'1.2-2'}}</ng-container>
      <ng-container *ngSwitchCase="ValueType.Currency">{{value | currency : 'USD'}}</ng-container>
      <ng-container *ngSwitchCase="ValueType.Duration">{{value | duration }}</ng-container>
      <small>{{label}}</small>
    </h1>
  </div>
  <i class="far fa-info-circle text-info metric-description" aria-hidden="true" *ngIf="infoText"
    matTooltip="{{infoText}}"></i>

</div>
