import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {LoginService} from './login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../auth/user.model';
import {AuthService} from '../auth/auth.service';
import {SnackBarService} from '../services/snackbar.service';
import {GlobalSpinnerService} from '../services/global-spinner.service';
import {Principal} from '../auth/principal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  @ViewChild('username', { static: false }) vc: ElementRef;

  user: User = new User();
  registerLinkVisible = false;
  private returnUrl: any | string;
  platformTitle: string = environment.platformTitle;
  logoUrl: string = environment.logoUrl;

  publisherDashboardPermissions = [
    "publisher-dashboard:read",
    "publisher-dashboard.home:read",
    "publisher-dashboard.reporting:read"
  ];

  constructor(private loginService: LoginService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private principal: Principal,
              private snackBarService: SnackBarService,
              private globalSpinnerService: GlobalSpinnerService) {
  }

  ngOnInit() {
    console.log();
    this.loginService.logout();
    this.route.queryParams.subscribe(next => {
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || null;
    });  }

  public login() {
    this.globalSpinnerService.showLoader();
    this.loginService.login(this.user)
      .then((user: User) => {
        user.roles.length > 1 ? this.navigate(['/select']) : this.navigate(['/dashboard']);
        this.globalSpinnerService.hideLoader();
      }).catch((error) => {
      console.error(error);
      this.globalSpinnerService.hideLoader();
      this.snackBarService.error('Invalid credentials');
    });
  }

  private navigate(location) {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(location);
    }
  }

}
