import { Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { MaskSelectComponent } from './mask-select.component';
import { LoginComponent } from './core/login/login.component';
import { LogoutComponent } from './core/login/logout.component';
import { AdminGuard } from './core/guards/admin-guard.service';
import { PublisherGuard } from './core/guards/publisher-guard.service';
import { ForbiddenComponent } from './core/components/forbidden.component';
import { NetworkGuard } from './core/guards/network-guard.service';
import { AppRoutePath } from './app-route-path.enum';
import { ForgotPasswordComponent } from "./core/reset-password/forgot-password.component";
import { ResetPasswordComponent } from "./core/reset-password/reset-password.component";
import { UserManagementGuard } from "./core/guards/user-management-guard";
import { HomeScreenGuard } from './core/guards/home-screen-guard.service';
import { AdvertiserGuard } from './core/guards/advertiser-guard.service';

export const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'p/:id',
    canLoad: [PublisherGuard],
    loadChildren: () => import('./modules/publisher/publisher.module').then(m => m.PublisherModule)
  },
  {
    path: 'adv/:id',
    canLoad: [AdvertiserGuard],
    loadChildren: () => import('./modules/advertiser/advertiser.module').then(m => m.AdvertiserModule)
  },
  {
    path: 'select',
    component: MaskSelectComponent
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AdminGuard],
    canActivateChild: [NetworkGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard 2.0/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
      },
      // {
      //   path: 'roles',
      //   loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),
      // },
      {
        path: 'publishers',
        loadChildren: () => import('./modules/publishers/publishers.module').then(m => m.PublishersModule),
      },
      {
        path: 'networks',
        loadChildren: () => import('./modules/networks/networks.module').then(m => m.NetworksModule),
      },
      {
        path: 'channels',
        loadChildren: () => import('./modules/channels/channels.module').then(m => m.ChannelsModule),
      },
      {
        path: 'collections',
        loadChildren: () => import('./modules/playlists/playlists.module').then(m => m.PlaylistsModule),
      },
      {
        path: 'videos',
        loadChildren: () => import('./modules/videos/videos.module').then(m => m.VideosModule),
      },
      {
        path: 'placements',
        loadChildren: () => import('./modules/placements/placements.module').then(m => m.PlacementsModule),
      },
      {
        path: 'forecast',
        loadChildren: () => import('./modules/forecast/forecast.module').then(m => m.ForecastModule),
      },
      {
        path: 'endpoint-generator',
        loadChildren: () => import('./modules/endpoint-generator/endpoint-generator.module').then(m => m.EndpointGeneratorModule),
      },
      {
        path: AppRoutePath.TARGETING,
        loadChildren: () => import('./modules/targeting/targeting.module').then(m => m.TargetingModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'custom-reports',
        loadChildren: () => import('./modules/custom-reports/custom-reports.module').then(m => m.CustomReportsModule),
      },
      {
        path: 'scheduled-reports',
        loadChildren: () => import('./modules/scheduled-reports/scheduled-reports.module').then(m => m.ScheduledReportsModule),
      },
      {
        path: 'content-owner',
        loadChildren: () => import('./modules/content-owner/content-owner.module').then(m => m.ContentOwnerModule),
      },
      {
        path: 'demand',
        loadChildren: () => import('./modules/demand/demand.module').then(m => m.DemandModule),
      },
      {
        path: 'integrations',
        loadChildren: () => import('./modules/connections/connections.module').then(m => m.ConnectionsModule),
      },
      {
        path: 'widget-types',
        loadChildren: () => import('./modules/widget-types/widget-types.module').then(m => m.WidgetTypesModule),
      },
      {
        path: 'deals',
        loadChildren: () => import('./modules/pmp-deals/pmp-deals.module').then(m => m.PmpDealsModule),
      },
      {
        path: 'changelog',
        loadChildren: () => import('./modules/changelog/changelog.module').then(m => m.ChangelogModule),
      },
      {
        path: 'ads-txt',
        loadChildren: () => import('./modules/ads-txt/ads-txt.module').then(m => m.AdsTxtModule),
      },
      {
        path: AppRoutePath.CAMPAIGNS,
        loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule),
      },
      {
        path: 'line-items',
        loadChildren: () => import('./modules/campaigns/line-items/line-items-table.module').then(m => m.LineItemsModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./modules/campaigns/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'apps',
        loadChildren: () => import('./modules/apps/apps.module').then(m => m.AppsModule),
      },
      {
        path: 'ai-web-player',
        loadChildren: () => import('./modules/ai-web-player/ai-web-player.module').then(m => m.AiWebPlayerModule)
      },
      {
        path: 'performance',
        loadChildren: () => import('./modules/performance/performance.module').then(m => m.PerformanceModule),
      },
      {
        path: 'assets',
        loadChildren: () => import('./modules/audio-to-video/assets/a2v-assets.module').then(m => m.A2vAssetsModule),
      },
      {
        path: 'brands',
        loadChildren: () => import('./modules/audio-to-video/brands/a2v-brands.module').then(m => m.A2vBrandsModule),
      },
      {
        path: 'network-template',
        loadChildren: () => import('./modules/audio-to-video/network-template/a2v-network-template.module').then(m => m.A2vNetworkTemplateModule),
      },
      {
        path: 'brand',
        loadChildren: () => import('./modules/campaigns/brand/brand.module').then(m => m.BrandModule),
      },
      {
        path: 'advertiser',
        loadChildren: () => import('./modules/campaigns/advertisers/advertisers.module').then(m => m.AdvertisersModule)
      },
      {
        path: 'tailored-supply',
        loadChildren: () => import('./modules/tailored-supply/tailored-supply.module').then(m => m.TailoredSupplyModule),
      },
    ]
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];
