import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { A2vConfiguration, Adomain, Asset, A2vBrand, GlobalA2vBrandTemplate, LivePreviewRequest, LivePreviewResponse, NetworkTemplateConfiguration, PublisherTemplateConfiguration } from '../domain/audio-to-video.model';
import { Page } from '../domain/page.model';
import { HttpUtils } from '../utils/http-utils';

@Injectable({
  providedIn: 'root'
})
export class A2vService {
  constructor(private httpClient: HttpClient) {
  }

  //#region Assets
  public getAssets(filter: any): Observable<Page<Asset>> {
    return this.httpClient.get<Page<Asset>>(`${environment.serviceUrl}/api/assets`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getAssetByID(assetID: string): Observable<Asset> {
    return this.httpClient.get<Asset>(`${environment.serviceUrl}/api/assets/${assetID}`);
  }

  public uploadAsset(asset: Asset): Observable<Asset> {
    const formData: FormData = new FormData();
    formData.append('width', asset.width.toString());
    formData.append('height', asset.height.toString());
    formData.append('file', asset.file);
    if (asset.tags) {
      asset.tags.forEach((tag, index) => {
        formData.append('tags['+index+']', tag);
      });
    }


    return this.httpClient.post<Asset>(`${environment.serviceUrl}/api/assets`, formData);
  }

  public updateAsset(asset: Asset): Observable<Asset> {
    return this.httpClient.put<Asset>(`${environment.serviceUrl}/api/assets/${asset.id}`, asset);
  }
  //#endregion

  //#region Brands
  public getBrands(filter: any): Observable<Page<A2vBrand>> {
    return this.httpClient.get<Page<A2vBrand>>(`${environment.serviceUrl}/api/a2vbrands`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getA2vBrandByID(brandID: string): Observable<A2vBrand> {
    return this.httpClient.get<A2vBrand>(`${environment.serviceUrl}/api/a2vbrands/${brandID}`);
  }

  public createA2vBrand(brand: A2vBrand): Observable<A2vBrand> {
    return this.httpClient.post<A2vBrand>(`${environment.serviceUrl}/api/a2vbrands`, brand);
  }

  public updateA2vBrand(brand: A2vBrand): Observable<A2vBrand> {
    return this.httpClient.put<A2vBrand>(`${environment.serviceUrl}/api/a2vbrands/${brand.id}`, brand);
  }

  public getGlobalA2vBrandTemplate(): Observable<GlobalA2vBrandTemplate> {
    return this.httpClient.get<GlobalA2vBrandTemplate>(`${environment.serviceUrl}/api/a2vbrands/global-configuration-latest`);
  }

  public createGlobalA2vBrandTemplate(template: GlobalA2vBrandTemplate): Observable<GlobalA2vBrandTemplate> {
    return this.httpClient.post<GlobalA2vBrandTemplate>(`${environment.serviceUrl}/api/a2vbrands/global-configuration`, template);
  }

  public updateGlobalA2vBrandTemplate(template: GlobalA2vBrandTemplate): Observable<GlobalA2vBrandTemplate> {
    return this.httpClient.put<GlobalA2vBrandTemplate>(`${environment.serviceUrl}/api/a2vbrands/global-configuration`, template);
  }
  //#endregion

  //#region Network Template
  public getNetworkTemplate(): Observable<NetworkTemplateConfiguration> {
    return this.httpClient.get<NetworkTemplateConfiguration>(`${environment.serviceUrl}/api/admin/networks/configuration`);
  }

  public updateNetworkTemplate(config: A2vConfiguration): Observable<A2vConfiguration> {
    return this.httpClient.put<A2vConfiguration>(`${environment.serviceUrl}/api/admin/networks/configuration`, config);
  }
  //#endregion

  //#region Publisher Template
  public getPublisherTemplate(pubID: string): Observable<PublisherTemplateConfiguration> {
    return this.httpClient.get<PublisherTemplateConfiguration>(`${environment.serviceUrl}/api/publishers/${pubID}/configuration`);
  }

  public updatePublisherTemplate(pubID: string, config: PublisherTemplateConfiguration): Observable<PublisherTemplateConfiguration> {
    return this.httpClient.put<PublisherTemplateConfiguration>(`${environment.serviceUrl}/api/publishers/${pubID}/configuration`, config);
  }
  //#endregion

  //#region Other
  public getTags(filter: any): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/assets/tags`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getAdomains(filter: any): Observable<Page<Adomain>> {
    return this.httpClient.get<Page<Adomain>>(`${environment.serviceUrl}/api/adomains`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public createQrImage(qrLink: string) {
    return this.httpClient.post(`${environment.serviceUrl}/api/assets/qrcode`, {text: qrLink}, {responseType: 'text'});
  }

  public createLivePreview(request: LivePreviewRequest): Observable<LivePreviewResponse[]> {
    return this.httpClient.post<LivePreviewResponse[]>(`${environment.a2vUrl}/a2v`, request);
  }

  //#endregion

}
