import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {Publisher, PublisherStatus} from '../../domain/publisher.model';
import {PublisherListFilter, PublishersService} from '../../services/publishers.service';
import {ContentOwnerService} from '../../services/content-owner.service';
import {ContentOwner} from '../../domain/content-owner.model';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-content-owner-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label >{{label ? label : 'Choose content owner'}}</mat-label>
      <input matInput
             #name
             (focus)="onFocusEvent()"
             name="coAutoComplete"
             aria-label="Content owner"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event.option.value)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
  })
export class ContentOwnerAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public contentOwnerId: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  items: ContentOwner[];

  @Input()
  disabled: boolean;

  @Output()
  contentOwnerSelected = new EventEmitter<ContentOwner>();

  serverSearching = false;

  filteredItems: Subject<ContentOwner[]>;
  filterCtrl = new UntypedFormControl();
  selectedContentOwner;
  filteredContentOwnersIds: string[] = [];
  contentOwnerList: ContentOwner[];

  constructor(private service: ContentOwnerService) {
    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedContentOwner) {
          this.selectedContentOwner = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          service.list({
            page: 0,
            size: 10 + this.filteredContentOwnersIds.length,
            name: next
          }).subscribe(resp => {
            let co = resp;
            co = co.filter(item => !this.filteredContentOwnersIds.includes(item.id));
            this.filteredItems.next(co.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          }, () => {
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          });
        }
      });
  }

  resetInput() {
    this.selectedContentOwner = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.filterCtrl.disable({onlySelf: true, emitEvent: true});
    }
    if (this.contentOwnerId) {
      this.service.getCoDetails(this.contentOwnerId).subscribe(next => {
        if (next) {
          this.selectedContentOwner = next;
          this.filterCtrl.setValue(next);
          this.emit();
        }
      });
    }

    this.service.list({
      page: 0,
      size: 1000,
    }).subscribe(resp => {
      this.contentOwnerList = resp;
    }, () => {
      this.filterCtrl.enable();
      this.nameFilter.nativeElement.focus();
      this.serverSearching = false;
    });
  }

  onFocusEvent() {
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(this.contentOwnerList.filter(item => !this.filteredContentOwnersIds.includes(item.id)));
    }
  }

  display(co?: ContentOwner) {
    return co ? co.name : undefined;
  }

  optionSelected(co: ContentOwner) {
    this.selectedContentOwner = co;
    this.filteredItems.next([]);
    this.emit();
  }

  updateFilteredCos(items) {
    this.filteredContentOwnersIds = items.map(item => item.id);
  }

  private emit() {
    this.contentOwnerSelected.emit(this.selectedContentOwner);
  }
}
