import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ServerErrorUtils } from '../../../../shared/utils/server-error-utils';
import { SnackBarService } from '../../../../core/services/snackbar.service';
import { DemandPartnersService } from 'src/app/shared/services/demand-partners.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  ConnectionPlacement, DeliveryTypeLabels,
  ProductTypePlacementLabels,
} from 'src/app/shared/domain/placements.model';
import { DemandPartnerConnection } from 'src/app/shared/domain/demand-partner-connection.model';
import {ActiveInactiveArchivedColorMap} from '../../../../shared/enums/active-inactive-archived.enum';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';

interface DpPlacementPreviewDialogInput {
  connection: DemandPartnerConnection;
}

@Component({
  selector: 'mt-placement-dialog',
  templateUrl: './placement-dialog.component.html',
  styleUrls: ['./placement-dialog.component.scss'],
})
export class DpPlacementPreviewDialog implements AfterViewInit, OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public readonly RolePermission = RolePermission;
  columns = ['name', 'deliveryType', 'bidFloor', 'cpm'];
  dataSource = new MatTableDataSource<ConnectionPlacement>();
  public readonly DeliveryTypeLabels = DeliveryTypeLabels;
  public readonly ActiveInactiveArchivedColorMap = ActiveInactiveArchivedColorMap;
  sbs: Subscription;
  loading = false;
  filter = {
    page: 0,
    size: 10,
    sortProperty: ['name'],
    sortDirection: 'ASC',
    name: undefined,
  } as any;

  constructor(
    public dialogRef: MatDialogRef<DpPlacementPreviewDialog>,
    private snackBarService: SnackBarService,
    private service: DemandPartnersService,
    private router: Router,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: DpPlacementPreviewDialogInput
  ) {}

  ngOnInit() {
    this.getPlacements();
  }

  getPlacements() {
    this.loading = true;
    this.sbs = this.service
      .getPlacementByIntegration(
        this.data.connection.demandPartner.id,
        this.data.connection.id,
        this.filter
      )
      .subscribe(
        (response) => {
          this.loading = false;
          this.paginator.length = response.totalElements;
          this.dataSource.data = response.content;
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error(
              'Error during while fetching demand partner placement'
            );
          }
          this.loading = false;
        }
      );
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('700px');
    this.sort.sortChange.subscribe((next) => {
      this.filter.sortProperty = [next.active];
      this.filter.sortDirection = next.direction.toUpperCase();
      this.refreshDataFirstPage();
    });

    this.paginator.page.subscribe((p: PageEvent) => {
      this.filter.size = p.pageSize;
      this.filter.page = p.pageIndex;
      this.refreshData();
    });
  }

  refreshDataFirstPage() {
    this.paginator.pageIndex = 0;
    this.filter.page = 0;
    this.refreshData();
  }

  refreshData() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: Object.assign({}, this.filter),
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.location.replaceState(urlTree.toString());
    if (this.sbs) {
      this.sbs.unsubscribe();
    }
    this.getPlacements();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
