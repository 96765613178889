import { KeyLabel } from '../domain/key-label.model';

export enum ActiveArchivedStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum ActiveArchivedColorMap {
  ACTIVE = 'green',
  ARCHIVED = 'gray',
}

export const ActiveArchivedLabels = {
  [ActiveArchivedStatus.ACTIVE]: 'Active',
  [ActiveArchivedStatus.ARCHIVED]: 'Archived',
};

export class StatusBox {
  count: number;
  status: string;
}

export const ActiveArchivedStatusKL = () =>
    (Object.keys(ActiveArchivedStatus).map(key => new KeyLabel(<ActiveArchivedStatus>key, ActiveArchivedLabels[key])));

export const ActiveInactiveArchivedOrder = Object.values(ActiveArchivedStatus);
