<div class="card-message" [hidden]="!loading">
  <mat-spinner [color]="'primary'"
               [mode]="'indeterminate'"
               [diameter]="30">
  </mat-spinner>
</div>
<div class="card-message"
     [hidden]="loading || showChart">
  <span class="text-warning"><i class="fa-regular fa-file-circle-xmark fa-2xl"></i></span>
</div>
<div [hidden]="loading || !showChart">
  <div #chart class="w-100"></div>
</div>
