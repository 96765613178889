<div mat-dialog-title>
  Endpoint generator
</div>
<div class="data-loader" [hidden]="!loading">
  <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="60">
  </mat-spinner>
</div>
<div mat-dialog-content [hidden]="loading" class="border-top border-bottom px-0">
  <div class="d-flex">
    <div class="col-12 col-lg-6 px-0 border-right">

      <div class="d-flex align-items-center p-2 border-bottom">
        <strong class="ml-3">Detected parameters: {{getMacrosArray.controls?.length || 0}}</strong>
        <button [hidden]="!data.bidderCode" mat-button color="primary" (click)="autoReplace()" class="ml-auto">
          <i class="fal fa-wand-magic-sparkles mr-2"></i>
          Auto replace
        </button>
      </div>

      <div class="endpoint-macro-row">
        <div class="col-4"><small class="text-muted">Parameter</small></div>
        <div class="col-1 px-0"></div>
        <div class="col-6"><small class="text-muted">Macro</small></div>
        <div class="col-1 px-0"></div>
      </div>

      <ng-container [formGroup]="form">
        <ng-container formArrayName="macrosArray">
          <div *ngFor="let control of getMacrosArray.controls; index as i" [formGroupName]="i">
            <div class="endpoint-macro-row" *ngIf="control.value.id !== 'pid' && control.value.id !== 'placementId'">
              <div class="col-4">{{control.value.id}}</div>
              <div class="col-1 px-0">=</div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="endpoint-macro-input w-100">
                  <input (change)="macroInputChanged(control)" matInput type="text" formControlName="macro">
                  <button matSuffix mat-icon-button [matMenuTriggerFor]="macrosListMenu" (click)="$event.stopPropagation()" matTooltip="Change macro" style="top:0">
                    <i class="fas fa-exchange-alt"></i>
                  </button>
                  <mat-menu #macrosListMenu="matMenu">
                    <ng-container *ngFor="let macro of this.data.dropdownMacroList">
                      <button (click)="macroSelectionChanged(control, macro)" [matTooltip]="macro.description" mat-menu-item>
                        {{macro.value}} <i *ngIf="macro.description" style="position: relative; top: -10px" class="fal fa-info-circle text-info"></i>
                      </button>
                    </ng-container>
                  </mat-menu>
                </mat-form-field>
              </div>
              <div class="col-1 px-0">
                <button mat-icon-button (click)="deleteMacro(control)"><i class="far fa-trash-alt"></i></button>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-12 col-lg-6">
      <div class=" my-3" style="top:16px; position:sticky;">
        <p class=""><strong>Preview:</strong></p>
        <div class="endpoint-container">
          <div class="endpoint-container_value">{{data.endpoint}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-stroked-button class="mr-2" [mat-dialog-close]="false">
    Cancel
  </button>
  <button mat-flat-button color="primary" (click)="save()">
    <span>Apply</span>
  </button>
</div>
