import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SnackBarService} from 'src/app/core/services/snackbar.service';

interface FileSelectComponentInput {
  title: string;
  description: string;
  accept: string;
}

@Component({
  selector: 'mt-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss'],
})
export class FileSelectComponent implements OnInit {
  @Input()
  accept: string[];

  @Output()
  onFileSelected = new EventEmitter<File>();

  @Output()
  onFileRemoved = new EventEmitter<any>();

  selectedFile: File;

  constructor(private snackBarService: SnackBarService) {
  }

  ngOnInit(): void {
  }

  removeFile() {
    this.selectedFile = null;
    this.onFileRemoved.emit(null);
  }

  onFileChange(file: File) {
    this.selectedFile = null;
    if (!file) {
      return this.snackBarService.error('File is not selected.');
    }

    const fileExt = file.name.substring(file.name.lastIndexOf('.'));

    if (this.accept?.length && !this.accept.includes(fileExt)) {
      return this.snackBarService.error('The selected file is not supported. Supported file types are: ' + this.accept);
    }

    this.selectedFile = file;
    this.onFileSelected.emit(file);
  }
}
