<div mat-dialog-title>
  <span>Parse feed</span>
</div>

<div mat-dialog-content>
  <form #f="ngForm" class="d-flex flex-column">

    <label>Feed Source</label>
    <mat-radio-group name="source" [(ngModel)]="feed.source" class="mb-3" (ngModelChange)="updateFeedType()">
      <mat-radio-button *ngFor="let source of feedSourceKL" [value]="source.key" color="primary" class="mr-3">{{source.label}}</mat-radio-button>
    </mat-radio-group>

    <mat-form-field floatLabel="always" color="primary" *ngIf="feed.feedTypeYT === feedType.CHANNEL" appearance="outline">
      <mat-label>Count</mat-label>
      <input name="count" type="number" matInput [(ngModel)]="feed.count" autocomplete="off" />
      <i class="fal fa-info-circle text-info float-right" matTooltip="If count is left blank, all videos from this channel will be ingested. If count is set to 10 for example, 10 most popular videos will be ingested from this channel"></i>
    </mat-form-field>

    <mat-form-field floatLabel="always" color="primary" *ngIf="feed.source === feedSource.UPLOAD" appearance="outline">
      <mat-label>Title</mat-label>
      <input name="title" matInput required [(ngModel)]="feed.title" autocomplete="off" />
    </mat-form-field>

    <mat-form-field floatLabel="always" color="primary" *ngIf="feed.source === feedSource.UPLOAD" appearance="outline">
      <mat-label>Description</mat-label>
      <input name="description" matInput [(ngModel)]="feed.description"  autocomplete="off" />
    </mat-form-field>

    <mt-video-upload-preview class="mb-3" *ngIf="feed.source === feedSource.UPLOAD" label="upload video" (fileSelected)="videoFile = $event"
                              (fileCanceled)="videoFile=null"></mt-video-upload-preview>

    <mt-image-upload-preview label="upload thumbnail"
                              *ngIf="feed.source === feedSource.UPLOAD"
                              (fileSelected)="thumbnailFile = $event"
                              (fileCanceled)="removeThumbNailImage()"
                              [fileName]="false"
                              [resolutonGreater]="true"
                              [allowedFileTypes]="'image/png, image/jpeg'"
                              [tooltip]="'PNG or JPG, 480x360'"
                              [resolutionWidth]="'480'"
                              [resolutionHeight]="'360'"
                              [imagePreview]="thumbnailFile"></mt-image-upload-preview>

    <mat-form-field floatLabel="always" color="primary" *ngIf="feed.newPlaylist" appearance="outline">
      <mat-label>New collection name</mat-label>
      <input name="playlistName" matInput [(ngModel)]="feed.newPlaylistName" autocomplete="off" required />
    </mat-form-field>

    <div class="row" *ngIf="feed.newPlaylist">
      <div class="col-lg-6 col-md-6">
        <label>Playlist poster</label><br/>
        <button type="button"
                mat-flat-button
                (click)="posterInput.click()"
                class="iconed">
          <i class="fas fa-cloud-upload-alt"></i>
          Upload
        </button>
        <input hidden (change)="onFileSelected()" #posterInput type="file" id="poster">
      </div>

      <div class="col-lg-6 col-md-6">
        <label>Playlist image</label><br/>
        <button type="button"
                mat-flat-button
                (click)="imageInput.click()"
                class="iconed">
          <i class="fas fa-cloud-upload-alt"></i>
          Upload
        </button>
        <input hidden (change)="onFileSelected()" #imageInput type="file" id="image">
      </div>
    </div>


    <mt-categories-multi-select [selectedCategory]="feed.category"
                                 (categorySelected)="feed.category = $event" *ngIf="feed.newPlaylist">
    </mt-categories-multi-select>

    <label *ngIf="!videoType">Owner type</label>
    <mat-radio-group name="ownerType" [(ngModel)]="feed.ownerType" class="mb-3" (change)="feed.ownerId = null" *ngIf="!videoType">
      <mat-radio-button color="primary" class="mr-3" *ngFor="let ownerType of ownerTypeKL" value="{{ownerType.key}}">{{ownerType.label}}</mat-radio-button>
    </mat-radio-group>


    <mt-content-owner-autocomplete (contentOwnerSelected)="setOwnerId($event)"  *ngIf="feed.ownerType === ownerType.CONTENT_OWNER && !videoType"></mt-content-owner-autocomplete>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button class="" [mat-dialog-close]="false">
    Cancel
  </button>

  <button mat-flat-button color="primary"
          [disabled]="!f.valid || !feed.ownerType || !validateUrl(feed.source, feed.url) ||
                      (!feed.category && feed.newPlaylist) ||
                      (feed.ownerType === ownerType.CONTENT_OWNER && !feed.ownerId)
                      "
          (click)="submit()">
    <span>Finish</span>
  </button>
</div>
