import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpUtils} from '../utils/http-utils';
import {SummedMasterReport} from '../domain/rb/summed-master-report.model';

@Injectable()
export class DashboardService {
  constructor(private httpClient: HttpClient) {
  }

  report(filter: { dateFrom: string; dateTo: string }): Observable<DashboardResponse> {
    return this.httpClient.get<DashboardResponse>(`${environment.serviceUrl}/api/dashboard/report`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  //DASHBOARD 2.0
  public getTodaysData(): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report/live`);
  }

  public getDataForRangePreset(range: string, offset: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report/overview-presets?presetDateRange=${range}&offsetCount=0`);
}

  public getDataForCustomRange(dateTo: string, dateFrom: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report`,
    {
      params: HttpUtils.getHttpParams({dateFrom, dateTo}),
    });
  }

  // DASHBOARD SA
  
    public getDataForRangePresetSuperAdmin(range: string, offset: number): Observable<any> {
      return this.httpClient.get<any>(`${environment.serviceUrl}/api/sa-dash/report/overview-presets?presetDateRange=${range}&offsetCount=0`);
  }
  
    public getDataForCustomRangeSuperAdmin(dateTo: string, dateFrom: string): Observable<any> {
      return this.httpClient.get<any>(`${environment.serviceUrl}/api/sa-dash/report`,
      {
        params: HttpUtils.getHttpParams({dateFrom, dateTo}),
      });
    }

  //ADVERTISER
  public getAdvertiserTodaysData(): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report/live`);
  }

  public getAdvertiserDataForRangePreset(range: string, offset: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/adv-dash/report/overview-presets?presetDateRange=${range}&offsetCount=0`);
  }

  public getAdvertiserDataForCustomRange(dateTo: string, dateFrom: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/adv-dash/report`,
    {
      params: HttpUtils.getHttpParams({dateFrom, dateTo}),
    });
  }

  //PUBLISHER DETAILS DASHBOARD
  public getPubsTodaysData(): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report/live`);
  }

  public getPubsDataForRangePreset(range: string, publisherId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report/${publisherId}/overview-presets?presetDateRange=${range}&offsetCount=0`);
  }

  public getPubsDataForCustomRange(dateTo: string, dateFrom: string, publisherId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report/${publisherId}`,
    {
      params: HttpUtils.getHttpParams({dateFrom, dateTo}),
    });
  }

  //GET LAST IMPORT TIME
  public getLastImportTime(): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/report/last-import-time`);
  }

  //GET DEMAND SOURCES DATA
  public getDemandSourcesByDate(dateTo: string, dateFrom: string, publisherId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report-publisher-top5-dp/${publisherId}`,
    {
      params: HttpUtils.getHttpParams({dateFrom, dateTo}),
    });
  }

  public getDemandSourcesForRangePreset(range: string, publisherId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/dashboard/report-publisher-top5-dp/${publisherId}/presets?presetDateRange=${range}&offsetCount=0`);
  }
}

export class DashboardResponse {
  publishers: SummedMasterReport[];
  domains: SummedMasterReport[];
  embeds: SummedMasterReport[];
  total: SummedMasterReport;
  apps: SummedMasterReport[];
  days: SummedMasterReport[];
}
