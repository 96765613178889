<div class="mat-dialog-header">
  <div class="mat-dialog-title">
    {{data.name}}
  </div>
  <div class="mat-dialog-header-box">
    <mat-form-field class="header-form-field w-100" [hidden]="loading" floatLabel="never">
      <mat-label>All demand partners</mat-label>
      <mat-select [(value)]="filter.demandPartnerIds" (selectionChange)="onDemandPartnerFilterChange($event)">
        <mat-option *ngFor="let item of demandPartnerList" [value]="item.id">{{item.displayName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mat-dialog-header-box p-2">
    <div class="status-plate-group">
      <mt-status-box *ngFor="let item of statusCounts" (statusClicked)="!loading && filterByStatus($event)"
                      [status]="setAdsTxtStatus(item)" [activeBox]="isStatusFilterSelected(item.status)"></mt-status-box>
    </div>
  </div>
</div>

<div class="mat-dialog-content">
  <div class="data-loader" [hidden]="!loading">
    <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
    </mat-spinner>
  </div>

  <div class="mx-n4 border-bottom" [hidden]="loading">
    <mat-card class="pt-0 mat-elevation-z0">
      <mat-card-content class="mat-card-content--full">
        <div class="data-alert" [hidden]="(dataSource.data && dataSource.data.length)">
          <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
        </div>
        <div class="table-scroll-wrapper" [hidden]="loading || !dataSource.data || !dataSource.filteredData.length">
          <mat-table id="domain-records-table" #table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="domain">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Domain</mat-header-cell>
              <mat-cell *matCellDef="let element">

                {{element.domain }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="publisherAccountId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Account ID</mat-header-cell>
              <mat-cell *matCellDef="let element">

                {{element.publisherAccountId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="accountType">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Account type</mat-header-cell>
              <mat-cell *matCellDef="let element">

                {{element.accountType }}
                <button *ngIf="element.certAuthority || element.comment" mat-icon-button color="primary" class="text-info"
                        [matMenuTriggerFor]="menu">
                  <i class="fal fa-info-circle"></i>
                </button>

                <mat-menu #menu="matMenu" overlapTrigger class="data-list-menu">
                  <ng-template matMenuContent>
                    <ul class="menu-info-list menu-info-list-400px">
                      <ng-container>
                        <li class="menu-info-list-header">Certification authority</li>
                        <li>
                          {{ element.certAuthority || '-' }}
                        </li>
                      </ng-container>
                      <ng-container>
                        <li class="menu-info-list-header">Comment</li>
                        <li>
                          {{ element.comment || '-' }}
                        </li>
                      </ng-container>
                    </ul>
                  </ng-template>
                </mat-menu>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="demandPartner">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Demand partner</mat-header-cell>
              <mat-cell *matCellDef="let element">

                {{element.demandPartner?.displayName}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="priority">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Priority</mat-header-cell>
              <mat-cell *matCellDef="let element" class="mat-cell--has-sort">

                {{element.priority }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="web">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Web</mat-header-cell>
              <mat-cell *matCellDef="let element" class="mat-cell--has-sort">

                <i class="fas fa-check text-success" *ngIf="element.web"></i>
                <i class="far fa-times text-muted" *ngIf="!element.web"></i>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ctv">
              <mat-header-cell *matHeaderCellDef mat-sort-header>CTV</mat-header-cell>
              <mat-cell *matCellDef="let element" class="mat-cell--has-sort">

                <i class="fas fa-check text-success" *ngIf="element.ctv"></i>
                <i class="far fa-times text-muted" *ngIf="!element.ctv"></i>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="notes">
              <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
              <mat-cell *matCellDef="let element">

                <i *ngIf="element.note" class='fal fa-info-circle text-info' matTooltip="{{element.note}}"></i>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element">

                <button mat-icon-button color="primary" (click)="copySingleRecord(element)">
                  <i class="fas fa-copy"></i>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns" [ngClass]="found(row) ? 'green-row' : 'red-row'">
            </mat-row>
          </mat-table>
        </div>

      </mat-card-content>
      <mat-card-footer [hidden]="loading || !dataSource.data || !dataSource.filteredData.length">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 100]">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>

  </div>
</div>

<mat-dialog-actions>
  <div class="mr-lg-auto text-center mb-4 mb-lg-0">
    <button mat-flat-button color="primary" (click)="copyMissingRecords()" class="iconed mb-2 mb-lg-0">
      <i class="fal fa-copy"></i> Missing records
    </button>
    <button mat-flat-button color="primary" (click)="copyAllRecords()" class="iconed">
      <i class="fal fa-copy"></i> All records
    </button>
  </div>
  <button mat-stroked-button (click)="closeDialog()">Close</button>
</mat-dialog-actions>
