import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ConnectionStatus, ConnectionStatusColorMap } from '../../domain/demand-partner-connection.model';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { SortUtils } from '../../utils/sort-utils';
import { OnDestroy } from '@angular/core';
import {FloatLabelType} from "@angular/material/form-field";
import { AppsService } from '../../services/apps.service';
import { Apps, AppListRequest } from '../../domain/apps.model';

@Component({
  selector: 'mt-application-id-autocomplete',
  template: ` <mat-form-field
  [floatLabel]="floatLabel || 'always'"
  class="w-100"
  [ngClass]="formFieldClass"
  [appearance]="formFieldAppearance ? formFieldAppearance : 'outline'"
>
  <mat-label>{{label ? label : 'Choose App(s)'}}</mat-label>
  <input
    matInput
    #search
    aria-label="Publisher"
    [matAutocomplete]="auto"
    [formControl]="filterCtrl"
    [required]="required"
  />
  <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="optionSelected($event)"
    [displayWith]="display"
  >
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{ item.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>`,
})
export class ApplicationIdAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: FloatLabelType;

  /** Hold list of networkIds to filter connections only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  required: boolean;

  @Input()
  preselectedApplicationId: Apps['id'];

  @Input()
  status: ConnectionStatus[];

  @Output()
  itemSelected = new EventEmitter<Apps>();

  filteredItems: Subject<Apps[]>; //List of Pubs to show in dropdown filtered with 'filteredItemIds'
  filterCtrl = new UntypedFormControl();
  connectionList: Apps[] = []; //stores currently fetched list of pubs
  selectedConnection: Apps; //currently selected connection
  filteredItemIds: string[] = []; //It stores all the IDs of selected connections
  public loading = false;
  public ConnectionStatusColorMap = ConnectionStatusColorMap;

  constructor(
    private readonly service: AppsService,
    private readonly snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getItems(searchTerm);
          }
          else if (searchTerm == '') { //triggers when user deletes everything from input
            this.filteredItems.next(undefined);
            this.itemSelected.emit(null);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedApplicationId) {
      this.getItems('', this.preselectedApplicationId);
    }
  }

  /** SearchTerm used for filtering by connection name. preselectedApplicationId used to get only one connection (preselected) at the component initialization. */
  private getItems(searchTerm?: string, preselectedApplicationId?: string) {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.getApps({
      page: 0,
      size: 1000,
      networkIds: this.networkIds,
      searchTerm: searchTerm,
      status: this.status,
      ids: [preselectedApplicationId]
    } as AppListRequest)
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedApplicationId ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected connection
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.connectionList = resp.content.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedApplicationId) {
            this.selectedConnection = this.connectionList[0]; //Naturally since the api call is filtered with one ID, selectedConnection will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedConnection);
            this.updateFilteredConnections([this.selectedConnection]);
            this.emit();
          }
          this.filteredItems.next(this.connectionList.filter(x => !this.filteredItemIds.includes(x.id)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching apps autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedConnection = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.itemSelected.emit(this.selectedConnection);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredItemIds. */
  public updateFilteredConnections(items) {
    this.filteredItemIds = items.map((item) => item.id);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(p?: Apps) {
    return p ? p.name : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
