import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {Embed} from '../../domain/embed.model';
import { VideosService } from '../../services/videos.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-genres-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label >{{label ? label : 'Choose genre'}}</mat-label>
      <input matInput
             #name
             autocomplete="off"
             (focus)="onFocusEvent()"
             aria-label="Genre"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class GenresAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public name: string;

  @Input()
  public activeGenres: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  availableGenres: string[];

  @Input()
  items: string[];

  @Output()
  genreSelected = new EventEmitter<string>();

  serverSearching = false;

  filteredItems: Subject<string[]>;
  filterCtrl = new UntypedFormControl();
  selectedGenre;
  allGenres: string[] = [];
  filteredGenres: string[] = [];
  availableGenresFlag = false;

  constructor(private service: VideosService, private snackBarService: SnackBarService,) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedGenre) {
          this.selectedGenre = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.searchGenre();
        }
      });
  }

  resetInput() {
    this.selectedGenre = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.name) {
      this.selectedGenre = this.name;
      this.filterCtrl.setValue(this.name);
      this.emit();
    }
    this.filteredGenres = this.activeGenres;
  }

  onFocusEvent() {
    if (this.availableGenresFlag) {
      this.allGenres = this.availableGenres;
    } else {
      if (!this.allGenres.length) {
        this.getAllTallGenres();
        return;
      }
    }
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(this.allGenres.filter(item => !this.filteredGenres.includes(item)));
    }
  }

  display(e?: Embed) {
    return e ? e.name : undefined;
  }

  optionSelected($event) {
    this.selectedGenre = $event.option.value;
    this.filteredItems.next([]);
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.genreSelected.emit(this.selectedGenre);
  }

  getAllTallGenres() {
    this.service.genresList().subscribe((response) => {
      this.allGenres = response;
      if (!this.filterCtrl.value?.length) {
        this.filteredItems.next(this.allGenres.filter(item => !this.filteredGenres.includes(item)));
      }
    }, e => {
      this.snackBarService.error('Error occurred during getting genres');
    });
  }

  updateFilteredGenres(items) {
    this.filteredGenres = items.map(item => item);
  }

  searchGenre() {
    if (this.allGenres.length > 0) {
      const search = this.allGenres.filter(e => e.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(search.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }
}
