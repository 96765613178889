import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ServerErrorUtils} from '../../utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {debounceTime, distinctUntilChanged, finalize, takeUntil} from 'rxjs/operators';
import { Publisher } from '../../domain/publisher.model';
import { PublisherListFilter, PublishersService } from '../../services/publishers.service';
import { SortUtils } from '../../utils/sort-utils';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-publishers-autocomplete-chips-input',
  template: `
    <mat-form-field [ngClass]="formFieldClass ? formFieldClass : 'w-100'" [floatLabel]="floatLabel || 'always'" [appearance]="formFieldAppearance ? formFieldAppearance : undefined">
      <mat-label>{{label ? label : 'Publisher/s'}}</mat-label>
      <mat-chip-list #chipList [disabled]='loading' class="form-field-chip-list">
        <mat-basic-chip
          *ngFor='let publisher of selectedPublishers'
          [selectable]='true'
          [removable]='checkIfRemovable(publisher.id)'
          (removed)='remove(publisher)'>
            {{publisher?.name}}
          <i class='fas fa-times-circle text-danger hand' *ngIf="checkIfRemovable(publisher.id)" matChipRemove></i>
        </mat-basic-chip>
        <input
          matInput
          #search
          aria-label="Publisher"
          [matAutocomplete]="auto"
          [formControl]="filterCtrl"
          [placeholder]="placeholder ? placeholder : ''"
          [required]="required"
          [matChipInputFor]='chipList'
        />
      </mat-chip-list>
      <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
      <mat-autocomplete #auto='matAutocomplete' (optionSelected)='optionSelected($event)'>
        <mat-option *ngFor='let publisher of filteredItems | async' [value]='publisher'>
          {{publisher.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint>Start typing (autocomplete)</mat-hint>
    </mat-form-field>`
})

export class PublisherAutocompleteChipsInputComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input() placeholder: string;
  @Input() floatLabel: FloatLabelType;
  /** Holds list of networkIds to filter pubs only by given networks.*/
  @Input() networkIds: string[];
  @Input() label: string;
  @Input() formFieldClass: string;
  @Input() formFieldAppearance: string;
  @Input() required: boolean;
  /** If false, initial/preselected pubs will not be removable. Default is true.*/
  @Input() initialPubsRemovable = true;
  @Input() preselectedPublishers: Publisher['id'][];
  @Output() publisherSelected = new EventEmitter<Publisher[]>();

  filteredItems: Subject<Publisher[]>; //List of Pubs to show in dropdown filtered with 'filteredPublisherIds'
  filterCtrl = new UntypedFormControl();
  publisherList: Publisher[] = []; //stores currently fetched list of pubs
  selectedPublishers: Publisher[] = []; //currently selected publishers
  filteredPublisherIds: string[] = []; //It stores all the IDs of selected pubs
  initialPubIds: string[] = []; //stores initial publisher IDs
  public loading = false;

  constructor(
    private service: PublishersService,
    private snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getPublishers(searchTerm);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedPublishers && this.preselectedPublishers?.length) {
      this.getPublishers('', this.preselectedPublishers);
    }
    if (!this.initialPubsRemovable) {
      this.initialPubIds = this.preselectedPublishers;
    }
  }

  /** SearchTerm used for filtering by pub name. PreselectedPublisherIds used to get all preselected publishers at the component initialization. */
  private getPublishers(searchTerm?: string, preselectedPublisherIds?: string[]) {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.listPublishers({
      page: 0,
      size: 1000,
      networkIds: this.networkIds,
      name: searchTerm,
      ids: preselectedPublisherIds
    } as PublisherListFilter)
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedPublisherIds ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected publisher
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.publisherList = resp.content.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedPublisherIds) {
            this.selectedPublishers = this.publisherList;
            // this.filterCtrl.setValue(this.selectedPublisher);
            this.updateFilteredPublishers(this.selectedPublishers);
            this.emit();
          }
          this.filteredItems.next(this.publisherList.filter(x => !this.filteredPublisherIds.includes(x.id)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching publisher autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedPublishers.push($event.option.value);
    this.emit();
    this.filterCtrl.setValue(null);
    this.searchFilter.nativeElement.value = '';
    this.updateFilteredPublishers(this.selectedPublishers);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component. */
  private emit() {
    this.publisherSelected.emit(this.selectedPublishers);
  }

  public updateFilteredPublishers(items) {
    this.filteredPublisherIds = items.map((item) => item.id);
  }

  public checkIfRemovable(id: string): boolean {
    if (this.initialPubIds.includes(id)) {
      return false;
    }
    else {return true;}
  }

  public remove(p: Publisher) {
    this.selectedPublishers.splice(this.selectedPublishers.indexOf(p), 1);
    this.publisherSelected.emit(this.selectedPublishers);
    this.updateFilteredPublishers(this.selectedPublishers);
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
