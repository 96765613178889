import {Component, Input} from '@angular/core';

@Component({
  selector: 'mt-card-filters',
  template: `
    <div class="mt-card-filters" [ngClass]="formFieldClass" [hidden]="!_showFilters">
	    <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./card-filters.component.scss']
})
export class CardFiltersComponent {
  _showFilters = true;

  @Input()
  public formFieldClass: string;

  @Input()
  set showFilters(showFilters: boolean) {
    this._showFilters = showFilters;
  }

  toggle() {
    this._showFilters = !this._showFilters;
  }
}
