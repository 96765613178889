import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {Subscription} from 'rxjs';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ContentOwnerDocument} from '../../../../../shared/domain/content-owner-document.model';
import {UntypedFormControl} from '@angular/forms';
import {ContentOwnerService} from '../../../../../shared/services/content-owner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {Location} from '@angular/common';
import {SnackBarService} from '../../../../../core/services/snackbar.service';
import {GlobalSpinnerService} from '../../../../../core/services/global-spinner.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ServerErrorUtils} from '../../../../../shared/utils/server-error-utils';
import {PublishersService} from '../../../../../shared/services/publishers.service';
import {DocumentsSharedUploadDocumentDialogComponent} from './documents-shared-upload-document-dialog/documents-shared-upload-document-dialog.component';
import {ConfirmDialogComponent} from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'mt-documents-shared-table',
  templateUrl: './documents-shared-table.component.html',
  styleUrls: ['./documents-shared-table.component.scss']
})
export class DocumentsSharedTableComponent implements AfterViewInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  documentsType: {id: string; type: string};

  columns = ['name', 'createdBy', 'description', 'createdOn', 'actions'];
  loading = false;
  filter = {
    page: 0,
    size: 10,
    sortProperty: ['name'],
    sortDirection: 'ASC',
    name: ''
  };
  sbs: Subscription;
  dataSource = new MatTableDataSource<ContentOwnerDocument>();
  nameControl = new UntypedFormControl();

  constructor(private service: ContentOwnerService,
              private route: ActivatedRoute,
              private matDialog: MatDialog,
              private router: Router,
              private location: Location,
              private snackBarService: SnackBarService,
              private gss: GlobalSpinnerService,
              private publisherService: PublishersService) {
  }

  ngAfterViewInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.filter.page = params.page || 0;
        this.filter.size = params.size || 10;
        this.filter.sortProperty = params.sortProperty || this.filter.sortProperty;
        this.filter.sortDirection = params.sortDirection || this.filter.sortDirection;
        this.filter.name = params.name || this.filter.name;
        this.refreshData();
      });
    this.sort.sortChange.subscribe(next => {
      this.filter.sortProperty = [next.active];
      this.filter.sortDirection = next.direction.toUpperCase();
      this.refreshDataFirstPage();
    });
    this.paginator.page.subscribe((p: PageEvent) => {
      this.filter.size = p.pageSize;
      this.filter.page = p.pageIndex;
      this.refreshData();
    });
    this.nameControl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(data => {
        this.filter.name = data;
        this.refreshDataFirstPage();
      });
  }

  refreshDataFirstPage() {
    this.paginator.pageIndex = 0;
    this.filter.page = 0;
    this.refreshData();
  }

  refreshData() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: Object.assign({}, this.filter),
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
    this.location.replaceState(urlTree.toString());
    if (this.sbs) {
      this.sbs.unsubscribe();
    }
    this.getCoDocuments();
  }

  getCoDocuments() {
    let serv;
    if (this.documentsType && this.documentsType.type === 'publisher') {
      serv = this.publisherService;
    } else {
      serv = this.service;
    }
    this.loading = true;
    this.sbs = serv.getDocuments(this.documentsType.id, this.filter).subscribe(
      response => {
        this.loading = false;
        this.paginator.length = response.totalElements;
        this.dataSource.data = response.content;
      }, error => {
        this.loading = false;
        ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error during while fetching content owner documents');
      }
    );
  }

  uploadDocument() {
    this.matDialog.open(DocumentsSharedUploadDocumentDialogComponent,
      {data: {documentsType: this.documentsType}} as MatDialogConfig).afterClosed().subscribe(
      next => {
        if (next) {
          this.refreshData();
        }
      }
    );
  }

  editDocument(coDocument: ContentOwnerDocument) {
    this.matDialog.open(DocumentsSharedUploadDocumentDialogComponent,
      {data: {documentsType: this.documentsType, documentId: coDocument.id,
          file: coDocument, description: coDocument.description}} as MatDialogConfig).afterClosed().subscribe(
      next => {
        if (next) {
          this.refreshData();
        }
      }
    );
  }

  removeDocument(documentId: string) {
    this.matDialog.open(ConfirmDialogComponent, {
      panelClass: 'alert-dialog-wrapper',
      data: {
        title: 'Delete file',
        subtitle: 'Are you sure you want to delete this file?',
        confirmButtonText: 'Yes, delete.',
        confirmButtonColor: 'warn'
      }
    }).afterClosed().subscribe(next => {
      if (next) {
        let serv;
        if (this.documentsType && this.documentsType.type === 'publisher') {
          serv = this.publisherService;
        } else {
          serv = this.service;
        }
        this.gss.showLoader();
        serv.removeDocument(documentId).subscribe(
          response => {
            this.gss.hideLoader();
            this.snackBarService.success('You have successfully removed document');
            this.refreshData();
          },
          error => {
            this.gss.hideLoader();
            ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error while removing document');
          }
        );
      }
    });
  }

  // todo changed to global model
  downloadDocument(coDocument: ContentOwnerDocument) {
    window.open(coDocument.documentUrl, '_blank');
  }

}
