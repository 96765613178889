import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PlaylistAutocompleteComponent} from './playlist-autocomplete.component';
import {Playlist, PlaylistListRequest} from '../../domain/playlist.model';
import {PlaylistsService} from '../../services/playlists.service';

@Component({
  selector: 'mt-playlist-autocomplete-chips',
  template: `
    <div class="cs-publisher-autocomplete-chips-wrapper">
      <mt-playlist-autocomplete #ac (playlistSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </mt-playlist-autocomplete>
      <div class="data-loader" *ngIf="loading">
        <mat-spinner [color]="'primary'"
                    [mode]="'indeterminate'"
                    [diameter]="30">
        </mat-spinner>
      </div>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item.title}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class PlaylistAutocompleteChipsComponent implements OnInit {

  @ViewChild(PlaylistAutocompleteComponent, {static: true}) ac: PlaylistAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public playlistIds: string[];

  @Input()
  public formFieldClass: string;

  @Output()
  playlistSelectionChange = new EventEmitter<Playlist[]>();

  items: Playlist[] = [];

  public loading = false;

  constructor(private service: PlaylistsService) {
  }

  ngOnInit(): void {
    if (this.playlistIds && this.playlistIds.length) {
      this.loading = true;
      this.service.listAll({ids: this.playlistIds} as PlaylistListRequest).subscribe(playlists => {
        playlists.forEach(au => {
          if (this.playlistIds.includes(au.id)) {
            this.onItemSelected(au);
          }
        });
        this.loading = false;
      });
    }
  }

  onItemSelected($event: Playlist) {
    if ($event && !this.items.find(x => x.id === $event.id)) {
      this.items.push($event);
      this.ac.updateFilteredPlaylists(this.items);
      this.playlistSelectionChange.emit(this.items);
    }
    this.ac.resetInput();
  }

  remove(p: Playlist) {
    this.items.splice(this.items.indexOf(p), 1);
    this.playlistSelectionChange.emit(this.items);
    this.ac.updateFilteredPlaylists(this.items);
  }

}
