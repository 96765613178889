import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  PubId,
  Publisher,
  PublisherCreateRequest,
  PublisherStatus,
  PublisherShare,
} from '../domain/publisher.model';
import { Page } from '../domain/page.model';
import { HttpUtils } from '../utils/http-utils';
import { PageSortRequest } from '../domain/page-sort-request.model';
import {Domain, DomainListRequest, DomainStatus} from '../domain/domain.model';
import { Embed, EmbedListRequest } from '../domain/embed.model';
import { PublisherDomain } from '../domain/publisher-domain.model';
import { Video, VideoListRequest } from '../domain/video.model';
import { User } from '../../core/auth/user.model';
import { ContentOwnerDocument } from '../domain/content-owner-document.model';
import { CreateEmbedRequest } from 'src/app/modules/publishers/publisher-details/publisher-details-embeds/create/embed-create-request.model';
import {
  AppListRequest, Apps,
  OOApps,
  OOAppsCreateRequest, PublisherAppImportRequest, PublisherAppImportResponse,
  PublisherApps,
  RokuFiles
} from '../domain/apps.model';
import { first, map } from 'rxjs/operators';
import {urlJoin} from 'url-join-ts';
import { Platform } from '../domain/platform.model';

export interface createAppInput {
  publisherId: string;
  bundleApp: {
    name: string;
    bundleId: string;
    bundleUrl: string;
    numericId: string;
    adsTxtLocation: string;
    platform: Platform;
    iabCategories: string[];
  };
}

export class PublisherListFilter extends PageSortRequest {
  page?: number;
  size?: number;
  name?: string;
  searchTerm?: string;
  status?: PublisherStatus[];
  ids?: string[];
  missingAdsTxt?: boolean;
  stage?: string;
  networks?: string[];
  networkIds?: string[];
  exchangeOnly?: boolean;
}

export class PublisherDomainListFilter extends PageSortRequest {
  publisherId?: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class PublishersService {
  private readonly api = 'api/publishers';

  constructor(private httpClient: HttpClient) {}

  listPublishers(filter: PublisherListFilter): Observable<Page<Publisher>> {
    return this.httpClient.get<Page<Publisher>>(
      `${environment.serviceUrl}/api/publishers`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  listApps(
    filter: AppListRequest,
    id: string
  ): Observable<Page<PublisherApps>> {
    return this.httpClient.get<Page<PublisherApps>>(
      `${environment.serviceUrl}/api/publishers/${id}/apps`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  listVbApps(): Observable<PublisherApps[]> {
    return this.httpClient.get<PublisherApps[]>(
      `${environment.serviceUrl}/api/roku-app/bundles`,
      {}
    );
  }

  listOOApps(filter: AppListRequest): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/roku-app`, {
      params: HttpUtils.getHttpParams(filter),
    });
  }

  appFloors(request: any, app: any) {
    return this.httpClient.put<any>(
      `${environment.serviceUrl}/api/publishers/${app.id.publisherId}/apps/${app.id.appId}`,
      request
    );
  }

  createPublisher(publisher: PublisherCreateRequest): Observable<Publisher> {
    return this.httpClient.post<Publisher>(
      `${environment.serviceUrl}/api/publishers`,
      publisher
    );
  }

  updatePublisher(pub: Publisher): Observable<Publisher> {
    return this.httpClient.put<Publisher>(
      `${environment.serviceUrl}/api/publishers/${pub.id}`,
      pub
    );
  }

  updateShares(
    id: string,
    shares: PublisherShare[]
  ): Observable<PublisherShare[]> {
    return this.httpClient.post<PublisherShare[]>(
      `${environment.serviceUrl}/api/publishers/${id}/shares`,
      shares
    );
  }

  updateCost(id: string, costs: any): Observable<any[]> {
    return this.httpClient.put<any[]>(
      `${environment.serviceUrl}/api/publishers/${id}/costs`,
      costs
    );
  }

  getShares(id: string): Observable<PublisherShare[]> {
    return this.httpClient.get<PublisherShare[]>(
      `${environment.serviceUrl}/api/publishers/${id}/shares`
    );
  }

  archivePublisher(id: string, status: string) {
    return this.httpClient.put(
      `${environment.serviceUrl}/api/publishers/${id}/${status}`,
      {}
    );
  }

  getPublisherById(id: string): Observable<Publisher> {
    return this.httpClient.get<Publisher>(
      `${environment.serviceUrl}/api/publishers/${id}`
    );
  }

  getPublisherByIds(ids: string[]): Observable<Publisher[]> {
    return this.httpClient.get<Publisher[]>(
      `${environment.serviceUrl}/api/publishers/get-by-ids`,
      {
        params: HttpUtils.getHttpParams({
          publisherIds: ids,
        }),
      }
    );
  }

  countByStatus(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.serviceUrl}/api/publishers/statuses`
    );
  }

  getPublisherDomains(
    filter: DomainListRequest,
    publisherId: any
  ): Observable<Page<PublisherDomain>> {
    return this.httpClient.get<Page<PublisherDomain>>(
      `${environment.serviceUrl}/api/publishers/` + publisherId + `/domains`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getAllPublishersAndDomains(filter: any): Observable<Page<PublisherDomain>>{
    return this.httpClient.get<Page<PublisherDomain>>(
      `${environment.serviceUrl}/api/publisher-domains`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getAdsTxtDomainsStatusCount(filter?: any) {
    return this.httpClient.get(`${environment.serviceUrl}/api/publisher-domains/adstxt-statuses`,
    {
      params: filter ? HttpUtils.getHttpParams(filter) : {}
    });
  }

  startDomainCrawl(domainID: string, publisherID: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/publishers/${publisherID}/domains/${domainID}/adstxt-crawl`);
  }

  getDomainRecords(publisherId: any, domainId: any): Observable<any>{
    return this.httpClient.get<any>(
      `${environment.serviceUrl}/api/publishers/` + publisherId + `/domains/` + domainId + `/adstxt-status`,
      {}
    );
  }

  getPublisherDomainById(
    publisherId: string,
    domainId: string
  ): Observable<PublisherDomain> {
    return this.httpClient.get<PublisherDomain>(
      `${environment.serviceUrl}/api/publishers/` +
        publisherId +
        `/domains/` +
        domainId
    );
  }

  domainsCountByStatus(publisherId: string): Observable<any> {
    return this.httpClient.get<Page<Domain>>(
      `${environment.serviceUrl}/api/publishers/` +
        publisherId +
        `/domains/count-by-status`
    );
  }

  getPublisherEmbeds(
    filter: EmbedListRequest,
    publisherId: string
  ): Observable<Page<Embed>> {
    return this.httpClient.get<Page<Embed>>(
      `${environment.serviceUrl}/api/publishers/${publisherId}/embeds`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getPublisherEmbedsCountsByStatus(publisherId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.serviceUrl}/api/publishers/${publisherId}/embeds/count-by-status`
    );
  }

  getPublisherAppsCountsByStatus(filter: any, publisherId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.serviceUrl}/api/publishers/${publisherId}/apps/statuses`,{
        params: HttpUtils.getHttpParams(filter)
      }
    );
  }

  getPublisherDomainsCountsByStatus(filter: any, publisherId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.serviceUrl}/api/publishers/${publisherId}/domains/statuses`,{
        params: HttpUtils.getHttpParams(filter)
      }
    );
  }

  getDomainEmbeds(
    filter: EmbedListRequest,
    domainId: string,
    publisherId: string
  ): Observable<Page<Embed>> {
    return this.httpClient.get<Page<Embed>>(
      `${environment.serviceUrl}/api/publishers/` +
        publisherId +
        `/domains/` +
        domainId +
        `/embeds`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  // TODO SET BASE URL GLOBAL
  getDomains(publisherId: string, params): Observable<any> {
    return this.httpClient
      .get<any>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/domains`,
        { params }
      )
      .pipe(first());
  }

  createDomains(publisherId: string, urls: string[]): Observable<Domain> {
    return this.httpClient
      .post<Domain>(
        `${environment.serviceUrl}/api/publishers/` + publisherId + `/domains`,
        { urls }
      )
      .pipe(first());
  }

  updateDomainFloors(publisherId: string, domainId: string, params) {
    return this.httpClient
      .put<any>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/domains/${domainId}`,
        params
      )
      .pipe(first());
  }

  createApp({ publisherId, bundleApp }: createAppInput): Observable<Domain> {
    return this.httpClient.post<Domain>(
      `${environment.serviceUrl}/api/publishers/` + publisherId + `/apps`,
      bundleApp
    );
  }

  embedsCountByStatus(domainId: string, publisherId: string): Observable<any> {
    return this.httpClient.get<Page<Domain>>(
      `${environment.serviceUrl}/api/publishers/` +
        publisherId +
        `/domains/` +
        domainId +
        `/embeds/count-by-status`
    );
  }

  embedCreate(
    publisherId: string,
    domainId: string,
    request: CreateEmbedRequest,
    imageFile: File
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('embed', JSON.stringify(request));
    if (imageFile) {
      formData.append('backgroundImage', imageFile, imageFile.name);
    }
    return this.httpClient.post(
      `${environment.serviceUrl}/publishers/${publisherId}/domains/${domainId}/embeds`,
      formData
    );
  }

  getPublishersWithPubId(): Observable<PubId[]> {
    return this.httpClient.get<PubId[]>(
      `${environment.serviceUrl}/api/publishers/sellers`
    );
  }

  getPublishersWithPubIdById(id: string): Observable<PubId[]> {
    return this.httpClient.get<PubId[]>(
      `${environment.serviceUrl}/api/publishers/sellers/${id}`
    );
  }

  videosList(filter: VideoListRequest, id: string): Observable<Page<Video>> {
    return this.httpClient.get<Page<Video>>(
      `${environment.serviceUrl}/api/publishers/${id}/videos`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getUsers(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(
      `${environment.serviceUrl}/api/publishers/${id}/users`
    );
  }

  getDocuments(id: string, filter): Observable<Page<ContentOwnerDocument>> {
    return this.httpClient.get<Page<ContentOwnerDocument>>(
      `${environment.serviceUrl}/api/publishers/${id}/documents`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  uploadDocument(id: string, file: File, documentDescription: string) {
    const formData: FormData = new FormData();
    formData.append('ioFile', file, file.name);
    formData.append('description', documentDescription);
    return this.httpClient.post(
      `${environment.serviceUrl}/api/publishers/${id}/documents`,
      formData
    );
  }

  editDocumentDescription(documentId: string, description: string) {
    return this.httpClient.put(
      `${environment.serviceUrl}/api/publishers/documents/${documentId}`,
      { description }
    );
  }

  removeDocument(documentId: string) {
    return this.httpClient.delete(
      `${environment.serviceUrl}/api/publishers/documents/${documentId}`
    );
  }

  removeVideo(videos: string[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { videos },
    };
    return this.httpClient.delete(
      `${environment.serviceUrl}/api/publishers/videos`,
      options
    );
  }

  getRoku(id: string): Observable<OOApps> {
    return this.httpClient.get<OOApps>(
      `${environment.serviceUrl}/api/roku-app/${id}`
    );
  }

  createRoku(rokuApp: OOAppsCreateRequest, rokuFiles: RokuFiles) {
    const formData: FormData = new FormData();
    formData.append('backgroundFhdKey', rokuFiles.backgroundFhdKey);
    formData.append('channelPosterFhdKey', rokuFiles.channelPosterFhdKey);
    formData.append('channelPosterHdKey', rokuFiles.channelPosterHdKey);
    formData.append('channelPosterSdKey', rokuFiles.channelPosterSdKey);
    formData.append('hdOverhangLogoHorKey', rokuFiles.hdOverhangLogoHorKey);
    formData.append('splashFhdKey', rokuFiles.splashFhdKey);
    formData.append('splashHdKey', rokuFiles.splashHdKey);
    formData.append('splashSdKey', rokuFiles.splashSdKey);
    formData.append('rokuApp', JSON.stringify(rokuApp));
    return this.httpClient.post(
      `${environment.serviceUrl}/api/roku-app`,
      formData
    );
  }

  editRoku(rokuApp: OOAppsCreateRequest, rokuFiles: RokuFiles, rokuId) {
    const formData: FormData = new FormData();
    formData.append('backgroundFhdKey', rokuFiles.backgroundFhdKey);
    formData.append('channelPosterFhdKey', rokuFiles.channelPosterFhdKey);
    formData.append('channelPosterHdKey', rokuFiles.channelPosterHdKey);
    formData.append('channelPosterSdKey', rokuFiles.channelPosterSdKey);
    formData.append('hdOverhangLogoHorKey', rokuFiles.hdOverhangLogoHorKey);
    formData.append('splashFhdKey', rokuFiles.splashFhdKey);
    formData.append('splashHdKey', rokuFiles.splashHdKey);
    formData.append('splashSdKey', rokuFiles.splashSdKey);
    formData.append('rokuApp', JSON.stringify(rokuApp));
    return this.httpClient.post(
      `${environment.serviceUrl}/api/roku-app/${rokuId}`,
      formData
    );
  }

  generateRokuFeed(id: string) {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/roku-app/${id}/feed`,
      { responseType: 'text' }
    );
  }

  generateRokuApp(id: string) {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/roku-app/${id}/app`,
      { responseType: 'text' }
    );
  }

  updateFeedStatus(rokuId, status) {
    return this.httpClient.post(
      `${environment.serviceUrl}/api/roku-app/${rokuId}/status`,
      { status }
    );
  }

  getSectionVideos(filter): Observable<Page<Video>> {
    return this.httpClient.post<Page<Video>>(
      `${environment.serviceUrl}/api/roku-app/section-videos`,
      filter
    );
  }

  getPlaylistsMetaData(filter): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.serviceUrl}/api/roku-app/playlist-metadata`,
      filter
    );
  }

  uploadBundleCSV(publisherId: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient
      .post<any>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/apps/csv/import`,
        formData
      )
      .pipe(first());
  }

  validateBundleId(id) {
    return this.httpClient.get<boolean>(
      `${environment.serviceUrl}/api/roku-app/bundle-validate/${id}`
    );
  }

  exportAllBundlesCSV(publisherId: string): Observable<string> {
    return this.httpClient
      .get<string>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/apps/csv/export`,
        {
          responseType: 'blob',
          header: {
            Accept: 'text/csv',
          },
        } as any
      )
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], {
            type: 'text/csv',
          });
          return URL.createObjectURL(blob);
        })
      );
  }

  updateBundlesCSV(publisherId: string, file: File): Observable<PublisherAppImportResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient
      .put<PublisherAppImportResponse>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/apps/csv/reimport`,
        formData
      )
      .pipe(response => response)
      .pipe(first());
  }

  downloadTemplateCsv(publisherId: string): Observable<string> {
    return this.httpClient
      .get<string>(
        `${environment.serviceUrl}/api/publishers/${publisherId}/apps/download/apps-csv-template`,
        {
          responseType: 'blob',
          header: {
            Accept: 'text/csv',
          },
        } as any
      )
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], {
            type: 'text/csv',
          });
          return URL.createObjectURL(blob);
        })
      );
  }

  public archivePublisherApp(publisherId: string, appId: string) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/publishers/${publisherId}/apps/${appId}/archive`, {});
  }

  public activatePublisherApp(publisherId: string, appId: string) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/publishers/${publisherId}/apps/${appId}/unarchive`, {});
  }

  public archivePublisherDomain(publisherId: string, domainId: string) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/publishers/${publisherId}/domains/${domainId}/archive`, {});
  }

  public activatePublisherDomain(publisherId: string, domainId: string) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/publishers/${publisherId}/domains/${domainId}/unarchive`, {});
  }


  public createPublisherApps(publisherId: string, apps: Array<PublisherAppImportRequest>): Observable<PublisherAppImportResponse> {
    return this.httpClient.post<PublisherAppImportResponse>(urlJoin(environment.serviceUrl, this.api, publisherId, 'apps', 'with-validation'), apps);
  }

  /** Creates single app from app request. */
  public createPublisherApp(publisherId: string, app: PublisherAppImportRequest): Observable<PublisherAppImportRequest> {
    return this.httpClient.post<PublisherAppImportRequest>(urlJoin(environment.serviceUrl, this.api, publisherId, 'apps'), app);
  }

  public validateAppsData(publisherId: string, apps: Array<PublisherAppImportRequest>): Observable<PublisherAppImportResponse> {
    return this.httpClient.post<PublisherAppImportResponse>(urlJoin(environment.serviceUrl, this.api, publisherId, 'apps', 'validate'), apps);
  }

  public getPublisherAppsFromCSV(publisherId: string, file: File): Observable<PublisherAppImportResponse> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }

    return this.httpClient.post<PublisherAppImportResponse>(urlJoin(environment.serviceUrl, this.api, publisherId, 'apps', 'csv-collect'), formData);
  }

  public getAppDataFromUrls(publisherId: string, urls: Array<string>): Observable<PublisherAppImportResponse> {
    return this.httpClient.post<PublisherAppImportResponse>(urlJoin(environment.serviceUrl, this.api, publisherId, 'apps', 'urls-data'), urls);
  }
}