import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpUtils} from '../utils/http-utils';
import {Page} from '../domain/page.model';
import {Video, VideoListRequest, VideoPendingJobs} from '../domain/video.model';
import {Feed, OwnerType} from '../domain/feed.model';
import {JobTva} from '../domain/job-tva.model';

@Injectable({
  providedIn: 'root',
})
export class VideosService {

  constructor(private httpClient: HttpClient) {
  }

  videosList(filter: VideoListRequest, id?: string): Observable<Page<Video>> {
    return this.httpClient.get<Page<Video>>(`${environment.serviceUrl}/api/videos`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  updateVideo(video: any) {
    return this.httpClient.put(`${environment.serviceUrl}/api/videos/${video.id}`, video);
  }

  createFeed(feed: Feed): Observable<Feed> {
    return this.httpClient.post<Feed>(`${environment.serviceUrl}/api/videos/ingest`, feed);
  }

  previewRssCount(feed: Feed) {
    return this.httpClient.post<Feed>(`${environment.serviceUrl}/api/videos/ingest/preview`, feed);
  }

  uploadVideo(feed: any, videoFile: File, thumbnailFile: File): Observable<any> {
    const formData: FormData = new FormData();
    if (videoFile) {
      formData.append('videoFile', videoFile, videoFile.name);
    }
    if (thumbnailFile) {
      formData.append('thumbnailFile', thumbnailFile, thumbnailFile.name);
    }
    formData.append('video', JSON.stringify(feed));
    return this.httpClient.post<any>(`${environment.serviceUrl}/api/videos/upload`, formData);
  }

  video(id: string) {
    return this.httpClient.get<Video>(`${environment.serviceUrl}/api/videos/` + id);
  }

  hasPendingJobs() {
    return this.httpClient.get<VideoPendingJobs>(`${environment.serviceUrl}/api/videos/import/has-pending-jobs`);
  }

  getPendingJobs(): Observable<JobTva[]> {
    return this.httpClient.get<JobTva[]>(`${environment.serviceUrl}/api/videos/import/pending-jobs`);
  }

  addVideosToPlaylists(playlists: string[], videos: string[]) {
    return this.httpClient.put(`${environment.serviceUrl}/api/videos/playlists`, {playlists, videos});
  }

  addOwner(coId: string, type: OwnerType, videoIds: string[]) {
    return this.httpClient.put(`${environment.serviceUrl}/api/videos/owners`, {coId, type, videoIds});
  }

  editVideos(request: any, thumbnailFile?: File): Observable<any> {
    const formData: FormData = new FormData();
    if (thumbnailFile) {
      formData.append('thumbnailFile', thumbnailFile, thumbnailFile.name);
    }
    formData.append('data', JSON.stringify(request));
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/videos/bulk`, formData);
  }

  removeOwner(videoIds: string[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {videoIds},
    };
    return this.httpClient.delete(`${environment.serviceUrl}/api/videos/owners`, options);
  }

  flagsList(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/videos/flags`);
  }

  tagsList(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/videos/tags`);
  }

  genresList(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/videos/genres`);
  }

  artistsList(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/videos/artists`);
  }

  updateThumbnail(id: string, thumbnailFile: File) {
    const formData: FormData = new FormData();
    if (thumbnailFile) {
      formData.append('thumbnailFile', thumbnailFile, thumbnailFile.name);
    }
    return this.httpClient.put(`${environment.serviceUrl}/api/videos/thumb/${id}`, formData);
  }
}