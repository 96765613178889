import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AfterViewInit, Component, Inject} from '@angular/core';
import {Principal} from '../../core/auth/principal.service';
import {GlobalSpinnerService} from '../../core/services/global-spinner.service';
import {SnackBarService} from '../../core/services/snackbar.service';
import {ServerErrorUtils} from '../../shared/utils/server-error-utils';
import {AdsConfiguration, Channel} from '../../shared/domain/channel.model';
import {ChannelsService} from '../../shared/services/channels.service';

@Component({
  selector: 'mt-channel-editor-dialog',
  template: `
    <div mat-dialog-title>
      <span>{{isEdit ? 'Edit channel' : 'Create new channel'}}</span>
    </div>
    <div mat-dialog-content>
      <form #f="ngForm" class="d-flex flex-column">
        <mat-form-field floatLabel="always" color="primary">
          <mat-label>Title</mat-label>
          <input name="title" matInput required [(ngModel)]="channel.title"/>
        </mat-form-field>
        <mat-form-field floatLabel="always" color="primary">
          <mat-label>Description</mat-label>
          <input name="description" matInput required [(ngModel)]="channel.description"/>
        </mat-form-field>
        <small class="d-block mb-3 mt-3"><strong>Pod Duration</strong></small>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
              <mat-label>Ads Frequency</mat-label>
              <mat-select [(ngModel)]="channel.adsConfiguration.adFrequency" name="adFrequency">
                <mat-option [value]="1">1 minutes</mat-option>
                <mat-option [value]="2">2 minutes</mat-option>
                <mat-option [value]="3">3 minutes</mat-option>
                <mat-option [value]="4">4 minutes</mat-option>
                <mat-option [value]="5">5 minutes</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
              <mat-label>Ads Duration</mat-label>
              <mat-select [(ngModel)]="channel.adsConfiguration.adsDuration" name="adsDuration">
                <mat-option [value]="30">30 seconds</mat-option>
                <mat-option [value]="60">60 seconds</mat-option>
                <mat-option [value]="90">90 seconds</mat-option>
                <mat-option [value]="120">120 seconds</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mt-image-upload-preview label="Channel image" (fileSelected)="imageFile = $event"
                                  (fileCanceled)="imageFile=null"></mt-image-upload-preview>
      </form>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button class="mr-3" [mat-dialog-close]="false">
        Close
      </button>
      <button mat-flat-button class="iconed" color="primary"
              [disabled]="!f.valid"
              (click)="createChannel()">
        <i class="fas fa-save"></i>
        <span>Save</span>
      </button>
    </div>
  `,
})
export class ChannelEditorDialogComponent implements AfterViewInit {

  isEdit: boolean;
  channel = new Channel();
  imageFile: File;

  constructor(public dialogRef: MatDialogRef<ChannelEditorDialogComponent>,
              private service: ChannelsService,
              private principal: Principal,
              private gss: GlobalSpinnerService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: { channel?: Channel }) {
    if (data && data.channel) {
      this.isEdit = !!data.channel;
      this.channel = data.channel;
    }
    if (!this.channel.adsConfiguration) {
      this.channel.adsConfiguration = new AdsConfiguration();
      this.channel.adsConfiguration.adsDuration = 60;
      this.channel.adsConfiguration.adFrequency = 3;
    }
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('500px');
  }

  createChannel() {
    this.gss.showLoader();
    if (this.isEdit) {
      this.service.update(this.channel, this.imageFile).subscribe(next => {
          this.gss.hideLoader();
          this.dialogRef.close(true);
          this.snackBarService.success('Channel updated successfully');
        },
        (e) => {
          const messages = ServerErrorUtils.getValidationMessages(e);
          if (messages) {
            messages.forEach(m => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error occurred during channel update');
          }
          this.gss.hideLoader();
        }
      );
    } else {
      this.service.create(this.channel, this.imageFile).subscribe(() => {
          this.gss.hideLoader();
          this.dialogRef.close(true);
          this.snackBarService.success('Channel created successfully');
        },
        (e) => {
          const messages = ServerErrorUtils.getValidationMessages(e);
          if (messages) {
            messages.forEach(m => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error occurred during collection creation');
          }
          this.gss.hideLoader();
        }
      );
    }
  }
}
