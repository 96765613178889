import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {Observable} from 'rxjs';
import {FloatLabelType} from "@angular/material/form-field";
import {Order, OrderStatusColorMap, OrderStatusKL} from '../../domain/campaigns.model';
import { OrdersAutocompleteComponent } from './orders-autocomplete.component';
import {CampaignsService} from "../../services/campaigns.service";

interface OrderGroup {
    name: string;
    orders: Order[];
}

@Component({
    selector: 'mt-orders-autocomplete-chips',
    template: `
        <mt-orders-autocomplete #pc
                                 [networkIds]="networkIds"
                                 (orderSelected)="onItemSelected($event)"
                                 [formFieldClass]="formFieldClass"
                                 [formFieldAppearance]="formFieldAppearance"
                                 [required]="required"
                                 [label]="placeholder"
                                 [floatLabel]="floatLabel">
        </mt-orders-autocomplete>
        <mat-chip-list class="mt-n3" #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''"
                       [hidden]="items.length < 1">
            <mat-basic-chip *ngFor="let item of items" (removed)="remove(item)">
                <i class="fas fa-circle mr-1 ml-0" ngClass="text-{{OrderStatusColorMap[item.status]}}"></i>
                {{item.name}}
                <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
            </mat-basic-chip>
        </mat-chip-list>`,
})
export class OrdersAutocompleteChipsComponent implements OnInit {
    @ViewChild(OrdersAutocompleteComponent, {static: true}) pc: OrdersAutocompleteComponent;

    /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter orders for that given network*/
    @Input() networkIdsChanged: Observable<string[]>;

    /** Pass clearFilterItems: Subject<void> = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
    @Input() clearItems: Observable<void>;

    @Input()
    public placeholder: string;

    @Input()
    public stacked: false;

    @Input()
    public orderIds: string[];

    @Input()
    public floatLabel: FloatLabelType;

    @Input()
    public formFieldClass: string;

    @Input()
    public formFieldAppearance: string;

    /** Hold networkId to filter order only by given network.*/
    @Input() public networkIds: string[];

    @Input()
    required = false;

    @Output()
    orderSelectionChange = new EventEmitter<Order[]>();

    items: Order[] = [];
    public OrderStatusColorMap = OrderStatusColorMap;

    constructor(private service: CampaignsService) {
    }

    ngOnInit(): void {
        if (this.orderIds && this.orderIds.length) {
            //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
            if (typeof this.orderIds === 'string') {
                this.orderIds = [this.orderIds];
            }
            //Fetch only preselected publishers
            this.service.getOrdersList({ids: this.orderIds}).subscribe(orders => {
                orders.content.map(order => {
                    this.items.push(order);
                });
                this.pc.updateFilteredOrders(this.items);
            });
        }

        this.networkIdsChanged?.subscribe((result) => this.updateOrderIdsChips(result));
        this.clearItems?.subscribe(() => this.clearFilter());
    }

    groupOrders(orders: Order[]) {
        return OrderStatusKL().map((item) => ({
            name: item.label,
            orders: orders.filter(
                (order) => order.status === item.key
            ),
        }));
    }

    updateOrderIdsChips(networks: string[]) {
        if (networks && networks.length > 0) {
            this.items = this.items.filter(x => networks.includes(x.network.id));
            this.pc.updateFilteredOrders(this.items);
        }
    }

    onItemSelected($event: Order) {
        if ($event) {
            this.items.push($event);
            this.orderSelectionChange.emit(this.items);
            this.pc.resetInput();
            this.pc.updateFilteredOrders(this.items);
        }
    }

    remove(o: Order) {
        this.items.splice(this.items.indexOf(o), 1);
        this.orderSelectionChange.emit(this.items);
        this.pc.updateFilteredOrders(this.items);
    }

    /** Clear all items from filter. */
    clearFilter() {
        this.items = [];
        this.pc.updateFilteredOrders(this.items);
    }

    disableFilter(disable: boolean) {
        if (disable) {
            this.pc.disable();
        } else {
            this.pc.enable();
        }
    }

}
