import {Publisher} from '../../shared/domain/publisher.model';
import {ContentOwner} from '../../shared/domain/content-owner.model';
import {Network} from 'src/app/shared/domain/network.model';
import {KeyLabel} from '../../shared/domain/key-label.model';
import {Role} from "../../shared/domain/roles-permissions.model";
import { Advertiser } from 'src/app/shared/domain/advertiser.model';

export class User {
  id?: string;
  accessToken?: string;
  username?: string;
  email: string;
  name: string;
  publisherIds: string[];
  publishers: Publisher[];
  roles: string[];
  userRoles: Role[];
  roleIds: number[];
  password: string;
  createdOn: string;
  lastUpdatedOn: string;
  extPublisherNames: string;
  contentOwnerIds?: string[];
  enabled?: boolean;
  contentOwners?: ContentOwner[];
  network?: Network;
  advertiserId: string;
  subAdvertiserIds: string[];
  subAdvertisers: Advertiser[];
  advertiser: Advertiser;
}

export class ChangePasswordRequest {
  username: string;
  oldPassword: string;
  newPassword: string;
}

export class ResetPasswordRequest {
  token: string;
  newPassword: string;
  key: string;
}

export class UpdateProfileRequest {
  name: string;
  username: string;
}

export const UsersStatusKL = () =>
  Object.keys(UsersStatus).map(
    (key) => new KeyLabel(key as UsersStatus, UsersStatusLabels[key])
  );

export class UsersStatusBox {
  count: number;
  status: string;
}

export enum UsersStatus {
  enabled = 'ENABLED',
  disabled = 'DISABLED',
}

export const UsersStatusLabels = {
  enabled: 'Active',
  disabled: 'Archived',
};

export enum UsersStatusColorMap {
  enabled = 'green',
  disabled = 'gray',
}
