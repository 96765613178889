import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { DpAutocompleteComponent } from './dp-autocomplete.component';
import { DemandPartner } from '../../domain/demand-partner.model';
import { FlagAutocompleteComponent } from './flags-autocomplete.component';

@Component({
  selector: 'mt-flags-autocomplete-chips',
  template: `
    <div class="mt-flags-autocomplete-chips-wrapper">
      <mt-flags-autocomplete #ec [availableFlags]="availableFlags" [activeFlags]="activeFlags" (flagSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </mt-flags-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class FlagAutocompleteChipsComponent implements OnInit {

  @ViewChild(FlagAutocompleteComponent, {static: true}) ec: FlagAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public activeFlags: string[];

  @Input()
  public availableFlags: string[];

  @Input()
  public availableFlagsFlag: boolean;

  @Input()
  public formFieldClass: string;

  @Output()
  flagSelectionChange = new EventEmitter<string[]>();

  items: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.items = this.activeFlags;
    if (this.availableFlagsFlag) {
      this.ec.availableFlagsFlag = this.availableFlagsFlag;
    }
  }

  onItemSelected($event: string) {
    if ($event && !this.items.find(x => x === $event)) {
      this.items.push($event);
      this.flagSelectionChange.emit(this.items);
    }
    this.ec.resetInput();
    this.ec.updateFilteredFlags(this.items);
  }

  remove(e: string) {
    this.items.splice(this.items.indexOf(e), 1);
    this.flagSelectionChange.emit(this.items);
    this.ec.updateFilteredFlags(this.items);
  }
}
