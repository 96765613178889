import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {ServerErrorUtils} from 'src/app/shared/utils/server-error-utils';
import {ClipboardService} from 'ngx-clipboard';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {AppsService} from 'src/app/shared/services/apps.service';
import {SnackBarService} from 'src/app/core/services/snackbar.service';
import {AdsTxtRecord} from 'src/app/shared/domain/ads-txt.model';
import {PublishersService} from 'src/app/shared/services/publishers.service';
import {StatusBox} from 'src/app/shared/enums/active-archived.enum';
import {
  AdstxtStatus,
  AdstxtStatusColorMap,
  AdstxtStatusKL,
  AdstxtStatusLabels
} from 'src/app/shared/domain/ads-txt-status.model';
import {AdsTxtService} from 'src/app/shared/services/ads-txt.service';
import {DemandPartnersService} from 'src/app/shared/services/demand-partners.service';
import {DemandPartner, DemandPartnerStatus, DemandPartnersRequest} from 'src/app/shared/domain/demand-partner.model';
import {AdsTxtExportUtils} from "../../../shared/utils/ads-txt-export-utils";

@Component({
  selector: 'mt-ads-txt-status-dialog',
  templateUrl: './ads-txt-status-dialog.component.html',
  styleUrls: ['./ads-txt-status-dialog.component.scss']
})
export class AdsTxtStatusDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  loading = true;

  public dataSource = new MatTableDataSource<AdsTxtRecord>();
  public demandPartnerList: DemandPartner[];

  private fullData: any;

  columns = ['domain', 'publisherAccountId', 'accountType', 'demandPartner', 'priority', 'web', 'ctv', 'notes', 'actions'];

  filter = {
    status: [],
    demandPartnerIds: null,
    adsTxtLocation: '',
  };

  statusCounts: StatusBox[] = [];
  adstxtStatus = AdstxtStatus;
  adstxtStatusKL = AdstxtStatusKL();
  adstxtStatusColorMap = AdstxtStatusColorMap;


  constructor(private publisherService: PublishersService,
    private snackBarService: SnackBarService,
    private appsService: AppsService,
    private demandPartnerService: DemandPartnersService,
    private adsTxtService: AdsTxtService,
    private clipboardService: ClipboardService,
    public dialogRef: MatDialogRef<AdsTxtStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {appId: string; name: string; dpId: string | string[]; publisherId: string; domainId: string; adsTxtUrl: string}) {
    this.filter.demandPartnerIds = this.data.dpId;
    this.filter.adsTxtLocation = this.data.adsTxtUrl;
  }

  ngOnInit(): void {
    this.getDemandPartners();
  }

  ngAfterViewInit() {
    this.getData();
  }

  private getData(){
    this.statusCounts = [];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'demandPartner': return item.demandPartner?.bidderCode;
        default: return item[property];
      }
    };
    if (this.data.appId) {
      this.getAppsData();
    }
    else if (this.data.adsTxtUrl) {
      this.getAdsTxtData();
    }
    else {
      this.getDomainsData();
    }
  }

  getDomainsData() {
    this.loading = true;
    this.publisherService.getDomainRecords(this.data.publisherId, this.data.domainId).subscribe(
      (data) => {
        this.dataSource.data = data.adstxt.adstxtRecords;
        this.fullData = data;
        this.paginator.length = this.dataSource.filteredData.length;
        this.paginator.pageIndex = 0;
        this.statusCounts.push(
          {status: AdstxtStatus.FOUND, count: data.adstxt.foundRecords},
          {status: AdstxtStatus.MISSING, count: data.adstxt.totalRecords - data.adstxt.foundRecords}
        );
      },
      error => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while fetching data');
        }
      },
      () => {
        this.loading = false;
      });
  }

  getAppsData() {
    this.loading = true;
    this.appsService.getAppAdsTxtStatus(this.data.appId).subscribe(
      (data) => {
        this.dataSource.data = data.adstxt.adstxtRecords;
        this.fullData = data;
        this.paginator.length = this.dataSource.filteredData.length;
        this.paginator.pageIndex = 0;
        this.statusCounts.push(
            {status: AdstxtStatus.FOUND, count: data.adstxt.foundRecords},
            {status: AdstxtStatus.MISSING, count: data.adstxt.totalRecords - data.adstxt.foundRecords}
        );
      },
      error => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while fetching data');
        }
      },
      () => {
        this.loading = false;
      });
  }

  getAdsTxtData() {
    this.loading = true;
    this.adsTxtService.getRecordsForAdsTxt(this.filter).subscribe(
      (data) => {
        this.dataSource.data = data.adstxt.adstxtRecords;
        this.fullData = data;
        this.paginator.length = this.dataSource.filteredData.length;
        this.paginator.pageIndex = 0;
        this.statusCounts.push(
            {status: AdstxtStatus.FOUND, count: data.adstxt.foundRecords},
            {status: AdstxtStatus.MISSING, count: data.adstxt.totalRecords - data.adstxt.foundRecords}
        );
      },
      error => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while fetching data');
        }
      },
      () => {
        this.loading = false;
      });
  }

  getDemandPartners() {
    this.demandPartnerService.listAll({status: [DemandPartnerStatus.ACTIVE]} as DemandPartnersRequest)
    .subscribe(
      (res) => {
        this.demandPartnerList = res;
      },
      (error) => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error while fetching data');
        }
      },
    );
  }

  onDemandPartnerFilterChange(event){
    this.filterData();
    this.dataSource.data = this.dataSource.data.filter(x => x.demandPartnerId == event.value);
  }

  copyToClipboard(data: any) {
    this.clipboardService.copy(data);
    this.snackBarService.success('Copied to clipboard!');
  }

  copyMissingRecords(){
    let dataToCopy = AdsTxtExportUtils.createRecordRows(this.dataSource.data.filter(x => !this.found(x)));
    this.clipboardService.copy(dataToCopy);
    this.snackBarService.success('Copied to clipboard!');
  }

  copyAllRecords(){
    let dataToCopy = AdsTxtExportUtils.createRecordRows(this.dataSource.data);
    this.clipboardService.copy(dataToCopy);
    this.snackBarService.success('Copied to clipboard!');
  }

  copySingleRecord(data: AdsTxtRecord){
    const dataToCopy = AdsTxtExportUtils.createRecordRow(data);
    this.clipboardService.copy(dataToCopy);
    this.snackBarService.success('Copied to clipboard!');
  }

  //Status Box
  filterByStatus(adsTxtStatus) {
    if (!this.isStatusFilterSelected(adsTxtStatus, true)) {
      this.filter.status.push(adsTxtStatus);
    } else {
      this.filter.status = this.filter.status.filter(
        (s) => s !== adsTxtStatus);
    }
    this.filterData();
  }

  isStatusFilterSelected(adsTxtStatus, filterCheck = false) {
    return filterCheck
      ? this.filter.status.includes(adsTxtStatus)
      : this.filter.status.includes(adsTxtStatus) ||
      this.filter.status.length === 0;
  }

  setAdsTxtStatus(adsTxtStatus: StatusBox) {
    return {
      color: AdstxtStatusColorMap[adsTxtStatus.status],
      label: AdstxtStatusLabels[adsTxtStatus.status],
      count: adsTxtStatus.count,
      statusName: adsTxtStatus.status,
    };
  }

  filterData() {
    if (this.filter.status[0] == AdstxtStatus.FOUND && this.filter.status.length == 1){
      this.dataSource.data = this.fullData.adstxt.found;
    }
    else if (this.filter.status[0] == AdstxtStatus.MISSING && this.filter.status.length == 1){
      this.dataSource.data = this.fullData.adstxt.notFound;
    }
    else if (this.filter.status.length == 0 || this.filter.status.length == 2){
      this.dataSource.data = this.fullData.adstxt.adstxtRecords;
    }
  }

  found(row) {
    for (let f of this.fullData.adstxt.found) {
      if (f.id === row.id) {
        return true;
      }
    }
    return false;
  }



  closeDialog(){
    this.dialogRef.close();
  }

}
