import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import { Principal } from 'src/app/core/auth/principal.service';

@Directive({
  selector: '[csHasNetworkPermission]'
})
export class HasNetworkPermissionDirective {


  constructor(private principal: Principal,
              private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
                this.updateView();
  }

  private updateView(): void {
    if (this.principal.user.network)
      this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
