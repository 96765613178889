/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Directive } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

export const DEFAULT_LOADER_ENTER_DELAY = 0;
export const DEFAULT_LOADER_LEAVE_DELAY = 0;

export interface IPrivateLoadingState {
  _loading$: BehaviorSubject<boolean | string>;
}

export interface IPublicLoadingState {
  loading$: Observable<boolean | string>;
}

@Directive()
export abstract class ComponentWithLoadingState {
  protected readonly loadingTrigger$ = new BehaviorSubject<void>(undefined);

  /** Accepts boolean or string.
   * If true (boolean) is sent, only the loading icon will be shown.
   * If string is sent, the following string will be showed as text next to the loading icon.
   * If false (boolean) is sent, no loading screen will be shown.
   */
  protected readonly _loading$!: IPrivateLoadingState['_loading$'];
  public readonly loading$!: IPublicLoadingState['loading$'];

  constructor(
    public readonly loaderEnterDelay: number = DEFAULT_LOADER_ENTER_DELAY,
    public readonly loaderLeaveDelay: number = DEFAULT_LOADER_LEAVE_DELAY
  ) {
    initializeLoadingState<ComponentWithLoadingState>(
      this,
      this.loaderEnterDelay,
      this.loaderLeaveDelay
    );
  }

  public onRetry(): void {
    this.loadingTrigger$.next();
  }
}

export function privateLoadingState(): IPrivateLoadingState {
  return {
    _loading$: new BehaviorSubject<boolean | string>(false),
  };
}

export function publicLoadingState(
  { _loading$ }: IPrivateLoadingState,
  loaderEnterDelay: number = DEFAULT_LOADER_ENTER_DELAY,
  loaderLeaveDelay: number = DEFAULT_LOADER_LEAVE_DELAY
): IPublicLoadingState {
  const loading$ = _loading$.pipe(
    debounce((loading) => timer(loading ? loaderEnterDelay : loaderLeaveDelay)),
    distinctUntilChanged()
  );

  return {
    loading$,
  };
}

export function initializeLoadingState<T>(
  component: T,
  loaderEnterDelay: number = DEFAULT_LOADER_ENTER_DELAY,
  loaderLeaveDelay: number = DEFAULT_LOADER_LEAVE_DELAY
): void {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _loading$ } = privateLoadingState();
  const { loading$ } = publicLoadingState(
    { _loading$ },
    loaderEnterDelay,
    loaderLeaveDelay
  );

  Object.assign(component, {
    _loading$,
    loading$,
  });
}
