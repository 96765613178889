import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {Placements, PlacementStatusColorMap, PlacementStatusKL} from '../../domain/placements.model';
import {PlacementsService} from '../../services/placements.service';
import {PlacementsAutocompleteComponent} from './placements-autocomplete';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-placements-autocomplete-chips',
  template: `
    <mt-placements-autocomplete #pc
                                 [networkIds]="networkIds"
                                 (placementSelected)="onItemSelected($event)"
                                 [formFieldClass]="formFieldClass"
                                 [formFieldAppearance]="formFieldAppearance"
                                 [required]="required"
                                 [label]="placeholder"
                                 [floatLabel]="floatLabel">
    </mt-placements-autocomplete>
    <mat-chip-list class="mt-n3" #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
      <mat-basic-chip *ngFor="let item of items" (removed)="remove(item)">
        <i class="fas fa-circle mr-1 ml-0" ngClass="text-{{PlacementStatusColorMap[item.status]}}"></i>
        {{item.name}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>`,
})
export class PlacementsAutocompleteChipsComponent implements OnInit {
  @ViewChild(PlacementsAutocompleteComponent, {static: true}) pc: PlacementsAutocompleteComponent;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter placements for that given network*/
  @Input() networkIdsChanged: Observable<string[]>;

  /** Pass clearFilterItems: Subject<void> = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public placementsIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  /** Hold list of networkIds to filter placements only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  required = false;

  @Output()
  placementSelectionChange = new EventEmitter<Placements[]>();

  items: Placements[] = [];
  public PlacementStatusColorMap = PlacementStatusColorMap;

  constructor(private service: PlacementsService) {
  }

  ngOnInit(): void {
    if (this.placementsIds && this.placementsIds.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.placementsIds === 'string') {
        this.placementsIds = [this.placementsIds];
      }
      //Fetch only preselected publishers
      this.service.listPlacements({ids: this.placementsIds}).subscribe(placements => {
        placements.content.map(placement => {
          this.items.push(placement);
        });
        this.pc.updateFilteredPlacements(this.items);
      });
    }

    this.networkIdsChanged?.subscribe((result) => this.updatePlacementIdsChips(result));
    this.clearItems?.subscribe(() => this.clearFilter());
  }

  groupPlacements(placements: Placements[]) {
    return PlacementStatusKL().map((item) => ({
      name: item.label,
      placements: placements.filter(
        (placement) => placement.status === item.key
      ),
    }));
  }

  updatePlacementIdsChips(networks: string[]) {
    this.items = this.items.filter(x => networks.includes(x.networkId));
    this.pc.updateFilteredPlacements(this.items);
  }

  onItemSelected($event: Placements) {
    if ($event) {
      this.items.push($event);
      this.placementSelectionChange.emit(this.items);
      this.pc.resetInput();
      this.pc.updateFilteredPlacements(this.items);
    }
  }

  remove(p: Placements) {
    this.items.splice(this.items.indexOf(p), 1);
    this.placementSelectionChange.emit(this.items);
    this.pc.updateFilteredPlacements(this.items);
  }

  /** Clear all items from filter. */
  clearFilter() {
    this.items = [];
    this.pc.updateFilteredPlacements(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.pc.disable();
    }
    else {
      this.pc.enable();
    }
  }

}
