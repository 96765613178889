<page-loader [show]="(loading$ | async)">
  <mat-card class="pb-0">
    <mat-card-header>
      <mat-card-title>Ads.txt URL Status</mat-card-title>
      <div class="mat-card-header-status">
        <dl class="data-list mb-0 mr-3">
          <dt>Crawl date:</dt>
          <dd class="mb-0">{{(latestCrawlDate | date: 'MM/dd/yyyy') || '/'}}</dd>
        </dl>
        <button mat-flat-button *csHasPermission="RolePermission.PUBLISHER_ADSTXT_CRAWL" color="accent" (click)="startCrawlAll()"><i class="fas fa-bug"></i> Crawl All </button>
      </div>
      <div class="mat-card-header-actions">
      </div>
    </mat-card-header>
    <mat-card-content class="mat-card-content--full rounded-bottom overflow-hidden">

      <mt-card-filters #filters showFilters>
        <div class="col-12">
          <mat-form-field class="w-100">
            <mat-label>Demand partners</mat-label>
            <mat-select multiple [(value)]="filter.demandPartnerIds" (selectionChange)="onDemandPartnerFilterChange()">
              <mat-option *ngFor="let item of demandPartnerList" [value]="item.id">{{item.bidderCode}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mt-card-filters>

      <div class="data-alert" [hidden]="((dataSource.data && dataSource.data.length) || !publisherId)">
        <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
      </div>

      <div class="data-alert" [hidden]="publisherId">
        Please select publisher
      </div>

      <div class="table-scroll-wrapper" [hidden]="!dataSource.data || !dataSource.data.length">
        <mat-table #table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

          <ng-container matColumnDef="url">
            <mat-header-cell *matHeaderCellDef>URL</mat-header-cell>
            <mat-cell *matCellDef="let element">

              <span>{{element.adsTxtLocation}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="appsConnected">
            <mat-header-cell *matHeaderCellDef>Apps connected</mat-header-cell>
            <mat-cell *matCellDef="let element">

              <span>{{element.appsConnected}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="found">
            <mat-header-cell *matHeaderCellDef>Found</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <i (click)="openFoundDialog(element.adsTxtLocation)" *ngIf="(element.adstxtFound === element.adstxtTotal) && element.adstxtTotal != null && element.adstxtTotal !== 0" class="fas fa-check mr-2 link" ngClass="text-green"></i>
              <span class="link" (click)="openFoundDialog(element.adsTxtLocation)" *ngIf="element.adstxtFound !== element.adstxtTotal">{{element.adstxtFound}}/{{element.adstxtTotal}}</span>
              <span *ngIf="element.adstxtTotal === 0">{{element.adstxtFound}}/{{element.adstxtTotal}}</span>
              <span *ngIf="element.adstxtTotal == null">N/A</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastCrawl">
            <mat-header-cell *matHeaderCellDef>Last successful crawl</mat-header-cell>
            <mat-cell *matCellDef="let element">

              <span>{{(element.adstxtCrawledOn | date: 'MM/dd/yyyy') || '/'}}</span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns"></mat-row>
        </mat-table>
      </div>

    </mat-card-content>
  </mat-card>
</page-loader>
