import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpUtils} from '../utils/http-utils';
import {Page} from '../domain/page.model';
import {ContentOwner, ContentOwnerRevenueShareHistory} from '../domain/content-owner.model';
import {Video, VideoListRequest} from '../domain/video.model';
import {User} from '../../core/auth/user.model';
import {DateRangeHolder} from '../domain/rb/date-range-holder.model';
import {ReportResponse} from '../domain/rb/report-response.model';
import {ContentOwnerVideo} from '../domain/content-owner-video.model';
import {ContentOwnerDocument} from '../domain/content-owner-document.model';
import {SummedMasterReport} from '../domain/rb/summed-master-report.model';

@Injectable({
  providedIn: 'root',
})
export class ContentOwnerService {

  constructor(private httpClient: HttpClient) {
  }

  list(filter): Observable<ContentOwner[]> {
    return this.httpClient.get<ContentOwner[]>(`${environment.serviceUrl}/api/content-owners`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  create(contentOwner?: any, images?: any) {
    const formData: FormData = new FormData();
    formData.append('coverImage', images.coverImage);
    formData.append('profileImage', images.profileImage);
    formData.append('contentOwnerCreateRequest', JSON.stringify(contentOwner));
    return this.httpClient.post(`${environment.serviceUrl}/api/content-owners`, formData);
  }

  update(coId: string, contentOwner?: any, images?: any) {
    const formData: FormData = new FormData();
    if (images.coverImage) {
      formData.append('coverImage', images.coverImage);
    }
    if (images.profileImage) {
      formData.append('profileImage', images.profileImage);
    }
    formData.append('contentOwnerUpdateRequest', JSON.stringify(contentOwner));
    return this.httpClient.put(`${environment.serviceUrl}/api/content-owners/${coId}`, formData);
  }

  countByStatus() {
    return this.httpClient.get(`${environment.serviceUrl}/api/content-owners/statuses`);
  }

  getCoDetails(id: string): Observable<ContentOwner> {
    return this.httpClient.get<ContentOwner>(`${environment.serviceUrl}/api/content-owners/${id}`);
  }

  videosList(filter: VideoListRequest, id: string): Observable<Page<Video>> {
    return this.httpClient.get<Page<Video>>(`${environment.serviceUrl}/api/content-owners/${id}/videos`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getUsers(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.serviceUrl}/api/content-owners/${id}/users`);
  }

  addOwnerToVideo(id: string, videos: string[]) {
    return this.httpClient.put(`${environment.serviceUrl}/api/content-owners/${id}/videos`, {videos});
  }

  getCoTopVideos(dateRange: DateRangeHolder, coId: string): Observable<ContentOwnerVideo[]> {
    return this.httpClient.get<ContentOwnerVideo[]>(`${environment.serviceUrl}/api/co-dash/top-videos`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        coId
      })
    });
  }

  getCoDashVideos(filter): Observable<ReportResponse> {
    return this.httpClient.get<ReportResponse>(`${environment.serviceUrl}/api/co-dash/videos`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getCoVideosByLocation(dateRange, coId: string): Observable<ReportResponse> {
    return this.httpClient.get<ReportResponse>(`${environment.serviceUrl}/api/co-dash/videos-by-location`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        coId
      })
    });
  }

  getCoVideosByPlatform(dateRange: DateRangeHolder, coId: string): Observable<ReportResponse> {
    return this.httpClient.get<ReportResponse>(`${environment.serviceUrl}/api/co-dash/videos-by-platform`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        coId
      })
    });
  }

  getCoTotalValues(dateRange: DateRangeHolder, coId: string): Observable<ReportResponse> {
    return this.httpClient.get<ReportResponse>(`${environment.serviceUrl}/api/co-dash/total`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        coId
      })
    });
  }

  getCoVideosByDay(dateRange: DateRangeHolder, coId: string): Observable<SummedMasterReport[]> {
    return this.httpClient.get<SummedMasterReport[]>(`${environment.serviceUrl}/api/co-dash/videos-by-day`, {
      params: HttpUtils.getHttpParams({
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        coId
      })
    });
  }

  getCoFinancial(filter): Observable<ReportResponse> {
    return this.httpClient.get<ReportResponse>(`${environment.serviceUrl}/api/content-owners/financial`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getDocuments(coId: string, filter): Observable<Page<ContentOwnerDocument>> {
    return this.httpClient.get<Page<ContentOwnerDocument>>(`${environment.serviceUrl}/api/content-owners/${coId}/documents`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  uploadDocument(coId: string, file: File, documentDescription: string) {
    const formData: FormData = new FormData();
    formData.append('ioFile', file, file.name);
    formData.append('description', documentDescription);
    return this.httpClient.post(`${environment.serviceUrl}/api/content-owners/${coId}/documents`, formData);
  }

  editDocumentDescription(documentId: string, description: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/content-owners/documents/${documentId}`, {description});
  }

  removeDocument(documentId: string) {
    return this.httpClient.delete(`${environment.serviceUrl}/api/content-owners/documents/${documentId}`);
  }

  getCoRevenueShareHistory(coId: string): Observable<ContentOwnerRevenueShareHistory[]> {
    return this.httpClient.get<ContentOwnerRevenueShareHistory[]>(`${environment.serviceUrl}/api/content-owners/${coId}/shares`);
  }

  removeVideo(videos: string[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {videos},
    };
    return this.httpClient.delete(`${environment.serviceUrl}/api/content-owners/videos`, options);
  }
 }