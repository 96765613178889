import { UserRole, UserRoleNew } from './user-role.model';
import { User } from './user.model';
import { Principal } from './principal.service';

export class PrincipalUtils {
  //Main roles should not be combined as it could make some breaking bugs
  static MAIN_ROLES: string[] = [UserRoleNew.SUPER_ADMIN, UserRoleNew.ADMIN, UserRoleNew.ADMIN_READ_ONLY, UserRoleNew.USER, UserRoleNew.USER_READ_ONLY];
  //Additional roles that can be combined with Main Roles
  static ADDITIONAL_ROLES: string[] = [UserRoleNew.PUBLISHER, UserRoleNew.ADVERTISER, UserRoleNew.ADVERTISER_READ_ONLY];
  static APP_MAP: { [key: string]: UserRoleNew } = {
    'admin': UserRoleNew.ADMIN,
    'publisher': UserRoleNew.PUBLISHER,
    'super-admin': UserRoleNew.SUPER_ADMIN,
    'user': UserRoleNew.USER,
    'user-readonly': UserRoleNew.USER_READ_ONLY,
    // 'co': UserRole.CONTENT_OWNER,
    // 'sr': UserRole.SALES_REPRESENTATIVE,
    // 'cm': UserRole.CAMPAIGN_MANAGER,
    // 'csm': UserRole.CLIENT_SERVICE_MANAGER,
    'admin-readonly': UserRoleNew.ADMIN_READ_ONLY,
    'advertiser': UserRoleNew.ADVERTISER,
    'advertiser-readonly': UserRoleNew.ADVERTISER_READ_ONLY
  };

  static hasMoreThanOneMainRole(user: User) {
    return user.roles.filter(r => this.MAIN_ROLES.includes(r)).length > 1;
  }

  static isMasked(principal: Principal) {
    console.log('isMasked %s', JSON.stringify(principal.mask));
    const hasMaskRole = principal.mask.role;
    if (hasMaskRole) {
      switch (hasMaskRole) {
        case UserRoleNew.ADMIN:
          return true;
        case UserRoleNew.PUBLISHER:
          return !!principal.mask.publisherId;
        case UserRoleNew.ADVERTISER:
          return !!principal.mask.advertiserId;
        case UserRoleNew.ADVERTISER_READ_ONLY:
          return !!principal.mask.advertiserId;
        // case UserRole.CONTENT_OWNER:
        //   return true;
        // case UserRole.SALES_REPRESENTATIVE:
        //   return true;
        // case UserRole.CAMPAIGN_MANAGER:
        //   return false;
        // case UserRole.CLIENT_SERVICE_MANAGER:
        //   return false;
        case UserRoleNew.USER:
          return true;
        case UserRoleNew.ADMIN_READ_ONLY:
          return true;
      }
    }
    return false;
  }


  static filterMainRoles(roles: string[]) {
    return roles.filter(r => this.MAIN_ROLES.includes(r));
  }
}
