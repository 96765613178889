import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IabCategory } from '../domain/iab-category.model';
import { environment } from '../../../environments/environment';
import { urlJoin } from 'url-join-ts';

@Injectable({
  providedIn: 'root',
})
export class IabCategoriesService {
  private readonly endpoint = 'api/iab-categories';

  constructor(private httpClient: HttpClient) {
  }

  fetchIabCategories(): Observable<IabCategory[]> {
    return this.httpClient.get<IabCategory[]>(urlJoin(environment.serviceUrl, this.endpoint));
  }

}
