import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CampaignsService } from '../../services/campaigns.service';
import {LineItem} from "../../domain/campaigns.model";
import {FloatLabelType} from "@angular/material/form-field";
import {LineItemsAutocompleteRbComponent} from "./line-items-autocomplete-rb.component";

@Component({
  selector: 'mt-line-items-autocomplete-chips-rb',
  template: `
    <mt-line-items-autocomplete-rb #pc 
                                    [orderIds]="orderIds" 
                                    [lineItemIds]="lineItemIds" 
                                    [networkIds]="networkIds" 
                                    (lineItemSelected)="onItemSelected($event)" 
                                    [formFieldClass]="formFieldClass" 
                                    [disabled]="disabled"
                                    [formFieldAppearance]="formFieldAppearance" 
                                    [label]="placeholder"
                                    [floatLabel]="floatLabel"
                                    [required]="required"
    >
    </mt-line-items-autocomplete-rb>
    <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
      <mat-basic-chip *ngFor="let item of items"
                      [removable]="!disabled"
                      (removed)="remove(item)">
        {{item.name}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  `,
})
export class LineItemsAutocompleteChipsRbComponent implements OnInit, OnDestroy {

  @ViewChild(LineItemsAutocompleteRbComponent, {static: true}) pc: LineItemsAutocompleteRbComponent;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter line items for that given network*/
  @Input() networkIdsChanged: Observable<string[]>;

  /** Pass eventsSubjectOrder = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectOrder.next(orderIds) to filter line items by the given order ids*/
  @Input() orderIdsChanged: Observable<string[]>;

  @Input()
  public placeholder: string;

  @Input()
  public label: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public stacked: false;

  @Input()
  public lineItemIds: string[];

  @Input()
  public orderIds: string[];

  @Input()
  public networkIds: string[];

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  disabled: boolean;

  @Input()
  required = false;

  @Output()
  lineItemSelectionChange = new EventEmitter<LineItem[]>();

  items: LineItem[] = [];
  networks: string[] = [];

  private eventsSubscription: Subscription;


  constructor(private service: CampaignsService) {
  }

  ngOnInit(): void {
    if (this.lineItemIds && this.lineItemIds.length) {
      if (this.lineItemIds && typeof this.lineItemIds === 'string') {
        this.lineItemIds = [ this.lineItemIds ];
      }
      this.service.getLineItemsListForOrderId({
        orderIds: this.orderIds,
        networkIds: this.networkIds,
        lineItemIds: this.lineItemIds
      }).subscribe(lineItems => {
        lineItems.content.map(li => {
          this.items.push(li);
        })
        this.pc.updateFilteredLineItems(this.items);
      });
    }

    this.networkIdsChanged?.subscribe((result) => this.updateLineItemsChipsNetworkIds(result));
    this.orderIdsChanged?.subscribe((result) => this.updateLineItemsChipsOrderIds(result));
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }

  updateLineItemsChipsNetworkIds(networks: string[]) {
    if (networks && networks.length > 0) {
      this.items = this.items.filter(x => networks.includes(x.order.network.id));
      this.pc.updateFilteredLineItems(this.items);
    }
  }

  updateLineItemsChipsOrderIds(orders: string[]) {
    if (orders && orders.length > 0) {
      this.items = this.items.filter(x => orders.includes(x.order.id));
      this.pc.updateFilteredLineItems(this.items);
    }
  }

  onItemSelected($event: LineItem) {
    if ($event && !this.items.find(x => x === $event)) {
      this.items.push($event);
      this.lineItemSelectionChange.emit(this.items);
    }
    this.pc.resetInput();
    this.pc.updateFilteredLineItems(this.items);
  }

  remove(li: LineItem) {
    this.items.splice(this.items.indexOf(li), 1);
    this.lineItemSelectionChange.emit(this.items);
    this.pc.updateFilteredLineItems(this.items);
  }

  disableSearch(disable: boolean) {
    this.pc.disableSearch(disable);
  }

  clearFilter() {
    this.items = [];
    this.lineItemSelectionChange.emit(this.items);
    this.pc.updateFilteredLineItems(this.items);
  }
}
