import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {FloatLabelType} from "@angular/material/form-field";
import { ApplicationIdAutocompleteComponent } from './application-id-autocomplete.component';
import { AppsService } from '../../services/apps.service';
import { Apps, AppListRequest, AppsTableStatusColorMap } from '../../domain/apps.model';

@Component({
  selector: 'mt-application-id-autocomplete-chips',
  template: `
    <mt-application-id-autocomplete #pc
                                     [networkIds]="networkIds"
                                     (itemSelected)="onItemSelected($event)"
                                     [formFieldClass]="formFieldClass"
                                     [formFieldAppearance]="formFieldAppearance"
                                     [required]="required"
                                     [label]="placeholder"
                                     [floatLabel]="floatLabel">
    </mt-application-id-autocomplete>
    <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
      <mat-basic-chip *ngFor="let item of items"
                      (removed)="remove(item)">
        <i class="fas fa-circle mr-1 ml-0" ngClass="text-{{AppsTableStatusColorMap[item.status]}}"></i>
        {{item.name}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  `,
})
export class ApplicationIdAutocompleteChipsComponent implements OnInit {
  @ViewChild(ApplicationIdAutocompleteComponent, {static: true}) pc: ApplicationIdAutocompleteComponent;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter pubs for that given network*/
  //@Input() networkIdsChanged: Observable<string[]>;

  /** Pass clearFilterItems = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public appIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  /** Hold list of networkIds to filter connections only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  required = false;

  @Output()
  appsSelectionChange = new EventEmitter<Apps[]>();

  items: Apps[] = [];
  public AppsTableStatusColorMap = AppsTableStatusColorMap;

  constructor(private service: AppsService) {
  }

  ngOnInit(): void {
    if (this.appIds && this.appIds.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.appIds === 'string') {
        this.appIds = [this.appIds];
      }
      //Fetch only preselected publishers
      this.service.getApps({ids: this.appIds} as AppListRequest).subscribe(publishers => {
        publishers.content.map(pub => {
          this.items.push(pub);
        });
        this.pc.updateFilteredConnections(this.items);
      });
    }
    this.clearItems?.subscribe(() => this.clearFilter());
  }

  onItemSelected($event: Apps) {
    if ($event) {
      this.items.push($event);
      this.appsSelectionChange.emit(this.items);
      this.pc.resetInput();
      this.pc.updateFilteredConnections(this.items);
    }
  }

  remove(p: Apps) {
    this.items.splice(this.items.indexOf(p), 1);
    this.appsSelectionChange.emit(this.items);
    this.pc.updateFilteredConnections(this.items);
  }

  /** Clear all items from filter. */
  clearFilter() {
    this.items = [];
    this.pc.updateFilteredConnections(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.pc.disable();
    }
    else {
      this.pc.enable();
    }
  }
}
