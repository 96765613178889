import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import { Principal } from 'src/app/core/auth/principal.service';
import { UserRole } from 'src/app/core/auth/user-role.model';

@Directive({
  selector: '[csHasSalesRepPermission]'
})
export class HasSalesRepPermissionDirective {


  constructor(private principal: Principal,
              private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
                this.updateView();
  }

  private updateView(): void {
    if (this.principal.user.roles.includes(UserRole.SALES_REPRESENTATIVE)) {
      this.viewContainerRef.clear();
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
