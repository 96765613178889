import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import { CampaignsService } from '../../services/campaigns.service';
import { LineItem } from '../../domain/campaigns.model';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-line-items-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label >{{label ? label : 'Choose line item'}}</mat-label>
      <input matInput
             #name
             aria-label="Line Item ID"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             [value]="selectedLineItem?.name">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class LineItemsAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public lineItemIds: string;

  @Input()
  public networkIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  items: string[];

  @Input()
  disabled: boolean;

  @Input()
  public pub: boolean;

  @Output()
  lineItemIdSelected = new EventEmitter<LineItem>();

  serverSearching = false;

  filteredItems: Subject<LineItem[]>;
  filterCtrl = new UntypedFormControl();
  selectedLineItem;
  filteredLineItemIds: string[] = [];
  lineItemList: LineItem[];

  filter = {
    size: 50,
    page: 0
  };

  constructor(private service: CampaignsService) {

    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedLineItem) {
          this.selectedLineItem = undefined;
          this.emit();
        }

        if (next != null && typeof next !== 'object') {
          this.searchLineItems();
        } else {
          this.filteredItems.next(this.lineItemList);
        }
      });
  }

  resetInput() {
    this.selectedLineItem = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.filterCtrl.disable({onlySelf: true, emitEvent: true});
    }

    this.getLineItems();
  }

  getLineItems() {
    this.serverSearching = true;
    this.filterCtrl.disable();
    this.service.getLineItemsListForOrderId(this.filter).subscribe(resp => {
      this.lineItemList = resp.content;
      this.filteredItems.next(this.lineItemList.filter(item => !this.filteredLineItemIds.includes(item.id)));
    }, error =>{},
     () => {
      this.filterCtrl.enable();
      this.serverSearching = false;
    });
  }

  searchLineItems() {
    if (this.lineItemList.length > 0) {
      const search = this.lineItemList.filter((e) =>
        e.name.toLowerCase().includes(this.filterCtrl.value.toLowerCase())
      );
      this.filteredItems.next(
        search.filter(
          (item) => !this.filteredLineItemIds.includes(item.id))
      );
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }


  display(p?: string) {
    return p ? p : undefined;
  }

  optionSelected($event) {
    this.selectedLineItem = $event.option.value;
    this.filteredItems.next([]);
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.lineItemIdSelected.emit(this.selectedLineItem);
  }

  updateFilteredLineItems(items: string[]) {
    this.filteredLineItemIds = items.map(item => item);
  }

  disableSearch(disable: true) {
    disable ? this.filterCtrl.disable() : this.filterCtrl.enable();
  }
}
