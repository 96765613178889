import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { User } from 'src/app/core/auth/user.model';
import _ from 'lodash';
import { PlatformsService } from '../../services/platforms.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-platform-autocomplete',
  template: ` <mat-form-field
    class="w-100"
    [ngClass]="formFieldClass"
    [floatLabel]="floatLabel ? floatLabel : 'always'"
    [appearance]="formFieldAppearance ? formFieldAppearance : undefined "
  >
    <mat-label>{{
      label ? label : 'Choose platform'
    }}</mat-label>
    <input
      matInput
      #name
      (focus)="onFocusEvent()"
      aria-label="User"
      [matAutocomplete]="auto"
      [formControl]="filterCtrl"
      [value]="this.selectedPlatform?.name"
    />
    <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="display"
    >
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>`,
})
export class PlatformAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: true }) nameFilter: ElementRef;

  filter = {
    enabled: true
  } as any;

  @Input()  public placeholder: string;
  @Input()  public label: string;
  @Input()  public floatLabel: FloatLabelType;
  @Input()  public formFieldClass: string;
  @Input()  public formFieldAppearance: string;
  @Input()  public platformId: string;
  @Input()  items: any[];

  @Output()
  platformSelected = new EventEmitter<any>();

  serverSearching = false;

  filteredItems: Subject<any[]>;
  filterCtrl = new UntypedFormControl();
  selectedPlatform;
  allPlatforms: any[] = [];
  filteredPlatformIds: string[] = [];

  platformKeys = [];

  constructor(public service: PlatformsService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((next) => {
        if (!next && this.selectedPlatform) {
          this.selectedPlatform = undefined;
          this.emit();
        }
        if (next && typeof next !=='object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.searchPlatforms();
        }
      });

  }

  resetInput() {
    this.selectedPlatform = null;
    this.filterCtrl.setValue('');
  }

  ngOnInit(): void {
    this.service.listActivePlatforms().subscribe(resp => {
      this.allPlatforms = resp;
      if (this.platformId) {
        this.selectedPlatform = (this.allPlatforms.find(x => x.id == this.platformId));
      }
    }, () => {
      this.filterCtrl.enable();
      //this.nameFilter.nativeElement.focus();
      this.serverSearching = false;
    });
    this.getAllPlatforms();

  }

  ngAfterViewInit(): void {
  }

  display(e?: User) {
    return e ? e : undefined;
  }

  optionSelected($event) {
    this.selectedPlatform = $event.option.value;
    this.filteredItems.next(this.allPlatforms.slice(0, 20));
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.platformSelected.emit(this.selectedPlatform);
  }

  getAllPlatforms() {
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(
        this.allPlatforms.filter(
          (item) => !this.filteredPlatformIds.includes(item.id)
        )
      );
    }
    this.filterCtrl.enable();
    //this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }

  updateFilteredPlatforms(items) {
    this.filteredPlatformIds = items.map((item) => item.id);
  }

  onFocusEvent() {
    this.getAllPlatforms();
  }

  searchPlatforms() {
    if (this.allPlatforms.length > 0) {
      const search = this.allPlatforms.filter((e) =>
        e.name.toLowerCase().includes(this.filterCtrl.value.toLowerCase())
      );
      this.filteredItems.next(
        search.filter(
          (item) => !this.filteredPlatformIds.includes(item))
      );
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

}
