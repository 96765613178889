import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { forkJoin, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { DemandPartnersService } from 'src/app/shared/services/demand-partners.service';
import { ServerErrorUtils } from 'src/app/shared/utils/server-error-utils';
import {
  ConnectionStatus,
  ConnectionStatusBox,
  ConnectionStatusColorMap,
  ConnectionStatusKL,
  ConnectionStatusLabels,
  ConnectionStatusListing,
  DemandPartnerConnection,
  DemandPartnersConnectionRequest,
  IntegrationTypeLabels,
} from 'src/app/shared/domain/demand-partner-connection.model';
import { DpPlacementPreviewDialog } from '../../dialogs/placement-preview-dialog/placement-dialog.component';
import { PlacementStatusBox } from 'src/app/shared/domain/placements.model';
import { GlobalSpinnerService } from 'src/app/core/services/global-spinner.service';
import _ from 'lodash';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';

@Component({
  selector: 'mt-dp-details-connections',
  templateUrl: './dp-details-connections.component.html',
  styleUrls: ['./dp-details-connections.component.scss'],
})
export class DpDetailsConnectionsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public readonly RolePermission = RolePermission;

  columns = [];

  dataSource = new MatTableDataSource<DemandPartnerConnection>();
  loading = false;
  integrationTypeLabels = IntegrationTypeLabels;
  sbs: Subscription;
  filter = {
    page: 0,
    size: 10,
    sortProperty: ['name'],
    sortDirection: 'ASC',
    name: undefined,
    status: [ConnectionStatus.ACTIVE],
  } as DemandPartnersConnectionRequest;
  nameControl: UntypedFormControl;
  dpId: string;
  statusCounts: ConnectionStatusBox[] = [];
  ConnectionStatusLabels = ConnectionStatusLabels;
  constructor(
    private service: DemandPartnersService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private gss: GlobalSpinnerService,
    private snackBarService: SnackBarService,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.dpId = this.route.snapshot.parent.params.id;
    if (this.dpId) {
      this.columns = [
        'name',
        'status',
        'placements',
        'sellerDomain',
        'integrationType',
        'duplication',
        'notes',
        'actions',
      ];
    } else {
      this.columns = [
        'name',
        'status',
        'dpDisplayName',
        'placements',
        'sellerDomain',
        'integrationType',
        'duplication',
        'notes',
        'actions',
      ];
    }
  }

  ngOnInit(): void {
    //read Filters and Search from url on back button
    if (this.activatedRoute.snapshot.queryParams.page) { //use any object that simply has to be in filter
      this.filter = _.cloneDeep(this.activatedRoute.snapshot.queryParams) as DemandPartnersConnectionRequest;
      if (!this.filter.status) {
        this.filter.status = [];
      }
    }
    this.nameControl = new UntypedFormControl(this.filter.name);
    this.refreshData();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((next) => {
      this.filter.sortProperty = [next.active];
      this.filter.sortDirection = next.direction.toUpperCase();
      this.refreshDataFirstPage();
    });

    this.paginator.page.subscribe((p: PageEvent) => {
      this.filter.size = p.pageSize;
      this.filter.page = p.pageIndex;
      this.refreshData();
    });
    this.nameControl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((data) => {
        this.filter.name = data;
        this.refreshDataFirstPage();
      });
  }

  refreshDataFirstPage() {
    this.paginator.pageIndex = 0;
    this.filter.page = 0;
    this.refreshData();
  }

  refreshData() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: Object.assign({}, this.filter),
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.location.replaceState(urlTree.toString());
    if (this.sbs) {
      this.sbs.unsubscribe();
    }

    if (this.dpId) {
      this.getDemandPartnerIntegration();
    } else {
      this.getAllIntegrations();
    }
  }

  getDemandPartnerIntegration() {
    this.loading = true;

    forkJoin([
      this.service.getDpIntegrations(this.filter, this.dpId),
      this.service.getStatusCount({ dpId: this.dpId }),
    ]).subscribe(
      ([response, statusCounts]) => {
        this.loading = false;
        this.statusCounts = Object.keys(ConnectionStatus).map((key) => ({
          status: key,
          count: statusCounts[key] || 0,
        }));

        this.paginator.length = response.totalElements;
        this.dataSource.data = response.content;
        this.loading = false;
      },
      (error) => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach((m) => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error fetching demand connections!');
        }
        this.loading = false;
      }
    );
  }

  getAllIntegrations() {
    this.loading = true;

    forkJoin([
      this.service.getAllIntegrations(this.filter),
      this.service.getStatusCount({}),
    ]).subscribe(
      ([response, statusCounts]) => {
        this.loading = false;
        this.statusCounts = Object.keys(ConnectionStatus).map((key) => ({
          status: key,
          count: statusCounts[key] || 0,
        }));

        this.paginator.length = response.totalElements;
        this.dataSource.data = response.content;
        this.loading = false;
      },
      (error) => {
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach((m) => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error fetching demand connections!');
        }
        this.loading = false;
      }
    );
  }

  createIntegration() {
    if (this.dpId) {
      this.router.navigate([`/demand/details/${this.dpId}/integrations/create`]);
    } else {
      this.router.navigate([`/demand/integrations/create`]);
    }
  }

  editIntegration(element) {
    if (this.dpId) {
      this.router.navigate([
        `/demand/details/${this.dpId}/integrations/edit/${element.id}`,
      ]);
    } else {
      this.router.navigate([
        `/demand/details/${element.demandPartner.id}/integrations/edit/${element.id}`,
      ]);
    }
  }

  openConnectionDetails(element) {
    this.router.navigate([
      `/demand/integration/${element.id}/details`,
    ]);
  }

  emit() {
    this.router.navigate([`/demand/list`]);
  }

  openPlacementDialog(element) {
    this.matDialog.open(DpPlacementPreviewDialog, {
      data: { connection: element },
      width: '700px',
      autoFocus: false
    } as MatDialogConfig);
  }

  filterByStatus(connectionStatus) {
    if (!this.isStatusFilterSelected(connectionStatus, true)) {
      if (this.filter.status && typeof this.filter.status === 'string') {
        this.filter.status = [this.filter.status];
      }
      this.filter.status.push(connectionStatus);
    } else {
      this.filter.status = this.filter.status.filter(
        (s) => s !== connectionStatus
      );
    }
    this.refreshData();
  }

  isStatusFilterSelected(connectionStatus, filterCheck = false) {
    if (this.filter.status && typeof this.filter.status === 'string') {
      this.filter.status = [ this.filter.status ];
    }
    return filterCheck
      ? this.filter.status.includes(connectionStatus)
      : this.filter.status.includes(connectionStatus) ||
          this.filter.status.length === 0;
  }

  setConnectionStatus(connectionStatus: ConnectionStatusBox) {
    return {
      color: ConnectionStatusColorMap[connectionStatus.status],
      label: ConnectionStatusLabels[connectionStatus.status],
      count: connectionStatus.count,
      statusName: connectionStatus.status,
    };
  }

  duplicateConnection(element: DemandPartnerConnection) {
    this.router.navigate([`/demand/integrations/create/${element.demandPartner.id}/${element.id}`]);
  }
}
