import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../../core/auth/user.model';
import { Observable } from 'rxjs';
import { HttpUtils } from '../utils/http-utils';
import { Page } from '../domain/page.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(private httpClient: HttpClient) {
  }

  login(user: User) {
    return this.httpClient.post(`${environment.serviceUrl}/login`, user, {
      observe: 'response',
      responseType: 'text'
    });
  }

  me(): Observable<User> {
    return this.httpClient.get<User>(`${environment.serviceUrl}/api/users/me`);
  }

  list(filter): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.serviceUrl}/api/users/list-all`, {
      params: filter ? HttpUtils.getHttpParams(filter) : {}
    });
  }

  listByNetwork(filter): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.serviceUrl}/api/users/list-all`, {
      params: filter ? HttpUtils.getHttpParams(filter) : {}
    });
  }

  create(u: User): Observable<User> {
    return this.httpClient.post<User>(`${environment.serviceUrl}/api/users`, u);
  }

  update(u: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.serviceUrl}/api/users/${u.email}`, u);
  }

  createNetwork(u: User, networkId: string): Observable<User> {
    return this.httpClient.post<User>(`${environment.serviceUrl}/api/admin/networks/${networkId}/users`, u);
  }

  enableUser(username) {
    return this.httpClient.put(`${environment.serviceUrl}/api/users/${username}/enable`, {});
  }

  disableUser(username) {
    return this.httpClient.put(`${environment.serviceUrl}/api/users/${username}/disable`, {});
  }

  delete(username) {
    return this.httpClient.delete(`${environment.serviceUrl}/api/users/${username}`);
  }

  updateUserStatus(username: string, status: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/users/${username}/${status}`, {});
  }

  getStatusCount(filter?: any) {
    return this.httpClient.get(`${environment.serviceUrl}/api/users/statuses`,
      {
        params: filter ? HttpUtils.getHttpParams(filter) : {}
      });
  }

  createUserSuperAdmin(u: User, networkId: string): Observable<User> {
    return this.httpClient.post<User>(`${environment.serviceUrl}/api/admin/users/${networkId}`, u);
  }

  updateUserSuperAdmin(u: User): Observable<User> {
    return this.httpClient.put<User>(`${environment.serviceUrl}/api/admin/users`, u);
  }


  listUsersSuperAdmin(filter): Observable<Page<User>> {
    return this.httpClient.get<Page<User>>(`${environment.serviceUrl}/api/admin/users`, {
      params: filter ? HttpUtils.getHttpParams(filter) : {}
    });
  }

  enableUserSuperAdmin(username) {
    return this.httpClient.put(`${environment.serviceUrl}/api/admin/users/${username}/enable`, {});
  }

  disableUserSuperAdmin(username) {
    return this.httpClient.put(`${environment.serviceUrl}/api/admin/users/${username}/disable`, {});
  }

  getStatusCountSuperAdmin(filter?: any) {
    return this.httpClient.get(`${environment.serviceUrl}/api/admin/users/statuses`,
      {
        params: filter ? HttpUtils.getHttpParams(filter) : {}
      });
  }
}