import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Embed, EmbedGeneralData} from '../domain/embed.model';
import {EmbedDemandPartner} from '../domain/embed-demand-partner.model';
import {EmbedChannel} from '../domain/embed-channel.model';
import {Page} from '../domain/page.model';
import {EmbedProfile, EmbedProfileRequest} from '../domain/embed-profile.model';
import {HttpUtils} from '../utils/http-utils';
import {EmbedAdSettings} from '../domain/embed-ad-info.model';

@Injectable({
  providedIn: 'root',
})
export class EmbedsService {

  constructor(private httpClient: HttpClient) {
  }

  getEmbedGeneralData(embedId: string): Observable<Embed> {
    return this.httpClient.get<Embed>(`${environment.serviceUrl}/api/embeds/` + embedId);
  }

  updateEmbedGeneralData(embedId: string, embedGeneralData: EmbedGeneralData, backgroundImage?: File) {
    const formData: FormData = new FormData();
    formData.append('embed', JSON.stringify(embedGeneralData));
    if (backgroundImage) {
      formData.append('backgroundImage', backgroundImage, backgroundImage.name);
    } else {
      formData.append('backgroundImage', JSON.stringify({}));
    }
    return this.httpClient.put(`${environment.serviceUrl}/api/embeds/${embedId}`, formData);
  }

  getEmbedPartners(embedId: string): Observable<EmbedDemandPartner[]> {
    return this.httpClient.get<EmbedDemandPartner[]>(`${environment.serviceUrl}/api/embeds/` + embedId + `/demand-partners`);
  }

  updateEmbedPartners(embedId: string, embedDemandPartnersList: EmbedDemandPartner[]) {
    return this.httpClient.put(`${environment.serviceUrl}/api/embeds/` + embedId + `/demand-partners`,
      {embedDemandPartners: embedDemandPartnersList});
  }

  getEmbedChannels(embedId: string): Observable<Page<EmbedChannel>> {
    return this.httpClient.get<Page<EmbedChannel>>(`${environment.serviceUrl}/api/embeds/` + embedId + `/channels`);
  }

  updateEmbedChannels(embedId: string, embedChannels: string[]) {
    return this.httpClient.put(`${environment.serviceUrl}/api/embeds/` + embedId + `/channels`, {channels: embedChannels});
  }

  getAllEmbeds(): Observable<Embed[]> {
    return this.httpClient.get<Embed[]>(`${environment.serviceUrl}/api/embeds`);
  }

  getEmbedProfile(filter: EmbedProfileRequest): Observable<Page<EmbedProfile>> {
    return this.httpClient.get<Page<EmbedProfile>>(`${environment.serviceUrl}/api/embeds/profile`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  embedCountingByStatus() {
    return this.httpClient.get(`${environment.serviceUrl}/api/embeds/profile/count-by-status`);
  }

  getEmbedProfileDp(id: string): Observable<EmbedDemandPartner[]> {
    return this.httpClient.get<EmbedDemandPartner[]>(`${environment.serviceUrl}/api/embeds/profile/${id}/demand-partners`);
  }

  getEmbedAdSettings(id: string): Observable<EmbedAdSettings> {
    return this.httpClient.get<EmbedAdSettings>(`${environment.serviceUrl}/api/embeds/${id}/adSettings`);
  }

  updateEmbedAdSetings(id, adInfo): Observable<EmbedAdSettings> {
    return this.httpClient.put<EmbedAdSettings>(`${environment.serviceUrl}/api/embeds/${id}/adSettings`, adInfo);
  }
}
