import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Principal } from '../auth/principal.service';
import { Observable } from 'rxjs';
import { User } from '../auth/user.model';

@Injectable()
export class NetworkGuard {
  private networkAllowedRoutes = ['/dashboard', '/publishers', '/reports','/tailored-supply', '/users', '/roles', '/demand', '/placements', '/domains', '/integrations', '/deals', '/endpoint-generator', '/advertiser',
    '/targeting', '/changelog', '/ads-txt', '/networks', '/orders', '/line-items', '/forecast', '/campaigns', '/apps', '/assets', '/brands', '/network-template', '/owned-operated-apps', '/collections', '/videos', '/content-owner', '/scheduled-reports', '/performance', '/errors', '/brand', '/widget-types', '/ai-web-player'];

  constructor(private router: Router,
    private principal: Principal) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isNetworkUser(this.principal.user) && this.isNotAllowedRoute(state.url)) {
      return this.router.createUrlTree(['/forbidden']);
    }
    return true;
  }

  private isNetworkUser(user: User): boolean {
    return !!user.network;
  }

  private isNotAllowedRoute(url: string): boolean {
    return !this.networkAllowedRoutes.some(item => url.startsWith(item));
  }
}
