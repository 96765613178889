import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Utils} from '../../utils/utils';

@Component({
  selector: 'mt-upload-button',
  template: `
    <label for="{{id}}" *ngIf="label" class="mt-upload-button-label">
      {{label}}
    </label>
    <label for="{{id}}" class="mt-upload-button" *ngIf="!file && !filePreview">
      <span class="fal fa-upload text-primary mr-3"></span>
      <span>{{ placeholder ? placeholder : 'Upload' }}</span>
      <input type="file" class="d-none"
             [id]="id" #fileInput
             (change)="handleFileInput($event.target.files)">
    </label>

    <div *ngIf="file || filePreview" class="mt-upload-preview p-2">
      <div class="col mt-upload-preview-name" *ngIf="fileName">
        <div class="mt-upload-preview-delete" *ngIf="!isEdit">
          <button mat-mini-fab color="warn" class="mat-elevation-z0" (click)="removeFile()" matTooltip="Delete">
            <i class="fal fa-trash-alt" aria-hidden="true"></i>
          </button>
        </div>
        <p class="mb-0">
          <small>File name:</small> <br>
          <span *ngIf="file">{{file.name}}</span>
          <span *ngIf="filePreview">{{filePreview.name}}</span>
        </p>
      </div>
    </div>
  `,
})
export class UploadButtonComponent {

  @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

  @Output()
  public fileSelected: EventEmitter<File> = new EventEmitter();

  @Output()
  public fileCanceled: EventEmitter<string> = new EventEmitter();

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public fileName = true;

  @Input()
  public filePreview;

  @Input()
  public isEdit: boolean;

  file: File;

  public id = Utils.generateGuid();

  handleFileInput(file: FileList): void {
    if (file.length) {
      this.file = file.item(0);
      this.fileSelected.emit(file.item(0));
    } else {
      const fileName = this.file.name;
      this.file = null;
      this.fileCanceled.emit(fileName);
    }
  }

  open() {
    this.fileInput.nativeElement.click();
  }

  getFile() {
    return this.file;
  }

  removeFile() {
    const fileName = this.file ? this.file.name : null;
    this.file = null;
    this.filePreview = null;
    this.fileCanceled.emit(fileName);
  }

}
