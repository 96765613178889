import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AdsTxtCrawledInfoModel,
  AdsTxtPublisherDomain,
  AdsTxtPublisherDomainRequest,
  AdsTxtRecord,
  AdsTxtRecordRequest,
  AdsTxtUrlStatusModel
} from '../domain/ads-txt.model';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page.model';
import {HttpUtils} from '../utils/http-utils';
import {DomainAdsTxtRecord} from '../domain/domain-ads-txt.model';

@Injectable({
  providedIn: 'root',
})
export class AdsTxtService {

  constructor(private httpClient: HttpClient) {
  }

  listFilter(filter: any): Observable<Page<AdsTxtRecord>> {
    return this.httpClient.get<Page<AdsTxtRecord>>(`${environment.serviceUrl}/api/adstxts`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  exportCsvListFilter(filter: any) {
    return this.httpClient.get(`${environment.serviceUrl}/api/adstxts/csv/export`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams(filter)
    });
  }

  createRecord(adsTxtRecord: AdsTxtRecord) {
    return this.httpClient.post(`${environment.serviceUrl}/api/adstxts`, adsTxtRecord);
  }

  updateRecord(adsTxtRecord: AdsTxtRecord) {
    return this.httpClient.put(`${environment.serviceUrl}/api/adstxts/${adsTxtRecord.id}`, adsTxtRecord);
  }

  getDomainAdsTxt(publisherId: string, domainId: string, filter): Observable<Page<DomainAdsTxtRecord>> {
    return this.httpClient.get<Page<DomainAdsTxtRecord>>(`${environment.serviceUrl}/adstxts/domain/init/${publisherId}/${domainId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  saveDomainAdsTxt(adsTxt, id: string) {
    return this.httpClient.post(`${environment.serviceUrl}/adstxts/domain/${id}`, adsTxt);
  }

  getPublisherAdsTxt(publisherId: string) {
    return this.httpClient.get(`${environment.serviceUrl}/adstxts/publisher/${publisherId}/not-found`);
  }

  deleteAdsTxtRecord(id: string) {
    return this.httpClient.delete(`${environment.serviceUrl}/api/adstxts/${id}`);
  }

  exportAdsTxtRecordsCSV(filter: AdsTxtRecordRequest) {
    return this.httpClient.get(`${environment.serviceUrl}/adstxts/export-csv`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getPublisherDomainAdsTxt(filter: AdsTxtPublisherDomainRequest): Observable<Page<AdsTxtPublisherDomain>> {
    return this.httpClient.get<Page<AdsTxtPublisherDomain>>(`${environment.serviceUrl}/adstxts/publisher-domain`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getDomainMissingRecords(publisherId: string, domainId: string): Observable<AdsTxtRecord[]> {
    return this.httpClient.get<AdsTxtRecord[]>(`${environment.serviceUrl}/adstxts/publisher-domain/missing`, {
      params: {publisherId, domainId}
    });
  }

  getDomainAddedRecords(publisherId: string, domainId: string): Observable<AdsTxtRecord[]> {
    return this.httpClient.get<AdsTxtRecord[]>(`${environment.serviceUrl}/adstxts/publisher-domain/added`, {
      params: {publisherId, domainId}
    });
  }

  exportPublisherDomainAdsTxt(filter: AdsTxtPublisherDomainRequest) {
    return this.httpClient.get(`${environment.serviceUrl}/adstxts/publisher-domain/export-csv`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams(filter)
    });
  }

  //Ads-Txt-Status-Table
  public getAdsTxt(filter: any): Observable<AdsTxtUrlStatusModel[]> {
    return this.httpClient.get<AdsTxtUrlStatusModel[]>(`${environment.serviceUrl}/api/apps/adstxt-locations-crawled/${filter.publisherId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getAdsTxtInfo(filter: any): Observable<AdsTxtCrawledInfoModel[]> {
    return this.httpClient.get<AdsTxtCrawledInfoModel[]>(`${environment.serviceUrl}/api/apps/adstxt-locations-crawled-info/${filter.publisherId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getRecordsForAdsTxt(filter: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/apps/adstxt-locations-crawled-status`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public crawlAllAdsTxtForPublisher(filter: any): Observable<AdsTxtUrlStatusModel[]> {
    return this.httpClient.get<AdsTxtUrlStatusModel[]>(`${environment.serviceUrl}/api/apps/adstxt-locations-crawl/${filter.publisherId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

}