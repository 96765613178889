<mat-card>
    <mat-card-header>
        <!-- <mat-card-title>
            {{title}}
        </mat-card-title> -->
        <div class="mat-card-header-search">
        <mat-form-field class="header-form-field mr-lg-n3" floatLabel="never">
            <input matInput [formControl]="searchControl" placeholder="search">
            <i class="far fa-search" matSuffix></i>
        </mat-form-field>
      </div>
      <div class="mat-card-header-actions mx-5">
        <ng-content select="[action-buttons]"></ng-content>
      </div>
      <div class="mat-card-header-status margin-adjustment">
        <ng-content select="[action-status]"></ng-content>
      </div>
        <div class="mat-card-header-actions">
            <ng-content select="[action-icons]"></ng-content>
        </div>
    </mat-card-header>
    <mat-card-content class="mat-card-content--full">
        <page-loader [show]="loading">
            <div class="table-scroll-wrapper">
                <mat-table [hidden]="dataSource.data.length === 0" #table [dataSource]="dataSource" matSort>
                    <ng-container *ngFor="let column of columns" [matColumnDef]="column.dataKey">
                        <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable">{{column.name}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">

                            <ng-container [ngSwitch]="column.dataType">
                                <mt-circle-info *ngSwitchCase="MasterTableDataType.BOOLEAN"
                                    [isChecked]="element | masterTablePipe: column"></mt-circle-info>
                                <mat-chip *ngSwitchCase="MasterTableDataType.STATUS" class="status-chip" [innerHTML]="element | masterTablePipe: column" [ngClass]="element | masterTablePipe: column | lowercase">
                                </mat-chip>
                                <span *ngSwitchDefault [matTooltip]="element | masterTablePipe: column"
                                    matTooltipShowDelay="500" [outerHTML]="element | masterTablePipe: column">
                                </span>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *csHasPermission="RolePermission.MASTER_TABLE_ACTIONS">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <i class="far fa-ellipsis-v"></i>
                            </button>
                            </ng-container>
                            <mat-menu #menu="matMenu">
                                <button *ngFor="let action of actions" mat-menu-item [matTooltip]="action.name"
                                    matTooltipShowDelay="500" (click)="action.click(element)" [hidden]="action.hidden">
                                    <i [ngClass]="action.icon" *ngIf="action.icon"></i>
                                    {{action.name}}
                                </button>
                                <ng-container *csHasPermission="RolePermission.DOMAIN_ARCHIVE">
                                  <button *ngIf="element.status === 'ACTIVE'" mat-menu-item [matTooltip]="archive.name"
                                    matTooltipShowDelay="500" (click)="archive.click(element)">
                                    <i [ngClass]="archive.icon"></i>
                                    {{archive.name}}
                                  </button>
                                  <button *ngIf="element.status === 'ARCHIVED'" mat-menu-item [matTooltip]="activate.name"
                                    matTooltipShowDelay="500" (click)="activate.click(element)">
                                    <i [ngClass]="activate.icon"></i>
                                    {{activate.name}}
                                  </button>
                                </ng-container>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
                </mat-table>
                <div class="data-alert" *ngIf="!initialState && dataSource.data.length === 0">
                    <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
                </div>
                <ng-content *ngIf="initialState && dataSource.data.length === 0" select="[empty-state]"></ng-content>
            </div>
        </page-loader>
    </mat-card-content>
    <mat-card-footer>
        <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50, 100]">
        </mat-paginator>
    </mat-card-footer>
</mat-card>
