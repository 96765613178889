import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {

  constructor(private httpClient: HttpClient) {
  }

  list(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/categories`);
  }
}
