import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { KeyValue } from 'src/app/shared/domain/key-value.model';

@Component({
  selector: 'page-loader',
  template: `
    <div *ngIf="show" class="loader-backdrop">
      <div class="loader-content">
        <mat-spinner [ngClass]="publisherEntery ? 'adjustment' : ''" diameter="35"></mat-spinner>
        <p class="mt-3" *ngIf="checkIfString() && show">{{show}}</p>
      </div>
    </div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageLoader {
  @Input() public show:  boolean | string;
  @Input() public inContainer: boolean; //Deprecated
  @Input() public publisherEntery:boolean = false; // Used to notify us if we will display pageLoader component from publisher section on a2v tab because there is need to lower spinner there

  public checkIfString(): boolean{
    return typeof this.show === 'string';
  }

  public checkIfBoolean(): boolean{
    return typeof this.show === 'boolean';
  }
}

