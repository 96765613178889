import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import {MomentDateUtils} from '../../utils/moment-date-utils';
import * as moment from 'moment';
import {MatDatepickerInputEvent, MatDateRangePicker} from '@angular/material/datepicker';
import {DateRangeType, DateRangeTypeLabelKV} from '../../domain/rb/date-range-type.enum';
import {CompareDateRangeHolder, DateRangeHolder} from '../../domain/rb/date-range-holder.model';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { CompareType, CompareTypeLabelsKV } from '../../domain/rb/date-range.model';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'mt-date-range-chooser',
  template: `
    <div class="date-range-wrapper">
      <div class="date-range-buttons">
        <ng-container *ngIf="!regularButtons; then toggleButtons; else buttons"></ng-container>
        <ng-template #toggleButtons>
          <mat-button-toggle-group class="date-range-chooser-toggle-group">
            <mat-button-toggle
              *ngFor="let dateRange of dateRangeTypes; index as i"
              checked="{{i === 0}}"
              [class.toggle-button-selected]="dateRangeType === dateRange.key"
              (click)="onRange(dateRange.key)">
              {{dateRange.value}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </ng-template>
        <ng-template #buttons>
          <button mat-button color="primary"
                  *ngFor="let dateRange of dateRangeTypes"
                  [class.selected]="dateRangeType === dateRange.key"
                  (click)="onRange(dateRange.key)">
            {{dateRange.value}}
          </button>
        </ng-template>
      </div>
      <div class="date-range-form-field">
        <mat-form-field appearance="outline">
          <mat-label>Date range:</mat-label>
          <mat-date-range-input #dateRangeInput [rangePicker]="picker" [max]="maxDate" [min]="minDate">
            <input matStartDate placeholder="Start date" [(ngModel)]="dateFromPicker" (dateChange)="dateFromChange($event)">
            <input matEndDate placeholder="End date" [(ngModel)]="dateToPicker" (dateChange)="dateToChange($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker" class="text-primary">
            <small class="far fa-calendar-range" matDatepickerToggleIcon></small>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker (closed)="datePickerClosed()"></mat-date-range-picker>
        </mat-form-field>

        <ng-container *ngIf="compareOption">
          <div class="d-flex align-items-center justify-content-between mt-n2 pb-2"><span>Compare: </span>
                    <mat-slide-toggle [color]="'primary'" [checked]="compareActive"
                      (change)="updateCompareOption()">
                      {{compareActive ? 'Enabled' : 'Disabled'}}
                    </mat-slide-toggle></div>
          <mat-form-field appearance="outline">
            <mat-select required [disabled]="!compareActive"
                        [(ngModel)]="compareType">
                <mat-option *ngFor="let item of compareTypeLabelsKV" [value]="item.key">{{item.value}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Date range:</mat-label>
            <mat-date-range-input #compareDateRangeInput [rangePicker]="comparePicker" [max]="maxDate" [min]="minDate">
              <input matStartDate placeholder="Start date" [(ngModel)]="compareDateFromPicker" (dateChange)="dateFromChange($event)" [disabled]="!compareActive || compareType === CompareType.PREVIOUS">
              <input matEndDate placeholder="End date" [(ngModel)]="compareDateToPicker" (dateChange)="dateToChange($event)" [disabled]="!compareActive || compareType === CompareType.PREVIOUS">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="comparePicker" class="text-primary">
              <small class="far fa-calendar-range" matDatepickerToggleIcon></small>
            </mat-datepicker-toggle>
            <mat-date-range-picker #comparePicker (closed)="datePickerClosed()"></mat-date-range-picker>
          </mat-form-field>
        </ng-container>

        <button mat-flat-button color="primary" class="mt-n2 w-100"
                [hidden]="autoSubmitCustomDate" [disabled]="dateRangeInputRef?.errorState"
                (click)="onSelect()">Select
        </button>
      </div>
    </div>
  `,
  styles: [`
    .mat-form-field{
      font-size:14px;
    }
    .selected {
      background-color: #e1f5fe;
    }
  `],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DateRangeChooserComponent implements OnInit, OnChanges {
  @ViewChild('dateRangeInput') dateRangeInputRef;

  @Input()
  public dateFrom;
  @Input()
  public dateTo;
  @Input()
  public maxDate;
  @Input()
  public minDate;
  @Input()
  public allowedDateRangeTypes: DateRangeType[] = <DateRangeType[]>Object.keys(DateRangeType);
  @Input()
  autoSubmitCustomDate: boolean;
  @Input()
  public maxRangeBetweenDates: number;
  @Input()
  public regularButtons: boolean;
  @Input()
  public compareOption: boolean;
  @Input()
  public compareActive = false;

  @Output()
  dateRangeChanged = new EventEmitter<DateRangeHolder>();

  @Output()
  compareDateRangeChanged = new EventEmitter<CompareDateRangeHolder>();

  today = MomentDateUtils.today();

  dateRangeType: DateRangeType;

  compareType: CompareType = CompareType.PREVIOUS;

  public dateRangeTypes = DateRangeTypeLabelKV();
  public readonly compareTypeLabelsKV = CompareTypeLabelsKV();
  public readonly CompareType = CompareType;

  dateFromPicker: Date;
  dateToPicker: Date;

  compareDateFromPicker: Date;
  compareDateToPicker: Date;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.minDate)
    const dateRangeTypeLabelKV = DateRangeTypeLabelKV();
    this.dateRangeTypes = this.allowedDateRangeTypes.map(t => dateRangeTypeLabelKV.find(v => v.key === t));
    this.dateRangeType = !this.dateFrom || !this.dateTo ? DateRangeType.LAST_7_DAYS : null;
    this.onRange(this.dateRangeType, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.compareActive) {

    }
  }

  dateFromChange(event: MatDatepickerInputEvent<Date>) {
    if (this.maxRangeBetweenDates) {
      this.checkForMaxRangeBetweenDates(event.value, 'dateFrom');
    }

    if (this.autoSubmitCustomDate) {
      this.onSelect();
    }
  }

  dateToChange(event: MatDatepickerInputEvent<Date>) {
    if (this.maxRangeBetweenDates) {
      this.checkForMaxRangeBetweenDates(event.value, 'dateTo');
    }

    if (this.autoSubmitCustomDate) {
      this.onSelect();
    }
  }

  // If DateTo was not chosen, dateTo is equal to dateFrom
  datePickerClosed(){
    if (!this.dateToPicker) {
      this.dateTo = this.dateFrom;
      this.dateToPicker = this.dateFromPicker;
      this.onSelect();
    }
  }

  // prevent user to select range bigger than maxRangeBetweenDates
  checkForMaxRangeBetweenDates(date: Date, dateChanged: 'dateFrom' | 'dateTo') {
    const changedDateFormated = MomentDateUtils.format(date);
    const notChangedDateFormated = (dateChanged === 'dateFrom') ? MomentDateUtils.format(this.dateToPicker) : MomentDateUtils.format(this.dateFromPicker);
    const range = MomentDateUtils.getDurationInDays(changedDateFormated, notChangedDateFormated);
    if (range > this.maxRangeBetweenDates + 0.5) {
      if (dateChanged === 'dateFrom') {
        this.dateToPicker = moment(MomentDateUtils.nDaysFromDate(changedDateFormated, this.maxRangeBetweenDates)).toDate();
        this.dateTo = MomentDateUtils.nDaysFromDate(changedDateFormated, this.maxRangeBetweenDates);
      } else {
        this.dateFromPicker = moment(MomentDateUtils.nDaysFromDate(changedDateFormated, -this.maxRangeBetweenDates)).toDate();
        this.dateFrom = MomentDateUtils.nDaysFromDate(changedDateFormated, -this.maxRangeBetweenDates);
      }
    }
  }

  onSelect() {
    const dateFrom = MomentDateUtils.format(this.dateFromPicker, 'YYYY-MM-DD');
    const dateTo = MomentDateUtils.format(this.dateToPicker, 'YYYY-MM-DD');
    this.onCustomDateRangeSelected(dateFrom, dateTo);
    if (this.compareActive) {
      this.setCompareDates();
      const compareDateFrom = MomentDateUtils.format(this.compareDateFromPicker, 'YYYY-MM-DD');
      const compareDateTo = MomentDateUtils.format(this.compareDateToPicker, 'YYYY-MM-DD');
      this.compareDateRangeChanged.emit(new CompareDateRangeHolder(compareDateFrom, compareDateTo, null, this.compareType));
    }
  }

  onCustomDateRangeSelected(dateFrom, dateTo, emit = true) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    dateFrom ? this.dateFromPicker = moment(this.dateFrom).toDate() : undefined;
    dateTo ? this.dateToPicker = moment(this.dateTo).toDate() : undefined;
    this.dateRangeType = null;
    if (emit) {
      this.dateRangeChanged.emit(new DateRangeHolder(this.dateFrom, this.dateTo, this.dateRangeType));
    }
  }

  onRange(type: DateRangeType, emit = true) {
    this.dateRangeType = type;
    const datesFromRange = MomentDateUtils.getDatesFromRange(type);
    if (datesFromRange) {
      this.dateFrom = datesFromRange.dateFrom;
      this.dateTo = datesFromRange.dateTo;
    }
    this.dateFromPicker = moment(this.dateFrom).toDate();
    this.dateToPicker = moment(this.dateTo).toDate();

    if (emit && !this.compareActive) {
      this.dateRangeChanged.emit(new DateRangeHolder(this.dateFrom, this.dateTo, type));
    }
    this.setCompareDates();
  }

  goToNextDay(weeklyType?: boolean) {
    const newDateFrom = MomentDateUtils.nDaysFromDate(this.dateFrom, weeklyType ? this.dateFrom === this.dateTo ? 0 : 7 : 1, 'YYYY-MM-DD');
    const newDateTo = MomentDateUtils.nDaysFromDate(this.dateTo, 7, 'YYYY-MM-DD');

    this.onCustomDateRangeSelected(newDateFrom, weeklyType ? newDateTo : newDateFrom);
    if (this.compareActive) {
      this.setCompareDates();
      const compareDateFrom = MomentDateUtils.format(this.compareDateFromPicker, 'YYYY-MM-DD');
      const compareDateTo = MomentDateUtils.format(this.compareDateToPicker, 'YYYY-MM-DD');
      this.compareDateRangeChanged.emit(new CompareDateRangeHolder(compareDateFrom, compareDateTo, null, this.compareType));
    }
  }

  isPrevDayEnabled() {
    return !this.minDate || this.minDate < this.dateFrom;
  }

  isNextDayEnabled() {
    return !this.maxDate || this.maxDate > this.dateTo;
  }

  goToPrevDay(weeklyType?: boolean) {
    const newDateFrom = MomentDateUtils.nDaysFromDate(this.dateFrom, weeklyType ? -7 : -1, 'YYYY-MM-DD');
    const newDateTo = MomentDateUtils.nDaysFromDate(this.dateTo, this.dateFrom === this.dateTo ? 0 : -7, 'YYYY-MM-DD');

    this.onCustomDateRangeSelected(newDateFrom, weeklyType ? newDateTo : newDateFrom);
    if (this.compareActive) {
      this.setCompareDates();
      const compareDateFrom = MomentDateUtils.format(this.compareDateFromPicker, 'YYYY-MM-DD');
      const compareDateTo = MomentDateUtils.format(this.compareDateToPicker, 'YYYY-MM-DD');
      this.compareDateRangeChanged.emit(new CompareDateRangeHolder(compareDateFrom, compareDateTo, null, this.compareType));
    }
  }

  setDates(dates: DateRangeHolder) {
    if (dates.dateRangeType) {
      this.onRange(dates.dateRangeType, false);
    } else {
      this.onCustomDateRangeSelected(dates.dateFrom, dates.dateTo, false);
    }
  }

  updateCompareOption(){
    this.compareActive = !this.compareActive;
    if (this.compareActive) {
      this.setCompareDates();
    }
  }

  reset(dateRange: DateRangeHolder, compareActive?: boolean){
    this.dateFrom = dateRange.dateFrom;
    this.dateTo = dateRange.dateTo;
    this.compareActive = compareActive;
  }

  setCompareDates() {
    const dayDifference = Math.floor((this.dateToPicker as any - (this.dateFromPicker as any)) / (1000 * 60 * 60 * 24))+1;

    switch (this.compareType) {
      case CompareType.PREVIOUS:
        this.compareDateFromPicker = moment(this.dateFrom).subtract(dayDifference, 'days').toDate();
        this.compareDateToPicker = moment(this.dateTo).subtract(dayDifference, 'days').toDate();
        break;

      default:
        break;
    }
  }
}

