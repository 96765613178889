import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCaseSplitted'
})

/**
 * e.g.transforms appStoreUrl to App store URL
 */
export class TitleCaseSplittedPipe implements PipeTransform {

  transform(value: string): string {
    return value ? this.titleCaseFormat(value) : '00:00';
  }

  titleCaseFormat(string: string): string {
    const temp = string.replace(/([A-Z])/g, ' $1').trim();
    return temp.charAt(0).toUpperCase() + temp.slice(1);
  }

}
