import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {LoginService} from '../login/login.service';
import {catchError, map} from 'rxjs/operators';
import {Principal} from '../auth/principal.service';
import {UserRoleNew} from '../auth/user-role.model';

@Injectable()
export class AdvertiserResolver  {
  constructor(private authService: AuthService, private loginService: LoginService, private principal: Principal) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const user = this.principal.getUser();
    if (route.params.id && user.advertiserId && user.advertiserId.includes(route.params.id)) {
        if (user.roles.includes(UserRoleNew.ADVERTISER_READ_ONLY)) {
            this.principal.setMask(UserRoleNew.ADVERTISER_READ_ONLY, route.params.id);
        } else if (user.roles.includes(UserRoleNew.ADVERTISER)) {
            this.principal.setMask(UserRoleNew.ADVERTISER, route.params.id);
        }
    }
    return this.authService.advertiserIdentity().pipe(
      map(advertiser => advertiser),
      catchError(error => {
        this.loginService.logout(true);
        return of(null);
      })
    );
  }
}
