<mt-toolbar [pageTitle]="'Demand integrations '" *ngIf="!dpId">
</mt-toolbar>
<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col p-0 m-0">
        <mat-card>
          <mat-card-header>
            <div class="mat-card-header-search">
              <mat-form-field class="header-form-field mr-lg-n3" floatLabel="never">
                <input matInput [value]="filter.name||''" [formControl]="nameControl" placeholder="search">
                <i class="far fa-search" matSuffix></i>
              </mat-form-field>

            <div class="mat-card-header-actions mx-5">
              <div *csHasPermission="RolePermission.DEMAND_CONNECTIONS_CREATE">
                <button mat-flat-button color="primary" (click)="createIntegration()" class="mat-elevation-z0 iconed">
                  <mat-icon>add</mat-icon>
                  <span>Add new integration</span>
                </button>
              </div>
            </div>
            </div>
            <div class="mat-card-header-status">
              <mt-status-box *ngFor="let item of statusCounts" (statusClicked)="!loading && filterByStatus($event)"
                              [status]="setConnectionStatus(item)" [activeBox]="isStatusFilterSelected(item.status)">
              </mt-status-box>
            </div>
          </mat-card-header>
          <mat-card-content class="mat-card-content--full">
            <div class="data-loader" [hidden]="!loading">
              <mat-spinner [color]="'primary'" [mode]="'indeterminate'" [diameter]="30">
              </mat-spinner>
            </div>
            <div class="data-alert" [hidden]="loading || (dataSource.data && dataSource.data.length)">
              <i class="fa-regular fa-file-circle-xmark fa-2xl"></i>
            </div>
            <div class="table-scroll-wrapper" [hidden]="loading || !dataSource.data || !dataSource.data.length">
              <mat-table #table [dataSource]="dataSource" matSort matSortActive="displayName" matSortDirection="asc">

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <a *csHasPermission="RolePermission.DEMAND_CONNECTIONS_EDIT" class="link" [routerLink]="'/integrations/' + element.id + '/demand/' + element.demandPartner.id + '/details/overview'">
                      {{element.name}}
                    </a>
                    <span *csHasNoPermission="RolePermission.DEMAND_CONNECTIONS_EDIT">
                      {{element.name}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <mat-chip class="status-chip" [ngClass]="element?.status.toLowerCase()">
                      {{ConnectionStatusLabels[element.status]}}
                    </mat-chip>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dpDisplayName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header="demandPartner.displayName">DP</mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <span>{{element.demandPartner.displayName}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="placements">
                  <mat-header-cell *matHeaderCellDef>Placements</mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <button mat-button color="primary" [disabled]="element.placementCnt === 0"
                      (click)="openPlacementDialog(element)">{{element.placementCnt}}</button>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sellerDomain">
                  <mat-header-cell *matHeaderCellDef>Seller domain (ASI)</mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <span>{{element.schainAsi}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="integrationType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-content-center">Integration type</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="justify-content-center">

                    <span>{{integrationTypeLabels[element.integrationType]}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="duplication">
                  <mat-header-cell *matHeaderCellDef class="justify-content-center">Duplication</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="justify-content-center">

                    <i class="fas fa-check text-success" *ngIf="element.duplicateDemandIntegration"></i>
                    <i class="far fa-times text-muted" *ngIf="!element.duplicateDemandIntegration"></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="added">
                  <mat-header-cell *matHeaderCellDef>Added</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span [matTooltip]="element.createdOn | date: 'HH:mm' : ''">{{element.createdOn |
                      date:'MM/dd/yyyy'}}</span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="notes">
                  <mat-header-cell *matHeaderCellDef class="justify-content-center">Note</mat-header-cell>
                  <mat-cell *matCellDef="let element" class="justify-content-center">

                    <i *ngIf="element.notes" class='fal fa-info-circle text-info' matTooltip="{{element.notes}}"></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <button *csHasPermission="[RolePermission.DEMAND_CONNECTIONS_EDIT, RolePermission.DEMAND_CONNECTIONS_DUPLICATE]" mat-icon-button disableRipple="true" (click)="$event.stopPropagation()"
                      [matMenuTriggerFor]="menu">
                      <i class="far fa-ellipsis-v"></i>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item *csHasPermission="RolePermission.DEMAND_CONNECTIONS_EDIT" matTooltip="Edit" (click)="editIntegration(element)">
                        <i class="far fa-edit"></i> Edit
                      </button>
                      <button mat-menu-item *csHasPermission="RolePermission.DEMAND_CONNECTIONS_DUPLICATE" matTooltip="Duplicate" (click)="duplicateConnection(element)">
                        <i class="fal fa-copy"></i> Duplicate
                      </button>
                    </mat-menu>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
              </mat-table>
            </div>
          </mat-card-content>
          <mat-card-footer [hidden]="loading || !dataSource.data || !dataSource.filteredData.length">
            <mat-paginator #paginator [pageSize]="filter.size" [pageSizeOptions]="[5, 10, 25, 50, 100]">
            </mat-paginator>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </div>
</div>
