import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SortUtils } from '../../utils/sort-utils';
import { DemandPartner, DemandPartnerStatus, DemandPartnerStatusColorMap } from '../../domain/demand-partner.model';
import { DemandPartnersService } from '../../services/demand-partners.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-dp-autocomplete',
  template: ` <mat-form-field
    [floatLabel]="floatLabel || 'always'"
    [appearance]="formFieldAppearance ? formFieldAppearance : undefined"
    class="w-100"
    [ngClass]="formFieldClass"
  >
    <mat-label>{{label ? label : 'Choose demand partner'}}</mat-label>
    <input
      matInput
      #search
      aria-label="DemandPartner"
      [matAutocomplete]="auto"
      [formControl]="filterCtrl"
      [placeholder]="placeholder ? placeholder : 'Search'"
      [required]="required"
    />
    <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="display"
    >
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item.displayName }}
<!--        <span *csHasNetworkPermission>-->
<!--          <span *ngIf="item.network?.id" class="badge badge-primary ml-3">N</span>-->
<!--        </span>-->
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>`,
})
export class DpAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input()
  public placeholder: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  required: boolean;

  @Input()
  preselectedDemandPartnerId: DemandPartner['id'];

  @Output()
  demandPartnerSelected = new EventEmitter<DemandPartner>();

  filteredItems: Subject<DemandPartner[]>; //List of DPs to show in dropdown filtered with 'filteredDemandPartnerIds'
  filterCtrl = new UntypedFormControl();
  demandPartnerList: DemandPartner[] = []; //stores currently fetched list of dps
  selectedDemandPartner: DemandPartner; //currently selected dp
  filteredDemandPartnerIds: string[] = []; //It stores all the IDs of selected dps
  public loading = false;
  DemandPartnerStatusColorMap = DemandPartnerStatusColorMap;

  constructor(
    private readonly service: DemandPartnersService,
    private readonly snackBarService: SnackBarService
  ) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getDemandPartners(searchTerm);
          }
          else if (searchTerm == '') { //triggers when user deletes everything from input
            this.filteredItems.next(undefined);
            this.demandPartnerSelected.emit(null);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedDemandPartnerId) {
      this.getDemandPartners('', this.preselectedDemandPartnerId);
    }
  }

  /** SearchTerm used for filtering by dp displayName. PreselectedDpID used to get only one dp (preselected) at the component initialization. */
  private getDemandPartners(searchTerm?: string, preselectedDpId?: string) {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.list({
      page: 0,
      size: 1000,
      displayName: searchTerm,
      ids: [preselectedDpId],
      status: [DemandPartnerStatus.ACTIVE] // Get only active Demand Partners 
    } as any)
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedDpId ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected dp
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.demandPartnerList = resp.content.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedDpId) {
            this.selectedDemandPartner = this.demandPartnerList[0]; //Naturally since the api call is filtered with one ID, selectedDemandPartner will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedDemandPartner);
            this.updateFilteredDemandPartners([this.selectedDemandPartner]);
            this.emit();
          }
          this.filteredItems.next(this.demandPartnerList.filter(x => !this.filteredDemandPartnerIds.includes(x.id)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching demand partner autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedDemandPartner = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.demandPartnerSelected.emit(this.selectedDemandPartner);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredDemandPartnerIds. */
  public updateFilteredDemandPartners(items) {
    this.filteredDemandPartnerIds = items.map((item) => item.id);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(p?: DemandPartner) {
    return p ? p.displayName : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
