<mat-toolbar [ngClass]="backBtnRouteUrl || routerBackOr || smartBackButton ? 'with-back-btn':''">

  <mat-toolbar-row class="no-gutters mat-toolbar-row--one">

    <div *ngIf="backBtnRouteUrl || routerBackOr || smartBackButton" class="mat-toolbar-back" matTooltip="Back">
      <button mat-mini-fab class="toolbar-back-btn" type="button" (click)="navigateBack()">
        <i class="fa-regular fa-arrow-left"></i>
      </button>
    </div>

    <div *ngIf="pageTitle" class="mat-toolbar-header col">
      <span class="mat-toolbar-title">
          {{pageTitle}}
        <span *ngIf="titleDescription" class="h6 small mt-n2 text-muted mat-toolbar-subtitle">
            {{titleDescription}}
          </span>
        </span>
      <i *ngIf="titleNext" class="fa fa-chevron-right title-seperator" aria-hidden="true"></i>
      <span *ngIf="titleNext" class="mat-toolbar-title mat-toolbar-title--next">
          {{titleNext}}
        </span>
    </div>

    <div class="toolbar-info col-auto">
      <ng-content select="[toolbar-info]"></ng-content>
    </div>

    <div class="toolbar-actions col-12 mt-3 col-lg mt-lg-0">
      <ng-content select="[toolbar-actions]"></ng-content>
    </div>

    <ng-content></ng-content>

  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="toolbarSecondRow" class="mat-toolbar-row--two">
    <div class="d-none d-lg-flex w-100">
      <ng-container *ngTemplateOutlet="toolbarSecondRow ? toolbarSecondRow : secondRow"></ng-container>
    </div>
  </mat-toolbar-row>

</mat-toolbar>

<ng-template #secondRow></ng-template>
