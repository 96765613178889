import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppListRequest, Apps, ExchangeApp } from '../domain/apps.model';
import { Page } from '../domain/page.model';
import { HttpUtils } from '../utils/http-utils';
import { Platform } from '../domain/platform.model';


export interface CreateAppInventoryInput {
  name: string;
  bundleId: string;
  bundleUrl: string;
  appStoreId: string;
  appStoreUrl: string;
  adsTxtLocation: string;
  platform: Platform;
  iabCategories: string[];
  adswizzGenres: string[];
};

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private readonly api = 'api/apps';

  constructor(private httpClient: HttpClient) {
  }

  createInventoryApp(bundleApp: any): Observable<Apps> {
    return this.httpClient.post<Apps>(
      `${environment.serviceUrl}/api/apps/inventory`,
      bundleApp.app
    );
  }

  updateInventoryApp(request: any, bundleApp: any) {
    return this.httpClient.put<any>(
      `${environment.serviceUrl}/api/apps/inventory/${bundleApp.app.id}`,
      request.app
    );
  }

  updateApp(request: Apps, appId: string) {
    return this.httpClient.put<any>(
      `${environment.serviceUrl}/api/apps/inventory/${appId}`,
      request
    );
  }

  listApps(filter: AppListRequest): Observable<Page<Apps>> {
    return this.httpClient.get<Page<Apps>>(`${environment.serviceUrl}/api/apps`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getAppAdsTxtStatus(appID: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/apps/${appID}/adstxt-status`);
  }

  startAppCrawl(appID: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/apps/${appID}/adstxt-crawl`);
  }

  bundleIdList(filter: AppListRequest): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/apps/bundles`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  bundleIdPubList(filter: AppListRequest): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.serviceUrl}/api/publishers/report/bundles`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  updateBundle(request, id) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/apps/${id}`, request);
  }

  //Apps-Table
  public getApps(filter: any): Observable<Page<any>> {
    return this.httpClient.get<Page<any>>(`${environment.serviceUrl}/api/apps`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getSingleApp(id: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/apps/${id}`, {});
  }
getAppsTableStatuses(filter: any): Observable<{ [key: string]: number }> {
    return this.httpClient.get<{ [key: string]: number }>(`${environment.serviceUrl}/api/apps/count-by-status`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getAppsTableExportList(filter?: any){
    // export apps
    return this.httpClient.get(`${environment.serviceUrl}/api/apps/csv/export`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public updateAppsCSV(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = `${environment.serviceUrl}/api/apps/csv/import-inventory-apps`;
    return this.httpClient.post(url, formData);
  }

  public archiveApp(id: string) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/publisher-apps/apps/${id}/archive`, {});
  }

  public activateApp(id: string) {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/publisher-apps/apps/${id}/unarchive`, {});
  }

  //App-requests
  public getAppRequests(filter: any): Observable<Page<ExchangeApp>> {
    return this.httpClient.get<Page<ExchangeApp>>(`${environment.serviceUrl}/exchange-apps`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public processAppRequests(apps: ExchangeApp[]): Observable<Apps> {
    return this.httpClient.put<Apps>(
      `${environment.serviceUrl}/exchange-apps/bulk`, apps);
  }

  public getAppRequestExportList(filter?: any){
    // export apps
    return this.httpClient.get(`${environment.serviceUrl}/exchange-apps/csv/export`, {
      responseType: 'text',
      params: HttpUtils.getHttpParams(filter)
    });
  }
}