import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mt-status-box',
  template: `
    <span
      class="status-plate status-plate--{{ status.color }}"
      (click)="onClick()"
      [ngClass]="{ 'status-plate-active': activeBox }"
    >
      <small>{{ status.label.toLowerCase() }}</small>
      <span>{{ status.count }}</span>
      <ng-content></ng-content>
    </span>
  `,
})
export class StatusBoxComponent {
  @Input()
  public status;
  @Input()
  public activeBox = false;

  @Output()
  statusClicked = new EventEmitter();

  onClick() {
    this.statusClicked.emit(this.status.statusName);
  }
}
