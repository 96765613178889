import { KeyLabel } from '../domain/key-label.model';

export enum ActiveInactiveArchived {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export enum ActiveInactiveArchivedColorMap {
  ACTIVE = 'green',
  INACTIVE = 'red',
  ARCHIVED = 'gray',
}

export const ActiveInactiveArchivedLabels = {
  [ActiveInactiveArchived.ACTIVE]: 'Active',
  [ActiveInactiveArchived.INACTIVE]: 'Inactive',
  [ActiveInactiveArchived.ARCHIVED]: 'Archived',
};

export const ActiveInactiveArchivedOrder = Object.values(ActiveInactiveArchived);

export const ActiveInactiveArchivedKL = () =>
    (Object.keys(ActiveInactiveArchived).map(key => new KeyLabel(<ActiveInactiveArchived>key, ActiveInactiveArchivedLabels[key])));
