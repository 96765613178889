import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Channel, ChannelListRequest} from '../domain/channel.model';
import {HttpUtils} from '../utils/http-utils';
import {Page} from '../domain/page.model';
import {Playlist, PlaylistListRequest} from '../domain/playlist.model';
import {ChannelPlaylist} from '../domain/channel-playlist.model';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {

  constructor(private httpClient: HttpClient) {
  }

  getChannelById(id: string): Observable<Channel> {
    return this.httpClient.get<Channel>(`${environment.serviceUrl}/api/channels/${id}`);
  }

  list(filter?: ChannelListRequest): Observable<Page<Channel>> {
    return this.httpClient.get<Page<Channel>>(`${environment.serviceUrl}/api/channels`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  channelPlaylists(id: string, filter?: PlaylistListRequest): Observable<Page<ChannelPlaylist>> {
    return this.httpClient.get<Page<ChannelPlaylist>>(`${environment.serviceUrl}/api/channels/${id}/playlists`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  channelPlaylistsAdd(id: string, playlistIds: string[]): Observable<Page<Channel>> {
    return this.httpClient.post<Page<Channel>>(`${environment.serviceUrl}/api/channels/${id}/playlists`, {
      playlistIds
    });
  }

  create(c: Channel, imageFile: File): Observable<any> {
    const formData: FormData = new FormData();
    if (imageFile) {
      formData.append('imageFile', imageFile, imageFile.name);
    }
    formData.append('channel', JSON.stringify(c));
    return this.httpClient.post<any>(`${environment.serviceUrl}/api/channels`, formData);
  }

  update(c: Channel, imageFile: File): Observable<any> {
    const formData: FormData = new FormData();
    if (imageFile) {
      formData.append('imageFile', imageFile, imageFile.name);
    }
    formData.append('channel', JSON.stringify(c));
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/channels/` + c.id, formData);
  }

  updatePlaylistOrder(channelId: string, playlistId: string, position: number) {
    return this.httpClient.put(`${environment.serviceUrl}/api/channels/${channelId}/playlists/position`, {playlistId, position});
  }

  deletePlaylistVideos(selectedPlaylists: string[], channelId: string) {
    return this.httpClient.request('delete', `${environment.serviceUrl}/api/channels/${channelId}/playlists`,
      {body: {playlistIds : selectedPlaylists}});
  }

  updateChannelBroadcasting(channel: Channel) {
    return this.httpClient.put(`${environment.serviceUrl}/api/channels/${channel.id}/${channel.active ? 'stop' : 'start'}`, {});
  }

  provisionChannel(id: string) {
    return this.httpClient.get(`${environment.serviceUrl}/api/channels/livestream/${id}`, {});
  }
}

