import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SortUtils } from '../../utils/sort-utils';
import { Publisher, PublisherStatus, PublisherStatusColorMap } from '../../domain/publisher.model';
import {
  PublisherListFilter,
  PublishersService,
} from '../../services/publishers.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import {FloatLabelType, MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector: 'mt-publisher-autocomplete',
  template: ` <mat-form-field
    [class.disabled]="disabled"
    [floatLabel]="floatLabel || 'always'"
    class="w-100"
    [ngClass]="formFieldClass"
    [appearance]="'outline'"
  >
    <mat-label [ngStyle] ="{ 'color': disabled ? '#a8a8a8' : 'initial' }" >{{label ? label : 'Choose publisher'}}<sup *ngIf="isFieldMandatory"> *</sup></mat-label>
    <input
      matInput
      #search
      [disabled]="disabled"
      aria-label="Publisher"
      [matAutocomplete]="auto"
      [formControl]="filterCtrl"
      [placeholder]="placeholder ? placeholder : ''"
      [required]="required"
      (keydown)="!disabled"
      (click)="!disabled"
      [ngClass]="{'hide-underline': disabled }"
      [ngStyle] ="{ 'color': disabled ? '#a8a8a8' : 'initial', 'caret-color': disabled ? 'transparent' : 'initial', 
      'cursor': disabled ? 'default' : 'initial', 'border':'none' }" 
    />
    <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="display"
    >
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>`,
  styles: [`
  .disabled ::ng-deep .mat-form-field-underline {
    display: none;
  }
`],
})
export class PublisherAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();
  @Input() disabled = false;
  @Input() public placeholder: string;
  @Input() public floatLabel: FloatLabelType;
  /** Hold list of networkIds to filter pubs only by given networks.*/
  @Input() public networkIds: string[];
  @Input() public label: string;
  @Input() public formFieldClass: string;
  @Input() public formFieldAppearance: MatFormFieldAppearance;
  @Input() required: boolean;
  @Input() exchangeOnly = false;
  @Input() preselectedPublisherId: Publisher['id'];
  @Input() isFieldMandatory:boolean = false;
  @Output() publisherSelected = new EventEmitter<Publisher>();
  @Output() emptyFilteredPlacements = new EventEmitter<boolean>();

  filteredItems: Subject<Publisher[]>; //List of Pubs to show in dropdown filtered with 'filteredPublisherIds'
  filterCtrl = new UntypedFormControl();
  publisherList: Publisher[] = []; //stores currently fetched list of pubs
  selectedPublisher: Publisher; //currently selected publisher
  filteredPublisherIds: string[] = []; //It stores all the IDs of selected pubs
  public loading = false;
  PublisherStatusColorMap = PublisherStatusColorMap;

  constructor(
    private service: PublishersService,
    private snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getPublishers(searchTerm);
          }
          else if (searchTerm == '') { //triggers when user deletes everything from input
            this.filteredItems.next(undefined);
            this.publisherSelected.emit(null);
            this.emptyFilteredPlacements.emit(true);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedPublisherId) {
      this.getPublishers('', this.preselectedPublisherId);
    }
  }

  /** SearchTerm used for filtering by pub name. PreselectedPubID used to get only one publisher (preselected) at the component initialization. */
  private getPublishers(searchTerm?: string, preselectedPubId?: string) {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.listPublishers({
      page: 0,
      size: 1000,
      networkIds: this.networkIds,
      name: searchTerm,
      exchangeOnly: this.exchangeOnly,
      ids: [preselectedPubId],
      status: [PublisherStatus.ACTIVE]
    } as PublisherListFilter)
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedPubId ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected publisher
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.publisherList = resp.content.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedPubId) {
            this.selectedPublisher = this.publisherList[0]; //Naturally since the api call is filtered with one ID, selectedPublisher will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedPublisher);
            this.updateFilteredPublishers([this.selectedPublisher]);
            this.emit();
          }
          this.filteredItems.next(this.publisherList.filter(x => !this.filteredPublisherIds.includes(x.id)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching publisher autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedPublisher = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.publisherSelected.emit(this.selectedPublisher);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredPublisherIds. */
  public updateFilteredPublishers(items) {
    this.filteredPublisherIds = items.map((item) => item.id);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(p?: Publisher) {
    return p ? p.name : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
