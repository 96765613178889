import { DemandPartnerConnection } from './demand-partner-connection.model';
import { PageRequest } from './page-request';
import { PageSortRequest } from './page-sort-request.model';
import { ConnectionPlacement, Placements } from './placements.model';
import { Publisher } from './publisher.model';

export class ChangelogListRequest extends PageRequest {
  placementIds: string[];
  publisherIds: string[];
  integrationIds: string[];
  lineItemIds: string[];
  createdBy: string;
  dateFrom: string;
  categories: ChangeLogCategory[];
  publisher: Publisher;
  placement: Placements;
  integration: DemandPartnerConnection;
  dateTo: string;
}

export class Changelog{
  id: number;
  category: ChangeLogCategory;
  type: ChangeLogType;
  createdOn: Date;
  fieldName: string;
  oldValue: string;
  newValue: string;
  createdBy: string;
  context: ChangeLogContext;
  publisher: Publisher;
  placement: ConnectionPlacement;
  integration: DemandPartnerConnection;
}


export enum ChangeLogCategory {
  PLACEMENT = 'PLACEMENT',
  PUBLISHER = 'PUBLISHER',
  INTEGRATION = 'INTEGRATION',
  LINE_ITEM ='LINE_ITEM',
  PUBLISHER_DOMAIN = 'PUBLISHER_DOMAIN',
  PUBLISHER_APP = 'PUBLISHER_APP',
  APP = 'APP',
  TARGETING = 'TARGETING',
  ADSTXT = 'ADSTXT',
  DEMAND_PARTNER = 'DEMAND_PARTNER',
  DEAL = 'DEAL',
  ADVERTISER = 'ADVERTISER',
  ORDER = 'ORDER',
  CREATIVE = 'CREATIVE',
  VIDEO = 'VIDEO',
  CONTENT_OWNER = 'CONTENT_OWNER',
  WIDGET = 'WIDGET',
  USER = 'USER',
  ASSET = 'ASSET',
  BRAND = 'BRAND',
  A2V_BRAND = 'A2V_BRAND',
  PLAYLIST = 'PLAYLIST',
  A2V_CONFIGURATION = 'A2V_CONFIGURATION'
}

export const ChangeLogCategoryLabels = {
  PLACEMENT: 'Placement',
  PUBLISHER: 'Publisher',
  INTEGRATION: 'Integration',
  LINE_ITEM: 'Line item',
  PUBLISHER_DOMAIN: 'Publisher domain',
  PUBLISHER_APP: 'Publisher app',
  APP: 'App',
  TARGETING: 'Targeting',
  ADSTXT: 'AdsTxt',
  DEMAND_PARTNER: 'Demand partner',
  DEAL: 'Deal',
  ADVERTISER: 'Advertiser',
  ORDER: 'Order',
  CREATIVE: 'Creative',
  VIDEO: 'Video',
  CONTENT_OWNER: 'Content owner',
  WIDGET: 'Widget',
  USER: 'User',
  ASSET: 'Asset',
  BRAND: 'Brand',
  A2V_BRAND: 'A2V Brand',
  PLAYLIST: 'Collection',
  A2V_CONFIGURATION: 'Network template configuration'
};



enum ChangeLogType {
  CREATE, UPDATE, DELETE
}

export class ChangeLogContext {
  context: Map<string, Object>;
}

export interface IChangelogDialogData {
  placementIds?: string[];
  publisherIds?: string[];
  integrationIds?: string[];
  lineItemIds?: string[];
  createdBy?: string;
  title: string;
}


export const fieldNameMap = {
   PLACEMENT: {
      name: 'Placement',
      secondaryId: 'Alternative ID',
      publishersIds: 'Publishers Ids',
      status: 'Status',
      deliveryType: 'Delivery',
      deduplicateUniqueAdvertiserDomain: 'Deduplication unique advertiser domain',
      deduplicateUniqueIABCategory: 'Deduplication unique iab category',
      deduplicateUniqueMediaFile: 'Deduplication unique media file',
      exclusionsEnabled: 'Exclusions enabled',
      gamEnabled: 'Gam Enabled',
      poddingEnabled: 'Podding Enabled',
      stMaxBids: 'Stitcher max bids',
      stMaxAvBids: 'Stitcher max av bids',
      stMaxVideoBids: 'Stitcher max video bids',
      stMaxBidsFromCache: 'Stitcher max bids from cache',
      impressionTrackers: 'Impression trackers',
      'connections.id': 'connections.id',
      targetings: 'Targetings',
      'targetings.id': 'targetigns.id',
      cpmCapEnabled: 'CPM capping',
      stIgnoreAdDuration: 'Stitcher ignore ad duration',
      stDedupeEnabled: 'Stitcher deduplication enabled',
      stDedupeUniqueMediaFile: 'Stitcher deduplication unique media file',
      stDedupeUniqueAdvertiserDomain: 'Stitcher deduplication unique advertiser domain',
      cpmCapMin: 'Cap min',
      cpmCapMax: 'Cap max',
      cpmCapIncrement: 'Cpm Cap Increment',
      'adUnit.publisherDomains': 'Domains',
      'adUnit.widgetName': 'Widget name',
      'adUnit.devicePlacement': 'Device placement',
      position: 'Position',
      'adUnit.unitConfig.refreshRate': 'Refersh rate',
      'adUnit.unitConfig.maxRefresh': 'Max refresh',
      publishers: 'Choose Publisher',
      bidFloor: 'Bid Floor',
      qpsLimit: 'QPS limit',
      maxAdDuration: 'Default max AD duration',
      shareOfVoice: 'Share of Voice',
      lineItemMargin: 'Line Item Margin',
      debug: 'Debug mode',
      ignoreComplience: 'Ignore compliance',
      passToDemandAppData: 'Pass App Data to DP',
      cpm: 'CPM Capping',
      pricingType: 'Pricing type',
      advertiserDomains: 'Advertiser Domains list',
      iabCategoriesExcluded: 'IAB categories',
      bidCacheA2VEnabled: 'Audio2Video',
      bidCacheVideoEnabled: 'Video',
      bidCacheAlwaysUseCache: 'Always use Cache',
      stitcherEnabled: 'Stitcher',
      dualModeEnabled: 'Dual mode',
      description: 'Description',
      notes: 'Notes',
      type: 'Placement Type',
    },
    PUBLISHER: {
      name: 'Publisher',
      companyName: 'Company name',
      contactName: 'Contact name',
      email: 'Email',
      status: 'Status',
      placementsIds: 'placementsIds',
      publisherId: {
        publisherId: 'Domains',
        domainId: 'Domains',
      },
      a2v: 'A2V',
      gamAdUnitId: 'Publisher GAM ID',
      mcmActive: 'MCM',
      phone: 'Phone',
      websiteUrl: 'Website',
      address: 'Address',
      city: 'City / Town',
      state: 'State',
      zipCode: 'Zip Code',
      country: 'Country',
      'allProductsCost.costType': 'Revenue type',
      'saasCost.costType': 'Saas Fee',
      'saasCost.costValue': 'Saas Revenue share',
      whitelistAppsEnabled: 'Whitelist Apps',
      whitelistDomainsEnabled: 'Whitelist Domains',
    },
    APP: {
        name: 'App name',
        domain: 'App domain',
        bundleId: 'Bundle ID',
        appStoreUrl: 'App URL',
        publisherNames: 'Publishers',
        iabCategories: 'IAB Categories',
        description: 'Description',
        adsTxtLocation: 'AdsTxt location',
        language: 'language',
        platform: 'Platform'
    },
    LINE_ITEM: {
      name: "Line item name",
      lineItemType: "Line Item Type",
      status: "Status",
      grossBudget: "Gross budget",
      agencyFee: "Agency fee (%)",
      cpm: "CPM",
      impressionsGoal: "Impression Goal",
      domain: "Advertiser Domain",
      iabCategories: "IAB categories",
      impressionTrackers: "Impression Trackers",
      "": "Impression URL*",
      "frequencyCapping.fcActive": "Frequency capping",
      "frequencyCapping.fcLimit": "Frequency capping",
      "frequencyCapping.fcDuration": "Frequency capping",
      "frequencyCapping.fcTimeUnit": "Frequency capping",
      startDate: "Start date & time",
      endDate: "End date & time",
      dayTimeActivities: "Scheduling",
      pacing: "Pacing",
      "targeting.deviceTypes": "Targeting Device Types",
      "targeting.geoLocations": "Targeting Geo Locations",
      "targeting.xpExcluded": "Targeting extra parameters Excluded"
    },
    TARGETING: {
      name: "Targeting name",
      status: "Activity",
      deviceTypes: "Device Types",
      geoLocations: "geoLocations",
      placements: "placements",
      xpExcluded: "Extra parameters excluded",
      dvExclusionsIds: "dvExclusionsIds",
      domains: "Domains",
      xp1: "Extra parameters",
      xp2: "Extra parameters",
      xp3: "Extra parameters",
      xp4: "Extra parameters"
    },
    ADSTXT: {
      domain: "Domain",
      publisherAccountId: "Account ID",
      accountType: "Account Type",
      demandPartner: "Demand Partner",
      certAuthority: "Certification authority",
      priority: "Priority",
      comment: "Comment",
      ctv: "Record type",
      web: "Record type"
    },
    DEAL: {
      name: "Name",
      startDate: "Flight dates",
      endDate: "Flight dates",
      dealId: "Deal ID",
      wseat: "Wseat",
      auctionType: "Auction type",
      bidFloor: "Bid floor",
      wadomain: "Wadomain",
      frequencyCapping: "Frequency capping",
      targetingIds: "targetingId",
      integrationIds: "integrationId",
      status: "Status"
    },
    DEMAND_PARTNER: {
      displayName: "Demand partner name",
      bidderCode: "Bidder code",
      address: "Demand partner address",
      status: "Status",
      approvalsRequired: "Approvals required",
      revShareValue: "Cost",
      revShareGrossNet: "Reporting data *",
      biddingGrossNet: "Bidding type",
      biddingTmax: "Timeout (tmax)",
      biddingTtl: "Bid TTL",
      includeUserIdInCacheKey: "User id",
      userSyncRedirect: "Redirect URL",
      userSyncIframe: "Iframe URL",
      qps: "Limit QPS"
    },
    USER: {
      email: "Email",
      name: "Name",
      enabled: "Status",
      roles: "Roles",
      publishers: "Choose publisher"
    },
    PLAYLIST: {
      title: "Title",
      description: "Description",
      categories: "Category"
    },
    A2V_CONFIGURATION: {
      templateType: "Template type",
      qrCodeEnabled: "QR code enabled",
      logoEnabled: "Logo enabled"
    },
    INTEGRATION: {
      sellerDomain: "Seller domain",
      sellerId: "Seller ID",
      blockBidsWithoutCategories: "Block bids without categories",
      blockBidsWithoutAdomain: "Block bids without adomain",
      duplicateDemandIntegration: "Duplicate integration",
      cpm: "Fixed net CPM",
      bidFloor: "Bid floor",
      qps: "QPS limit",
      shareOfVoice: "Share Of Voice",
      publisherId: "Publisher ID",
      siteId: "Site ID",
      placementId: "Placement ID",
      appId: "App ID",
      tagId: "Tag ID",
      displayManager: "Display manager",
      tripleLftInventoryCode: "TripleLift inventory code",
      openxDelDomain: "Openx Del Domain",
      unrulyTargetingUuid: "Unruly Targeting UUID",
      openxUnitId: "Openx Unit ID",
      smartAdServerPageId: "Smart Ad Server Page ID",
      smartAdServerNetworkId: "Smart Ad Server Network ID",
      smartAdServerFormatId: "Smart Ad Server Format ID",
      gzip: "Gzip compression",
      auction: "Gzip compression",
      "targeting.id": "Gzip compression",
      "targeting.xpExcluded": "Targeting extra parameters excluded",
      "targeting.dvExclusionsIds": "Targeting extra parameters excluded",
      privateAuction: "PMP private auction",
      pmpDeals: "PMP deals",
      dupCount: "PMP deals",
      dupUseDefaultFloor: "PMP deals",
      dupMinFloor: "PMP deals",
      dupMaxFloor: "PMP deals",
      dupIncrement: "PMP deals",
      placementCnt: "PMP deals",
      "targeting.deviceTypes": "Targeting device types",
      "targeting.geoLocations": "Targeting geolocations",
      integrationType: "Integration type",
      endpointUri: "Enter your tag"
    },
    BRAND: {
      brandName: "Name",
      status: "Status",
      companyName: "Company",
      contactName: "Contact",
      phone: "Phone",
      email: "Email",
      websiteUrl: "Website",
      country: "Country",
      state: "State",
      city: "City",
      zipCode: "ZIP Code",
      address: "Address"
    },
    ORDER: {
      name: "Order Name",
      brand: "Brand",
      trafficker: "Trafficker",
      agency: "Agency",
      salesperson: "Salesperson",
      poNumber: "PO number",
      status: "Status"
    },
    ADVERTISER: {
      name: "Advertiser name",
      status: "Status"
    },
    CONTENT_OWNER: {
      name: "Name",
      tags: "Custom tag",
      categories: "Custom category"
    },
    WIDGET: {
      enabled: "Enabled",
      fixedSize: "Fixed size",
      name: "Name",
      displayName: "Display name",
      width: "Width",
      height: "Height",
      adWidth: "Ad Width",
      adHeight: "Ad Height",
      responsive: "Responsive"
    },
    VIDEO: {
      title: "Title",
      description: "Description",
      ownerType: "Owner type"
    },
    CREATIVE: {
      name: "Name",
      advertiser: "Advertiser",
      status: "Status"
    },
    A2V_BRAND: {
      adomains: "Advertiser domains",
      name: "Brand name"
    },
    ASSET: {
      name: "Name",
      type: "Type",
      tags: "Tags"
    },
    PUBLISHER_APP: {
      "app.name": "App name",
      "app.storeUrls": "App store Urls"
    },
    PUBLISHER_DOMAIN: {
      "domain.url": "Domain Url",
      "domain.status": "Status"
    }
};
