import { HttpErrorResponse } from '@angular/common/http';
import {SnackBarService} from '../../core/services/snackbar.service';

export class ServerErrorUtils {

  static getValidationMessages(response: HttpErrorResponse): string[] {

    if (response && response.error && response.error.error && ['2005', '2001', '2008', '2003'].includes(response.error.error.code)) {
      return response.error.error.messages;
    }
    return null;
  }

  static serverValidationOrMsg(e: HttpErrorResponse, sbs: SnackBarService, defaultMsg: string) {
    const messages = ServerErrorUtils.getValidationMessages(e);
    if (messages) {
      messages.forEach(m => sbs.error(m));
    } else {
      sbs.error(defaultMsg);
    }
  }

}
