import {Injectable} from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {CsTableColumn} from './cs-table-column.model';
import {SummedMasterReport} from '../../domain/rb/summed-master-report.model';
import {ReportMetric, ReportMetricFormat, ReportMetricsAll} from '../../domain/rb/report-metric.enum';
import {Utils} from '../../utils/utils';
import {MomentDateUtils} from '../../utils/moment-date-utils';
import {CostTypeLabels, CpmTypeLabels} from '../../domain/publisher.model';

@Injectable()
export class CsTableColumnCommonService {

  private metrics: { [key: string]: CsTableColumn } = {};

  constructor(private decimalPipe: DecimalPipe,
              private currencyPipe: CurrencyPipe,
              private datePipe: DatePipe) {
    ReportMetricsAll().forEach(m => {
      this.metrics[m.key] = <CsTableColumn>{
        key: m.field,
        header: m.label,
        sortable: true,
        description: m.description,
        cell: (row: SummedMasterReport, field?: string) => {
          const value = row[field || m.field];
          if (value === null || typeof value === 'undefined') {
            switch (m.format) {
              case ReportMetricFormat.DURATION:
              case ReportMetricFormat.NUMBER: {
                return 0;
              }
              case ReportMetricFormat.PERCENTAGE:
              case ReportMetricFormat.PERCENTAGE_PLAIN: {
                return '-';
              }
              case ReportMetricFormat.CURRENCY: {
                return '-';
              }
              default: {
                return '-';
              }
            }
          }
          switch (m.format) {
            case ReportMetricFormat.PERCENTAGE: {
              return decimalPipe.transform(value * 100, '1.2-2') + '%';
            }
            case ReportMetricFormat.PERCENTAGE_PLAIN: {
              return decimalPipe.transform(value, '1.2-2') + '%';
            }
            case ReportMetricFormat.DECIMAL: {
              return decimalPipe.transform(value, '1.2-2');
            }
            case ReportMetricFormat.NUMBER: {
              return decimalPipe.transform(value, '1.0-0');
            }
            case ReportMetricFormat.DATE_TIME: {
              return MomentDateUtils.format(value, 'MM/DD/YYYY');
            }
            case ReportMetricFormat.CURRENCY: {
              return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
            }
            case ReportMetricFormat.DURATION: {
              return Utils.getTimeFromSeconds(value);
            }
            case ReportMetricFormat.BID_FLOOR: {
              return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-4');
            }
            default:
              switch (m.key) {
                case ReportMetric.PUBLISHER_NAME:
                  return `${value} ${row.publisherNetwork ? '' : ''}`;
                case ReportMetric.NETWORK_SAAS_METRIC:
                  return CpmTypeLabels[value];
                case ReportMetric.NETWORK_SAAS_TYPE:
                  return CostTypeLabels[value];
                default:
                  return value;
              }
          }
        }
      };
    });
  }

  metric(metric: ReportMetric) {
    return this.metrics[metric];
  }

  getAllForReportMetrics(): CsTableColumn[] {
    return Object.keys(this.metrics).map(key => this.metrics[key]);
  }
}
