import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { AdServerTypeMacros } from '../../domain/placements.model';
import { PlacementsService } from '../../services/placements.service';

class endpointMacro {
  id: string;
  macro: string;
}
@Component({
  selector: 'mt-endpoint-generator-macro-dialog',
  templateUrl: './endpoint-generator-macro-dialog.component.html',
  styleUrls: ['./endpoint-generator-macro-dialog.component.scss']
})
export class EndpointGeneratorMacroDialogComponent {

  public macrosList = [];
  private splittedEndpointMacros: string[] = [];
  private domain: string;
  public loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: PlacementsService,
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<EndpointGeneratorMacroDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {endpoint: string; dropdownMacroList: AdServerTypeMacros[];
        bidderCode: string;}) // if bidderCode null, then we dont have autoReplace button (used on connection VAST type edit with macros)
        {
        this.data.dropdownMacroList = this.data.dropdownMacroList.sort((a, b) => (a.value > b.value) ? 1 : -1);
        dialogRef.disableClose = true;
        this.detectParameters();
  }

  public form = new UntypedFormGroup({
    macrosArray: new UntypedFormArray([])
  });

  public autoReplace(){
    this.loading = true;
    this.service.generateKmExchangeTag(this.data.endpoint, this.data.bidderCode).subscribe(
      (response) => {
        this.data.endpoint = response;
        this.clearParameters();
        this.detectParameters();
      },
      (error) => {
        this.snackBarService.error('Error while parsing macro to Exchange tag');
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  private detectParameters(): void {
    if (!this.data.endpoint) return;

    this.domain = this.data.endpoint.split('?')[0];
    const urlParamsString = this.data.endpoint.split('?')[1];

    if (!urlParamsString) return;

    this.splittedEndpointMacros = urlParamsString.split('&');

    this.splittedEndpointMacros.forEach(urlPair => {
      const urlParamItem = urlPair.split('=');

      this.getMacrosArray.push(this.createMacrosFormControl({
        id: urlParamItem[0],
        macro: urlParamItem[1]
        }));
    });
  }

  private clearParameters() {
    this.domain = null;
    this.splittedEndpointMacros = [];
    this.getMacrosArray.clear();
  }

  public macroInputChanged(control: UntypedFormControl) {
    const index = this.splittedEndpointMacros.indexOf(this.splittedEndpointMacros.find(x => x.split('=')[0] == control.value.id));
    if (index !== -1) {
      this.splittedEndpointMacros[index] = control.value.id + '=' + control.value.macro;
    }
    this.data.endpoint = this.domain + '?' + this.splittedEndpointMacros.join('&');
  }

  public macroSelectionChanged(control: UntypedFormControl, macro: AdServerTypeMacros) {
    const index = this.splittedEndpointMacros.indexOf(this.splittedEndpointMacros.find(x => x.split('=')[0] == control.value.id));
    if (index !== -1) {
      this.splittedEndpointMacros[index] = control.value.id + '=' + macro.value;
    }
    this.data.endpoint = this.domain + '?' + this.splittedEndpointMacros.join('&');

    this.getMacrosArray.controls[index].patchValue({id: control.value.id, macro: macro.value});
  }

  public deleteMacro(control: UntypedFormControl) {
    const index = this.splittedEndpointMacros.indexOf(this.splittedEndpointMacros.find(x => x.split('=')[0] == control.value.id));
    if (index !== -1) {
      this.splittedEndpointMacros.splice(index,1);
    }
    this.data.endpoint = this.domain + '?' + this.splittedEndpointMacros.join('&');

    this.getMacrosArray.removeAt(index);
  }

  private createMacrosFormControl(macroData: endpointMacro): UntypedFormGroup {
    return this.formBuilder.group({id: macroData.id, macro: macroData.macro});
  }

  public get getMacrosArray(): UntypedFormArray {
    return this.form.get('macrosArray') as UntypedFormArray;
  }

  public save(){
    this.dialogRef.close({event:'Apply', data: this.data.endpoint});
  }

}
