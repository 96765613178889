import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {Embed} from '../../domain/embed.model';
import { VideosService } from '../../services/videos.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-artists-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label >{{label ? label : 'Choose artists'}}</mat-label>
      <input matInput
             #name
             autocomplete="off"
             (focus)="onFocusEvent()"
             aria-label="Artists"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class ArtistsAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public name: string;

  @Input()
  public activeArtists: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public availableArtists: string[];

  @Input()
  items: string[];

  @Output()
  artistSelected = new EventEmitter<string>();

  serverSearching = false;

  filteredItems: Subject<string[]>;
  filterCtrl = new UntypedFormControl();
  selectedArtist;
  allArtists: string[] = [];
  filteredArtists: string[] = [];
  availableArtistsFlag = false;

  constructor(private service: VideosService, private snackBarService: SnackBarService,) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedArtist) {
          this.selectedArtist = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.searchArtist();
        }
      });
  }

  resetInput() {
    this.selectedArtist = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.name) {
      this.selectedArtist = this.name;
      this.filterCtrl.setValue(this.name);
      this.emit();
    }
    this.filteredArtists = this.activeArtists;
  }

  onFocusEvent() {
    if (this.availableArtistsFlag) {
      this.allArtists = this.availableArtists;
    } else {
      if (!this.allArtists.length) {
        this.getAllTallArtists();
        return;
      }
    }
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(this.allArtists.filter(item => !this.filteredArtists.includes(item)));
    }
  }

  display(e?: Embed) {
    return e ? e.name : undefined;
  }

  optionSelected($event) {
    this.selectedArtist = $event.option.value;
    this.filteredItems.next([]);
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.artistSelected.emit(this.selectedArtist);
  }

  getAllTallArtists() {
    this.service.artistsList().subscribe((response) => {
      this.allArtists = response;
      if (!this.filterCtrl.value?.length) {
        this.filteredItems.next(this.allArtists.filter(item => !this.filteredArtists.includes(item)));
      }
    }, e => {
      this.snackBarService.error('Error occurred during getting artists');
    });
  }

  updateFilteredArtists(items) {
    this.filteredArtists = items.map(item => item);
  }

  searchArtist() {
    if (this.allArtists.length > 0) {
      const search = this.allArtists.filter(e => e.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(search.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }
}
