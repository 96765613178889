import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {User} from '../../../../../core/auth/user.model';
import {Subscription} from 'rxjs';
import {UserRole, UserRoleLabels} from '../../../../../core/auth/user-role.model';
import {ContentOwnerService} from '../../../../../shared/services/content-owner.service';
import {ActivatedRoute} from '@angular/router';
import {SnackBarService} from '../../../../../core/services/snackbar.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ServerErrorUtils} from '../../../../../shared/utils/server-error-utils';
import {PublishersService} from '../../../../../shared/services/publishers.service';
import {UserEditorDialogComponent} from '../../../../users/user-editor/user-editor-dialog.component';
import {GlobalSpinnerService} from '../../../../../core/services/global-spinner.service';
import {UsersService} from '../../../../../shared/services/users.service';

@Component({
  selector: 'mt-users-shared-table',
  templateUrl: './users-shared-table.component.html',
  styleUrls: ['./users-shared-table.component.scss']
})
export class UsersSharedTableComponent implements AfterViewInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input()
  usersType: {id: string; type: string};

  columns = ['name', 'username', 'roles', 'enabled', 'lastLoginTime', 'createdOn', 'actions'];

  dataSource = new MatTableDataSource<User>();

  loading = false;
  coId: string;
  filter = {
    page: 0,
    size: 20,
    sortProperty: null,
    sortDirection: null,
  };
  sbs: Subscription;
  userRoleLabels = UserRoleLabels;

  constructor(private service: ContentOwnerService,
              private route: ActivatedRoute,
              private snackBarService: SnackBarService,
              private matDialog: MatDialog,
              private publisherService: PublishersService,
              private gss: GlobalSpinnerService,
              private usersService: UsersService) {
    this.coId = route.snapshot.parent.data.coId;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.refreshData();
  }

  refreshData() {
    let filter = {};
    if (this.usersType) {
      filter = this.usersType.type === 'publisher' ? {publisherId: this.usersType.id} : {coId: this.usersType.id};
    }
    this.loading = true;
    this.sbs = this.usersService.list(filter).subscribe(
      response => {
        this.loading = false;
        this.dataSource.data = response;
      },
      error => {
        this.loading = false;
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error during while fetching users');
        }
      }
    );
  }

  searchUser(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setUserRolesLabel(roles: UserRole[]) {
    return roles.map(r => this.userRoleLabels[r]).join(', ');
  }

  addNewUser() {
    this.matDialog.open(UserEditorDialogComponent, {data: {userType: this.usersType} as MatDialogConfig
    }).afterClosed().subscribe(next => {
      if (next) {
        this.refreshData();
      }
    });
  }

  editUser(editObject) {
    this.matDialog.open(UserEditorDialogComponent, {data: {user: Object.assign({}, editObject),
        userType: {type: 'co', id: this.coId}} as MatDialogConfig
    }).afterClosed().subscribe(next => {
      if (next) {
        this.refreshData();
      }
    });
  }

  updateUserStatus(username: string, status: string) {
    this.gss.showLoader();
    this.usersService.updateUserStatus(username, status ? 'enable' : 'disable').subscribe(
      response => {
        this.gss.hideLoader();
        this.snackBarService.success(`You have successfully ${status ? 'enable' : 'disable'} user`);
        this.refreshData();
      },
      error => {
        this.gss.hideLoader();
        ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error occurred while updating user status');
      }
    );
  }
}
