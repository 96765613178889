<div mat-dialog-title>
  <span>Edit selected videos</span>
</div>

<div mat-dialog-content>
  <div class="d-flex flex-column">
    <mt-image-upload-preview label="upload thumbnail"
                              (fileSelected)="thumbnailFile = $event"
                              (fileCanceled)="removeThumbNailImage()"
                              [fileName]="false"
                              [resolutonGreater]="true"
                              [allowedFileTypes]="'image/png, image/jpeg'"
                              [tooltip]="'PNG or JPG, 480x360'"
                              [resolutionWidth]="'480'"
                              [resolutionHeight]="'360'"
                              [imagePreview]="thumbnailFile"></mt-image-upload-preview>
  </div>

  <div class="d-flex flex-column">
    <label>Owner type</label>
    <mat-radio-group name="ownerType" [(ngModel)]="type" class="mb-3" (change)="coId = null">
      <mat-radio-button color="primary" class="mr-3" *ngFor="let ownerType of ownerTypeKL" value="{{ownerType.key}}">{{ownerType.label}}</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- <mt-publisher-autocomplete (publisherSelected)="ownerSelected($event)" *ngIf="type === ownerType.PUBLISHER"></mt-publisher-autocomplete> -->
  <mt-content-owner-autocomplete (contentOwnerSelected)="ownerSelected($event)" *ngIf="type === ownerType.CONTENT_OWNER"></mt-content-owner-autocomplete>

  <div class="d-flex flex-column">
    <label>Metadata</label>

    <div class="tags-wrapper">
      <h6><b>Tags</b></h6>
      <mat-form-field class="w-100 mt-1">
        <mat-label>Enter custom tag</mat-label>
        <input matInput
              #tagInput
              type="text"
              autocomplete="off"
              (keydown.enter)="addTag(tagInput.value)"
              [(ngModel)]="metaTag"
              aria-label="tag"
              placeholder="Press enter to add a new value">
      </mat-form-field>
      <div>
        <mat-chip-list>
          <mat-basic-chip *ngFor="let item of tags"
                          [removable]="true"
                          (removed)="removeTag(item)">
              {{item}}
            <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
          </mat-basic-chip>
        </mat-chip-list>
      </div>
    </div>

    <mat-divider class="mb-3 mt-3"></mat-divider>

    <div class="genre-wrapper">
      <h6><b>Genre</b></h6>
      <mat-form-field class="w-100 mt-1">
        <mat-label>Enter custom genre</mat-label>
        <input matInput
              type="text"
              #genreInput
              autocomplete="off"
              (keydown.enter)="addGenre(genreInput.value)"
              [(ngModel)]="metaGenre"
              aria-label="Genre"
              placeholder="Press enter to add a new value">
      </mat-form-field>
      <div>
        <mat-chip-list>
          <mat-basic-chip *ngFor="let item of genres"
                          [removable]="true"
                          (removed)="removeGenre(item)">
              {{item}}
            <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
          </mat-basic-chip>
        </mat-chip-list>
      </div>
    </div>

    <mat-divider class="mb-3 mt-3"></mat-divider>

    <div class="flags-wrapper">
      <h6><b>Flags</b></h6>
      <mt-flags-autocomplete-chips #autoFlag
        [activeFlags]="flags"
        (flagSelectionChange)="filterFlagsChange($event)">
      </mt-flags-autocomplete-chips>
    </div>

    <mat-divider class="mb-3 mt-3"></mat-divider>

    <div class="video-info-wrapper">
      <h6><b>Info</b></h6>
      <mat-form-field class="w-100" floatLabel="always" color="primary">
        <mat-label>Artist</mat-label>
        <input name="artist" matInput [(ngModel)]="artist" autocomplete="off" />
      </mat-form-field>

      <mat-form-field class="w-50 d-block">
        <input matInput [max]="today"
               (dateChange)="dateChange($event)"
               [matDatepicker]="datePublishdAtRef"
               [(ngModel)]="publishedAt"
               placeholder="Published At">
        <mat-datepicker-toggle matSuffix [for]="datePublishdAtRef"></mat-datepicker-toggle>
        <mat-datepicker #datePublishdAtRef></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button class="" [mat-dialog-close]="false">
    Cancel
  </button>
  <!-- TODO: check if hasPermission should get CONTENT_VIDEOS_INGEST or CONTENT_VIDEOS_UPDATE -->
  <button mat-flat-button color="primary" *csHasPermission="RolePermission.VIDEOS_CREATE"
          [disabled]="!coId && type !== ownerType.KM"
          (click)="addOwnerToVideo()">
    <span>Save</span>
  </button>
</div>
