import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { merge } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';
import { MasterTableAction } from '../../domain/master-table/master-table-action';
import { MasterTableColumn, MasterTableDataType } from '../../domain/master-table/master-table-column';
import { OutputPropEvent } from '../../domain/master-table/master-table-output';
import { ActiveArchivedColorMap } from '../../enums/active-archived.enum';
export interface ArchiveActivateActions {
  name: String;
  icon?: string;
  click(param?: any): void;
}

@Component({
  selector: 'mt-master-table',
  templateUrl: './master-table.component.html',
  styleUrls: ['./master-table.component.scss'],
})
export class MasterTableComponent implements OnInit, AfterViewInit, OnChanges {
  public readonly RolePermission = RolePermission;
  dataSource = new MatTableDataSource([]);
  displayColumns: string[];
  searchControl = new UntypedFormControl('');

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  @Input() loading = false;
  @Input() columns: MasterTableColumn[] = [];
  @Input() actions: MasterTableAction[] = [];
  @Input() activate: ArchiveActivateActions;
  @Input() archive: ArchiveActivateActions;
  @Input() data: [] = [];
  @Input() initialState: boolean;
  @Input() title = '';

  @Output() onTableChange = new EventEmitter<OutputPropEvent>();

  public readonly pubDomainsStatusColorMap = ActiveArchivedColorMap;


  public get MasterTableDataType(): typeof MasterTableDataType {
    return MasterTableDataType;
  }

  constructor() {}

  ngOnInit(): void {
    this.displayColumns = this.columns.map((col) => col.dataKey);
    if (this.actions.length) {this.displayColumns.push('actions');}
  }

  ngAfterViewInit(): void {
    merge(
      this.paginator.page,
      this.sort.sortChange,
      this.searchControl.valueChanges.pipe(
        debounceTime(800),
        distinctUntilChanged()
      )
    ).subscribe(() => {
      this.onTableChange.emit({
        page: this.paginator,
        sort: this.sort,
        search: this.searchControl.value,
      });
    });
  }

  ngOnChanges(changes) {
    if (changes.data && changes.data.currentValue.content)
      {this.setDataSource(changes.data.currentValue);}
  }

  setDataSource(data): void {
    this.dataSource = new MatTableDataSource<any>(data.content);
    this.paginator.pageIndex = data.pageable.pageNumber;
    this.paginator.pageSize = data.pageable.pageSize;
    this.paginator.length = data.totalElements;
  }
}
