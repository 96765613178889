import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Changelog, ChangelogListRequest} from '../domain/changelog.model';
import { Page } from '../domain/page.model';
import { HttpUtils } from '../utils/http-utils';

@Injectable({
  providedIn: 'root',
})

export class ChangelogService{

  constructor(private httpClient: HttpClient) {
  }

  getChangelogs(filter?: any): Observable<Page<Changelog>> {
    return this.httpClient.get<Page<Changelog>>(`${environment.serviceUrl}/api/change-log`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  getChangelogExportList(filter?: ChangelogListRequest): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/change-log/export`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }
}
