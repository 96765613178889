import {Observable, throwError as observableThrowError} from 'rxjs';

import {tap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {LoginService} from '../login/login.service';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,
              private location: Location) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not instance authService in construction due to circular reference error
    const authService = this.injector.get(AuthService);
    const loginService = this.injector.get(LoginService);
    const token = authService.getToken();
    const url = request.url.split('/');
    const doRegex = environment.cdnUrl && environment.cdnUrl.indexOf('//') >= 0 ? new RegExp(environment.cdnUrl.split("//")[1].replace('.', '\.')) : null;
    if (!doRegex || !doRegex.test(request.url)) {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      } else {
        loginService.logout(true);
      }
    }
    return next.handle(request).pipe(tap(event => {
        // all good
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            const path = this.location.path(false);
            if (path !== '/login' && path !== '/' && path !== '') {
              loginService.logoutWithReturnUrl(path);
            } else {
              loginService.logout(true);
            }
            return observableThrowError(err);
          }
        }
      }
    ));
  }
}
