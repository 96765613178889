import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {Principal} from "../auth/principal.service";
import {SnackBarService} from "../services/snackbar.service";
import {GlobalSpinnerService} from "../services/global-spinner.service";
import {ResetPasswordService} from "../../shared/services/reset-password.service";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'mt-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ForgotPasswordComponent implements OnInit {

    userEmail: string = '';
    logoUrl: string = environment.logoUrl;

    constructor(private forgotPasswordService: ResetPasswordService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private principal: Principal,
                private snackBarService: SnackBarService,
                private globalSpinnerService: GlobalSpinnerService) {}

    ngOnInit() {
    }

    public sendEmailToResetPassword() {
        this.globalSpinnerService.showLoader();
        this.forgotPasswordService.sendEmailToResetPassword(this.userEmail).subscribe((data) => {
            this.globalSpinnerService.hideLoader();
            this.snackBarService.success("Check your email");
        }, (err) => {
            console.log(err.error);
            this.globalSpinnerService.hideLoader();
            this.snackBarService.error(err.error.error.messages[0]);
        });
    }

    public isEmailEmpty(): boolean {
        return this.userEmail.trim() === '';
    }
}