export interface MasterTableColumn {
  name: string;
  dataKey: string;
  dataType: MasterTableDataType;
  sortable?: boolean;
}

export enum MasterTableDataType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  CURRENCY = 'CURRENCY',
  BOOLEAN = 'BOOLEAN',

  STATUS = 'STATUS'
}
