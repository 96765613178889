import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {Channel, ChannelListRequest} from '../../domain/channel.model';
import {Principal} from '../../../core/auth/principal.service';
import {SortUtils} from '../../utils/sort-utils';
import {ChannelsService} from '../../services/channels.service';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-channels-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass">
      <mat-label >{{label ? label : 'Channel'}}</mat-label>
      <input matInput
             #name
             aria-label="Channel"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="{{placeholder ? placeholder : ''}}">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.title}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class ChannelsAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public channelId: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Output()
  channelSelected = new EventEmitter<Channel>();

  serverSearching = false;

  filteredItems: Subject<Channel[]>;
  filterCtrl = new UntypedFormControl();
  selectedChannel;

  constructor(private service: ChannelsService, private principal: Principal) {
    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedChannel) {
          this.selectedChannel = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.service.list({
            page: 0,
            size: 10,
            title: next
          } as ChannelListRequest).subscribe(resp => {
            const pubs = resp.content;
            this.filteredItems.next(pubs.sort(SortUtils.propertyComparatorString('title')).splice(0, 10));
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          }, () => {
            this.filterCtrl.enable();
            this.nameFilter.nativeElement.focus();
            this.serverSearching = false;
          });
        }
      });
  }

  resetInput() {
    this.selectedChannel = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.channelId) {
      this.service.getChannelById(this.channelId).subscribe(next => {
        if (next) {
          this.selectedChannel = next;
          this.filterCtrl.setValue(next);
          this.emit();
        }
      });
    }
  }

  display(p?: Channel) {
    return p ? p.title : undefined;
  }

  optionSelected($event) {
    this.selectedChannel = $event.option.value;
    this.filteredItems.next([]);
    this.emit();
  }

  private emit() {
    this.channelSelected.emit(this.selectedChannel);
  }
}
