import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import {Embed} from '../../domain/embed.model';
import {EmbedsService} from '../../services/embeds.service';
import { DemandPartner } from '../../domain/demand-partner.model';
import { Video } from '../../domain/video.model';
import { VideosService } from '../../services/videos.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-flags-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" appearance="outline">
      <mat-label >{{label ? label : 'Choose flag'}}</mat-label>
      <input matInput
             #name
             autocomplete="off"
             (focus)="onFocusEvent()"
             aria-label="Flag"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>`,
})
export class FlagAutocompleteComponent implements OnInit {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public name: string;

  @Input()
  public activeFlags: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public availableFlags: string[];

  @Input()
  items: string[];

  @Output()
  flagSelected = new EventEmitter<string>();

  serverSearching = false;

  filteredItems: Subject<string[]>;
  filterCtrl = new UntypedFormControl();
  selectedFlag;
  allFlags: string[] = [];
  filteredFlags: string[] = [];
  availableFlagsFlag = false;

  constructor(private service: VideosService, private snackBarService: SnackBarService,) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedFlag) {
          this.selectedFlag = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.searchDp();
        }
      });
  }

  resetInput() {
    this.selectedFlag = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.name) {
      this.selectedFlag = this.name;
      this.filterCtrl.setValue(this.name);
      this.emit();
    }
    this.filteredFlags = this.activeFlags;
  }

  onFocusEvent() {
    if (this.availableFlagsFlag) {
      this.allFlags = this.availableFlags;
    } else {
      if (!this.allFlags.length) {
        this.getAllFlags();
        return;
      }
    }
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(this.allFlags.filter(item => !this.filteredFlags.includes(item)));
    }
  }

  display(e?: Embed) {
    return e ? e.name : undefined;
  }

  optionSelected($event) {
    this.selectedFlag = $event.option.value;
    this.filteredItems.next([]);
    this.nameFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.flagSelected.emit(this.selectedFlag);
  }

  getAllFlags() {
    this.service.flagsList().subscribe((response) => {
      this.allFlags = response;
      if (!this.filterCtrl.value?.length) {
        this.filteredItems.next(this.allFlags.filter(item => !this.filteredFlags.includes(item)));
      }
    }, e => {
      this.snackBarService.error('Error occurred during getting flags');
    });
  }

  updateFilteredFlags(items) {
    this.filteredFlags = items.map(item => item);
  }

  searchDp() {
    if (this.allFlags.length > 0) {
      const search = this.allFlags.filter(e => e.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(search.sort(SortUtils.propertyComparatorString('name')).splice(0, 10));
    }

    this.filterCtrl.enable();
    this.nameFilter.nativeElement.focus();
    this.serverSearching = false;
  }
}
