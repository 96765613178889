import {Component, ElementRef, ViewChild, Input, OnChanges} from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {KeyValue} from '../../../../../shared/domain/key-value.model';
import {PubDashChartType} from '../../pub-dash-chart-type.enum';

import * as Highcharts from 'highcharts';
import {DurationPipe} from '../../../../../shared/components/pipes/duration.pipe';
import {DashboardResponse} from "../../../../../shared/services/dashboard.service";
import {ReportMetric, ReportMetricDetails, ReportMetricsAll} from "../../../../../shared/domain/rb/report-metric.enum";
import {MomentDateUtils} from "../../../../../shared/utils/moment-date-utils";
import {SummedMasterReport} from "../../../../../shared/domain/rb/summed-master-report.model";


@Component({
    selector: 'mt-pub-dashboard-domains-chart',
    templateUrl: './pub-dashboard-domains-chart.component.html',
    styleUrls: ['./pub-dashboard-domains-chart.component.scss']
})
export class PubDashboardDomainsChartComponent implements OnChanges {
    @ViewChild('chart', {static: true}) chartEl: ElementRef;

    // key is date and value is value for particular date
    @Input() set chartData(value: DashboardResponse) {
        if (value?.days.length > 0) {
            this.renderChart(value.days);
            this.showChart = true;
        } else {
            this.showChart = false;
        }
    };

    @Input() chartType: PubDashChartType;
    @Input() loading: boolean;

    @Input()
    metrics: ReportMetric[];

    reportMetricDetails: ReportMetricDetails[] = [];

    showChart = false; // hide chart if chart has no data

    constructor() {
    }

    ngOnChanges() {
        this.reportMetricDetails = ReportMetricsAll().filter(m => this.metrics.includes(m.key));
        // this.loadChartData();
    }

    renderChart(dataReport: SummedMasterReport[]) {
        const self = this;

        const opts: any = {
            chart: {
                type: 'spline',
                zoomType: 'xy',
                height: 472,
                animation: false
            },

            title: {
                text: ''
            },

            yAxis: this.reportMetricDetails.map(details => {
                return {
                    title: {
                        text: details.label,
                    },
                    opposite: false
                };
            }),

            tooltip: {
                shared: true,
                animation: false
            },

            xAxis: {
                crosshair: true,
                categories: dataReport.map(d => MomentDateUtils.format(d.day, 'MM/DD/YYYY')),
                labels: {
                    step: dataReport.length > 150 ? 10 : dataReport.length > 50 ? 5 : dataReport.length > 20 ? 2 : 1,
                    rotation: -45
                }
            },

            series: this.reportMetricDetails.map((details, index) => {
                const settings = {
                    name: details.label,
                    yAxis: index,
                    lineWidth: 1.5,
                    marker: {
                        enabled: false
                    }
                };
                switch (details.key) {
                    default:
                        Object.assign(settings, {
                            data: dataReport.map(item => item[details.field]),
                        });
                }
                switch (details.key) {
                    case ReportMetric.PROFIT:
                    case ReportMetric.REVENUE:
                    case ReportMetric.PUB_REVENUE:
                        Object.assign(settings, {
                            tooltip: {
                                valueDecimals: 2,
                                valuePrefix: '$'
                            },
                        });
                        break;
                }
                return settings;
            })
        };

        Highcharts.chart(this.chartEl.nativeElement, opts);
    }

}
