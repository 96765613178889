<div mat-dialog-title>
  <span>Metadata</span>
</div>
<div mat-dialog-content>
  <div class="tags-wrapper">
    <h6><b>Tags</b></h6>
    <mat-form-field class="w-100 mt-1">
      <mat-label>Enter custom tag</mat-label>
      <input matInput
            #tagInput
            type="text"
            autocomplete="off"
            (keydown.enter)="addTag(tagInput.value)"
            [(ngModel)]="metaTag"
            aria-label="tag"
            placeholder="Press enter to add a new value">
    </mat-form-field>
    <div>
      <mat-chip-list [hidden]="video.tags?.length < 1">
        <mat-basic-chip *ngFor="let item of video.tags"
                        [removable]="true"
                        (removed)="removeTag(item)">
            {{item}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  </div>

  <mat-divider class="mb-3 mt-3"></mat-divider>

  <div class="genre-wrapper">
    <h6><b>Genre</b></h6>
    <mat-form-field class="w-100 mt-1">
      <mat-label>Enter custom genre</mat-label>
      <input matInput
            type="text"
            #genreInput
            autocomplete="off"
            (keydown.enter)="addGenre(genreInput.value)"
            [(ngModel)]="metaGenre"
            aria-label="Genre"
            placeholder="Press enter to add a new value">
    </mat-form-field>
    <div>
      <mat-chip-list [hidden]="video.genres?.length < 1">
        <mat-basic-chip *ngFor="let item of video.genres"
                        [removable]="true"
                        (removed)="removeGenre(item)">
            {{item}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  </div>

  <mat-divider class="mb-3 mt-3"></mat-divider>

  <div class="flags-wrapper">
    <h6><b>Flags</b></h6>
    <mt-flags-autocomplete-chips #autoFlag
      [activeFlags]="video.flags"
      (flagSelectionChange)="filterFlagsChange($event)">
    </mt-flags-autocomplete-chips>
  </div>

  <mat-divider class="mb-3 mt-3"></mat-divider>

  <div class="video-info-wrapper">
    <h6><b>Info</b></h6>
    <mat-form-field class="w-100" floatLabel="always" color="primary">
      <mat-label>Artist</mat-label>
      <input name="artist" matInput [(ngModel)]="video.artist" autocomplete="off" />
    </mat-form-field>

    <mat-form-field class="w-50 d-block">
      <input matInput [max]="today"
             (dateChange)="dateChange($event)"
             [matDatepicker]="datePublishdAtRef"
             [(ngModel)]="video.publishedAt"
             placeholder="Published At">
      <mat-datepicker-toggle matSuffix [for]="datePublishdAtRef"></mat-datepicker-toggle>
      <mat-datepicker #datePublishdAtRef></mat-datepicker>
    </mat-form-field>

    <!-- <h6><b>Owner type</b></h6>
    <mat-radio-group name="ownerType" [(ngModel)]="video.ownerType" class="mb-3">
      <mat-radio-button color="primary" class="mr-3" *ngFor="let ownerType of ownerTypeKL" value="{{ownerType.key}}">{{ownerType.label}}</mat-radio-button>
    </mat-radio-group>

    <mt-publisher-autocomplete [publisherId]="video.publisherVideo?.publisher.id" (publisherSelected)="setOwnerId($event)" *ngIf="video.ownerType === ownerType.PUBLISHER"></mt-publisher-autocomplete>

    <mt-content-owner-autocomplete [contentOwnerId]="video.coVideo?.contentOwner.id" (contentOwnerSelected)="setOwnerId($event)"  *ngIf="video.ownerType === ownerType.CONTENT_OWNER"></mt-content-owner-autocomplete> -->
  </div>
</div>

<div mat-dialog-actions class="">
  <button mat-stroked-button class="" [mat-dialog-close]="false">
    Close
  </button>
  <button mat-flat-button class="iconed" color="primary"
    (click)="saveMetadata()">
    <i class="fas fa-save"></i>
    <span>Save</span>
  </button>
</div>
