import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ServerErrorUtils} from '../../utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import { debounceTime, distinctUntilChanged, finalize, takeUntil, filter } from 'rxjs/operators';
import { IabCategoriesService } from '../../services/iab-categories.service';
import { IabCategory } from '../../domain/iab-category.model';
import { OnDestroy } from '@angular/core';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-iab-categories-autocomplete-chips-input',
  template: `
    <mat-form-field [ngClass]="formFieldClass ? formFieldClass : 'w-100'" [floatLabel]="floatLabel || 'always'" [appearance]="formFieldAppearance ? formFieldAppearance : undefined">
      <mat-label>{{label ? label : 'Iab Categories'}}</mat-label>
      <mat-chip-list #chipList [disabled]='disabled || loading' class="form-field-chip-list">
        <mat-basic-chip
          *ngFor='let iabCat of selectedIabCats'
          [selectable]='true'
          [hidden]="classicChips"
          [removable]='checkIfRemovable(iabCat.id)'
          [matTooltip]='getTooltip(iabCat)'
          matTooltipPosition='above'
          (removed)='remove(iabCat)'>
            {{iabCat?.code}}
          <i class='fas fa-times-circle text-danger hand' *ngIf="checkIfRemovable(iabCat.id)" matChipRemove></i>
        </mat-basic-chip>
        <input
          matInput
          #search
          aria-label="Iab Category"
          [matAutocomplete]="auto"
          [formControl]="filterCtrl"
          [placeholder]="placeholder ? placeholder : ''"
          [required]="required"
          [matChipInputFor]='chipList'
        />
      </mat-chip-list>
      <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
      <mat-autocomplete #auto='matAutocomplete' (optionSelected)='optionSelected($event)'>
        <mat-option *ngFor='let category of filteredItems | async' [value]='category'>
            {{includeTitle ? getFullTitle(category) : category.code}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint [hidden]="classicChips">Start typing (autocomplete)</mat-hint>
    </mat-form-field>

    <mat-chip-list #chipList [ngClass]="selectedIabCats?.length > 0 ? 'mt-n3 mb-3':''" [hidden]="!classicChips || selectedIabCats?.length === 0">
        <mat-basic-chip *ngFor='let category of selectedIabCats'
                        [removable]="true"
                        (removed)="remove(category)">
          {{category.code}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
    </mat-chip-list>`
})
export class IabCategoriesAutocompleteChipsInputComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input() placeholder: string;
  @Input() floatLabel: FloatLabelType;
  @Input() label: string;
  @Input() formFieldClass: string;
  @Input() formFieldAppearance: string;
  /** If true, we display full title of iabCat.*/
  @Input() includeTitle: boolean;
  @Input() required: boolean;
  /** If true, we check preselected iabCats by code instead of ID.*/
  @Input() checkByCode: boolean;
  /** If true, we stack chips under input instead of inside input.*/
  @Input() classicChips: boolean;
  /** If false, initial/preselected iabCats will not be removable. Default is true.*/
  @Input() disabled: boolean;
  @Input() initialIabCatsRemovable = true;
  @Input() preselectedIabCategories: any;
  @Output() iabCategorySelected = new EventEmitter<IabCategory[]>();

  filteredItems: Subject<IabCategory[]>; //List of iabCats to show in dropdown filtered with 'filteredIabCatIds'
  filterCtrl = new UntypedFormControl();
  iabCatsList: IabCategory[] = []; //stores currently fetched list of iabCats
  selectedIabCats: IabCategory[] = []; //currently selected iabCats
  filteredIabCatIds: string[] = []; //It stores all the IDs of selected iabCats
  initialPubIds: string[] = []; //stores initial iabCat IDs
  public loading = false;

  constructor(
    private service: IabCategoriesService,
    private snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
    .pipe(debounceTime(0), distinctUntilChanged(), takeUntil(this.onDestroy))
    .subscribe(
      (searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string') {
          this.filteredItems.next(
            this.iabCatsList.filter(x => !this.filteredIabCatIds.includes(x.id))
            .filter(
              x => this.includeTitle
              ? this.getFullTitle(x).toLowerCase().includes(searchTerm.toLowerCase())
              : x.code.toLowerCase().includes(searchTerm.toLowerCase())));
        }
        else if (searchTerm == '') {
          this.filteredItems.next(undefined);
        }
      }
    );
  }

  ngOnInit(): void {
    this.getIabCategories();
    if (!this.initialIabCatsRemovable) {
      this.initialPubIds = this.preselectedIabCategories;
    }
  }

  private getIabCategories() {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.fetchIabCategories()
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.iabCatsList = resp;
          if (this.preselectedIabCategories) {
            this.selectedIabCats = this.getPreselectedIabCategories();
            this.updateFilteredIabCats(this.selectedIabCats);
          }
          this.filteredItems.next(this.iabCatsList.filter(x => !this.filteredIabCatIds.includes(x.id)));
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching Iab Category autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedIabCats.push($event.option.value);
    this.emit();
    this.filterCtrl.setValue(null);
    this.searchFilter.nativeElement.value = '';
    this.updateFilteredIabCats(this.selectedIabCats);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component. */
  private emit() {
    this.iabCategorySelected.emit(this.selectedIabCats);
  }

  public updateFilteredIabCats(items) {
    this.filteredIabCatIds = items.map((item) => item.id);
  }

  private getPreselectedIabCategories(): IabCategory[]{
    if (this.checkByCode) {
      return this.iabCatsList.filter(x => this.preselectedIabCategories.split(', ').includes(x.code));
    }
    else {
      return this.iabCatsList.filter(x => this.preselectedIabCategories.includes(x.id));
    }
  }

  public getFullTitle(category) {
    if (!category) {
      return null;
    }
    return `${category.code} | ${category.title} | ${category.titlev2}`;
  }

  public getTooltip(category) {
    if (!category) {
      return null;
    }
    if (category.title.trim().toLowerCase() === category.titlev2.trim().toLowerCase()){
      return category.title;
    } else {
      return `${category.title} | ${category.titlev2}`;
    }
  }

  public checkIfRemovable(id: string): boolean {
    if (this.initialPubIds.includes(id)) {
      return false;
    }
    else {return true;}
  }

  public remove(p: IabCategory) {
    this.selectedIabCats.splice(this.selectedIabCats.indexOf(p), 1);
    this.iabCategorySelected.emit(this.selectedIabCats);
    this.updateFilteredIabCats(this.selectedIabCats);
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
