import { KeyLabel } from '../key-label.model';
import { SortUtils } from '../../utils/sort-utils';

export enum ReportMetric {
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    PUBLISHER_NAME = 'PUBLISHER_NAME',
    PUBLISHER_ID = 'PUBLISHER_ID',
    DOMAIN = 'DOMAIN',
    DEAL = 'DEAL',
    AUCTION_TYPE = 'AUCTION_TYPE',
    APP_ID = 'APP_ID',
    APP_BUNDLE_ID = 'APP_BUNDLE_ID',
    DEMAND_PARTNER = 'DEMAND_PARTNER',
    DP_AD_REQUESTS = 'DP_AD_REQUESTS',
    DP_AD_REQUEST_RATIO = 'DP_AD_REQUEST_RATIO',
    AD_IMPRESSIONS = 'AD_IMPRESSIONS',
    AD_REQUESTS = 'AD_REQUESTS',
    AD_REQUESTS_FILLED = 'AD_REQUESTS_FILLED',
    AD_UNIT_IMPRESSIONS = 'AD_UNIT_IMPRESSIONS',
    FILLED_IMPRESSIONS = 'FILLED_IMPRESSIONS',
    RENDER_RATE = 'RENDER_RATE',
    RESPONSE_RATE = 'RESPONSE_RATE',
    REVENUE = 'REVENUE',
    NET_REVENUE = 'NET_REVENUE',
    RPM = 'RPM',
    MARGIN_PCT = 'MARGIN_PCT',
    NETWORK_MARGIN_PCT = 'NETWORK_MARGIN_PCT',
    GROSS_REVENUE = 'GROSS_REVENUE',
    PUB_REVENUE = 'PUB_REVENUE',
    PROFIT = 'PROFIT',
    PUBLISHER_REVENUE_SHARE = 'PUBLISHER_REVENUE_SHARE',
    BIDS = 'BIDS',
    BID_FILL_RATIO = 'BID_FILL_RATIO',
    WON_BIDS = 'WON_BIDS',
    LOST_RATIO = 'LOST_RATIO',
    WIN_RATIO = 'WIN_RATIO',
    BID_RATIO = 'BID_RATIO',
    INSERTION_RATE = 'INSERTION_RATE',
    LOST_BIDS = 'LOST_BIDS',
    BID_WIN_LOST_RATIO = 'BID_WIN_LOST_RATIO',
    CONTENT_IMPRESSIONS = 'CONTENT_IMPRESSIONS',
    STICH_IMPRESSIONS = 'STICH_IMPRESSIONS',
    FILLED_IMPRESSIONS_STITCHED = 'FILLED_IMPRESSIONS_STITCHED',
    AD_IMPRESSIONS_STITCHED = 'AD_IMPRESSIONS_STITCHED',
    PRODUCT_TYPE = 'PRODUCT_TYPE',
    PUBLISHER_NETWORK = 'PUBLISHER_NETWORK',
    PUBLISHER_NETWORK_CPM = 'PUBLISHER_NETWORK_CPM',
    PUBLISHER_CPM = 'PUBLISHER_CPM',
    VIDEO_ID = 'VIDEO_ID',
    VIDEO_NAME = 'VIDEO_NAME',
    VIDEO = 'VIDEO',
    CONTENT_TYPE = 'CONTENT_TYPE',
    IMMU_VIDEO_PLAYED = 'IMMU_VIDEO_PLAYED',
    CONTENT_REQUEST = 'CONTENT_REQUEST',
    PAGE = 'PAGE',
    XP = 'XP',
    XP1 = 'XP1',
    XP2 = 'XP2',
    XP3 = 'XP3',
    NETWORK_ID = 'NETWORK_ID',
    NETWORK_NAME = 'NETWORK_NAME',
    BUNDLE_REVENUE_SHARE = 'BUNDLE_REVENUE_SHARE',
    BUNDLE_CPM = 'BUNDLE_CPM',
    NETWORK_SAAS_TYPE = 'NETWORK_SAAS_TYPE',
    NETWORK_SAAS_VALUE = 'NETWORK_SAAS_VALUE',
    NETWORK_SAAS_METRIC = 'NETWORK_SAAS_METRIC',
    NETWORK_SAAS_FEE = 'NETWORK_SAAS_FEE',
    NETWORK_REVENUE = 'NETWORK_REVENUE',
    NETWORK_PROFIT = 'NETWORK_PROFIT',
    NETWORK_REVENUE_PCT = 'NETWORK_REVENUE_PCT',
    SCHAIN_ASI = 'SCHAIN_ASI',
    WON_BIDS_CACHE = 'WON_BIDS_CACHE',
    FILLED_IMPRESSIONS_CACHE = 'FILLED_IMPRESSIONS_CACHE',
    AD_IMPRESSIONS_CACHE = 'AD_IMPRESSIONS_CACHE',
    REVENUE_CACHE = 'REVENUE_CACHE',
    PUB_REVENUE_CACHE = 'PUB_REVENUE_CACHE',
    RPM_NET = 'RPM_NET',
    PLACEMENT = 'PLACEMENT',
    PLACEMENT_ID = 'PLACEMENT_ID',
    BIDS_LI = 'BIDS_LI',
    WON_BIDS_LI = 'WON_BIDS_LI',
    FILLED_IMPRESSIONS_LI = 'FILLED_IMPRESSIONS_LI',
    AD_IMPRESSIONS_LI = 'AD_IMPRESSIONS_LI',
    REVENUE_LI = 'REVENUE_LI',
    PUB_REVENUE_LI = 'PUB_REVENUE_LI',
    RPM_LI = 'RPM_LI',
    RENDER_RATE_LI = 'RENDER_RATE_LI',
    LINE_ITEM = 'LINE_ITEM',
    ORDER = 'ORDER',
    DEMAND_INTEGRATION = 'DEMAND_INTEGRATION',
    REVENUE_STITCHED = 'REVENUE_STITCHED',
    RPM_STITCHED = 'RPM_STITCHED',
    RENDER_RATE_STITCHED = 'RENDER_RATE_STITCHED',
    COUNTRY = 'COUNTRY',
    REGION = 'REGION',
    ERROR_MESSAGE = 'ERROR_MESSAGE',
    ERROR_CODE = 'ERROR_CODE',
    PLATFORM = 'PLATFORM',
    PLATFORM_ID = 'PLATFORM_ID',
    APP_STORE_ID = 'APP_STORE_ID',
    MP_REVENUE = 'MP_REVENUE',
    PUBLISHER_SECONDARY_ID = 'PUBLISHER_SECONDARY_ID',
    ERRORS = 'ERRORS',
    AD_START = 'AD_START',
    AD_QUARTILE_1 = 'AD_QUARTILE_1',
    AD_MID = 'AD_MID',
    AD_QUARTILE_3 = 'AD_QUARTILE_3',
    AD_COMPLETION = 'AD_COMPLETION',
    AD_IMPRESSIONS_STITCHED_PCT = 'AD_IMPRESSIONS_STITCHED_PCT',
    PUB_RENDER_RATE = 'PUB_RENDER_RATE',
    PUB_AD_IMPRESSIONS = 'PUB_AD_IMPRESSIONS',
    PUB_RPM = 'PUB_RPM',
    PUBLISHER_REVENUE_SHARE_IO = 'PUBLISHER_REVENUE_SHARE_IO',
    DP_FILL_RATIO = 'DP_FILL_RATIO',
    DV_SEGMENT = 'DV_SEGMENT',
    AD_IMPRESSIONS_DV_IQM = 'AD_IMPRESSIONS_DV_IQM',
    REVENUE_PCT = 'REVENUE_PCT',
    RPS = 'RPS',
    PUB_GROSS_REVENUE = 'PUB_GROSS_REVENUE',
    BC_FIND = 'BC_FIND',
    BC_STORE = 'BC_STORE',
    BC_UNLOCK = 'BC_UNLOCK',
    BC_USE = 'BC_USE',
    BID_CPM = 'BID_CPM',
    FILL_CPM = 'FILL_CPM',
    RPM_FILL = 'RPM_FILL',
    IVT_PROVIDER = 'IVT_PROVIDER',
    IVT_TYPE = 'IVT_TYPE',
    WEB_VIDEO_PAGE_REQUEST = 'WEB_VIDEO_PAGE_REQUEST',
    BRAND_NAME = 'BRAND_NAME',
    BRAND_ID = 'BRAND_ID',
    ADVERTISER_NAME = 'ADVERTISER_NAME',
    ADVERTISER_ID = 'ADVERTISER_ID',
    DEVICE_TYPE = 'DEVICE_TYPE',
    BIDFLOOR_REQ = 'BIDFLOOR_REQ',
    AD_CLICKS = 'AD_CLICKS',
    CTR = 'CTR',
    CLICK_CPM = 'CLICK_CPM',
    CREATIVE_ID = 'CREATIVE_ID',
    CREATIVE_NAME = 'CREATIVE_NAME',
    VIEW = 'VIEW',
    VIEW_PCT = 'VIEW_PCT',
    APPLICATION_ID = 'APPLICATION_ID',
    APPLICATION_NAME = 'APPLICATION_NAME',
    LR_SEGMENT = 'LR_SEGMENT',
    LR_SEGMENT_NAME = 'LR_SEGMENT_NAME',
    LR_SEGMENT_COST = 'LR_SEGMENT_COST',
    STITCH_CPM_COST = 'STITCH_CPM_COST'
}

export enum ReportMetricFormat {
    STRING,
    NUMBER,
    DECIMAL,
    DATE_TIME,
    PERCENTAGE,
    PERCENTAGE_PLAIN,
    CURRENCY,
    DURATION,
    BID_FLOOR
}

export class ReportMetricDetails extends KeyLabel<ReportMetric> {
    field: string;
    _selected: boolean;
    description: string;
    format: ReportMetricFormat = ReportMetricFormat.STRING;

    constructor(
        key: ReportMetric,
        label: string,
        field: string,
        description?: string
    ) {
        super(key, label);
        this.field = field;
        this.description = description;
    }

    ofFormat(type: ReportMetricFormat): ReportMetricDetails {
        this.format = type;
        return this;
    }
}

export const ReportMetricsAll = () =>
    [
        // general
        new ReportMetricDetails(ReportMetric.HOUR, 'Hour', 'hour'),
        new ReportMetricDetails(ReportMetric.DAY, 'Day', 'day').ofFormat(
            ReportMetricFormat.DATE_TIME
        ),
        new ReportMetricDetails(ReportMetric.MONTH, 'Month', 'month'),
        new ReportMetricDetails(ReportMetric.YEAR, 'Year', 'year'),
        new ReportMetricDetails(ReportMetric.PLACEMENT, 'Placement name', 'placement'),
        new ReportMetricDetails(ReportMetric.PLACEMENT_ID, 'Placement ID', 'placementId'),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_NAME,
            'Publisher name',
            'publisherName'
        ),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_ID,
            'Publisher Id',
            'publisherId'
        ),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_NETWORK,
            'Publisher network id',
            'publisherNetwork'
        ),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_NETWORK_CPM,
            'Publisher network CPM',
            'pubNetworkCpm',
            'Fixed value Network user is obliged to pay to its publishers per 1000 impressions'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_CPM,
            'Publisher CPM',
            'publisherCpm',
            'Parent value, defined in IO'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(ReportMetric.DEVICE_TYPE, 'Device type', 'deviceType'),
        new ReportMetricDetails(ReportMetric.DOMAIN, 'Publisher domain', 'domain'),
        new ReportMetricDetails(ReportMetric.APP_ID, 'App id', 'appId'),
        new ReportMetricDetails(ReportMetric.DEAL, 'Deal', 'deal'),
        new ReportMetricDetails(ReportMetric.AUCTION_TYPE, 'Auction type', 'auctionType'),
        new ReportMetricDetails(ReportMetric.VIDEO, 'Video', 'video'),
        new ReportMetricDetails(ReportMetric.PAGE, 'Page', 'page'),
        new ReportMetricDetails(ReportMetric.CONTENT_TYPE, 'Content type', 'contentType'),
        new ReportMetricDetails(ReportMetric.VIDEO_ID, 'Content ID', 'videoId'),
        new ReportMetricDetails(ReportMetric.VIDEO_NAME, 'Content name', 'videoName'),
        new ReportMetricDetails(ReportMetric.IMMU_VIDEO_PLAYED, 'Content played', 'immuVideoPlayed').ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.CONTENT_REQUEST,
            'Page requests',
            'contentRequests',
            'Total number of page requests - Last 7 day'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.WEB_VIDEO_PAGE_REQUEST,
            'Page requests',
            'wvPageRequests',
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.APP_BUNDLE_ID,
            'Publisher app bundle id',
            'appBundleId'
        ),
        new ReportMetricDetails(
            ReportMetric.DEMAND_PARTNER,
            'Demand partner name',
            'demandPartner'
        ),
        new ReportMetricDetails(
            ReportMetric.DP_AD_REQUESTS,
            'Bid requests',
            'dpAdRequests',
            'Total number of Demand Partner Ad Requests'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_UNIT_IMPRESSIONS,
            'Ad unit impressions',
            'adUnitImpressions',
            'Total number of Ad Unit script loads'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.DP_AD_REQUEST_RATIO,
            'Bid requests ratio',
            'dpAdRequestRatio',
            'Bid Requests / Ad Requests'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.AD_IMPRESSIONS,
            'Ad impressions',
            'adImpressions',
            'Total number of Ads shown to the visitor (Rendered Ads)'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_REQUESTS,
            'Ad requests',
            'adRequests',
            'Total number of Ad Requests from Ad Server'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_REQUESTS_FILLED,
            'Fill rate',
            'adRequestsFilled',
            '(Ad Requests Filled) Ad Impressions / Ad Requests'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.FILLED_IMPRESSIONS,
            'Filled impressions',
            'filledImpressions',
            'Total number of Ads'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.RENDER_RATE,
            'Render rate',
            'renderRate',
            'Ad Impressions / Filled Impressions'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.RESPONSE_RATE,
            'Response rate',
            'responseRate',
            '(Filled percent) Filled Impressions / Ad Requests'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.REVENUE,
            'Revenue',
            'revenue',
            'Total revenue generated, Gross revenue'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.NET_REVENUE,
            'Net revenue',
            'netRevenue',
            'Revenue - Publisher revenue'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.GROSS_REVENUE,
            'Gross revenue',
            'grossRevenue',
            'Currently equals to Revenue'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUB_REVENUE,
            'Publisher revenue',
            'pubRevenue',
            'Total revenue earned per Publisher'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PROFIT,
            'Profit',
            'profit',
            'Net revenue + Publisher SaaS Fee - Platform cost'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.RPM,
            'RPM', 
            'rpm',
            'Revenue / Ad impressions * 1000'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.NETWORK_MARGIN_PCT,
            'Margin',
            'networkMarginPct',
            'Profit / Revenue'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.MARGIN_PCT,
            'Margin',
            'marginPct',
            'Profit / Revenue'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_REVENUE_SHARE,
            'Publisher revenue share',
            'publisherRevenueShare',
            'Parent value, defined in IO'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.BIDS,
            'Bid responses',
            'bids',
            'Number of DP bids'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.BID_FILL_RATIO,
            'Bid Fill Ratio',
            'bidFillRatio',
            'Percentage of Ad Impressions / Bid Responses'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.WON_BIDS,
            'Won bids',
            'wonBids',
            'Number of Won DP bids'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.INSERTION_RATE,
            'Insertion rate',
            'insertionRatio',
            'Ad Impressions / Bid Won'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.WIN_RATIO,
            'Win ratio',
            'winRatio',
            'Bid Won / Bid Responses'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.BID_RATIO,
            'Bid ratio',
            'bidRatio',
            'Bid responses / Bid Requests'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.LOST_RATIO,
            'Lost ratio',
            'lostRatio',
            'Bid Lost / Bid Responses'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.LOST_BIDS,
            'Lost bids',
            'lostBids',
            'bid responses - won bids'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.BID_WIN_LOST_RATIO,
            'Bid win/lost ratio',
            'bidWinLostRatio',
            'Won / Lost bids'
        ).ofFormat(ReportMetricFormat.DECIMAL),
        new ReportMetricDetails(
            ReportMetric.CONTENT_IMPRESSIONS,
            'Content impressions',
            'contentImpressions',
            'Total number of Content byte impressions'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.STICH_IMPRESSIONS,
            'Stitch impressions',
            'stichImpressions',
            'Total number of Content byte stitch impressions'
        ).ofFormat(ReportMetricFormat.NUMBER),

        new ReportMetricDetails(
            ReportMetric.FILLED_IMPRESSIONS_STITCHED,
            'Filled stitch impressions',
            'filledImpressionsStitched'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_IMPRESSIONS_STITCHED,
            'Ad stitch impressions',
            'adImpressionsStitched'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.PRODUCT_TYPE,
            'Product type',
            'productType'
        ),
        new ReportMetricDetails(
            ReportMetric.XP,
            'XP1',
            'xp',
            'Exchange extra parameter'
        ),
        new ReportMetricDetails(
            ReportMetric.XP1,
            'XP2',
            'xp1'
        ),
        new ReportMetricDetails(
            ReportMetric.XP2,
            'XP3',
            'xp2'
        ),
        new ReportMetricDetails(
            ReportMetric.XP3,
            'XP4',
            'xp3'
        ),
        new ReportMetricDetails(ReportMetric.NETWORK_ID, 'Network ID', 'networkId'),
        new ReportMetricDetails(
            ReportMetric.NETWORK_NAME,
            'Network name',
            'networkName'
        ),
        new ReportMetricDetails(
            ReportMetric.BUNDLE_REVENUE_SHARE,
            'Bundle revenue share',
            'bundleRevenueShare',
            'Child value, will override Parent value, defined in IO'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.BUNDLE_CPM,
            'Bundle CPM',
            'bundleCpm',
            'Child value, will override Parent value, defined in IO'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.NETWORK_SAAS_TYPE,
            'Network cost type',
            'networkSaasType',
            'Expected value: Revshare or CPM, defined in IO'
        ),
        new ReportMetricDetails(
            ReportMetric.NETWORK_SAAS_VALUE,
            'Network cost value',
            'networkSaasValue',
            'Value for Revshare or CPM, defined in IO'
        ), // custom
        new ReportMetricDetails(
            ReportMetric.NETWORK_SAAS_METRIC,
            'Network cost metric',
            'networkSaasMetric',
            'Metric attached to CPM, defined in IO'
        ),
        new ReportMetricDetails(
            ReportMetric.NETWORK_SAAS_FEE,
            'Platform cost',
            'networkSaasFee',
            'Total platform cost, attached to metric and defined in IO'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.NETWORK_REVENUE,
            'Net revenue',
            'networkRevenue',
            'Gross revenue - Publisher revenue'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.NETWORK_PROFIT,
            'Profit',
            'networkProfit',
            'Net revenue + Publisher SaaS fee - Platform cost'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.NETWORK_REVENUE_PCT,
            'Net revenue %',
            'networkRevenuePct'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.SCHAIN_ASI,
            'Demand source',
            'schainAsi'
        ),
        new ReportMetricDetails(
            ReportMetric.WON_BIDS_CACHE,
            'Cache won',
            'wonBidsCache'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.FILLED_IMPRESSIONS_CACHE,
            'Filled Impressions Cache',
            'filledImpressionsCache'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_IMPRESSIONS_CACHE,
            'Ad Impressions Cache',
            'adImpressionsCache'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.REVENUE_CACHE,
            'Revenue Cache',
            'revenueCache'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUB_REVENUE_CACHE,
            'PUB Revenue Cache',
            'pubRevenueCache'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(ReportMetric.RPM_NET,
            'RPM Net',
            'rpmNet'
        ).ofFormat(ReportMetricFormat.CURRENCY
        ),
        new ReportMetricDetails(
            ReportMetric.BIDS_LI,
            'Bids line item',
            'bidsLineItem'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.WON_BIDS_LI,
            'Won bids line item',
            'wonBidsLineItem'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.FILLED_IMPRESSIONS_LI,
            'Filled impressions line item',
            'filledImpressionsLineItem'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_IMPRESSIONS_LI,
            'Ad impressions line item',
            'adImpressionsLineItem'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.REVENUE_LI,
            'Revenue line item',
            'revenueLineItem'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUB_REVENUE_LI,
            'Publisher revenue line item',
            'pubRevenueLineItem'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.RPM_LI,
            'RPM line item',
            'rpmLineItem'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.RENDER_RATE_LI,
            'Render rate line item',
            'renderRateLineItem'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.LINE_ITEM,
            'Line item',
            'lineItem'
        ),
        new ReportMetricDetails(
            ReportMetric.ORDER,
            'Order',
            'order'
        ),
        new ReportMetricDetails(
            ReportMetric.DEMAND_INTEGRATION,
            'Demand integration',
            'demandIntegration'
        ),
        new ReportMetricDetails(
            ReportMetric.REVENUE_STITCHED,
            'Revenue stitched',
            'revenueStitched'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.RPM_STITCHED,
            'Stitched CPM',
            'rpmStitched'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.RENDER_RATE_STITCHED,
            'Render rate stitched',
            'renderRateStitched'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.COUNTRY,
            'Country',
            'country'
        ),
        new ReportMetricDetails(
            ReportMetric.REGION,
            'Region',
            'region'
        ),
        new ReportMetricDetails(
            ReportMetric.ERROR_MESSAGE,
            'Error message',
            'errorMessage'
        ),
        new ReportMetricDetails(
            ReportMetric.ERROR_CODE,
            'Error code',
            'errorCode'
        ),
        new ReportMetricDetails(
            ReportMetric.PLATFORM,
            'Platform',
            'platform'
        ),
        new ReportMetricDetails(
            ReportMetric.PLATFORM_ID,
            'Platform ID',
            'platformId'
        ),
        new ReportMetricDetails(
            ReportMetric.APP_STORE_ID,
            'App store ID',
            'appStoreId'
        ),
        new ReportMetricDetails(
            ReportMetric.MP_REVENUE,
            'MP revenue',
            'mpRevenue'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_ID,
            'Publisher ID',
            'publisherId'
        ),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_SECONDARY_ID,
            'Publisher alternative ID',
            'publisherSecondaryId'
        ),
        new ReportMetricDetails(
            ReportMetric.ERRORS,
            'Errors',
            'errors'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_START,
            'Ad start',
            'adStart'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_QUARTILE_1,
            'Ad quartile 1',
            'adQuartile1'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_QUARTILE_3,
            'Ad quartile 3',
            'adQuartile3'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_MID,
            'Ad mid',
            'adMid'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_COMPLETION,
            'Ad completion',
            'adCompletion'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_IMPRESSIONS_STITCHED_PCT,
            'Stitched percentage',
            'adStitchedImpressionPct',
            'Percentage of Stitched Impressions based on total number of Bid Responses'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.PUB_RENDER_RATE,
            'Publisher Render rate',
            'pubRenderRate',
            'Publisher Render rate'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.PUBLISHER_REVENUE_SHARE_IO,
            'Publisher Revenue share IO',
            'pubRevenueShareIo'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.PUB_AD_IMPRESSIONS,
            'Publisher Ad impressions',
            'pubAdImpressions'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.AD_CLICKS,
            'Ad clicks',
            'adClicks'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.CTR,
            'CTR',
            'ctr'
        ).ofFormat(ReportMetricFormat.PERCENTAGE_PLAIN),
        new ReportMetricDetails(
            ReportMetric.CLICK_CPM,
            'Click CPM',
            'clickCpm'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.CREATIVE_ID,
            'Creative ID',
            'creativeId'
        ).ofFormat(ReportMetricFormat.STRING),
        new ReportMetricDetails(
            ReportMetric.CREATIVE_NAME,
            'Creative name',
            'creativeName'
        ).ofFormat(ReportMetricFormat.STRING),
        new ReportMetricDetails(
            ReportMetric.VIEW,
            'Views',
            'view'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.VIEW_PCT,
            'Views percentage',
            'viewPct'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.APPLICATION_ID,
            'Application Id',
            'applicationId'
        ).ofFormat(ReportMetricFormat.STRING),
        new ReportMetricDetails(
            ReportMetric.APPLICATION_NAME,
            'Application name',
            'applicationName'
        ).ofFormat(ReportMetricFormat.STRING),
        new ReportMetricDetails(
            ReportMetric.LR_SEGMENT,
            'LR segment',
            'lrSegment'
        ).ofFormat(ReportMetricFormat.STRING),
        new ReportMetricDetails(
            ReportMetric.LR_SEGMENT_NAME,
            'LR segment name',
            'lrSegmentName'
        ).ofFormat(ReportMetricFormat.STRING),
        new ReportMetricDetails(
            ReportMetric.LR_SEGMENT_COST,
            'LR segment cost',
            'lrSegmentCost'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.STITCH_CPM_COST,
            'Stitch CPM Cost',
            'stitchCpmCost',
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUB_RPM,
            'Publisher RPM',
            'pubRpm'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.DV_SEGMENT,
            'DV Segment',
            'dvSegment'
        ),
        new ReportMetricDetails(
            ReportMetric.DP_FILL_RATIO,
            'DP Fill Ratio',
            'dpFillRatio'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.AD_IMPRESSIONS_DV_IQM,
            'DV IQM ad impressions',
            'adImpressionsDvIqm'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.REVENUE_PCT,
            'Revenue %',
            'revenuePct'
        ).ofFormat(ReportMetricFormat.PERCENTAGE),
        new ReportMetricDetails(
            ReportMetric.RPS,
            'Revenue per second',
            'rps'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.PUB_GROSS_REVENUE,
            'Publisher Gross Revenue',
            'pubGrossRevenue'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.BC_FIND,
            'Bid cache find events',
            'bcFind'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.BC_STORE,
            'Bid cache store events',
            'bcStore'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.BC_UNLOCK,
            'Bid cache unlock events',
            'bcUnlock'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.BC_USE,
            'Bid cache use events',
            'bcUse'
        ).ofFormat(ReportMetricFormat.NUMBER),
        new ReportMetricDetails(
            ReportMetric.BID_CPM,
            'Bid CPM',
            'bidCpm',
            'Average Bidding CPM for all Won and Lost bids'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.FILL_CPM,
            'Fill CPM',
            'fillCpm',
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.BIDFLOOR_REQ,
            'Bidfloor from request',
            'bidfloorReq',
        ).ofFormat(ReportMetricFormat.BID_FLOOR),
        new ReportMetricDetails(
            ReportMetric.RPM_FILL,
            'RPM Fill',
            'rpmFill',
            'Revenue / Filled Impressions *1000'
        ).ofFormat(ReportMetricFormat.CURRENCY),
        new ReportMetricDetails(
            ReportMetric.IVT_PROVIDER,
            'IVT Provider',
            'ivtProvider',
        ),
        new ReportMetricDetails(
            ReportMetric.IVT_TYPE,
            'IVT Type',
            'ivtType',
        ),
        new ReportMetricDetails(ReportMetric.ADVERTISER_NAME, 'Advertiser name', 'advertiserName'),
        new ReportMetricDetails(ReportMetric.ADVERTISER_ID, 'Advertiser ID', 'advertiserId'),
        new ReportMetricDetails(ReportMetric.BRAND_NAME, 'Brand name', 'brandName'),
        new ReportMetricDetails(ReportMetric.BRAND_ID, 'Brand ID', 'brandId'),
    ].sort(SortUtils.propertyComparatorString('label'));

export const ReportMetricsDetailsMap = (): {
    [key: string]: ReportMetricDetails;
} =>
    ReportMetricsAll().reduce((prev, curr) => {
        prev[curr.key] = curr;
        return prev;
    }, {});

export const ReportMetricLabels = Object.freeze(
    ReportMetricsAll().reduce((prev, curr) => {
        prev[curr.key] = curr.label;
        return prev;
    }, {})
);

export const ReportMetricLabelsFL = Object.freeze(
    ReportMetricsAll().reduce((prev, curr) => {
        prev[curr.field] = curr.label;
        return prev;
    }, {})
);

export function TopNMetrics() {
    const selectedMetrics = [
        ReportMetric.AD_REQUESTS,
        ReportMetric.AD_IMPRESSIONS,
        ReportMetric.REVENUE,
        ReportMetric.NETWORK_PROFIT
    ];

    const filteredMetrics = ReportMetricsAll().filter(metricDetail => 
        selectedMetrics.includes(metricDetail.key)
    ).map(metricDetail => ({
        key: metricDetail.key,
        title: metricDetail.label
    }));

    return filteredMetrics;
}
