import { KeyLabel } from './key-label.model';

export enum AdstxtStatusColorMap {
  FOUND = 'green',
  MISSING = 'red'
}

export enum AdstxtStatus {
  FOUND = 'FOUND',
  MISSING = 'MISSING'
}

export const AdstxtStatusLabels = {
  FOUND : 'Found',
  MISSING : 'Missing'
};

export const AdstxtStatusKL = () =>
    Object.keys(AdstxtStatus).map((key) => new KeyLabel(<AdstxtStatus>key, AdstxtStatusLabels[key]));
