import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {Principal} from "../auth/principal.service";
import {SnackBarService} from "../services/snackbar.service";
import {GlobalSpinnerService} from "../services/global-spinner.service";
import {ResetPasswordService} from "../../shared/services/reset-password.service";
import {ResetPasswordRequest} from "../auth/user.model";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'mt-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ResetPasswordComponent implements OnInit {

    resetPasswordRequest: ResetPasswordRequest = new ResetPasswordRequest();
    logoUrl: string = environment.logoUrl;

    constructor(private resetPasswordService: ResetPasswordService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private principal: Principal,
                private snackBarService: SnackBarService,
                private globalSpinnerService: GlobalSpinnerService) {}

    ngOnInit() {
    }

    public resetPassword() {
        this.globalSpinnerService.showLoader();

        this.route.queryParams
            .subscribe(params => {
                this.resetPasswordRequest.token = params.token;
            })

        this.resetPasswordService.resetPassword(this.resetPasswordRequest).subscribe((data) => {
            this.globalSpinnerService.hideLoader();
            this.snackBarService.success("Successful password reset");
        }, (err) => {
            console.log(err.message);
            this.globalSpinnerService.hideLoader();
            this.snackBarService.error(err.message);
        });
    }

}