import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/legacy-autocomplete';
import {ServerErrorUtils} from '../../utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import { GenresService } from '../../services/genres.service';
import { AdswizzGenre } from '../../domain/adswizzGenres.model';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-genres-autocomplete-chips-input',
  template: `
    <mat-form-field class='w-100' [floatLabel]="floatLabel || 'always'" [appearance]="formFieldAppearance ? formFieldAppearance : undefined" [ngClass]="formFieldClass">
      <mat-label>{{title ? title : 'Genres'}}</mat-label>
      <mat-chip-list #chipList [disabled]='disabled || loading' class="form-field-chip-list">
        <mat-basic-chip
          [hidden]="classicChips"
          *ngFor='let genre of selectedGenres'
          [selectable]='true'
          [removable]='true'
          (removed)='remove(genre)'
          matTooltipPosition='above'>
          {{genre?.dbValue}}
          <i class='fas fa-times-circle text-danger hand' matChipRemove></i>
        </mat-basic-chip>
        <input
          [disabled]='disabled || loading'
          [placeholder]="placeholder ? placeholder : ''" required
          #genreInput
          [formControl]='genresCtrl'
          [matAutocomplete]='auto'
          [matChipInputFor]='chipList'
          (focus)="onFocusEvent()">
      </mat-chip-list>
      <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
      <mat-autocomplete #auto='matAutocomplete' (optionSelected)='selected($event)'>
        <mat-option *ngFor='let genre  of filteredGenres | async' [value]='genre'
                   [disabled]='disabled'>
          {{genre.dbValue}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint [hidden]="classicChips">Start typing (autocomplete)</mat-hint>
    </mat-form-field>

    <mat-chip-list #chipList [ngClass]="selectedGenres?.length > 0 ? 'mb-3':''" [hidden]="!classicChips || selectedGenres?.length === 0">
        <mat-basic-chip *ngFor='let genre of selectedGenres'
                        [removable]="!disabled"
                        (removed)="remove(genre)">
          {{genre.dbValue}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
    </mat-chip-list>
    `
})
export class GenresAutocompleteChipsInputComponent implements OnInit {

  @ViewChild('genreInput') genreInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() genres: AdswizzGenre[];
  @Input() disabled: boolean;
  @Input() classicChips: boolean; //if true, we stack chips under input instead of inside input
  selectedGenres: AdswizzGenre[];
  @Input() public placeholder: string;
  @Input() public floatLabel: FloatLabelType;
  @Input() public title: string;
  @Input() public formFieldClass: string;
  @Input() public formFieldAppearance: string;

  @Output() genreSelectionChange = new EventEmitter<AdswizzGenre[]>();

  loading: boolean;
  allGenres: AdswizzGenre[] = [];
  genresCtrl = new UntypedFormControl();
  filteredGenres: Subject<AdswizzGenre[]>;

  constructor(private genresService: GenresService,
              private sbs: SnackBarService) {
  }

  ngOnInit(): void {
    this.getAllGenres();
    this.filteredGenres = new Subject();
    this.genresCtrl.valueChanges.pipe(
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredGenres.next([]);
        if (next && typeof next !== 'object' && this.allGenres.length > 0) {
          this._filter(next);
        }
        else {
          this.onFocusEvent();
        }
      });
  }

  getAllGenres() {
    this.loading = true;
    this.genresService.fetchAdswizzGenres().subscribe( (response) => {
      this.allGenres = response;
      if (!this.selectedGenres) {
        this.selectedGenres = this.genres || [];
        this.selectedGenres?.forEach(element => {
          this.allGenres = this.allGenres.filter(x => x.id !== element.id);
        });
      }
    }, error => {
        ServerErrorUtils.serverValidationOrMsg(error, this.sbs, 'Error occurred while fetching iab selectedGenres');
    },
    () => {
      this.loading = false;
    });

  }

  remove(genre: AdswizzGenre): void {
    this.selectedGenres.splice(this.selectedGenres.indexOf(genre), 1);
    this.allGenres.push(genre);
    this.emit();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedGenres.push(event.option.value);
    this.allGenres.splice(this.allGenres.indexOf(event.option.value), 1);

    this.genreInput.nativeElement.value = '';
    this.genresCtrl.setValue(null);
    this.emit();
  }

  emit() {
    this.genreSelectionChange.emit(this.selectedGenres);
  }

  onFocusEvent() {
    if (!this.genresCtrl.value?.length) {
      this.filteredGenres.next(this.allGenres);
    }
  }


  private _filter(value: any) {
    if (!value) {
      this.filteredGenres.next(this.allGenres);
    }

    let filterValue = '';
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.code.toLowerCase();
    }

    this.filteredGenres.next(this.allGenres.filter(genre => genre.dbValue.toLowerCase().includes(this.genresCtrl.value.toLowerCase())
    ).sort());
  }
}
