import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Playlist} from '../../../shared/domain/playlist.model';
import {PlaylistsService} from '../../../shared/services/playlists.service';
import {GlobalSpinnerService} from '../../../core/services/global-spinner.service';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {ServerErrorUtils} from '../../../shared/utils/server-error-utils';
import {Feed, FeedSource, FeedSourceKL, FeedTypeKL, FeedTypeYT, OwnerType, OwnerTypeKL} from '../../../shared/domain/feed.model';
import {VideosService} from '../../../shared/services/videos.service';
import { MomentDateUtils } from 'src/app/shared/utils/moment-date-utils';
import {AbstractControl, UntypedFormControl, ValidationErrors, Validators} from '@angular/forms';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'mt-playlist-ingest-feed-dialog',
  templateUrl: './playlist-ingest-feed-dialog.component.html',
  styleUrls: ['./playlist-ingest-feed-dialog.component.scss']
})
export class PlaylistIngestFeedDialogComponent implements AfterViewInit {
  today = MomentDateUtils.today();
  playlists: Playlist[] = [];
  feedSourceKL = FeedSourceKL();
  feedSource = FeedSource;
  feedTypeKL;
  feedType = FeedTypeYT;
  feed = new Feed();
  videoFile: File;
  thumbnailFile?: File;
  ownerTypeKL = OwnerTypeKL();
  ownerType = OwnerType;
  videoType: {id: string; type: OwnerType};
  request: { title: string; description: string; ownerType: OwnerType; coId?: string};
  videosCount = 0;
  domainControl = new UntypedFormControl('', Validators.pattern(Utils.getDomainValidationPattern()));

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private playlistsService: PlaylistsService,
              private videosService: VideosService,
              private gss: GlobalSpinnerService,
              public dialogRef: MatDialogRef<PlaylistIngestFeedDialogComponent>,
              private snackBarService: SnackBarService) {
    this.feed.ownerType = OwnerType.KM;
    this.feedTypeKL = FeedTypeKL().filter(ft => ft.key !== FeedTypeYT.TRILLER);
    if (data && data.playlists) {
      this.playlists = data.playlists;
    }

    if (data && data.videoType) {
      this.videoType = data.videoType;
      this.feed.ownerType = data.videoType.type;
      this.feed.coId = data.videoType.id;
    }
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('500px');
  }
t;
  submit() {
    if (this.feed.source === this.feedSource.UPLOAD) {
      this.uploadVideo();
    } else {
      this.createFeed();
    }
  }

  createFeed() {
    this.gss.showLoader();
    this.videosService.createFeed(this.feed).subscribe(next => {
      this.dialogRef.close(true);
      this.snackBarService.success('Feed created successfully');
      this.gss.hideLoader();
      }, e => {
        const messages = ServerErrorUtils.getValidationMessages(e);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error occurred during feed creation');
        }
        this.gss.hideLoader();
      });
  }

  uploadVideo() {
    this.request = {
      title: this.feed.title,
      description: this.feed.description,
      ownerType: this.feed.ownerType,
      coId: this.feed.ownerId,
    };

    this.gss.showLoader();
    this.videosService.uploadVideo(this.request, this.videoFile, this.thumbnailFile).subscribe(next => {
      this.dialogRef.close(true);
      this.snackBarService.success('Feed created successfully');
      this.gss.hideLoader();
      }, e => {
        const messages = ServerErrorUtils.getValidationMessages(e);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error occurred during feed creation');
        }
        this.gss.hideLoader();
      });
  }

  onFileSelected() {}

  removeThumbNailImage() {
    this.thumbnailFile = null;
  }

  updateFeedUrl(event) {
    this.feed.url = event;
    this.getRssCountPreview();
  }

  getRssCountPreview() {
    if (!this.feed?.url?.length) {
      this.videosCount = 0;
      return false;
    }
    this.gss.showLoader();
    this.videosService.previewRssCount(this.feed).subscribe(next => {
      this.videosCount = next.count;
      this.gss.hideLoader();
      }, e => {
      const messages = ServerErrorUtils.getValidationMessages(e);
      if (messages) {
        messages.forEach(m => this.snackBarService.error(m));
      } else {
        this.snackBarService.error('Error occurred during feed count preview');
      }
      this.gss.hideLoader();
    });
  }

  dateChange(event) {
    if (event.value) {
      const offsetMs = event?.value.getTimezoneOffset() * 60000;
      this.feed.dateFrom = new Date(event.value.getTime() - offsetMs);
    } else {
      this.feed.dateFrom = null;
    }
    this.getRssCountPreview();
  }

  isFeedTypeDisabled(feedType: FeedTypeYT) {
    return feedType === FeedTypeYT.CHANNEL;
  }

  setOwnerId(owner) {
    this.feed.ownerId = owner.id;
  }

  updateFeedType() {
    this.feedTypeKL = FeedTypeKL().filter(ft =>
      this.feed.source === FeedSource.UPLOAD ?  ft.key === FeedTypeYT.TRILLER : ft.key !== FeedTypeYT.TRILLER);
    this.feed.feedTypeYT = this.feed.source === FeedSource.UPLOAD ? FeedTypeYT.TRILLER : null;
  }

  validateUrl(source, url) {
    return source === FeedSource.UPLOAD || Utils.getDomainValidationPattern().test(url);
  }
}
