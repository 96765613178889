import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EmbedAutocompleteComponent} from './embed-autocomplete.component';
import {Embed} from '../../domain/embed.model';
import {EmbedsService} from '../../services/embeds.service';

@Component({
  selector: 'mt-embed-autocomplete-chips',
  template: `
    <div class="mt-embed-autocomplete-chips-wrapper">
      <mt-embed-autocomplete #ec (embedSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </mt-embed-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class EmbedAutocompleteChipsComponent implements OnInit {

  @ViewChild(EmbedAutocompleteComponent, {static: true}) ec: EmbedAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public embedIds: string[];

  @Input()
  public formFieldClass: string;

  @Output()
  embedSelectionChange = new EventEmitter<Embed[]>();

  items: Embed[] = [];

  constructor(private service: EmbedsService) {
  }

  ngOnInit(): void {
    if (this.embedIds && this.embedIds.length) {
      this.service.getAllEmbeds().subscribe(embeds => {
        embeds.forEach(e => {
          if (this.embedIds.includes(e.id)) {
            this.onItemSelected(e);
          }
        });
      });
    }
  }

  onItemSelected($event: Embed) {
    if ($event && !this.items.find(x => x.id === $event.id)) {
      this.items.push($event);
      this.embedSelectionChange.emit(this.items);
    }
    this.ec.resetInput();
  }

  remove(e: Embed) {
    this.items.splice(this.items.indexOf(e), 1);
    this.embedSelectionChange.emit(this.items);
  }
}
