import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BundleIdAutocompleteComponent} from './bundle-id-autocomplete.component';
import {AppsService} from '../../services/apps.service';
import {Observable, Subscription} from 'rxjs';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-bundle-id-autocomplete-chips',
  template: `
    <!-- TODO: add [publisherIds]="publisherIds" to pass publisherIds filter, right now it does not work bcz it is not supported on BE -->
    <mt-bundle-id-autocomplete #pc
                                [networkIds]="networkIds"
                                [publisherIds]="publisherIds"
                                (bundleSelected)="onItemSelected($event)"
                                [formFieldClass]="formFieldClass"
                                [formFieldAppearance]="formFieldAppearance"
                                [required]="required"
                                [label]="placeholder"
                                [floatLabel]="floatLabel">
    </mt-bundle-id-autocomplete>
    <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
      <mat-basic-chip *ngFor="let item of items"
                      (removed)="remove(item)">
        {{item}}
        <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
      </mat-basic-chip>
    </mat-chip-list>
  `,
})
export class BundleIdAutocompleteChipsComponent implements OnInit {
  @ViewChild(BundleIdAutocompleteComponent, {static: true}) pc: BundleIdAutocompleteComponent;

  /** Pass clearFilterItems = new Subject<void>(); as Observable into it and then call clearFilterItems.next() to clear all the items*/
  @Input() clearItems: Observable<void>;

  /** Pass eventsSubjectNetwork = new Subject<string[]>(); as Observable into it and then call this.eventsSubjectNetwork.next(networkIds) to filter pubs for that given network*/
  @Input() networkIdsChanged: Observable<string[]>;

  @Input() publisherIdsChanged: Observable<string[]>;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public publisherIds: string[];

  @Input()
  public bundles: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  /** Hold list of networkIds to filter pubs only by given networks.*/
  @Input() public networkIds: string[];

  @Input()
  required = false;

  @Output()
  bundleSelectionChange = new EventEmitter<string[]>();

  items: string[] = [];

  constructor(private service: AppsService) {
  }

  ngOnInit(): void {
    if (this.bundles && this.bundles.length) {
      //On parent component reload if there is single preselected item, it will actually be passed as string instead of a list
      if (typeof this.bundles === 'string') {
        this.bundles = [this.bundles];
      }
      this.pc.updateFilteredBundles(this.items);
    }
    this.clearItems?.subscribe(() => this.clearFilter());
  }

  /** Remove items that are not part of given network/s or publisher. */
  updatePublisherIdsChips(networks: string[]) {
    //TODO: Cant filter right now on FE since the BE return list of strings that dont have any info about network or pub inside them
  }

  onItemSelected($event: string) {
    this.items.push($event);
    this.bundleSelectionChange.emit(this.items);
    this.pc.resetInput();
    this.pc.updateFilteredBundles(this.items);
  }

  remove(p: string) {
    this.items.splice(this.items.indexOf(p), 1);
    this.bundleSelectionChange.emit(this.items);
    this.pc.updateFilteredBundles(this.items);
  }

  /** Clear all items from filter. */
  clearFilter() {
    this.items = [];
    this.pc.updateFilteredBundles(this.items);
  }
}
