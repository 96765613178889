<div mat-dialog-title>
    {{data.title}}
</div>
<div mat-dialog-content>
    <div *ngFor="let description of data.descriptions">{{description}}</div>
    <div class="redirect-buttons">
        <span *ngFor="let button of data.redirectButtons" (click)="redirect(button.path)" class="redirect-button">
            <div class="redirect-button-icon">
                <i class="{{button.icon}}"></i>
            </div>
            <b>{{button.label}}</b>
        </span>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-flat-button mat-dialog-close cdkFocusInitial>Cancel</button>
</div>