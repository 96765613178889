<label [for]="id" *ngIf="label" class="mt-upload-button-label">
  {{label}}
</label>
<label [for]="id" class="mt-upload-button" *ngIf="!videoPreview && !file">
  <span class="fal fa-upload text-primary mr-3"></span>
  <span>Upload</span>
  <input type="file" class="d-none"
         [id]="id" #fileInput
         (change)="handleFileInput($event)">
</label>
<div *ngIf="videoPreview" class="mt-upload-preview">
    <div class="mt-upload-preview-image" ngClass="{{fileName ? 'col-4' : 'col-12'}}">
      <video [src]="videoPreview" alt="" width="100%"></video>
      <div class="mt-upload-preview-delete">
        <button mat-mini-fab color="warn" class="mat-elevation-z0" (click)="removeVideo()" matTooltip="Delete">
          <i class="fal fa-trash-alt" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="col mt-upload-preview-name" *ngIf="fileName">
      <p class="mb-0">
        <small>File name:</small> <br>
        <span>{{file.name}}</span>
      </p>
    </div>
</div>
