<div mat-dialog-title>
  <span>Add to collection</span>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-10">
      <mt-playlist-autocomplete-chips (playlistSelectionChange)="playlistSelectionChange($event)"></mt-playlist-autocomplete-chips>
    </div>
    <div class="col-2">
      <button mat-mini-fab color="accent" class="mat-elevation-z0 mr-1" (click)="createNewPlaylist()">
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button class="" [mat-dialog-close]="false">
    Cancel
  </button>
  <button mat-flat-button color="primary"
          [disabled]="!videos.length || !playlists.length"
          (click)="addVideosToPlaylists()">
    <span>Save</span>
  </button>
</div>
