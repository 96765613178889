import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_LEGACY_INPUT_VALUE_ACCESSOR as MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/legacy-input';

@Directive({
  selector: 'input[commifiedNumberInputDirective]',
    providers: [
    {provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CommifiedNumberInputDirective},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommifiedNumberInputDirective),
      multi: true,
    }
  ]
})
export class CommifiedNumberInputDirective {
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>,
  ) {
  }


  get value(): string | null {
    return this._value;
  }

  @Input()
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: string | null) {
    if (value !== null) {
      this.elementRef.nativeElement.value = this.numberWithCommas(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    this._value = value.replace(/[^\d.-]/g, '');
    if (value) {
      this.elementRef.nativeElement.value = this._value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._value = value.replace(/[^\d.-]/g, '');
    this._onChange(this._value); // here to notify Angular Validators
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: any) {
    const numericKeys = '0123456789';
    if (-1 == numericKeys.indexOf(event.key)) {
      event.preventDefault();
      return;
    }
  }

  _onChange(value: any): void {
  }

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value); // format Value
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
