import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {User} from 'src/app/core/auth/user.model';
import {Platform} from '../../domain/platform.model';
import {PlatformsService} from '../../services/platforms.service';
import {PlatformAutocompleteComponent} from './platform-autocomplete.component';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-platform-autocomplete-chips',
  template: `
    <div class="mt-users-autocomplete-chips-wrapper">
      <mt-platform-autocomplete #ec (platformSelected)="onItemSelected($event)"
                                 [formFieldClass]="formFieldClass"
                                 [formFieldAppearance]="formFieldAppearance"
                                 [floatLabel]="floatLabel">
      </mt-platform-autocomplete>
      <mat-chip-list #chipList class="mt-n3" [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-3' : '']" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class PlatformAutocompleteChipsComponent implements OnInit {

  @ViewChild(PlatformAutocompleteComponent, {static: true}) ec: PlatformAutocompleteComponent;

  @Input() events: Observable<void>;

  @Input() platformNamesChanged: Observable<string[]>;

  @Input()
  public platformIds: string[];

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Output()
  platformSelectionChange = new EventEmitter<Platform[]>();

  items: any[] = [];

  private eventsSubscription: Subscription;

  constructor(public service: PlatformsService) {
  }

  ngOnInit(): void {
    this.eventsSubscription = this.events?.subscribe(() => this.clearFilter());
    //Preselect
    if (this.platformIds && this.platformIds.length) {
      if (this.platformIds && typeof this.platformIds === 'string') {
        this.platformIds = [this.platformIds];
      }
      this.service.listActivePlatforms().subscribe(platforms => {
        this.ec.updateFilteredPlatforms(platforms.filter(x => this.platformIds.some(y => x.id.toString() == y)));
        platforms.filter(x => this.platformIds.some(y => x.id.toString() == y)).map(platform => this.items.push(platform));
      });
    }
  }

  onItemSelected($event: Platform) {
    if ($event && !this.items.find(x => x === $event)) {
      this.items.push($event);
      this.platformSelectionChange.emit(this.items);
    }
    this.ec.resetInput();
    this.ec.updateFilteredPlatforms(this.items);
  }

  remove(e: User) {
    this.items.splice(this.items.indexOf(e), 1);
    this.platformSelectionChange.emit(this.items);
    this.ec.updateFilteredPlatforms(this.items);
  }

  clearFilter() {
    this.items = [];
    this.ec.updateFilteredPlatforms(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.ec.disable();
    }
    else {
      this.ec.enable();
    }
  }
}
