import {Component} from '@angular/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'mt-root',
  template: `
    <div class="app-wrapper mat-app-background">
      <router-outlet></router-outlet>
      <mt-loader #loader></mt-loader>
    </div>`
})
export class AppComponent {
  constructor() {
    document.title = environment.platformTitle;
    document.getElementById('appFavicon').setAttribute('href', environment.faviconUrl);
  }
}
