import { Component, Input } from '@angular/core';

@Component({
  selector: 'mt-circle-info',
  template: `
    <ng-container [ngSwitch]="isChecked">
      <i *ngSwitchCase="true" class="fal fa-check-circle text-success"></i>
      <i *ngSwitchCase="false" class="far fa-times-circle text-danger"></i>
      <i *ngSwitchDefault class="fa fa-minus-circle text-muted"></i>
    </ng-container>
  `,
})
export class CircleInfoComponent {
  @Input() isChecked: boolean;
}
