import {Component} from '@angular/core';

@Component({
  selector: 'mt-forbidden',
  template: `Forbidden`,
})
export class ForbiddenComponent {

  constructor() {
  }


}
