import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component, HostBinding, Inject} from '@angular/core';

@Component({
  selector: 'mt-delete-dialog',
  template: `
    <h1 mat-dialog-title class="text-danger">
      <i class="fas fa-exclamation-triangle mr-3"></i>
      {{data && data.title || 'Please confirm your action'}}
    </h1>
    <div mat-dialog-content>
      <p>{{data?.subtitle || 'Are you sure?'}}</p>
      <p *ngIf="data.item">{{data.item}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="warn" [mat-dialog-close]="true">{{data?.confirmButtonText || 'Yes, delete.'}}</button>
    </div>
  `,
})
export class DeleteDialogComponent {
  @HostBinding('class.mat-dialog-warn') applyClazz = true;

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
