import {DateRangeType} from './date-range-type.enum';
import { CompareType } from './date-range.model';

export class DateRangeHolder {
  dateFrom: string;
  dateTo: string;
  dateRangeType: DateRangeType;

  constructor(dateFrom: string, dateTo: string, dateRangeType?: DateRangeType) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.dateRangeType = dateRangeType;
  }
}

export class CompareDateRangeHolder extends DateRangeHolder{
  compareType: CompareType;
  constructor(dateFrom: string, dateTo: string, dateRangeType?: DateRangeType, compareType?: CompareType) {
    super(dateFrom = dateFrom, dateTo = dateTo, dateRangeType = dateRangeType);
    this.compareType = compareType;
  }
}
