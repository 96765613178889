import {Component, HostBinding, Input} from '@angular/core';
import { ValueType } from '../../../../shared/domain/value-type.enum';
import {PubDashChartType} from '../pub-dash-chart-type.enum';

@Component({
  selector: 'mt-metric-summary-card',
  templateUrl: './metric-summary-card.component.html',
  styleUrls: ['./metric-summary-card.component.scss']
})
export class MetricSummaryCardComponent {
  // @HostBinding('class') classList = 'mt-metric-summary-card';
  @Input() infoText: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() value: number;
  @Input() loading: boolean;
  @Input() valueType: ValueType = ValueType.Number;  // default pipe for value is number, can be percentage...

  ValueType = ValueType;
}
