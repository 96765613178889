import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Network } from '../domain/network.model';
import { Publisher, PublisherCreateRequest } from '../domain/publisher.model';
import { HttpUtils } from '../utils/http-utils';
import { Page } from '../domain/page.model';


@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  constructor(private httpClient: HttpClient) {
  }

  listNetworksPaging(filter?: any): Observable<Page<Network>> {
    return this.httpClient.get<Page<Network>>(`${environment.serviceUrl}/api/admin/networks`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  listNetworks(): Observable<Network[]> {
    return this.httpClient.get<Network[]>(`${environment.serviceUrl}/api/admin/networks/list-all`);
  }

  create(request: any) {
    return this.httpClient.post(`${environment.serviceUrl}/api/admin/networks`, request);
  }

  activateNetwork(id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/admin/networks/${id}/activate`,{});
  }

  archiveNetwork(id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/admin/networks/${id}/archive`, {});
  }

  update(networkId: string, request: any) {
    return this.httpClient.put(`${environment.serviceUrl}/api/admin/networks/${networkId}`, request);
  }

  getNetworkById(id) {
    return this.httpClient.get<Network>(`${environment.serviceUrl}/api/admin/networks/${id}`);
  }

  createPublisher(publisher: PublisherCreateRequest, id: string): Observable<Publisher> {
    return this.httpClient.post<Publisher>(`${environment.serviceUrl}/api/admin/networks/${id}/publishers`, publisher);
  }

  getStatusCount(filter: any) {
    return this.httpClient.get(`${environment.serviceUrl}/api/admin/networks/statuses`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }
}