import { Injectable } from '@angular/core';
import { User } from './user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Publisher } from '../../shared/domain/publisher.model';
import { UserRole, UserRoleNew } from './user-role.model';
import { Router, UrlTree } from '@angular/router';
import { PrincipalUtils } from './principal.utils';
import { PrincipalMask } from './principal-mask.model';
import { Advertiser } from 'src/app/shared/domain/advertiser.model';

@Injectable({ providedIn: 'root' })
export class Principal {

  userFromToken: User;
  public user: User;
  public publisher: Publisher;
  public advertiser: Advertiser;
  accessToken: string;

  mask: PrincipalMask = new PrincipalMask();

  constructor(private router: Router) {
  }

  setToken(token: string) {
    try {
      this.userFromToken = new JwtHelperService().decodeToken(token);
    } catch (e) {
      console.error('user could not be extracted from token');
    }
    this.accessToken = token;
  }

  getToken() {
    return this.accessToken;
  }

  cleanup() {
    console.log('principal cleanup');
    this.userFromToken = null;
    this.user = null;
    this.publisher = null;
    this.advertiser = null;
    this.accessToken = null;
    this.setMask();
  }

  setUser(user: User) {
    this.user = user;
    console.log('setUser: %o', user);
    if (user) {


      const mask = localStorage.getItem('mask');
      if (mask) {
        console.log('mask found in local cache %s', mask);
        this.mask = JSON.parse(mask);
        this.validateMask();
      } else {
        console.log('no mask in local cache');
      }
    }
  }

  getUser() {
    return this.user;
  }

  hasPermission(permission: string) {
    return this.hasAnyPermission([permission]);
  }

  hasAnyPermission(permissions: string[]) {
    const userRoles = this.user.userRoles;
    for (let role of userRoles) {
      if (role.permissions.includes('*:*')) {
        return true;
      }

      for (let permission of role.permissions) {
        if (permissions.some(p => p === permission)) {
          return true;
        }
      }
    }
    return false;
  }

  hasAuthority(authority: string) {
    return this.hasAnyAuthority([authority]);
  }

  hasAnyAuthority(authorities: string[]) {
    if (!authorities || !this.user || !this.user.roles) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (this.user.roles.indexOf(authorities[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  isAuthenticated() {
    return this.getToken() != null;
  }

  canAccess(app: 'admin' | 'publisher' | 'master' | 'co' | 'sr' | 'cm' | 'csm' | 'advertiser'): boolean | UrlTree {
    if (!this.hasAnyAuthority(PrincipalUtils.MAIN_ROLES) && !this.hasAnyAuthority(PrincipalUtils.ADDITIONAL_ROLES)) {
      alert('wrong user setup, please contact our support');
      return false;
    }

    if (!PrincipalUtils.isMasked(this)) {
      this.tryToSetDefaultMask();
    }

    //All Main Roles using main dash
    if (PrincipalUtils.MAIN_ROLES.includes(this.mask.role)) {
      return true;
    }

    if (PrincipalUtils.APP_MAP[app] !== this.mask.role) {
      console.log(this.mask.role)
      return this.router.createUrlTree([this.getRedirectPath()]);
    }

    return true;
  }

  setMask(role?: UserRoleNew, id?) {
    this.mask = new PrincipalMask();
    this.mask.role = role;
    if (role === UserRoleNew.PUBLISHER) {
      this.mask.publisherId = id;
    }
    if (role === UserRoleNew.ADVERTISER) {
      this.mask.advertiserId = id;
    }
    if (role === UserRoleNew.ADVERTISER_READ_ONLY) {
      this.mask.advertiserId = id;
    }
    // if (role === UserRole.CONTENT_OWNER) {
    //   this.mask.contentOwnerId = id;
    // }
    localStorage.setItem('mask', JSON.stringify(this.mask));
    console.log('setMask=%s', JSON.stringify(this.mask));
  }

  private tryToSetDefaultMask() {
    console.log('tryToSetDefaultMask');
    // const mainRoles = PrincipalUtils.filterMainRoles(this.user.roles);
    console.log(<UserRoleNew>this.user.roles[0])

    // set defaults
    if (this.user.roles.length === 1) {
      const maskRole = <UserRoleNew>this.user.roles[0];
      switch (maskRole) {
        case UserRoleNew.PUBLISHER:
          if (this.user.publisherIds.length === 1) {
            this.setMask(maskRole, this.user.publisherIds[0]);
          }
          break;
        case UserRoleNew.ADVERTISER:
          if (this.user.advertiserId) {
            this.setMask(maskRole, this.user.advertiserId);
          }
          break;
        case UserRoleNew.ADVERTISER_READ_ONLY:
          if (this.user.advertiserId) {
            this.setMask(maskRole, this.user.advertiserId);
          }
          break;
        case UserRoleNew.ADMIN:
          this.setMask(maskRole, null);
          break;
        case UserRoleNew.SUPER_ADMIN:
          this.setMask(maskRole, null);
          break;
        case UserRoleNew.ADMIN_READ_ONLY:
          this.setMask(maskRole, null);
          break;
        case UserRoleNew.USER:
          this.setMask(maskRole, null);
          break;
        case UserRoleNew.USER_READ_ONLY:
          this.setMask(maskRole, null);
          break;
      }
    }
  }

  getRedirectPath() {
    if (!PrincipalUtils.isMasked(this)) {
      return '/select';
    }

    switch (this.mask.role) {
      case UserRoleNew.ADMIN:
        return '/';
      case UserRoleNew.PUBLISHER:
        return `/p/${this.mask.publisherId}`;
      case UserRoleNew.ADVERTISER:
        return `/adv/${this.mask.advertiserId}`;
      case UserRoleNew.ADVERTISER_READ_ONLY:
        return `/adv/${this.mask.advertiserId}`;
      case UserRoleNew.USER_READ_ONLY:
        return `/`;
      case UserRoleNew.USER:
        return `/`;
      case UserRoleNew.ADMIN_READ_ONLY:
        return `/`;
      case UserRoleNew.SUPER_ADMIN:
        return `/`;
    }
  }

  private validateMask() {
    const roleOk = this.user.roles.includes(this.mask.role);
    const pubOk = !this.mask.publisherId || this.user.publisherIds && this.user.publisherIds.includes(this.mask.publisherId);
    if (!roleOk || !pubOk) {
      this.mask = new PrincipalMask();
    }
  }
}
