import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SortUtils } from '../../utils/sort-utils';
import { User } from 'src/app/core/auth/user.model';
import { UsersService } from '../../services/users.service';
import _ from 'lodash';
import { UserRole } from 'src/app/core/auth/user-role.model';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-users-autocomplete',
  template: ` <mat-form-field
  [floatLabel]="floatLabel || 'always'"
  class="w-100"
  [ngClass]="formFieldClass"
  [appearance]="formFieldAppearance ? formFieldAppearance : 'outline'"
>
  <mat-label>{{label ? label : 'Choose user'}}</mat-label>
  <input
    matInput
    #search
    aria-label="User"
    [matAutocomplete]="auto"
    [formControl]="filterCtrl"
    [required]="required"
  />
  <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="optionSelected($event)"
    [displayWith]="display"
  >
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{ item.name }} <small class="text-muted"> - {{item.email}}</small>
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="formFieldHint">{{formFieldHint ? formFieldHint : ''}}</mat-hint>
</mat-form-field>`,
})
export class UsersAutocompleteComponent implements OnInit {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input() public placeholder: string;
  @Input() public floatLabel: FloatLabelType;
  @Input() public publisherIds: string[];
  @Input() public label: string;
  @Input() public formFieldClass: string;
  @Input() public formFieldAppearance: string;
  @Input() public formFieldHint: string;
  @Input() public required: boolean;
  @Input() public filterByRoles: UserRole[];
  @Input() public preselectedUserId: User['id'];
  @Output() userSelected = new EventEmitter<User>();

  filteredItems: Subject<User[]>; //List of Users to show in dropdown filtered with 'filteredUserIds'
  filterCtrl = new UntypedFormControl();
  usersList: User[] = []; //stores currently fetched list of users
  selectedUser: User; //currently selected user
  filteredUserIds: string[] = []; //It stores all the IDs of selected user
  public loading: boolean = false;

  constructor(
    private service: UsersService,
    private snackBarService: SnackBarService) {
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getUsers(searchTerm);
          }
          else if (searchTerm == '') { //triggers when user deletes everything from input
            this.filteredItems.next(undefined);
            this.userSelected.emit(null);
          }
        }
      )
  }

  ngOnInit(): void {
    if (this.preselectedUserId) {
      this.getUsers('', this.preselectedUserId);
    }
  }

  /** SearchTerm used for filtering by pub name. preselectedUserId used to get only one user (preselected) at the component initialization. */
  private getUsers(searchTerm?: string, preselectedUserId?: string) {
    this.loading = true
    this.filterCtrl.disable();
    return this.service.list({
      page: 0,
      size: 1000,
      enabled: true,
      userRole: this.filterByRoles,
      searchTerm: searchTerm,
      ids: [preselectedUserId]
    })
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedUserId ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected users
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe(
        (resp) => {
          this.usersList = resp.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedUserId) {
            this.selectedUser = this.usersList[0]; //Naturally since the api call is filtered with one ID, selectedUser will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedUser);
            this.updateFilteredUsers([this.selectedUser]);
            this.emit();
          }
          this.filteredItems.next(this.usersList.filter(x => !this.filteredUserIds.includes(x.id)))
        },
        (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching users autocomplete data');
          }
        },
      );
  }

  public optionSelected($event) {
    this.selectedUser = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.userSelected.emit(this.selectedUser);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredUserIds. */
  public updateFilteredUsers(items) {
    this.filteredUserIds = items.map((item) => item.id);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(p?: User) {
    return p ? p.name : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined) //Reset list since the input value of autocomplete is empty
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
