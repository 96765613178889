import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Advertiser } from "../domain/advertiser.model";
import { environment } from "src/environments/environment";
import { HttpUtils } from "../utils/http-utils";
import { Page } from "../domain/page.model";

@Injectable({
    providedIn: 'root'
})

export class AdvertiserService {

    constructor(private httpClient: HttpClient){}

    getAdvertiserById(id: string): Observable<Advertiser> {
        return this.httpClient.get<Advertiser>(`${environment.serviceUrl}/api/advertisers/${id}`);
    }

    getAllAdvertisers(filter: any): Observable<Page<Advertiser>> {
        return this.httpClient.get<Page<Advertiser>>(`${environment.serviceUrl}/api/advertisers`, {
            params: HttpUtils.getHttpParams(filter)
        });
    }

    updateAdvertiser(id: string, advertiserUpdateDto: any): Observable<Advertiser> {
        return this.httpClient.put<Advertiser>(`${environment.serviceUrl}/api/advertisers/${id}`, advertiserUpdateDto)
    }

    createAdvertiser(advertiser: any): Observable<Advertiser> {
        return this.httpClient.post<Advertiser>(`${environment.serviceUrl}/api/advertisers`, advertiser)
    }
}