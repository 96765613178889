import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFilters } from '../interfaces/filters.model';
import { Observable } from 'rxjs';
import { Brand, CreativeType, Discussion, DiscussionPost, LineItem, Order } from '../domain/campaigns.model';
import { Page } from '../domain/page.model';
import { urlJoin } from 'url-join-ts';
import { environment } from '../../../environments/environment';
import { HttpUtils } from '../utils/http-utils';
import { CreativeLibrary } from '../domain/creative-library.model';
import {
  IAddCreativeLibraryFormData
} from '../../modules/campaigns/pages/creative-library/components/add-creative-library-dialog/add-creative-library-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  private readonly endpoint = 'api/campaign';

  constructor(private httpClient: HttpClient) {
  }

  //#region LineItems
  public getLineItemsList(): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/campaign/line-items`);
  }

  public getLineItemsListForOrderId(filter?: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/campaign/line-items`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getLineItemsListForCreativeId(filter?: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.serviceUrl}/api/campaign/line-items`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public addLineItem(data: LineItem): Observable<any> {
    return this.httpClient.post<LineItem>(urlJoin(environment.serviceUrl, this.endpoint, 'line-items'), data);
  }

  public updateLineItem(data: LineItem): Observable<any> {
    return this.httpClient.put<LineItem>(urlJoin(`${environment.serviceUrl}/api/campaign/line-items/${data.id}`), data);
  }

  public getLineItemByID(id: string): Observable<LineItem> {
    return this.httpClient.get<LineItem>(`${environment.serviceUrl}/api/campaign/line-items/${id}`);
  }

  public getLineItemsStatuses(filters: IFilters): Observable<{ [key: string]: number }> {
    return this.httpClient.get<{ [key: string]: number }>(urlJoin(environment.serviceUrl, this.endpoint, 'line-items/statuses'), {
      params: HttpUtils.getHttpParams(filters)
    });
  }

  public getLineItemsDeliveryStatuses(filters: IFilters): Observable<{ [key: string]: number }> {
    return this.httpClient.get<{ [key: string]: number }>(urlJoin(environment.serviceUrl, this.endpoint, 'line-items/delivery-statuses'), {
      params: HttpUtils.getHttpParams(filters)
    });
  }

  public extendLineItem(lineItemId: string, data: LineItem): Observable<any> {
    return this.httpClient.post<LineItem>(urlJoin(environment.serviceUrl, this.endpoint, `line-items/${lineItemId}/extend`), data);
  }
  //#endregion

  //#region Advertiser - newly BRAND 12.2.2023
  public getBrandList(filter: any): Observable<Page<Brand>> {
    return this.httpClient.get<Page<Brand>>(`${environment.serviceUrl}/api/campaign/brands`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getBrandByID(id: string): Observable<Brand> {
    return this.httpClient.get<Brand>(`${environment.serviceUrl}/api/campaign/brands/${id}`);
  }

  public createBrand(data: Brand): Observable<Brand> {
    return this.httpClient.post<Brand>(`${environment.serviceUrl}/api/campaign/brands`, data);
  }

  public updateBrand(data: Brand): Observable<Brand> {
    return this.httpClient.put<Brand>(`${environment.serviceUrl}/api/campaign/brands/${data.id}`, data);
  }

  //#region Orders
  public getOrdersList(filter?: any): Observable<Page<Order>> {
    return this.httpClient.get<Page<Order>>(`${environment.serviceUrl}/api/campaign/orders`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getOrderByID(id: string): Observable<Order> {
    return this.httpClient.get<Order>(`${environment.serviceUrl}/api/campaign/orders/${id}`);
  }

  public createOrder(data: Order): Observable<any> {
    return this.httpClient.post<any>(`${environment.serviceUrl}/api/campaign/orders`, data);
  }

  public updateOrder(request: Order): Observable<any> {
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/campaign/orders/${request.id}`, request);
  }

  public getOrdersStatuses(filters?: IFilters): Observable<{ [key: string]: number }> {
    return this.httpClient.get<{ [key: string]: number }>(urlJoin(environment.serviceUrl, this.endpoint, 'orders/statuses'), {
      params: HttpUtils.getHttpParams(filters)
    });
  }

  public createDiscussion(data: Discussion, orderId: string): Observable<Discussion> {
    return this.httpClient.post<Discussion>(`${environment.serviceUrl}/api/campaign/orders/${orderId}/discussions`, data);
  }

  public getDiscussionById(discussionId: string, orderId: string): Observable<Discussion> {
    return this.httpClient.get<Discussion>(`${environment.serviceUrl}/api/campaign/orders/${orderId}/discussions/${discussionId}`);
  }

  public getDiscussions(orderId: string): Observable<Discussion[]> {
    return this.httpClient.get<Discussion[]>(`${environment.serviceUrl}/api/campaign/orders/${orderId}/discussions`);
  }

  public createPost(data: DiscussionPost, discussionId: string, orderId: string): Observable<DiscussionPost> {
    return this.httpClient.post<DiscussionPost>(`${environment.serviceUrl}/api/campaign/orders/${orderId}/discussions/${discussionId}/post`, data);
  }

  public discussionSeen(discussionId: string, orderId: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.serviceUrl}/api/campaign/orders/${orderId}/discussions/${discussionId}/seen`, {});
  }
  //#endregion


  //#region Creatives
  public getCreativeLibraryList(filters: IFilters): Observable<Page<CreativeLibrary>> {
    return this.httpClient.get<Page<CreativeLibrary>>(urlJoin(environment.serviceUrl, this.endpoint, 'creatives'), {
      params: HttpUtils.getHttpParams(filters)
    });
  }

  public createCreativeLibrary(data: IAddCreativeLibraryFormData): Observable<CreativeLibrary> {
    const formData: FormData = new FormData();
    let request = { ...data }
    if (data.file && data.file.name) {
      request.creative = data.file.name;
    }

    delete request.file
    formData.append('createRequest', new Blob([JSON.stringify(request)], { type: "application/json" }));
    formData.append('file', data.file);
    return this.httpClient.post<CreativeLibrary>(urlJoin(environment.serviceUrl, this.endpoint, 'creatives'), formData);
  }

  public updateCreativeLibrary(creativeLibrary: CreativeLibrary): Observable<CreativeLibrary> {
    return this.httpClient.put<CreativeLibrary>(urlJoin(environment.serviceUrl, this.endpoint, 'creatives', creativeLibrary.id),
      creativeLibrary
    );
  }

  public getCreativesStatuses(filters: IFilters): Observable<{ [key: string]: number }> {
    return this.httpClient.get<{ [key: string]: number }>(urlJoin(environment.serviceUrl, this.endpoint, 'creatives/statuses'), {
      params: HttpUtils.getHttpParams(filters)
    });
  }
  //#endregion

  public uploadCampaignsFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(`${environment.serviceUrl}/api/campaign/orders/attachment-upload`, formData);
  }

}