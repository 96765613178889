import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ContentOwnerService} from '../../../../../../shared/services/content-owner.service';
import {GlobalSpinnerService} from '../../../../../../core/services/global-spinner.service';
import {SnackBarService} from '../../../../../../core/services/snackbar.service';
import {ServerErrorUtils} from '../../../../../../shared/utils/server-error-utils';
import {PublishersService} from '../../../../../../shared/services/publishers.service';

@Component({
  selector: 'mt-documents-shared-upload-document-dialog',
  templateUrl: './documents-shared-upload-document-dialog.component.html',
  styleUrls: ['./documents-shared-upload-document-dialog.component.scss']
})
export class DocumentsSharedUploadDocumentDialogComponent implements AfterViewInit {

  documentsType: {type: 'co' | 'publisher'; id: string};
  documentDescription: string;
  file: File;
  isEdit: boolean;
  documentId: string;

  constructor(public dialogRef: MatDialogRef<DocumentsSharedUploadDocumentDialogComponent>,
              private service: ContentOwnerService,
              private publishersService: PublishersService,
              private gss: GlobalSpinnerService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data:
                {documentsType: {type: 'co' | 'publisher'; id: string}; documentId?: string; file?: File; description?: string}) {
    this.documentsType = data.documentsType;
    if (data && data.documentId) {
      this.isEdit = true;
      this.documentId = data.documentId;
    }
    if (data && data.file) {
      this.file = data.file;
    }
    if (data && data.description) {
      this.documentDescription = data.description;
    }
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('450px');
  }

  uploadDocument() {
    let serv;
    if (this.documentsType && this.documentsType.type === 'publisher') {
      serv = this.publishersService;
    } else {
      serv = this.service;
    }
    this.gss.showLoader();
    if (!this.isEdit) {
      serv.uploadDocument(this.documentsType.id, this.file, this.documentDescription).subscribe(
        response => {
          this.gss.hideLoader();
          this.dialogRef.close(true);
          this.snackBarService.success('You have successfully added document');
        }, error => {
          this.gss.hideLoader();
          ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error occurred during document upload');
        }
      );
    } else {
      serv.editDocumentDescription(this.documentId, this.documentDescription).subscribe(
        response => {
          this.gss.hideLoader();
          this.dialogRef.close(true);
          this.snackBarService.success('You have successfully updated description');
        }, error => {
          this.gss.hideLoader();
          ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error occurred during description update');
        }
      );
    }
  }
}
