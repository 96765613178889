import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }
  public back(url: string): void {
    if (url) {
      this.router.navigateByUrl(url);
    }
    else {
      if (this.history[this.history.length-2]) {
        this.history.pop();
        this.location.back();
      }
      else {
        //User can open link straight from somewhere and since we dont have any history on our side, so the back button would naturally redirect
        //back to the site the user was before, this code intercepts that and redirects to parent url inside our app
        this.router.navigateByUrl(this.router.url.split('/')[1]);
      }
    }
  }

  public goToPreviousUrl(): void {
    if (this.getPreviousUrl()) {
      this.router.navigateByUrl(this.getPreviousUrl());
    }
    else {this.location.back();}
  }

  public getPreviousUrl(): string {
    if (this.history.length > 0) {
      return this.history[this.history.length - 2];
    }
    return '';
  }
}
