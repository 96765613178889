import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {Publisher} from '../../shared/domain/publisher.model';
import {AuthService} from '../auth/auth.service';
import {LoginService} from '../login/login.service';
import {catchError, map} from 'rxjs/operators';
import {Principal} from '../auth/principal.service';
import {UserRole, UserRoleNew} from '../auth/user-role.model';

@Injectable()
export class PublisherResolver  {
  constructor(private authService: AuthService, private loginService: LoginService, private principal: Principal) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const user = this.principal.getUser();
    if (route.params.id && user.publisherIds.length && user.publisherIds.includes(route.params.id)) {
      this.principal.setMask(user.roles.includes(UserRoleNew.PUBLISHER) ? UserRoleNew.PUBLISHER : null, route.params.id);
    }
    return this.authService.publisherIdentity().pipe(
      map(publisher => publisher),
      catchError(error => {
        this.loginService.logout(true);
        return of(null);
      })
    );
  }
}
