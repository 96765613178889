import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UsersService } from '../../../shared/services/users.service';
import { Principal } from '../../../core/auth/principal.service';
import { GlobalSpinnerService } from '../../../core/services/global-spinner.service';
import { SnackBarService } from '../../../core/services/snackbar.service';
import { ServerErrorUtils } from '../../../shared/utils/server-error-utils';
import { User } from '../../../core/auth/user.model';
import { UserRole, UserRoleKL, UserRoleKLNew, UserRoleNew } from '../../../core/auth/user-role.model';
import { Publisher, PublisherStatus } from '../../../shared/domain/publisher.model';
import { ContentOwnerService } from '../../../shared/services/content-owner.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';
import { PrincipalUtils } from 'src/app/core/auth/principal.utils';
import { Role } from "../../../shared/domain/roles-permissions.model";
import { RolesService } from "../../../shared/services/roles.service";
import { AdvertiserService } from 'src/app/shared/services/advertiser.service';
import { Advertiser } from 'src/app/shared/domain/advertiser.model';
import { NetworkService } from 'src/app/shared/services/network.service';
import { Network } from 'src/app/shared/domain/network.model';

@Component({
  selector: 'mt-user-editor-dialog-dialog',
  templateUrl: './user-editor-dialog.component.html',
  styleUrls: ['./user-editor-dialog.component.css']
})
export class UserEditorDialogComponent implements OnInit, AfterViewInit {
  public readonly RolePermission = RolePermission;
  isEdit: boolean;
  showPassword: boolean = false;
  user = new User();
  userRoles: Role[];
  userRoleKL = UserRoleKL();
  userRole = UserRole;
  userRoleKLNew = UserRoleKLNew();
  userRoleNew = UserRoleNew;
  publisherStatuses = [PublisherStatus.ACTIVE];
  userType: { type: 'co' | 'publisher'; id: string };
  disabled = false;
  networkId: string;
  eventsSubjectNetwork = new Subject<string[]>();
  clearFilterItems: Subject<void> = new Subject<void>();
  advertiserList: Advertiser[] = [];
  isAdvertiserListLoading: boolean = false;
  isAdvertiserDropdownDisabled: boolean = true;
  networkList: Network[] = [];
  isNetworkListLoading: boolean = true;
  isNetworkDropdownDisabled: boolean = true;
  userNetwork: string = '';

  constructor(public dialogRef: MatDialogRef<UserEditorDialogComponent>,
    private service: UsersService,
    private rolesService: RolesService,
    private principal: Principal,
    private route: ActivatedRoute,
    private gss: GlobalSpinnerService,
    private snackBarService: SnackBarService,
    private coService: ContentOwnerService,
    private advertiserService: AdvertiserService,
    private networkService: NetworkService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = new User();
    if (data && data.userType) {
      this.userType = data.userType;
      this.userType.type === 'co' ? this.user.contentOwnerIds = [this.userType.id] : this.user.publisherIds = [this.userType.id];
      if (this.userType.type === 'co') {
        this.getContentOwnerDetail();
      }
      this.user.roles = [this.userType.type && this.userType.type === 'co' ? this.userRole.CONTENT_OWNER : this.userRole.PUBLISHER];
      this.disabled = true;
    }
    if (data && data.user) {
      this.isEdit = !!data.user;
      this.user = data.user;
    }
    if (data && data.publisherId) {
      this.user.publisherIds = data.publisherId ? [data.publisherId] : [];
    }

    if (data && data.networkId) {
      this.networkId = data.networkId;
      this.eventsSubjectNetwork.next([this.networkId]);
    }
  }

  ngOnInit(): void {
    if (this.networkId) {
      this.getAdvertisers();
      this.userRoleKL = this.userRoleKL.filter(item => ![UserRole.CONTENT_OWNER, UserRole.READ_ONLY_ADMIN, UserRole.CAMPAIGN_MANAGER, UserRole.CLIENT_SERVICE_MANAGER].includes(item.key));
    } else {
      this.getNetworks();
    }

    if (this.user?.network === undefined) {
      return;
    } else {
      this.userNetwork = this.user.network.id
    }

  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('500px');
  }

  isPublisher() {
    return this.user.roles && (this.user.roles.includes(this.userRoleNew.PUBLISHER));
  }

  isAdvertiser() {
    return this.user.roles && ((this.user.roles.includes(this.userRoleNew.ADVERTISER) || this.user.roles.includes(this.userRoleNew.ADVERTISER_READ_ONLY)));
  }

  // Determine if the currently logged user has SA priviledges
  isSA() {
    return this.principal.user.roles.includes(this.userRoleNew.SUPER_ADMIN);
  }

  public getAdvertisers() {
    this.isAdvertiserListLoading = true;
    this.isAdvertiserDropdownDisabled = true;
    // Get only Active Advertisers
    this.advertiserService.getAllAdvertisers({ status: "ACTIVE" }).subscribe({
      next: (data) => {
        this.advertiserList = data.content;
        this.isAdvertiserListLoading = false;
        this.isAdvertiserDropdownDisabled = false;
      },
      error: () => {
        this.isAdvertiserListLoading = false;
        this.isAdvertiserDropdownDisabled = true;
        this.snackBarService.error('Error while fetching advertiser data');
      }
    }
    );
  }

  public getNetworks() {
    this.isNetworkListLoading = true;
    this.isNetworkDropdownDisabled = true;
    // Get only Active Networks
    this.networkService.listNetworksPaging({ status: "ACTIVE" }).subscribe({
      next: (data) => {
        this.networkList = data.content;
        this.isNetworkListLoading = false;
        this.isNetworkDropdownDisabled = false;
      },
      error: () => {
        this.isNetworkListLoading = false;
        this.isNetworkDropdownDisabled = true;
        this.snackBarService.error('Error while fetching network data');
      }
    }
    );
  }

  createUser() {
    this.gss.showLoader();

    if (!this.user.roles) {
      this.user.roles = [];
    }

    if (!(this.user.roles.includes(UserRoleNew.PUBLISHER))) {
      this.user.publisherIds = [];
    }

    if (!this.user.roles.includes(UserRole.CONTENT_OWNER)) {
      this.user.contentOwnerIds = [];
    }
    if (this.isEdit) {

      if (this.networkId) {
        this.removeAdvertiserIdIfNoRole();
        this.service.update(this.user).subscribe({
          next: (next) => {
            this.gss.hideLoader();
            this.dialogRef.close(true);
            this.snackBarService.success('User updated successfully');
          },
          error: (e) => {
            const messages = ServerErrorUtils.getValidationMessages(e);
            if (messages) {
              messages.forEach(m => this.snackBarService.error(m));
            } else {
              this.snackBarService.error('Error occurred during user creation');
            }
            this.gss.hideLoader();
          }
        });
      } else {
        this.service.updateUserSuperAdmin(this.user).subscribe({
          next: (next) => {
            this.gss.hideLoader();
            this.dialogRef.close(true);
            this.snackBarService.success('User updated successfully');
          },
          error: (e) => {
            const messages = ServerErrorUtils.getValidationMessages(e);
            if (messages) {
              messages.forEach(m => this.snackBarService.error(m));
            } else {
              this.snackBarService.error('Error occurred during user creation');
            }
            this.gss.hideLoader();
          }
        });
      }

    } else {
      if (!this.networkId) {
        this.createNetworkUser();
      } else {
        this.createNormalUser();
      }
    }
  }

  public removeAdvertiserIdIfNoRole() {
    if (!(this.user.roles.includes(UserRoleNew.ADVERTISER) || this.user.roles.includes(UserRoleNew.ADVERTISER_READ_ONLY))) {
      this.user.advertiserId = null;
    }
  }

  createNetworkUser() {
    this.service.createUserSuperAdmin(this.user, this.userNetwork).subscribe(next => {
      this.gss.hideLoader();
      this.dialogRef.close(true);
      this.snackBarService.success('User created successfully');
    },
      (e) => {
        const messages = ServerErrorUtils.getValidationMessages(e);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error occurred during user creation');
        }
        this.gss.hideLoader();
      }
    );
  }

  createNormalUser() {
    this.service.create(this.user).subscribe(next => {
      this.gss.hideLoader();
      this.dialogRef.close(true);
      this.snackBarService.success('User created successfully');
    },
      (e) => {
        const messages = ServerErrorUtils.getValidationMessages(e);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error occurred during user creation');
        }
        this.gss.hideLoader();
      }
    );
  }

  onPublisherSelection(pubs: Publisher[]) {
    this.user.publisherIds = pubs.map(p => p.id);
  }

  onContentOwnerSelection(contentOwnerIds: string[]) {
    this.user.contentOwnerIds = contentOwnerIds;
  }

  getContentOwnerDetail() {
    this.coService.getCoDetails(this.userType.id).subscribe(
      response => {
        this.user.contentOwners = [response];
      },
      error => {
        ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error occurred fetching content owner');
      }
    );
  }

  moreThanOneMainRoleSelected(): boolean {
    return PrincipalUtils.hasMoreThanOneMainRole(this.user);
  }

  // getRoles(){
  //   this.gss.showLoader();

  //   this.rolesService.getAllRoles().subscribe(next => {
  //         this.gss.hideLoader();
  //         this.userRoles = next;

  //         this.setRoleSelection();
  //       },
  //       (e) => {
  //         const messages = ServerErrorUtils.getValidationMessages(e);
  //         if (messages) {
  //           messages.forEach(m => this.snackBarService.error(m));
  //         } else {
  //           this.snackBarService.error('Error occurred during role fetch');
  //         }
  //         this.gss.hideLoader();
  //       }
  //   );
  // }

  // private setRoleSelection(){
  //   if(!this.isEdit){
  //     return;
  //   }

  //   this.user.roleIds = [];
  //   this.user.userRoles.forEach(userRole => {
  //     this.user.roleIds.push(userRole.id);
  //   });
  // }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  generateSecurePassword() {
    let passwordLength = 12;
    let specialCharacters = ['!', '@', '?', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '-', '='];

    this.user.password = this.generatePassword(passwordLength, specialCharacters);
    this.snackBarService.success("Password generated successfully.")
  }

  private generatePassword(passwordLength: number, specialCharacters: string[]): string {
    const lowercaseLetters = 'abcdefghijkmnpqrstuvwxyz'; // excluding 'l' and 'o'
    const uppercaseLetters = lowercaseLetters.toUpperCase();
    const numbers = '23456789'; // excluding '0', '1', and '0'
    const allCharacters = lowercaseLetters + uppercaseLetters + numbers + specialCharacters.join('');

    let password = '';

    // Add at least one special character and one number to the password
    password += this.getRandomElement(specialCharacters) + this.getRandomElementFromString(numbers);

    // Generate remaining characters for the password
    for (let i = password.length; i < passwordLength; i++) {
      password += this.getRandomElementFromString(allCharacters);
    }

    return password;
  }

  private getRandomElement(array: string[]): string {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  private getRandomElementFromString(str: string): string {
    const randomIndex = Math.floor(Math.random() * str.length);
    return str.charAt(randomIndex);
  }

}
