import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {Domain, DomainListFilter, DomainStatus} from '../../domain/domain.model';
import {PublisherDomainsService} from "../../services/publisher-domains.service";
import {DomainsService} from "../../services/domains.service";
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'mt-domain-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'" class="w-100" [ngClass]="formFieldClass" [appearance]="formFieldAppearance ? formFieldAppearance : undefined">
      <mat-label >{{label ? label : 'Choose domain(s)'}}</mat-label>
      <input matInput
             #url
             aria-label="Domain"
             (focus)="onFocusEvent()"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             placeholder="Search">
      <i class="fas fa-spinner fa-spin" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.url}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint *ngIf="allDomains?.length === 0 && loading === false" class="text-warning">Publisher doesn't have any domains</mat-hint>
    </mat-form-field>`,
})
export class DomainAutocompleteComponent implements OnInit {
  @ViewChild('url', {static: true}) urlFilter: ElementRef;

  @Input()
  public placeholder: string;

  @Input()
  public url: string;

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  public publisherIds: string[];

  @Input()
  public items: Domain[];

  @Input()
  public pub: boolean;

  @Output()
  domainSelected = new EventEmitter<Domain>();

  serverSearching = false;

  filteredItems: Subject<Domain[]>;
  filterCtrl = new UntypedFormControl();
  selectedDomain;
  allDomains: Domain[] = [];
  filteredDomainIds: string[] = [];
  loading = false;
  filter: DomainListFilter;


  constructor(private publisherDomainsService: PublisherDomainsService, private domainsService: DomainsService) {
    this.loading = true;
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(next => {
        this.filteredItems.next([]);
        if (!next && this.selectedDomain) {
          this.selectedDomain = undefined;
          this.emit();
        }

        if (next && typeof next !== 'object') {
          this.serverSearching = true;
          this.filterCtrl.disable();
          this.allDomains.length === 0 ? this.getAllDomains() : this.searchDomain();
        }
      });


  }

  resetInput() {
    this.selectedDomain = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {

    this.filter = {
      page: 0,
      size: 1000,
      sortProperty: 'url',
      sortDirection: 'ASC',
      status: [DomainStatus.ACTIVE],
      webVideoStatus: [],
      immuContentType: [],
      publisherIds: this.publisherIds
    };

    this.getAllDomains();
    if (this.url) {
      const dom = Domain.of(this.url);
      this.selectedDomain = dom;
      this.filterCtrl.setValue(dom);
      this.emit();
    }
  }

  private httpCall(): any{
    if (this.pub) {
      return this.getPubDomainsList({publisherIds: this.publisherIds});
    }
    else {
      return this.getDomainsList(this.filter);
    }
  }

  private getDomainsList(filter: any): any {
    return this.domainsService.getAllDomains(filter);
  }

  private getPubDomainsList(filter: any): any {
    return this.publisherDomainsService.getAllPubDomains(filter);
  }

  display(p?: Domain) {
    return p ? p.url : undefined;
  }

  optionSelected($event) {
    this.selectedDomain = $event.option.value;
    this.filteredItems.next([]);
    this.urlFilter.nativeElement.blur();
    this.emit();
  }

  private emit() {
    this.domainSelected.emit(this.selectedDomain);
  }

  getAllDomains() {
    this.httpCall().subscribe(
      response => {
        this.allDomains = response.content;
      },
      error => {
        console.log(error);
      },
    () => {
      this.serverSearching = false;
      this.filterCtrl.enable();
      this.loading = false;
    }
    );
  }

  onFocusEvent() {
    if (!this.filterCtrl.value?.length) {
      this.filteredItems.next(this.allDomains.filter(item => !this.filteredDomainIds.includes(item.url)));
    }
  }

  searchDomain() {
    if (this.allDomains.length > 0) {
      const search = this.allDomains.filter(domain => domain.url.toLowerCase().includes(this.filterCtrl.value.toLowerCase()));
      this.filteredItems.next(
        search.filter(
          (item) => !this.filteredDomainIds.includes(item.url)
        )
      );
    }

    this.filterCtrl.enable();
    this.urlFilter.nativeElement.focus();
    this.serverSearching = false;
    this.loading = true;
  }

  //Fill the list with choosen domains so we dont show them again on dropdown list
  updateFilteredDomains(items) {
    this.filteredDomainIds = items.map(item => item.url);
  }

  disableSearch(disable: true) {
    disable ? this.filterCtrl.disable() : this.filterCtrl.enable();
  }
}
