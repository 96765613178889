import { User } from "src/app/core/auth/user.model";
import { Network } from "./network.model";

export class Advertiser {
    id: string;
    altId: string;
    name: string;
    status: string;
    network: Network;
    createdBy: User;
    createdOn: string;
}