import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ContentOwnerAutocompleteComponent} from './content-owner-autocomplete.component';
import {ContentOwner} from '../../domain/content-owner.model';

@Component({
  selector: 'mt-content-owner-autocomplete-chips',
  template: `
    <div class="cs-publisher-autocomplete-chips-wrapper">
      <mt-content-owner-autocomplete #cat
                                  (contentOwnerSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass" [disabled]="disabled"
      [formFieldAppearance]="formFieldAppearance ? formFieldAppearance : ''">
      </mt-content-owner-autocomplete>
      <mat-chip-list #chipList class="mt-n3"  [ngClass]="[stacked ? 'mat-chip-list-stacked' : '', items.length > 0 ? 'mb-4' : '']" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="!disabled"
                        (removed)="remove(item)">
          {{item.name}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class ContentOwnerAutocompleteChipsComponent implements OnInit {

  @ViewChild(ContentOwnerAutocompleteComponent, {static: true}) cat: ContentOwnerAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public contentOwners: ContentOwner[];

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  disabled: boolean;

  @Output()
  contentOwnerSelectionChange = new EventEmitter<string[]>();

  items: ContentOwner[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.contentOwners && this.contentOwners.length) {
      this.items = this.contentOwners;
    }
  }

  onItemSelected(contentOwner: ContentOwner) {
    if (contentOwner && this.items.findIndex(co => co.id === contentOwner.id) === -1) {
      this.items.push(contentOwner);
      this.contentOwnerSelectionChange.emit(this.items.map(co => co.id));
    }
    this.cat.resetInput();
    this.cat.updateFilteredCos(this.items);
  }

  remove(contentOwner: ContentOwner) {
    const index = this.items.findIndex(co => co.id === contentOwner.id);
    if (index > -1) {
      this.items.splice(index, 1);
      this.contentOwnerSelectionChange.emit(this.items.map(co => co.id));
      this.cat.updateFilteredCos(this.items);
    }
  }
}
