import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ArtistsAutocompleteComponent } from './artists-autocomplete.component';

@Component({
  selector: 'mt-artists-autocomplete-chips',
  template: `
    <div class="mt-artist-autocomplete-chips-wrapper">
      <mt-artists-autocomplete #ec [activeArtists]="activeArtists" [availableArtists]="availableArtists" (artistSelected)="onItemSelected($event)" [formFieldClass]="formFieldClass">
      </mt-artists-autocomplete>
      <mat-chip-list #chipList [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="true"
                        (removed)="remove(item)">
          {{item}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class ArtistsAutocompleteChipsComponent implements OnInit {

  @ViewChild(ArtistsAutocompleteComponent, {static: true}) ec: ArtistsAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public activeArtists: string[];

  @Input()
  public availableArtists: string[];

  @Input()
  public availableArtistsFlag: boolean;

  @Input()
  public formFieldClass: string;

  @Output()
  artistSelectionChange = new EventEmitter<string[]>();

  items: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.items = this.activeArtists;
    if (this.availableArtistsFlag) {
      this.ec.availableArtistsFlag = this.availableArtistsFlag;
    }
  }

  onItemSelected($event: string) {
    if ($event && !this.items.find(x => x === $event)) {
      this.items.push($event);
      this.artistSelectionChange.emit(this.items);
    }
    this.ec.resetInput();
    this.ec.updateFilteredArtists(this.items);
  }

  remove(e: string) {
    this.items.splice(this.items.indexOf(e), 1);
    this.artistSelectionChange.emit(this.items);
    this.ec.updateFilteredArtists(this.items);
  }
}
