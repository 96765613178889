import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpUtils } from '../utils/http-utils';
import { Page } from '../domain/page.model';
import {
  DemandPartner,
  DemandPartnerCreate,
  DemandPartnersRequest,
  DemandPartnerStatus,
} from '../domain/demand-partner.model';
import {
  DemandPartnerDomain,
  DemandPartnerDomainFilter,
} from '../domain/demand-partner-domain.model';
import {
  DemandPartnerConnection,
  DemandPartnerConnectionCreate,
  DemandPartnersConnectionRequest,
} from '../domain/demand-partner-connection.model';
import { Placements } from '../domain/placements.model';
import { first } from 'rxjs/operators';
import { DpAppCompliance } from '../domain/demand-partner-compliance.model';
import { Discrepancy, DiscrepancyImportHistory } from '../domain/discrepancies.model';

@Injectable({
  providedIn: 'root',
})
export class DemandPartnersService {
  constructor(private httpClient: HttpClient) {}

  list(filter: DemandPartnersRequest): Observable<Page<DemandPartner>> {
    return this.httpClient.get<Page<DemandPartner>>(
      `${environment.serviceUrl}/api/dps`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  listAll(filter: DemandPartnersRequest): Observable<DemandPartner[]> {
    return this.httpClient.get<DemandPartner[]>(
      `${environment.serviceUrl}/api/dps/all`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getDpStatuses(filter: DemandPartnersRequest): Observable<DemandPartnerStatus> {
    return this.httpClient.get<DemandPartnerStatus>(
      `${environment.serviceUrl}/api/dps/statuses`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getDpById(id) {
    return this.httpClient.get(`${environment.serviceUrl}/api/dps/${id}`);
  }

  updateDemandPartner(dp: DemandPartnerCreate) {
    return this.httpClient.put(`${environment.serviceUrl}/api/dps/${dp.id}`, dp);
  }

  addDemandPartner(dp: DemandPartnerCreate) {
    return this.httpClient.post(`${environment.serviceUrl}/api/dps`, dp);
  }

  countByStatus() {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/dp/count-by-status`
    );
  }

  getDemandPartnerBundleIds(
    filter: DemandPartnerDomainFilter,
    id: string
  ): Observable<Page<DemandPartnerDomain>> {
    return this.httpClient
      .post<Page<DemandPartnerDomain>>(
        `${environment.serviceUrl}/api/dps/approvals/${id}`,
        filter
      )
      .pipe(first());
  }

  getDemandPartnerDomains(filter: DemandPartnerDomainFilter, id: string): Observable<Page<DemandPartnerDomain>> {
    return this.httpClient.get<Page<DemandPartnerDomain>>(`${environment.serviceUrl}/api/dp/domain-approvals/${id}`, {
        params: HttpUtils.getHttpParams(filter)
      });
  }


  dpProfileCountByStatus(name: string) {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/dp/profile/count-by-status`,
      {
        params: HttpUtils.getHttpParams({ name }),
      }
    );
  }

  exportDemandPartnerProfileCsv(name: string) {
    return this.httpClient.get(`${environment.serviceUrl}/api/dp/export`, {
      responseType: 'blob',
      params: HttpUtils.getHttpParams({ name }),
    });
  }

  exportDpAppsApprovalsCsv(dpId: string, request: any) {
    return this.httpClient.post(
      `${environment.serviceUrl}/api/compliances/dps/${dpId}/approvals/csv/export`,
      request, { responseType: 'blob' });
  }

  importDpCsv(file: File, name: string) {
    const formData: FormData = new FormData();
    formData.append('excelFile', file, file.name);

    return this.httpClient.post(
      `${environment.serviceUrl}/api/dp/import/${name}`,
      formData
    );
  }

  importDpAppsApprovalsCsv(file: File, dpId: string) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post(
      `${environment.serviceUrl}/api/compliances/dps/${dpId}/approvals/csv/reimport`,
      formData
    );
  }

  getDpIntegrations(
    filter: DemandPartnersConnectionRequest,
    id: string
  ): Observable<Page<DemandPartnerConnection>> {
    return this.httpClient.get<Page<DemandPartnerConnection>>(
      `${environment.serviceUrl}/api/dps/${id}/integrations`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getAllIntegrations(
    filter: DemandPartnersConnectionRequest
  ): Observable<Page<DemandPartnerConnection>> {
    return this.httpClient
      .get<Page<DemandPartnerConnection>>(
        `${environment.serviceUrl}/api/dps/integrations`,
        {
          params: HttpUtils.getHttpParams(filter),
        }
      )
      .pipe(first());
  }

  createIntegration(request: DemandPartnerConnectionCreate, dpId: string) {
    return this.httpClient.post(
      `${environment.serviceUrl}/api/dps/${dpId}/integrations`,
      request
    );
  }

  editIntegration(
    request: DemandPartnerConnectionCreate,
    dpId: string,
    integrationId: string
  ) {
    return this.httpClient.put(
      `${environment.serviceUrl}/api/dps/${dpId}/integrations/${integrationId}`,
      request
    );
  }

  getIntegrationById(
    dpId: string,
    integrationId: string
  ): Observable<DemandPartnerConnectionCreate> {
    return this.httpClient.get<DemandPartnerConnectionCreate>(
      `${environment.serviceUrl}/api/dps/${dpId}/integrations/${integrationId}`
    );
  }

  getPlacementByIntegration(
    dpId: string,
    integrationId: string,
    filter: any
  ): Observable<Page<Placements>> {
    return this.httpClient.get<Page<Placements>>(
      `${environment.serviceUrl}/api/dps/${dpId}/integrations/${integrationId}/placements`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getStatusCount(filter?) {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/dps/integrations/statuses`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  getAdsTxtRecordsByDPId(dpId: string): Observable<DemandPartner> {
    return this.httpClient.get<DemandPartner>(`${environment.serviceUrl}/api/dp/${dpId}`);
  }

  //DP App Approvalls
  public getAppsApprovalsByDpID(filter: any, dpId: string): Observable<Page<DpAppCompliance>> {
    return this.httpClient.get<Page<DpAppCompliance>>(`${environment.serviceUrl}/api/compliances/dp/${dpId}`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getAppsApprovalsStatusCount(filter: any, dpId: string) {
    return this.httpClient.get(
      `${environment.serviceUrl}/api/compliances/dps/${dpId}/statuses`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  //Discrepancies
  public getImportStatusHistory(filter: any): Observable<DiscrepancyImportHistory> {
    return this.httpClient.get<DiscrepancyImportHistory>(
      `${environment.serviceUrl}/api/dps/dp-revenue/imports`,
      {
        params: HttpUtils.getHttpParams(filter),
      }
    );
  }

  public getDiscrepancies(filter: any): Observable<Discrepancy[]> {
    return this.httpClient.post<Discrepancy[]>(
      `${environment.serviceUrl}/api/report/discrepancy`, filter);
  }

  public getSupportedImportDPs(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.serviceUrl}/api/dps/dp-revenue/supported-imports`
    );
  }

  public importDiscrepanciesCsv(file: File, dpId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const url = `${environment.serviceUrl}/api/dps/${dpId}/dp-revenue/csv/import`;
    return this.httpClient.post(url, formData);
  }
}