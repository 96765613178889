import {debounceTime, distinctUntilChanged, finalize, takeUntil} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {SortUtils} from '../../utils/sort-utils';
import { CampaignsService } from '../../services/campaigns.service';
import {LineItem, LineItemStatus} from '../../domain/campaigns.model';
import {FloatLabelType} from "@angular/material/form-field";
import {ServerErrorUtils} from "../../utils/server-error-utils";
import {SnackBarService} from "../../../core/services/snackbar.service";

@Component({
  selector: 'mt-line-items-autocomplete-rb',
  template: `
    <mat-form-field [floatLabel]="floatLabel || 'always'"
                    class="w-100" 
                    [ngClass]="formFieldClass"
                    [appearance]="formFieldAppearance ? formFieldAppearance : undefined"
    >
      <mat-label>{{label ? label : 'Choose line item'}}</mat-label>
      <input matInput
             #name
             [disabled]="disabled"
             aria-label="Line Item"
             [matAutocomplete]="auto"
             [formControl]="filterCtrl"
             [required]="required"
             (keydown)="!disabled"
             (click)="!disabled"
      />
      <i class="far fa-spinner fa-spin text-primary" matSuffix [hidden]="!serverSearching"></i>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="optionSelected($event)"
                        [displayWith]="display">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-hint *ngIf="formFieldHint">{{formFieldHint ? formFieldHint : ''}}</mat-hint>
    </mat-form-field>`,
})
export class LineItemsAutocompleteRbComponent implements OnInit, OnDestroy {
  @ViewChild('name', {static: true}) nameFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();

  @Input()
  public placeholder: string;

  @Input()
  public lineItemIds: string[];

  @Input()
  public orderIds: string[];

  @Input()
  public networkIds: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public label: string;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  public formFieldHint: string;

  @Input()
  items: string[];

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Output()
  lineItemSelected = new EventEmitter<LineItem>();

  serverSearching = false;

  filteredItems: Subject<LineItem[]> = new Subject<LineItem[]>();
  filterCtrl = new UntypedFormControl();
  selectedLineItem: LineItem;
  filteredLineItemIds: string[] = [];
  lineItemList: LineItem[];

  constructor(private service: CampaignsService,
              private snackBarService: SnackBarService) {

    //noinspection TypeScriptUnresolvedFunction
    this.filteredItems = new Subject();
    this.filterCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      takeUntil(this.onDestroy))
      .subscribe((searchTerm) => {
        if (searchTerm && typeof searchTerm === 'string') {
          this.getLineItems(searchTerm);
        }
        else if (searchTerm == '') {
          this.filteredItems.next(undefined);
          this.lineItemSelected.emit(null);
        }
      });
  }

  resetInput() {
    this.selectedLineItem = null;
    this.filterCtrl.setValue(null);
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.filterCtrl.disable({onlySelf: true, emitEvent: true});
    }

  }

  /** SearchTerm used for filtering by line item name */
  private getLineItems(searchTerm?: string) {
    this.serverSearching = true;
    this.filterCtrl.disable();
    return this.service.getLineItemsListForOrderId({
      page: 0,
      size: 50,
      networkIds: this.networkIds,
      orderIds: this.orderIds,
      name: searchTerm,
      status: [LineItemStatus.ACTIVE],
    } )
        .pipe(
            finalize(() => {
              this.filterCtrl.enable();
              this.nameFilter.nativeElement.focus();
              this.serverSearching = false;
            }),
            takeUntil(this.onDestroy)
        )
        .subscribe({
          next: (resp) => {
            this.lineItemList = resp.content.sort(SortUtils.propertyComparatorString('name'));
            this.filteredItems.next(this.lineItemList.filter(x => !this.filteredLineItemIds.includes(x.id)));
          },
          error: (error) => {
            const messages = ServerErrorUtils.getValidationMessages(error);
            if (messages) {
              messages.forEach((m) => this.snackBarService.error(m));
            } else {
              this.snackBarService.error('Error while fetching line items autocomplete data');
            }
          },
        });
  }

  display(p?: string) {
    return p ? p : undefined;
  }

  optionSelected($event) {
    this.selectedLineItem = $event.option.value;
    this.emit();
    this.nameFilter.nativeElement.blur();
    this.filteredItems.next(undefined);
  }

  private emit() {
    this.lineItemSelected.emit(this.selectedLineItem);
  }

  updateFilteredLineItems(items: LineItem[]) {
    this.filteredLineItemIds = items.map(item => item.id);
  }

  disableSearch(disable: boolean = true) {
    disable ? this.filterCtrl.disable() : this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }

  openDropdown() {
    setTimeout(() => {
      this.getLineItems()
    }, 400);
  }

}
