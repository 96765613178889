import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ChangelogListRequest, Changelog, ChangeLogCategory, ChangeLogCategoryLabels, IChangelogDialogData } from '../../domain/changelog.model';
import { ChangelogService } from '../../services/changelog.service';
import { MomentDateUtils } from '../../utils/moment-date-utils';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mt-changelog-dialog',
  templateUrl: './changelog-dialog.component.html',
  styleUrls: ['./changelog-dialog.component.scss']
})
export class ChangelogDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  clearFilterItems: Subject<void> = new Subject<void>();

  filter = {
    page: 0,
    size: 10,
    sortProperty: ['createdOn'],
    sortDirection: 'DESC',
    dateFrom: '',
    dateTo: '',
  } as ChangelogListRequest;

  changeLog: Changelog;
  loading = false;

  changeLogCategory = ChangeLogCategory;
  changeLogCategoryLabels = ChangeLogCategoryLabels;
  dataSource = new MatTableDataSource<Changelog>();
  columns = ['createdOn', 'type', 'fieldName', 'oldValue', 'arrow', 'newValue', 'createdBy'];


  constructor(
    private service: ChangelogService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: IChangelogDialogData) {
      this.filter.publisherIds = data.publisherIds;
      this.filter.placementIds = data.placementIds;
      this.filter.lineItemIds = data.lineItemIds;
      this.filter.integrationIds = data.integrationIds;
      this.filter.createdBy = data.createdBy;
     }

  ngOnInit(): void {
    this.refreshDataFirstPage();
  }

  ngAfterViewInit(): void {
    this.loading = true;
    this.paginator.page.subscribe((p: PageEvent) => {
      this.filter.size = p.pageSize;
      this.filter.page = p.pageIndex;
      this.refreshData();
    });
    //this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe(next => {
      this.filter.sortProperty = next.active;
      this.filter.sortDirection = next.direction.toUpperCase();
      this.refreshDataFirstPage();
    });
  }

  refreshDataFirstPage() {
    this.filter.dateTo =  MomentDateUtils.today();
    this.filter.dateFrom = '';
    this.paginator.pageIndex = 0;
    this.filter.page = 0;
    this.refreshData();
  }

  refreshData() {
    this.loading = true;
    this.service.getChangelogs(this.filter).subscribe(
      (data) => {
      this.loading = false;
      this.dataSource.data = data.content;
      this.paginator.length = data.totalElements;
      //console.log(data);
    },
    error => {
      const messages = ServerErrorUtils.getValidationMessages(error);
      if (messages) {
        messages.forEach(m => this.snackBarService.error(m));
      } else {
        this.snackBarService.error('Error while fetching change logs');
      }
      this.loading = false;
    });
  }
}
