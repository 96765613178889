import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { urlJoin } from 'url-join-ts';
import { AdswizzGenre } from '../domain/adswizzGenres.model';

@Injectable({
  providedIn: 'root',
})
export class GenresService {
  private readonly endpoint = 'api/adswizz-genres';

  constructor(private httpClient: HttpClient) {
  }

  fetchAdswizzGenres(): Observable<AdswizzGenre[]> {
    return this.httpClient.get<AdswizzGenre[]>(urlJoin(environment.serviceUrl, this.endpoint));
  }

}
