import {KeyLabel} from './key-label.model';
import { environment } from 'src/environments/environment';

export class Feed {
  url: string;
  title: string;
  description: string;
  category: string;
  source: FeedSource;
  feedTypeYT: FeedTypeYT;
  playlistIds: string[];
  newPlaylist: boolean;
  newPlaylistName?: string;
  ownerType?: OwnerType;
  coId?: string;
  ownerId?: string;
  count?: number;
  categories: string[];
  thumbnailFile?: string;
  dateFrom?: Date;
}

export enum FeedTypeYT {
  PLAYLIST = 'PLAYLIST',
  VIDEO = 'VIDEO',
  CHANNEL = 'CHANNEL',
  TRILLER = 'TRILLER',
}

export const FeedTypeLabels = {
  PLAYLIST: 'Playlist',
  VIDEO: 'Video',
  CHANNEL: 'Channel',
  TRILLER: 'Triller',
};

export const FeedTypeKL = () =>
  (Object.keys(FeedTypeYT).map(key => new KeyLabel(<FeedTypeYT> key, FeedTypeLabels[key])));

export enum FeedSource {
  UPLOAD = 'UPLOAD',
}

export const FeedSourceLabels = {
  UPLOAD: 'Upload',
};

export const FeedSourceKL = () =>
  (Object.keys(FeedSource).map(key => new KeyLabel(<FeedSource> key, FeedSourceLabels[key])));

export class FeedCategoriesYT {
  id: string;
  title: string;
}

export enum OwnerType {
  KM = 'KM',
  CONTENT_OWNER = 'CONTENT_OWNER'
}

export const OwnerTypeLabels = {
  KM: environment.platformTitle,
  CONTENT_OWNER: 'Content owner'
};

export const OwnerTypeKL = () =>
  (Object.keys(OwnerType).map(key => new KeyLabel(key as OwnerType, OwnerTypeLabels[key])));
