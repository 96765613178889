import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Video} from '../../../shared/domain/video.model';
import {VideosService} from '../../../shared/services/videos.service';
import {ServerErrorUtils} from '../../../shared/utils/server-error-utils';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {Utils} from '../../../shared/utils/utils';
import {VideoCategories} from '../../../shared/services/playlists.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatLegacyChipInputEvent as MatChipInputEvent} from '@angular/material/legacy-chips';
import {UntypedFormControl} from '@angular/forms';
import videojs from 'video.js';
import { GlobalSpinnerService } from 'src/app/core/services/global-spinner.service';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';

@Component({
  selector: 'mt-playlist-video-preview',
  templateUrl: './playlist-video-preview.component.html',
  styleUrls: ['./playlist-video-preview.component.scss']
})
export class PlaylistVideoPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  public readonly RolePermission = RolePermission;

  videoId: string;
  video: Video;
  loading: boolean;
  editMode = false;
  videoCategories = VideoCategories;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  player: videojs.Player;
  thumbnail: File;
  options: any;

  constructor(private service: VideosService,
              @Inject(MAT_DIALOG_DATA) public data: { id: string },
              private gss: GlobalSpinnerService,
              private videosService: VideosService,
              public dialogRef: MatDialogRef<PlaylistVideoPreviewComponent>,
              private elementRef: ElementRef,
              private snackBarService: SnackBarService) {
    this.videoId = data.id;
  }

  ngOnInit(): void {
    this.getVideo();
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('700px');
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  getVideo() {
    this.loading = true;
    this.service.video(this.videoId).subscribe(
      next => {
        this.loading = false;
        if(next.posters.length > 0){
          next.posters[0] = `${next.posters[0]}?${Date.now()}}`;
        }
        this.video = next;
        this.options = {
          autoplay: false,
          controls: true,
          muted: false,
          sources: [
            {
              src: this.video.mp4,
            }
          ]
        };
      },
      error => {
        this.loading = false;
        this.dialogRef.close();
        const messages = ServerErrorUtils.getValidationMessages(error);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error occurred during fetching video');
        }
      }
    );
  }

  removeThumbnail() {
    this.thumbnail = null;
  }

  thumbnailSelected(event) {
    this.thumbnail = event;
  }

  saveThumbnail() {
    this.gss.showLoader();
    this.videosService.updateThumbnail(this.video.id, this.thumbnail).subscribe(next => {
      this.dialogRef.close(true);
      this.snackBarService.success('Thumbnail updated successfully');
      this.gss.hideLoader();
      }, e => {
        const messages = ServerErrorUtils.getValidationMessages(e);
        if (messages) {
          messages.forEach(m => this.snackBarService.error(m));
        } else {
          this.snackBarService.error('Error occurred during updating Thumbnail');
        }
        this.gss.hideLoader();
      });
  }

  getTimeFromSeconds(durationInSeconds: number) {
    return Utils.getTimeFromSeconds(durationInSeconds);
  }

  updateVideo() {
    this.gss.showLoader();
    console.log(this.video);

    this.videosService.updateVideo(this.video).subscribe(next => {
      this.dialogRef.close(true);
      this.snackBarService.success('Video updated successfully');
      this.gss.hideLoader();
    }, e => {
      const messages = ServerErrorUtils.getValidationMessages(e);
      if (messages) {
        messages.forEach(m => this.snackBarService.error(m));
      } else {
        this.snackBarService.error('Error occurred during video update');
      }
      this.gss.hideLoader();
    });
  }

  removeTag(tag: string) {
    this.video.tags.splice(this.video.tags.indexOf(tag), 1);
  }

  addTag(newTag: MatChipInputEvent): void {
    if ((newTag.value || '').trim() && !this.video.tags.find(tag => tag === newTag.value.trim())) {
      this.video.tags.push(newTag.value.trim());
    }
    this.tagCtrl.setValue(null);
  }
}
