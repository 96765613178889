export const environment = {
  production: false,
  serviceUrl: 'https://api.dev.dxkulture.com',
  vastUrl: 'https://',
  cdnUrl: 'https://cdn.dev.dxkulture.com',
  a2vUrl: 'https://a2v.dev.dxkulture.com',
  platformTitle: 'DXKulture',
  defaultBidderCode: 'dxkulture',
  vastEndpoint: 'https://ads.dev.dxkulture.com/vast',
  exchangeDomainUrl: 'https://ads.dev.dxkulture.com/',
  defaultAdsTxtDomain: 'dxkulture.com',
  defaultAdsTxtTagID: '259726033fc4df0c',
  sellerDomainLabel: 'dxkulture',
  logoUrl: 'https://dxkulture.com/media/logo.png',
  logoLightUrl: 'https://dxkulture.com/media/logo-light.png',
  faviconUrl: 'https://dxkulture.com/media/favicon-grey.ico'
};
