import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AfterViewInit, Component, Inject} from '@angular/core';
import {Principal} from '../../core/auth/principal.service';
import {GlobalSpinnerService} from '../../core/services/global-spinner.service';
import {SnackBarService} from '../../core/services/snackbar.service';
import {ServerErrorUtils} from '../../shared/utils/server-error-utils';
import {Playlist} from '../../shared/domain/playlist.model';
import {PlaylistsService} from '../../shared/services/playlists.service';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';
import { PublisherData } from 'src/app/shared/domain/publisher-data.model';

@Component({
  selector: 'mt-playlist-editor-dialog',
  template: `
    <div mat-dialog-title>
      <span>{{isEdit ? 'Edit collection' : 'Create new collection'}}</span>
    </div>
    <div>
      <form #f="ngForm" class="d-flex flex-column">
        <mat-form-field floatLabel="always" color="primary" appearance="outline">
          <mat-label>Title</mat-label>
          <input name="title" matInput required [(ngModel)]="playlist.title"/>
        </mat-form-field>
        <mat-form-field floatLabel="always" color="primary" appearance="outline">
          <mat-label>Description</mat-label>
          <input name="description" matInput required [(ngModel)]="playlist.description"/>
        </mat-form-field>
      </form>
      <div class="row">
        <div class="col-12 mb-3">
          <mt-categories-multi-select [selectedCategory]="playlist.categories"
                                       [multiple]="true"
                                       (categorySelected)="playlist.categories = $event">
          </mt-categories-multi-select>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button class="" [mat-dialog-close]="false">
        Close
      </button>
      <button mat-flat-button *csHasPermission="RolePermission.CONTENT_COLLECTIONS_CREATE" class="iconed" color="primary"
              [disabled]="!f.valid"
              (click)="createPlaylist()">
        <i class="fas fa-save"></i>
        <span>Save</span>
      </button>
    </div>
  `,
})
export class PlaylistEditorDialogComponent implements AfterViewInit {
  public readonly RolePermission = RolePermission;

  isEdit: boolean;
  playlist = new Playlist();

  constructor(public dialogRef: MatDialogRef<PlaylistEditorDialogComponent>,
              private service: PlaylistsService,
              private principal: Principal,
              private gss: GlobalSpinnerService,
              private snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: { playlist?: Playlist }) {
    if (data && data.playlist) {
      this.isEdit = !!data.playlist;
      this.playlist = data.playlist;
    }
  }

  ngAfterViewInit() {
    this.dialogRef.updateSize('500px');
  }

  createPlaylist() {
    this.gss.showLoader();
    if (this.isEdit) {
      this.service.update(this.playlist).subscribe({
        next: (response) => {
          this.gss.hideLoader();
          this.dialogRef.close(true);
          this.snackBarService.success('Collection updated successfully');
        },
        error: (e) => {
          const messages = ServerErrorUtils.getValidationMessages(e);
          if (messages) {
            messages.forEach(m => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error occurred during collection update');
          }
          this.gss.hideLoader();
        }
      });
    } else {
      this.service.create(this.playlist).subscribe({
        next: () => {
          this.gss.hideLoader();
          this.dialogRef.close(true);
          this.snackBarService.success('Collection created successfully');
        },
        error: (e) => {
          const messages = ServerErrorUtils.getValidationMessages(e);
          if (messages) {
            messages.forEach(m => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error occurred during collection creation');
          }
          this.gss.hideLoader();
        }
      });
    }
  }
}
