import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WidgetType } from '../domain/widget-type.model';
import {IFilters} from "../interfaces/filters.model";
import {Page} from "../domain/page.model";
import {HttpUtils} from "../utils/http-utils";


@Injectable()
export class WidgetTypeService {

    constructor(private http:HttpClient){}


    public listAllWidgetTypes(filters: IFilters): Observable<Page<WidgetType>> {
        return this.http.get<Page<WidgetType>>(`${environment.serviceUrl}/api/widget-types`, {
            params: HttpUtils.getHttpParams(filters)
        });
    }

    createWidgetType(widgetType: WidgetType): Observable<WidgetType>{
        return this.http.post<WidgetType>(`${environment.serviceUrl}/api/widget-types`, widgetType);
    }

    updateWidgetType(widgetType: WidgetType): Observable<WidgetType> {
        return this.http.put<WidgetType>(`${environment.serviceUrl}/api/widget-types/${widgetType.id}`, widgetType);
    }
}