import { User } from 'src/app/core/auth/user.model';
import { KeyLabel } from './key-label.model';
import { PageRequest } from './page-request';
import { Playlist } from './playlist.model';
import { DimensionType } from './product-type.model';
import { ProductCost, Publisher } from './publisher.model';
import { Exchange } from './exchange.model';
import { Platform } from './platform.model';
import { AdswizzGenre } from './adswizzGenres.model';

export class Apps{
  appStoreId: string;
  appStoreUrl: string;
  bundleId: string;
  bundleUrl: string;
  createdBy: any;
  createdOn: string;
  id: string;
  name: string;
  updatedBy: string;
  adsTxtLocation: string;
  adstxtCrawledOn: string;
  storeUrls: string[];
  updatedOn: string;
  status: string;
  description: string;
  numericId: string;
  language: string;
  domain: string;
  platform: Platform;
  iabCategories: string[];
  adswizzGenres: AdswizzGenre[];
  uniqueApp?: boolean; // This field is for FE only
  publisherIds: string[];
  network: {
    id: number;
    name: string;
    status: string;
  };
  platformId: string;
  iabCategoryIds: string[];
  languages: string[];
}

export class PublisherAppImportRequest {
  appStoreId: string;
  appStoreUrl: string;
  bundleId: string;
  bundleUrl: string;
  createdBy?: any;
  createdOn?: string;
  appId: string;
  name: string;
  updatedBy?: string;
  updatedOn?: string;
  numericId?: string;
  platform: any;
  uniqueApp?: boolean;
  invalidApp?: boolean;
  iabCategories?: string;
  iabCategoriesIds?: string[];
  adswizzGenresIds?: string[];
  description?: string;
  domain: string;
  adsTxtLocation: string;
  errorMessages?: string;
  id?: string;
}

export class ExchangeApp {
  networkId: number;
  networkName: string;
  publisherId: string;
  publisherSecondaryId: string;
  publisherName: string;
  platformId: string;
  platformName: string;
  bundleId: string;
  appStoreId: string;
  appStoreUrl: string;
  adRequests: number;
  appPublisherId: string;
  LocalDateTime: string;
  processedBy: string;
  processedAy: string;
  errorMsg: string;
  processed: boolean;
  blocked: boolean;
  storeUrls: string[];
  storeUrlRequests: string[];
  appId: string;
  appName: string;
  note: string;

  id: string; //used only for mapping on app-request-edit-dialog. This is not appRequest ID!!
}

export class PublisherAppImportResponse {
  message: string;
  data: PublisherAppImportRequest[];
}

export class PublisherApps{
  constructor() {
    this.app = new Apps();
  }

  app: Apps;
  allProductsCost: any;
  createdOn: string;
  updatedOn: string;
  bidFloorAv: number;
  bidFloorCb: number;
  bidFloorVideo: number;
  id: AppIds;
  ownedAndOperated: boolean;
  adsTxtLocation: string;
  storeUrls: string[];
}

export class AppListRequest extends PageRequest {
  name?: string;
  searchTerm?: string;
  createdBy?: User;
  createdOn?: string;
  updatedOn?: string;
  ids?: string[];
  bundleId?: string;
  publisherIds?: string[];
  publisherId?: string;
  statuses?: RokuFeedStatus[];
  networkIds?: string[];
  status?: string[];
  adstxtLocationExists?: boolean;
  adstxtFoundCriteria?: string;
}

export class SectionVideosFilter extends PageRequest {
  playlistIds: string[];
  tags: string[];
  genres: string[];
  flags: string[];
  artists: string[];
}

export class AppIds {
  appId: string;
  publisherId: string;
}

export class OOApps {
  active: false;
  bundleUrl: string;
  adFrequency: string;
  adsDuration: string;
  appAscentColor: string;
  textColor: string;
  appContentFeedUrl: string;
  appUiResolution: string;
  appVersionBuild: string;
  appVersionMajor: string;
  appVersionMinor: string;
  backgroundFhdKey: string;
  channelPosterFhdKey: string;
  channelPosterHdKey: string;
  channelPosterSdKey: string;
  createdOn: string;
  description: string;
  hdOverhangLogoHorKey: string;
  id: string;
  bundleId: string;
  midroll: boolean;
  name: string;
  playlists: Playlist[];
  preroll: boolean;
  publisher: Publisher;
  splashFhdKey: string;
  splashHdKey: string;
  splashSdKey: string;
  updatedOn: string;
  status: string;
  dt: DimensionType;
  sections: SectionList[];
  ownedAndOperated: boolean;
  allProductsCost: ProductCost;
  layout: LayoutType;
}

export class SectionList {
  name: string;
  tags?: string[];
  flags?: string[];
  genres?: string[];
  artists?: string[];
  orderNo: number;
}

export enum LayoutTab {
  TOP = 'top',
  BOTTOM = 'bottom',
  SIDE = 'side',
}

export class OOAppsCreateRequest {
  name: string;
  bundleUrl: string;
  bundleId: string;
  appVersionMajor: string;
  appVersionMinor: string;
  appVersionBuild: string;
  appUiResolution: string;
  appAscentColor: string;
  textColor: string;
  playlistsIds: string[];
  preroll: boolean;
  midroll: boolean;
  adFrequency: string;
  adsDuration: string;
  publisherId: string;
  dt: DimensionType;
  sections: SectionList[] = [];
  status: string;
  ownedAndOperated: boolean;
  allProductsCost: ProductCost;
  layout: LayoutType;
}

export class RokuFiles {
  backgroundFhdKey: string;
  channelPosterFhdKey: string;
  channelPosterHdKey: string;
  channelPosterSdKey: string;
  hdOverhangLogoHorKey: string;
  splashFhdKey: string;
  splashHdKey: string;
  splashSdKey: string;
}

export class AppsFileSelect {
  appName: string;
  appStoreURL: string;
  bundleId: string;
  AppStoreId: string;
  Platform: string;
}

export enum RokuFeedStatus {
  DRAFT = 'DRAFT',
  READY = 'READY',
  PUBLISHING = 'PUBLISHING',
  PUBLISHED = 'PUBLISHED',
}

export class RokuFeedStatusBox {
  count: number;
  status: string;
}

export class RokuFeedStatusListing {
  DRAFT: number;
  READY: number;
  PUBLISHING: number;
  PUBLISHED: number;
}

export enum SegmentButtonFilter {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
  NEW = 'NEW'
}

export const SegmentButtonFilterLabel = {
  ALL: 'All',
  SELECTED: 'Selected',
  NEW: 'New'
};

export const SegmentButtonFilterKL = () =>
  Object.keys(SegmentButtonFilter).map(
    (key) => new KeyLabel(key as SegmentButtonFilter, SegmentButtonFilterLabel[key])
  );


export enum LayoutType {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
}

export enum RokuFeedStatusColorMap {
  DRAFT = 'orange',
  READY = 'gray',
  PUBLISHING = 'blue',
  PUBLISHED = 'green',
}

export const RokuFeedStatusLabels = {
  DRAFT: 'Draft',
  READY: 'Ready',
  PUBLISHING: 'Publishing',
  PUBLISHED: 'Published',
};

export const RokuFeedStatusKL = () =>
  Object.keys(RokuFeedStatus).map(
    (key) => new KeyLabel(key as RokuFeedStatus, RokuFeedStatusLabels[key])
  );

export enum AppsStatus {
  VALID = 'VALID',
  DUPLICATE = 'DUPLICATE',
}

export enum AppsStatusColorMap {
  VALID = 'green',
  DUPLICATE = 'gray'
}

export const AppsStatusLabels = {
  [AppsStatus.VALID]: 'Valid',
  [AppsStatus.DUPLICATE]: 'Duplicate'
};

export enum AppsTableStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum AppsTableStatusColorMap {
  ARCHIVED = 'gray',
  ACTIVE = 'green',
}
export class AppsTableStatusBox {
  count: number;
  status: string;
}

export const AppsTableStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
};

export enum AdsTxtAppsFound {
  ALL_APPS = 'ALL_APPS',
  ZERO_RECORDS_FOUND = 'ZERO_RECORDS_FOUND',
  INCOMPLETE_LIST = 'INCOMPLETE_LIST',
  COMPLETE_LIST = 'COMPLETE_LIST',
  MISSING_CRAWL = 'MISSING_CRAWL'
}

export const AdsTxtAppsFoundLabels = {
  ALL_APPS : 'All apps',
  ZERO_RECORDS_FOUND : 'Zero records found',
  INCOMPLETE_LIST : 'Incomplete',
  COMPLETE_LIST : 'Complete',
  MISSING_CRAWL : 'Missing crawl',
};

export const AdsTxtAppsFoundKL = () =>
    (Object.keys(AdsTxtAppsFound).map(key => new KeyLabel(<AdsTxtAppsFound>key, AdsTxtAppsFoundLabels[key])));


