import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'mt-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss']
})
export class SearchControlComponent implements OnInit {
  @Input() set searchText(value: string) {
    if (!Utils.isNullOrUndefined(value) && value !== this.searchFormControl.value) {
      this.searchFormControl.setValue(value, {emitEvent: false});
    }
  }

  @Output() searchChange = new EventEmitter<string>();

  searchFormControl = new UntypedFormControl();

  constructor() { }

  ngOnInit(): void {
    this.setSearchControl();
  }

  setSearchControl() {
    this.searchFormControl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged())
      .subscribe(data => {
        this.searchChange.next(data);
      });
  }

}
