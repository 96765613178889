import { KeyLabel } from './key-label.model';
import { User } from '../../core/auth/user.model';
import { Country } from './country.enum';
import { Exchange } from './exchange.model';

export class PublisherCreateRequest {
  constructor() {

    this.mcmActive = false;
    
    this.bidCache = {
      enabled: false,
      saasFee: { value: 0, costType: null, cpmMetric: null },
    };
    this.maxPodSlotCount = {
      enabled: false,
      value: 0,
      saasFee: { value: 0, costType: null, cpmMetric: null },
    };
    this.bidStitch = {
      enabled: false,
      saasFee: { value: 0, costType: null, cpmMetric: null },
    };

    this.xpParametersMappings = [];
  }

  id?: string = "";
  address: string;
  appRevenueShare: number;
  saasCpmFee: number;
  audioBackfillRevenueShare: number;
  videoRevenueShare: number;
  pubRevenueShare: number;
  cbRevenueShare: number;
  pubNetworkCpm: number;
  city: string;
  companyName: string;
  contactName: string;
  country: Country;
  email: string;
  name: string;
  notes: string;
  phone: string;
  state: string;
  websiteUrl: string;
  zipCode: string;
  gamIdEnabled?: boolean = false;
  gamAdUnitId?: string;
  adServingCost: number;
  ioDuration: IODuration;
  ioStartDate: string;
  revenueShare: number;
  sellerId: string;
  urls: string[];
  video: boolean;
  audioBackfill: boolean;
  allProductsCost: ProductCost;
  saasCost: SaasCost;
  whitelistAppsEnabled: boolean;
  whitelistDomainsEnabled: boolean;
  mcmActive: boolean;

  bidCache: BidCache;
  maxPodSlotCount: MultislotVast;
  bidStitch: BidStitch;
  revenueShareIo: number;

  salesRepresentative: User;
  status?: PublisherStatus;
  xpParametersMappings: { value: string; xpParameter: string }[];
}

export class Publisher {
  id: string;
  name: string;
  status: PublisherStatus;
  createdOn: string;
  updatedOn: string;
  createdBy: User;
  numberOfDomains: number;
  numberOfEmbeds: number;
  publisherId: string;
  sellerId: string;
  publisherCpm: number;
  appRevenueShare: number;
  saasCpmFee: number;
  cbRevenueShare: number;
  pubRevenueShare: number;
  pubNetworkCpm: number;
  audioBackfillRevenueShare: number;
  onboardingStage: OnboardingStage;
  secondaryId: string;
  placementsIds: string[];
  // company info
  contactName: string;
  companyName: string;
  phone: string;
  email: string;
  websiteUrl: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: Country;
  companyInfoCompleted: boolean;
  video: boolean;
  audioBackfill: boolean;
  debug: boolean;
  powerDebug: boolean;
  whitelistAppsEnabled: boolean;
  whitelistDomainsEnabled: boolean;
  pricingCompleted: boolean;
  ioStartDate: string;
  ioDuration: IODuration;
  ioRequested: boolean;
  ioExecutedStatus: IOExecutedStatus;
  koCallStatus: KOCallStatus;
  koCallStatusCompletedAt: string;
  kickOffCallDone: boolean;
  kickOffCallDate: string;
  scaledPartnership: boolean;
  scaledPartnershipFormSent: boolean;
  scaledPartnershipStatus: ScaledPartnershipStatus;
  contractCompletedOn: string;
  financeCompletedOn: string;
  revenueShareIo: number;
  mailSent: boolean;
  gamIdEnabled: boolean;
  gamAdUnitId: string;
  mcmActive: boolean;
  shares: PublisherShare[];
  allProductsCost: ProductCost;
  saasCost: SaasCost;
  network: any;
  notes: string;
  xpParametersMappings: { value: string; xpParameter: string }[];
  stats: {
    global7daysRevenuePct: number;
    network7daysRevenuePct: number;
  };
  salesRepresentative: User;
}
export class BidCache {
  enabled: boolean;
  saasFee: SaasCost;
}

export class MultislotVast {
  enabled: boolean;
  value: number;
  saasFee: SaasCost;
}

export class BidStitch {
  enabled: boolean;
  saasFee: SaasCost;
}

export class ProductCost {
  costType: CostType;
  value: number;
}

export class SaasCost {
  costType: CostType;
  value: number;
  cpmMetric?: CpmType;
}

export enum CostType {
  CPM = 'CPM',
  REVSHARE = 'REVSHARE',
  PLACEMENT = 'PLACEMENT',
}

export const CostTypeLabels = {
  CPM: 'CPM',
  REVSHARE: 'Revenue share',
  PLACEMENT: 'Placement',
};

export class PublisherNumericId {
  id: number;
}

export enum PublisherStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export class PublisherStatusBox {
  count: number;
  status: string;
}

export class PublisherStatusListing {
  ACTIVE: number;
  ARCHIVED: number;
}

export class PublisherShare {
  createdBy?: User;
  createdOn?: string;
  day?: string;
  pubShareType: PubShareType;
  value: number;
}

export enum PublisherStatusColorMap {
  ACTIVE = 'green',
  ARCHIVED = 'gray',
}

export const PublisherStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
};

export const PublisherStatusKL = () =>
  Object.keys(PublisherStatus).map(
    (key) => new KeyLabel(<PublisherStatus>key, PublisherStatusLabels[key])
  );

export enum PublisherType {
  ALL = 'all',
}

export const PublisherTypeLabels = {
  ALL: 'All',
};

export class PubId {
  domain: string;
  name: string;
  seller_id: string;
  seller_type: string;
}

export enum PubShareType {
  SERVING_COST = 'SERVING_COST',
  REVENUE = 'REVENUE',
  VIDEO_REVENUE = 'VIDEO_REVENUE',
  APP = 'APP',
  VIDEO = 'VIDEO',
  AUDIO_BACKFILL = 'AUDIO_BACKFILL',
  SAAS_CPM_FEE = 'SAAS_CPM_FEE',
  CB_REVENUE = 'CB_REVENUE',
  NETWORK_PUB_CPM = 'NETWORK_PUB_CPM',
}

export enum IOExecutedStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  EXECUTED = 'EXECUTED',
  REJECTED = 'REJECTED',
}

export enum ScaledPartnershipStatus {
  PENDING = 'PENDING',
  N_A = 'N_A',
  FORM_SENT = 'FORM_SENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum KOCallStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

export enum CpmType {
  AD_REQUESTS = 'AD_REQUESTS',
  FILLED_IMPRESSIONS = 'FILLED_IMPRESSIONS',
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
}

export const CpmTypeLabels = {
  AD_REQUESTS: 'Ad requests',
  FILLED_IMPRESSIONS: 'Filled impressions',
  AD_IMPRESSIONS: 'Ad Impressions',
};

export const CpmTypeKL = () =>
  Object.keys(CpmType).map(
    (key) => new KeyLabel(key as CpmType, CpmTypeLabels[key])
  );

export enum OnboardingStage {
  PROPOSAL = 'PROPOSAL',
  FINANCE = 'FINANCE',
  KICK_OFF_CALL = 'KICK_OFF_CALL',
  PRODUCTION = 'PRODUCTION',
  GO_LIVE = 'GO_LIVE',
  ONBOARDING_FINISHED = 'ONBOARDING_FINISHED',
}

export const OnboardingStageLabels = {
  PROPOSAL: 'Proposal',
  FINANCE: 'Finance',
  KICK_OFF_CALL: 'Kick off call',
  PRODUCTION: 'Production',
  GO_LIVE: 'Go live',
  ONBOARDING_FINISHED: 'Live',
};

export const OnboardingStageOrder = {
  PROPOSAL: 1,
  FINANCE: 2,
  KICK_OFF_CALL: 3,
  PRODUCTION: 4,
  GO_LIVE: 5,
  ONBOARDING_FINISHED: 6,
};

export const OnboardingStageKL = () =>
  Object.keys(OnboardingStage).map(
    (key) => new KeyLabel(key as OnboardingStage, OnboardingStageLabels[key])
  );

export enum IODuration {
  M3 = 'M3',
  M6 = 'M6',
  Y1 = 'Y1',
  Y2 = 'Y2',
  Y3 = 'Y3',
  Y5 = 'Y5',
}

export const IODurationLabels = {
  M3: '3 months',
  M6: '6 months',
  Y1: '1 year',
  Y2: '2 years',
  Y3: '3 years',
  Y5: '5 years',
};

export const IODurationKL = () =>
  Object.keys(IODuration).map(
    (key) => new KeyLabel(key as IODuration, IODurationLabels[key])
  );
