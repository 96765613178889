import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Network} from '../../domain/network.model';
import {Location} from '../../interfaces/location.model';
import {GeolocationsService} from '../../services/geolocations.service';
import {GeolocationsCountryAutocompleteComponent} from './geolocation-country-autocomplete';
import {FloatLabelType} from "@angular/material/form-field";

@Component({
  selector: 'mt-geolocations-country-autocomplete-chips',
  template: `
    <div class="cs-network-autocomplete-chips-wrapper">
      <mt-geolocations-country-autocomplete #cc (countrySelected)="onItemSelected($event)"
                                             [disabled]="disabled"
                                             [formFieldClass]="formFieldClass"
                                             [formFieldAppearance]="formFieldAppearance"
                                             [floatLabel]="floatLabel">
      </mt-geolocations-country-autocomplete>
      <mat-chip-list #chipList class="mt-n3" [ngClass]="stacked ? 'mat-chip-list-stacked' : ''" [hidden]="items.length < 1">
        <mat-basic-chip *ngFor="let item of items"
                        [removable]="!disabled"
                        (removed)="remove(item)">
          {{item.countryName}}
          <i class="fas fa-times-circle text-danger hand" matChipRemove></i>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  `,
})
export class GeolocationsCountryAutocompleteChipsComponent implements OnInit {

  @ViewChild(GeolocationsCountryAutocompleteComponent, {static: true}) cc: GeolocationsCountryAutocompleteComponent;

  @Input()
  public placeholder: string;

  @Input()
  public stacked: false;

  @Input()
  public countriesIsoCodes: string[];

  @Input()
  public floatLabel: FloatLabelType;

  @Input()
  public formFieldClass: string;

  @Input()
  public formFieldAppearance: string;

  @Input()
  disabled: boolean;

  @Output()
  countrySelectionChange = new EventEmitter<Location[]>();

  items: Location[] = [];

  constructor(private service: GeolocationsService) {
  }

  ngOnInit(): void {
  }

  onItemSelected($event: Location) {
    if ($event && !this.items.find(x => x.countryName === $event.countryName)) {
      this.items.push($event);
      this.countrySelectionChange.emit(this.items);
    }
    this.cc.resetInput();
    this.cc.updateFilteredCountries(this.items);
  }

  remove(p: Location) {
    this.items.splice(this.items.indexOf(p), 1);
    this.countrySelectionChange.emit(this.items);
    this.cc.updateFilteredCountries(this.items);
  }

  disableSearch(disable: true) {
    this.cc.disableSearch(disable);
    if (disable) {
      this.items = [];
      this.countrySelectionChange.emit(this.items);
    }
  }

  /** Clear all items from filter. */
  clearFilter(){
    this.items = [];
    this.cc.updateFilteredCountries(this.items);
  }

  disableFilter(disable: boolean) {
    if (disable) {
      this.cc.disable();
    }
    else {
      this.cc.enable();
    }
  }
}
