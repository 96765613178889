import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from '../../../../core/login/login.service';
import {Principal} from '../../../../core/auth/principal.service';
import {Publisher} from '../../../domain/publisher.model';
import {PublisherListFilter, PublishersService} from '../../../services/publishers.service';
import {SnackBarService} from '../../../../core/services/snackbar.service';
import {UserRole} from '../../../../core/auth/user-role.model';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ContentOwnerService} from '../../../services/content-owner.service';
import {ContentOwner} from '../../../domain/content-owner.model';

@Component({
  selector: 'mt-toolbar-publisher',
  templateUrl: './toolbar-publisher.component.html',
  styleUrls: ['./toolbar-publisher.component.scss']
})
export class ToolbarPublisherComponent implements OnInit, OnDestroy {

  @Input()
  public pageTitle: string;
  @Input()
  public titleNext: string;

  publishers: Publisher[];
  userRole = UserRole;
  subscription = new Subscription();
  contentOwners: ContentOwner[];

  navLinks = [
    // {path: '/p/dashboard', label: 'Dashboard', disabled: false, tooltip: ''},
  ];

  constructor(private loginService: LoginService,
              public principal: Principal,
              private publisherService: PublishersService,
              private sbs: SnackBarService,
              private contentOwnerService: ContentOwnerService) { }

  ngOnInit(): void {
    this.getUserPublishers();
    this.getUserCo();

  }

  getUserPublishers() {
    if (this.principal.user.publisherIds && this.principal.user.publisherIds.length) {
      this.subscription.add(
        this.publisherService.listPublishers({ids: this.principal.user.publisherIds} as PublisherListFilter).subscribe(publishers => {
          this.publishers = publishers.content;
        }, () => {
          this.sbs.error('Error during while fetching publishers');
        })
      );
    }
  }

  getUserCo() {
    if (this.principal.user.contentOwnerIds && this.principal.user.contentOwnerIds.length) {
      this.subscription.add(
        this.contentOwnerService.list({coIds: this.principal.user.contentOwnerIds}).subscribe(response => {
          this.contentOwners = response.filter(co => this.principal.user.contentOwnerIds.includes(co.id));
        }, () => {
          this.sbs.error('Error during while fetching content owners');
        })
      );
    }
  }

  public logout() {
    this.loginService.logout(true);
  }

  changeUrl(type: 'co'|'publisher'|'main', id?: string) {
    if (type === 'main') {
      window.open(`/#/`, '_blank').focus();
    } else if (type === 'publisher') {
      window.open(`/#/p/${id}`, '_self').location.reload();
    } else {
      window.open(`/#/co`, '_blank').focus();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
