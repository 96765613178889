import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {CsTableColumn} from './cs-table-column.model';

@Component({
  selector: 'mt-simple-mat-table-col-vis',
  template: `
		<div class="filter-dropdown">
			<button mat-mini-fab color="accent" (click)="opened = !opened" matTooltip="Columns"
			        class="mat-elevation-z0" *ngIf="buttonType !== 'icon'">
				<i class="fas fa-align-justify fa-rotate-90 pr-1"></i>
			</button>
      <button mat-icon-button color="accent" (click)="opened = !opened" matTooltip="Columns"
              class="mat-elevation-z0" *ngIf="buttonType === 'icon'">
        <i class="fas fa-align-justify fa-rotate-90"></i>
      </button>
			<span class="dropdown" [class.opened]="opened">
        <h6 class="dropdown-title">{{header}}</h6>
        <span class="dropdown-content d-flex flex-column">
          <mat-checkbox color="primary"
                        *ngFor="let col of allColumns"
		          (change)="toggleColumn(col)"
		          [checked]="isChecked(col)">
            {{col | titleCaseSplitted}}
          </mat-checkbox>
        </span>
      </span>
		</div>
  `,
  styleUrls: ['./mat-table-col-vis.component.scss']
})

//Difference between this component and MatTableColVisComponent is that this one requires only list of columns names (string[]), instead of whole model
export class SimpleMatTableColVisComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  opened: boolean;
  @Input() public header: string;

  @Input() public allColumns: string[];

  @Input() public visibleColumns: string[];

  @Input() toggleColumns$: Observable<string[]>;

  @Input()
  buttonType: string;

  @Output()
  change = new EventEmitter<string[]>();

  constructor(private _eref: ElementRef) {
  }

  ngOnInit(): void {
    this.toggleColumns$?.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(
      (columns) => {
        columns.forEach(element => {
          this.toggleColumn(element);
        });
      }
    );
  }

  close() {
    this.opened = false;
  }

  isChecked(col: string) {
    if (this.visibleColumns.includes(col)) {
      return true;
    }
    else {return false;}
  }

  toggleColumn(col: string) {
    if (this.visibleColumns.includes(col)) {
      this.visibleColumns = this.visibleColumns.filter(x => x != col);
    }
    else {this.visibleColumns.push(col);}

    this.visibleColumns.sort((a, b) => this.allColumns.indexOf(a) - this.allColumns.indexOf(b));
    this.change.emit(this.visibleColumns);
  }

  @HostListener('document:click', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const insideWidget = this._eref.nativeElement.contains(e.target);
    const optionClick = (<Element>e.target).className === 'mat-option-text';
    const backdropClick = (<Element>e.target).className === 'cdk-overlay-backdrop';
    if (!insideWidget && !optionClick && !backdropClick) {
      this.opened = false;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }
}
