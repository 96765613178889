import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Principal } from '../auth/principal.service';
import { Observable } from 'rxjs';
import { User } from '../auth/user.model';

@Injectable()
export class NetworkGuardPublisher  {
  private networkAllowedRoutes = ['/publisher-apps'];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private principal: Principal) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.isNetworkUser(this.principal.user) && this.isNotAllowedRoute(state.url)) {
      return this.router.createUrlTree(['/forbidden']);
    }
    return true;
  }

  private isNetworkUser(user: User): boolean {
    return !!user.network;
  }

  private isNotAllowedRoute(url: string): boolean {
    return !this.networkAllowedRoutes.some(item => url.includes(item));
  }
}
