import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {KitchenAdUnit} from '../domain/kitchen-ad-unit.model';

@Injectable({
  providedIn: 'root',
})
export class KitchenService {

  constructor(private httpClient: HttpClient) {
  }

  adUnits(publisherId: string): Observable<KitchenAdUnit[]> {
    return this.httpClient.get<KitchenAdUnit[]>(`${environment.serviceUrl}/kitchen/adUnit/${publisherId}`);
  }
}
