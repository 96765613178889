import {Component} from '@angular/core';
import {LoginService} from './login.service';

@Component({
  selector: 'mt-logout',
  template: '',
})
export class LogoutComponent {

  constructor(private loginService: LoginService) {
    loginService.logout(true);
  }

}
