import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SnackBarService} from '../../../core/services/snackbar.service';
import {GlobalSpinnerService} from '../../../core/services/global-spinner.service';
import {ContentOwner} from '../../../shared/domain/content-owner.model';
import {Publisher} from '../../../shared/domain/publisher.model';
import {OwnerType, OwnerTypeKL} from '../../../shared/domain/feed.model';
import {VideosService} from '../../../shared/services/videos.service';
import {ServerErrorUtils} from '../../../shared/utils/server-error-utils';
import { MomentDateUtils } from 'src/app/shared/utils/moment-date-utils';
import { FlagAutocompleteChipsComponent } from 'src/app/shared/components/utils/flags-autocomplete-chips';
import { RolePermission } from 'src/app/core/auth/rbac/role-permission.service';

@Component({
  selector: 'mt-edit-videos-dialog',
  templateUrl: './edit-videos-dialog.component.html',
  styleUrls: ['./edit-videos-dialog.component.scss']
})
export class EditVideosDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(FlagAutocompleteChipsComponent) autoFlag;
  public readonly RolePermission = RolePermission;

  coId: string;
  type: OwnerType = OwnerType.KM;
  videos: string[];
  ownerTypeKL = OwnerTypeKL();
  ownerType = OwnerType;
  today = MomentDateUtils.today();
  artist: string;
  metaTag: string;
  tags: string[] = [];
  metaGenre: string;
  genres: string[] = [];
  flags: string[] = [];
  publishedAt: Date;
  selectedFlags: string[];
  thumbnailFile?: File;

  constructor(public dialogRef: MatDialogRef<EditVideosDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { videos: string[] },
              private snackBarService: SnackBarService,
              private gss: GlobalSpinnerService,
              private service: VideosService) {
    this.videos = data.videos;
  }

  ngOnInit(): void {
    this.gss.showLoader();
    this.service.flagsList().subscribe((response) => {
      this.flags = response;
      this.gss.hideLoader();
    }, e => {
      this.snackBarService.error('Error occurred during getting flags');
      this.gss.hideLoader();
    });
  }

  ngAfterViewInit(): void {
    this.dialogRef.updateSize('500px');
  }

  ownerSelected(owner: ContentOwner | Publisher) {
    this.coId = owner ? owner.id : null;
  }

  removeTag(item) {
    this.tags.splice(this.tags.indexOf(item), 1);
  }

  addTag(value) {
    this.tags.push(value);
    this.metaTag = '';
  }

  removeGenre(item) {
    this.genres.splice(this.genres.indexOf(item), 1);
  }

  addGenre(value) {
    this.genres.push(value);
    this.metaGenre = '';
  }

  filterFlagsChange(items: string[]) {
    this.selectedFlags = items;
  }

  dateChange(event) {
    const offsetMs = event.value.getTimezoneOffset() * 60000;
    this.publishedAt = new Date(event.value.getTime() - offsetMs);
  }

  removeThumbNailImage() {
    this.thumbnailFile = null;
  }

  addOwnerToVideo() {
    this.gss.showLoader();
    const request = {
      videoIds: this.videos,
      data: {
        ownerType: this.type,
        coId: this.coId,
        artist: this.artist,
        publishedAt: this.publishedAt,
        tags: this.tags,
        genres: this.genres,
        flags: this.selectedFlags,
      },
    };

    this.service.editVideos(request, this.thumbnailFile).subscribe(
      response => {
        this.gss.hideLoader();
        this.dialogRef.close(true);
        this.snackBarService.success('You have successfully edit videos');
      },
      error => {
        ServerErrorUtils.serverValidationOrMsg(error, this.snackBarService, 'Error occurred during add owner to video');
        this.gss.hideLoader();
      }
    );
  }
}
