import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpUtils} from '../utils/http-utils';
import {Page} from '../domain/page.model';
import {AdServerTypeMacros, Placements} from '../domain/placements.model';
import {Targeting} from '../domain/targeting.model';
import {urlJoin} from 'url-join-ts';
import {IFilters} from '../interfaces/filters.model';
import { EndpointGeneratorRequest } from '../domain/endpoint-generator.model';

@Injectable({
  providedIn: 'root',
})
export class PlacementsService {
  private readonly endpoint = 'api/placements';

  constructor(private httpClient: HttpClient) {
  }

  listPlacements(filter: any): Observable<Page<Placements>> {
    return this.httpClient.get<Page<Placements>>(`${environment.serviceUrl}/api/placements`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  addPlacement(placement: any) {
    return this.httpClient.post(`${environment.serviceUrl}/api/placements`, placement);
  }

  buildAdUnit(id){
    return this.httpClient.post(`${environment.serviceUrl}/api/placements/ad-unit/${id}/build`, null);
  }

  getAdUnitConfig(id){
    return this.httpClient.get(`${environment.serviceUrl}/api/placements/ad-unit/${id}/config`)
  }

  updatePlacement(placement: any, id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/placements/${id}`, placement);
  }

  getPlacementById(id): Observable<Placements> {
    return this.httpClient.get<Placements>(`${environment.serviceUrl}/api/placements/${id}`);
  }

  getStatusCount(filters: any) {
    return this.httpClient.get(`${environment.serviceUrl}/api/placements/statuses`, {
      params: HttpUtils.getHttpParams(filters)
    });
  }

  public getPlacementTargeting(id: string, filters: IFilters): Observable<Page<Targeting>> {
    return this.httpClient.get<Page<Targeting>>(urlJoin(environment.serviceUrl, this.endpoint, id, 'targetings'), {
      params: HttpUtils.getHttpParams(filters)
    });
  }

  archivePlacement(id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/placements/${id}/archive`, {});
  }

  unarchivePlacement(id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/placements/${id}/unarchive`, {});
  }

  //Endpoint generator
  public generateEndpointTag(request: EndpointGeneratorRequest) {
    return this.httpClient.post(`${environment.serviceUrl}/api/endpoint-generator/exchange-tag-generator`, request,
      {responseType: 'text'}
    );
  }

  public generateKmExchangeTag(endpoint: string, source: string) {
    return this.httpClient.post(`${environment.serviceUrl}/api/endpoint-generator/exchange-connection-generator`, {endpoint, source},
      {responseType: 'text'}
    );
  }

  public getMacrosList(filter: any) {
    return this.httpClient.get<AdServerTypeMacros[]>(`${environment.serviceUrl}/api/endpoint-generator/macros-ad-server`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  public getKmExchangeMacrosList() {
    return this.httpClient.get<AdServerTypeMacros[]>(`${environment.serviceUrl}/api/endpoint-generator/macros-exchange`);
  }
}