import {PageRequest} from './page-request';
import {User} from '../../core/auth/user.model';
import { PublisherDomain } from './publisher-domain.model';


export class Playlist {
  id?: string;
  title?: string;
  description?: string;
  createdOn: string;
  updatedOn: string;
  createdBy: User;
  numberOfVideos: number;
  imageUrl?: string;
  posterFile?: string;
  totalLengthOfVideos: number;
  categories?: string[];
  totalFeedDuration: number;
  tags: string[];
  flags: string[];
  genres: string[];
  artists: string[];
  publisherDomains: PublisherDomain[] = [];
}

export class PlaylistListRequest extends PageRequest {
  title: string;
  ids: string[];
  flags?: string;
  tags?: string;
  genres?: string;
  artists?: string;
}
