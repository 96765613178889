import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subscriber} from 'rxjs';
import {Playlist, PlaylistListRequest} from '../domain/playlist.model';
import {HttpUtils} from '../utils/http-utils';
import {Page} from '../domain/page.model';
import {FeedCategoriesYT} from '../domain/feed.model';
import {VideoListRequest} from '../domain/video.model';
import {PlaylistVideo} from '../domain/playlist-video.model';

@Injectable({
  providedIn: 'root',
})
export class PlaylistsService {

  constructor(private httpClient: HttpClient) {
  }

  getPlaylistById(id: string): Observable<Playlist> {
    return this.httpClient.get<Playlist>(`${environment.serviceUrl}/api/playlists/${id}`);
  }

  list(filter?: PlaylistListRequest): Observable<Page<Playlist>> {
    return this.httpClient.get<Page<Playlist>>(`${environment.serviceUrl}/api/playlists`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  listAll(filter?: PlaylistListRequest): Observable<Playlist[]> {
    return this.httpClient.get<Playlist[]>(`${environment.serviceUrl}/api/playlists/list-all`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  saveContent(selectedVideos: string[], id: string) {
    return this.httpClient.put(`${environment.serviceUrl}/api/playlists/${id}/videos`, {videos: selectedVideos});
  }

  deletePlaylistVideos(selectedVideos: string[], id: string) {
    return this.httpClient.request('delete', `${environment.serviceUrl}/api/playlists/${id}/videos`, {body: {videos: selectedVideos}});
  }

  playlistVideos(id: string, filter?: VideoListRequest): Observable<Page<PlaylistVideo>> {
    return this.httpClient.get<Page<PlaylistVideo>>(`${environment.serviceUrl}/api/playlists/${id}/videos`, {
      params: HttpUtils.getHttpParams(filter)
    });
  }

  create(playlist: Playlist): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('playlist', JSON.stringify(playlist));
    return this.httpClient.post<any>(`${environment.serviceUrl}/api/playlists`, formData);
  }

  update(u: Playlist): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('playlist', JSON.stringify(u));
    return this.httpClient.put<any>(`${environment.serviceUrl}/api/playlists/` + u.id, formData);
  }

  getFeedCategories(): Observable<any> {

    return new Observable((subscriber: Subscriber<FeedCategoriesYT[]>) => subscriber.next(VideoCategories));
  }

  updateVideoOrder(playlistId: string, videoId: string, position: number) {
    return this.httpClient.put(`${environment.serviceUrl}/api/playlists/${playlistId}/videos/position`, {videoId, position});
  }

  exportMrss(id) {
    return this.httpClient.get(`${environment.serviceUrl}/api/mrss/frequency/${id}`, {
      responseType: 'text',
    });
  }
}

export const VideoCategories = [
  {id: '1', title: 'Film & Animation'},
  {id: '2', title: 'Autos & Vehicles'},
  {id: '10', title: 'Music'},
  {id: '15', title: 'Pets & Animals'},
  {id: '17', title: 'Sports'},
  {id: '19', title: 'Travel & Events'},
  {id: '20', title: 'Gaming'},
  {id: '22', title: 'People & Blogs'},
  {id: '23', title: 'Comedy'},
  {id: '24', title: 'Entertainment'},
  {id: '25', title: 'News & Politics'},
  {id: '26', title: 'Howto & Style'},
  {id: '27', title: 'Education'},
  {id: '28', title: 'Science & Technology'},
  {id: '29', title: 'Nonprofits & Activism'}
];


