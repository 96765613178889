<ng-container *ngIf="!selectedFile">
    <div class="drag-drop-zone" (drop)="$event.preventDefault(); onFileChange($event.dataTransfer.files[0])"
        (dragover)="$event.stopPropagation(); $event.preventDefault();">
        <div class="drag-drop-zone-icon">
            <i class="fal fa-cloud-upload"></i>
        </div>
        <div class="drag-drop-zone-text">
            <span>Drag and drop or <a class="link" (click)="csvInput.click()">browse</a> your file</span>
        </div>
    </div>
    <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="onFileChange($event.target.files[0])"
        [accept]="accept" />
</ng-container>

<ng-container *ngIf="selectedFile">
    <div class="selected-files-list">
        <p>File ready</p>
        <div class="selected-file">
            <div class="d-flex align-items-center">
                <i class="file-type fal fa-file"></i>
                <b>{{selectedFile.name}}</b>
            </div>
            <button (click)="removeFile()" mat-icon-button><i
                    class="remove fas fa-times-circle text-danger"></i></button>
        </div>
    </div>
</ng-container>
