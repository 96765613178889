import { NgModule } from '@angular/core';
import { CoreModule } from '../../core/core.module';
import { CircleInfoComponent } from './circle-info/circle-info.component';
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { PlaylistAutocompleteComponent } from './utils/playlist-autocomplete.component';
import { PlaylistAutocompleteChipsComponent } from './utils/playlist-autocomplete-chips.component';
import { UploadButtonComponent } from './utils/upload-button.component';
import { StatusBoxComponent } from './utils/status-box.component';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { DateRangeChooserComponent } from './date-selector/date-range-chooser.component';
import { CsTableColumnCommonService } from './table/cs-table-column-common.service';
import { PublisherAutocompleteComponent } from './utils/publisher-autocomplete.component';
import { PublisherAutocompleteChipsComponent } from './utils/publisher-autocomplete-chips.component';
import { DomainAutocompleteComponent } from './utils/domain-autocomplete.component';
import { DomainAutocompleteChipsComponent } from './utils/domain-autocomplete-chips.component';
import { EmbedAutocompleteComponent } from './utils/embed-autocomplete.component';
import { EmbedAutocompleteChipsComponent } from './utils/embed-autocomplete-chips.component';
import { ImageUploadPreviewComponent } from './utils/image-upload-preview/image-upload-preview.component';
import { MatTableColVisComponent } from './table/mat-table-col-vis.component';
import { CardFiltersComponent } from './cards/card-filters.component';
import { ToolbarPublisherComponent } from './toolbar/toolbar-publisher/toolbar-publisher.component';
import { ContentOwnerAutocompleteComponent } from './utils/content-owner-autocomplete.component';
import { PlaylistIngestFeedDialogComponent } from '../../modules/playlists/playlist-ingest-feed-dialog/playlist-ingest-feed-dialog.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SearchControlComponent } from './search-control/search-control.component';
import { DurationPipe } from './pipes/duration.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CategoriesAutocompleteChipsComponent } from './utils/categories-autocomplete-chips.component';
import { CategoriesAutocompleteComponent } from './utils/categories-autocomplete.component';
import { CategoriesMultiSelectComponent } from './utils/categories-multi-select.component';
import { ChannelsAutocompleteComponent } from './utils/channels-autocomplete.component';
import { CategoriesAutocompleteChipsInputComponent } from './utils/categories-autocomplete-chips-input.component';
import { VideosTableComponent } from '../../modules/videos/videos-table/videos-table.component';
import { ContentOwnerAutocompleteChipsComponent } from './utils/content-owner-autocomplete-chips.component';
import { VideoUploadPreviewComponent } from './utils/video-upload-preview/video-upload-preview.component';
import { UsersSharedTableComponent } from 'src/app/modules/content-owner/details/users/users-shared-table/users-shared-table.component';
import { DocumentsSharedTableComponent } from 'src/app/modules/content-owner/details/documents/documents-shared-table/documents-shared-table.component';
import { DocumentsSharedUploadDocumentDialogComponent } from 'src/app/modules/content-owner/details/documents/documents-shared-table/documents-shared-upload-document-dialog/documents-shared-upload-document-dialog.component';
import { PubDashboardDomainsChartComponent } from 'src/app/modules/publisher/dashboard/pub-dashboard-domains/pub-dashboard-domains-chart/pub-dashboard-domains-chart.component';
import { MetricSummaryCardComponent } from 'src/app/modules/publisher/dashboard/metric-summary-card/metric-summary-card.component';
import { RemovableMessageCardComponent } from 'src/app/modules/publisher/dashboard/removable-message-card/removable-message-card.component';
import { DpAutocompleteComponent } from './utils/dp-autocomplete.component';
import { DpAutocompleteChipsComponent } from './utils/dp-autocomplete-chips';
import { BundleIdAutocompleteComponent } from './utils/bundle-id-autocomplete.component';
import { BundleIdAutocompleteChipsComponent } from './utils/bundle-id-autocomplete-chips.component';
import { FlagAutocompleteComponent } from './utils/flags-autocomplete.component';
import { FlagAutocompleteChipsComponent } from './utils/flags-autocomplete-chips';
import { VjsPlayerComponent } from 'src/app/modules/playlists/playlist-video-preview/vjs-player.component';
import { TagsAutocompleteComponent } from './utils/tags-autocomplete.component';
import { TagsAutocompleteChipsComponent } from './utils/tags-autocomplete-chips';
import { GenresAutocompleteComponent } from './utils/genres-autocomplete.component';
import { GenresAutocompleteChipsComponent } from './utils/genres-autocomplete-chips';
import { ArtistsAutocompleteComponent } from './utils/artists-autocomplete.component';
import { ArtistsAutocompleteChipsComponent } from './utils/artists-autocomplete-chips';
import { NetworkAutocompleteChipsComponent } from './utils/network-autocomplete-chips.component';
import { NetworkAutocompleteComponent } from './utils/network-autocomplete.component';
import { PlacementsAutocompleteComponent } from './utils/placements-autocomplete';
import { DpDetailsConnectionsComponent } from 'src/app/modules/demand/details/connections/dp-details-connections.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PageLoader } from './loaders/page-loader/page-loader.component';
import { MasterTableComponent } from './master-table/master-table.component';
import { MasterTablePipe } from './master-table/pipes/master-table.pipe';
import { FileSelectComponent } from './file-select/file-select.component';
import { SuccessRedirectDialogComponent } from './success-redirect-dialog/success-redirect-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ConnectionsAutocompleteComponent } from './utils/dp-connections-autocomplete.component';
import { PlacementsAutocompleteChipsComponent } from './utils/placement-autocomplete-chips.component';
import { ConnectionsAutocompleteChipsComponent } from './utils/dp-connection-autocomplete-chips.component';
import {
  IabCategoriesAutocompleteChipsInputComponent
} from './utils/iab-categories-autocomplete-chips-input.component';
import { UsersAutocompleteComponent } from './utils/users-autocomplete.component';
import { UsersAutocompleteChipsComponent } from './utils/users-autocomplete-chips.components';
import { CreativesAutocompleteComponent } from './utils/creatives-autocomplete';
import { PlatformAutocompleteComponent } from './utils/platform-autocomplete.component';
import { PlatformAutocompleteChipsComponent } from './utils/platform-autocomplete-chips.component';
import { GenresAutocompleteChipsInputComponent } from './utils/genres-autocomplete-chips-input.component';
import { GeolocationsCountryAutocompleteComponent } from './utils/geolocation-country-autocomplete';
import { GeolocationsCountryAutocompleteChipsComponent } from './utils/geolocation-country-autocomplete-chips';
import { EndpointGeneratorMacroDialogComponent } from './endpoint-generator-macros/endpoint-generator-macro-dialog.component';
import { LineItemsAutocompleteComponent } from './utils/line-items-autocomplete.component';
import { LineItemsAutocompleteChipsComponent } from './utils/line-items-autocomplete-chips.component';
import { ChangelogDialogComponent } from './changelog-dialog/changelog-dialog.component';
import { PublisherAutocompleteChipsInputComponent } from './utils/publisher-autocomplete-chips-input.component';
import { SimpleMatTableColVisComponent } from './table/simple-mat-table-col-vis.component';
import { TitleCaseSplittedPipe } from './pipes/title-case-splitted.pipe';
import { A2vTagsAutocompleteChipsInputComponent } from './utils/a2v-tags-autocomplete-chips';
import { AdsTxtUrlStatusTableComponent } from './ads-txt-url-status-table/ads-txt-url-status-table.component';
import { CustomUploadMenuButtonComponent } from './upload-menu-button/custom-upload-menu-button.component';
import {ReportingDateRangeChooserComponent} from "./date-selector/reporting-date-range-chooser.component";
import {OrdersAutocompleteComponent} from "./utils/orders-autocomplete.component";
import {OrdersAutocompleteChipsComponent} from "./utils/orders-autocomplete-chips.component";
import {LineItemsAutocompleteRbComponent} from "./utils/line-items-autocomplete-rb.component";
import {LineItemsAutocompleteChipsRbComponent} from "./utils/line-items-autocomplete-chips-rb.component";
import { ApplicationIdAutocompleteComponent } from './utils/application-id-autocomplete.component';
import { ApplicationIdAutocompleteChipsComponent } from './utils/application-id-autocomplete-chips.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        PlaylistAutocompleteComponent,
        PlaylistAutocompleteChipsComponent,
        UploadButtonComponent,
        StatusBoxComponent,
        DateSelectorComponent,
        DateRangeChooserComponent,
        ReportingDateRangeChooserComponent,
        PublisherAutocompleteComponent,
        PublisherAutocompleteChipsComponent,
        NetworkAutocompleteChipsComponent,
        NetworkAutocompleteComponent,
        BundleIdAutocompleteComponent,
        BundleIdAutocompleteChipsComponent,
        DpAutocompleteComponent,
        DpAutocompleteChipsComponent,
        FlagAutocompleteComponent,
        FlagAutocompleteChipsComponent,
        TagsAutocompleteComponent,
        TagsAutocompleteChipsComponent,
        GenresAutocompleteComponent,
        GenresAutocompleteChipsComponent,
        ArtistsAutocompleteComponent,
        ArtistsAutocompleteChipsComponent,
        PlaylistAutocompleteChipsComponent,
        DomainAutocompleteComponent,
        DomainAutocompleteChipsComponent,
        EmbedAutocompleteComponent,
        EmbedAutocompleteChipsComponent,
        ImageUploadPreviewComponent,
        VideoUploadPreviewComponent,
        MatTableColVisComponent,
        CardFiltersComponent,
        ToolbarPublisherComponent,
        ContentOwnerAutocompleteComponent,
        PlaylistIngestFeedDialogComponent,
        SearchControlComponent,
        DurationPipe,
        ConfirmDialogComponent,
        CategoriesAutocompleteChipsComponent,
        CategoriesAutocompleteComponent,
        CategoriesMultiSelectComponent,
        PlacementsAutocompleteComponent,
        UsersSharedTableComponent,
        DocumentsSharedTableComponent,
        DocumentsSharedUploadDocumentDialogComponent,
        VideosTableComponent,
        ConfirmDialogComponent,
        DeleteDialogComponent,
        ContentOwnerAutocompleteChipsComponent,
        ChannelsAutocompleteComponent,
        IabCategoriesAutocompleteChipsInputComponent,
        CategoriesAutocompleteChipsInputComponent,
        RemovableMessageCardComponent,
        MetricSummaryCardComponent,
        PubDashboardDomainsChartComponent,
        DpDetailsConnectionsComponent,
        VjsPlayerComponent,
        PageLoader,
        MasterTableComponent,
        MasterTablePipe,
        CircleInfoComponent,
        FileSelectComponent,
        SuccessRedirectDialogComponent,
        ConnectionsAutocompleteComponent,
        PlacementsAutocompleteChipsComponent,
        ConnectionsAutocompleteChipsComponent,
        UsersAutocompleteComponent,
        UsersAutocompleteChipsComponent,
        CreativesAutocompleteComponent,
        PlatformAutocompleteComponent,
        PlatformAutocompleteChipsComponent,
        GenresAutocompleteChipsInputComponent,
        GeolocationsCountryAutocompleteComponent,
        GeolocationsCountryAutocompleteChipsComponent,
        EndpointGeneratorMacroDialogComponent,
        LineItemsAutocompleteComponent,
        LineItemsAutocompleteChipsComponent,
        ChangelogDialogComponent,
        PublisherAutocompleteChipsInputComponent,
        SimpleMatTableColVisComponent,
        TitleCaseSplittedPipe,
        A2vTagsAutocompleteChipsInputComponent,
        AdsTxtUrlStatusTableComponent,
        CustomUploadMenuButtonComponent,
        OrdersAutocompleteComponent,
        OrdersAutocompleteChipsComponent,
        LineItemsAutocompleteRbComponent,
        LineItemsAutocompleteChipsRbComponent,
        ApplicationIdAutocompleteComponent,
        ApplicationIdAutocompleteChipsComponent
    ],
    imports: [CoreModule, HighchartsChartModule, MatProgressSpinnerModule],
    providers: [
        DatePipe,
        DecimalPipe,
        PercentPipe,
        CurrencyPipe,
        DurationPipe,
        CsTableColumnCommonService,
    ],
    exports: [
        ToolbarComponent,
        PlaylistAutocompleteChipsComponent,
        PlaylistAutocompleteComponent,
        UploadButtonComponent,
        StatusBoxComponent,
        DateSelectorComponent,
        DateRangeChooserComponent,
        ImageUploadPreviewComponent,
        VideoUploadPreviewComponent,
        PublisherAutocompleteComponent,
        PublisherAutocompleteChipsComponent,
        NetworkAutocompleteChipsComponent,
        NetworkAutocompleteComponent,
        BundleIdAutocompleteComponent,
        BundleIdAutocompleteChipsComponent,
        DpAutocompleteComponent,
        DpAutocompleteChipsComponent,
        FlagAutocompleteComponent,
        FlagAutocompleteChipsComponent,
        TagsAutocompleteComponent,
        TagsAutocompleteChipsComponent,
        GenresAutocompleteComponent,
        GenresAutocompleteChipsComponent,
        ArtistsAutocompleteComponent,
        ArtistsAutocompleteChipsComponent,
        PlaylistAutocompleteChipsComponent,
        DomainAutocompleteComponent,
        DomainAutocompleteChipsComponent,
        EmbedAutocompleteComponent,
        EmbedAutocompleteChipsComponent,
        MatTableColVisComponent,
        CardFiltersComponent,
        ToolbarPublisherComponent,
        ContentOwnerAutocompleteComponent,
        PlaylistIngestFeedDialogComponent,
        SearchControlComponent,
        DurationPipe,
        VideosTableComponent,
        ConfirmDialogComponent,
        CategoriesAutocompleteChipsComponent,
        CategoriesAutocompleteComponent,
        CategoriesMultiSelectComponent,
        PlacementsAutocompleteComponent,
        DeleteDialogComponent,
        UsersSharedTableComponent,
        DocumentsSharedTableComponent,
        DocumentsSharedUploadDocumentDialogComponent,
        ContentOwnerAutocompleteChipsComponent,
        ChannelsAutocompleteComponent,
        IabCategoriesAutocompleteChipsInputComponent,
        CategoriesAutocompleteChipsInputComponent,
        RemovableMessageCardComponent,
        MetricSummaryCardComponent,
        PubDashboardDomainsChartComponent,
        DpDetailsConnectionsComponent,
        VjsPlayerComponent,
        PageLoader,
        MasterTableComponent,
        CircleInfoComponent,
        FileSelectComponent,
        ConnectionsAutocompleteComponent,
        PlacementsAutocompleteChipsComponent,
        ConnectionsAutocompleteChipsComponent,
        UsersAutocompleteComponent,
        UsersAutocompleteChipsComponent,
        CreativesAutocompleteComponent,
        PlatformAutocompleteComponent,
        PlatformAutocompleteChipsComponent,
        GenresAutocompleteChipsInputComponent,
        GeolocationsCountryAutocompleteComponent,
        GeolocationsCountryAutocompleteChipsComponent,
        LineItemsAutocompleteComponent,
        LineItemsAutocompleteChipsComponent,
        PublisherAutocompleteChipsInputComponent,
        SimpleMatTableColVisComponent,
        TitleCaseSplittedPipe,
        A2vTagsAutocompleteChipsInputComponent,
        AdsTxtUrlStatusTableComponent,
        CustomUploadMenuButtonComponent,
        ReportingDateRangeChooserComponent,
        OrdersAutocompleteComponent,
        OrdersAutocompleteChipsComponent,
        LineItemsAutocompleteRbComponent,
        LineItemsAutocompleteChipsRbComponent,
        ApplicationIdAutocompleteComponent,
        ApplicationIdAutocompleteChipsComponent
    ]
})
export class SharedComponentsModule {}
