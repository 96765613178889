import {PageRequest} from './page-request';
import {User} from '../../core/auth/user.model';
import {LayoutSource} from './layout.model';
import {KeyLabel} from './key-label.model';

export class Embed {
  id: string;
  name: string;
  code: string;
  partners: string;
  channels: number;
  createdOn: string;
  updatedOn: string;
  layout: LayoutSource;
  muted: boolean;
  tvaId: string;
  noOfChannels: string;
  createdBy: User;
  numberOfDemandPartners: number;
  status: EmbedStatus;
  adhesion: boolean;
  backgroundImageUrl?: string;
  dpsApproved?: number;
  dpsPending?: number;
  dpsRejected?: number;
  dpsTotal?: number;
  postHealthCheck?: boolean;
  type?: EmbedType;
  device?: EmbedDevice;
  publisherId: string;
  publisherName: string;
  domainId: string;
  url: string;
  rokuName?: string;
  rokuUrl?: string;
  somoId?: string;
  adUnitName?: string;
  adUnitId?: string;

  static of(name: string) {
    const e = new Embed();
    e.name = name;
    return e;
  }
}

export class EmbedListRequest extends PageRequest {
  name: any;
  status?: EmbedStatus[];
}

export class EmbedStatusBox {
  count: number;
  status: string;
}

export enum EmbedStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  READY = 'READY',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE ',
}

export class EmbedStatusListing {
  ACTIVE: number;
  ARCHIVED: number;
  READY: number;
  DRAFT: number;
  INACTIVE: number;
}

export enum EmbedStatusColorMap {
  ACTIVE = 'green',
  ARCHIVED = 'gray',
  READY = 'info',
  DRAFT = 'orange',
  INACTIVE = 'red',
}

export const EmbedStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  READY: 'Ready',
  DRAFT: 'Draft',
  INACTIVE: 'Inactive',
};

export interface EmbedGeneralData {
  layout: LayoutSource;
  muted?: boolean;
  adhesion?: boolean;
  removeImage?: boolean;
  type?: EmbedType;
  device?: EmbedDevice;
  rokuName?: string;
  rokuUrl?: string;
  somoId?: string;
  adUnitId?: string;
}

export enum EmbedType {
  DEDICATED = 'DEDICATED',
  ADHESION = 'ADHESION'
}

export const EmbedTypeLabels = {
  DEDICATED: 'Dedicated Page',
  ADHESION: 'Adhesion'
};

export const EmbedTypeKL = () =>
  (Object.keys(EmbedType).map(key => new KeyLabel(key as EmbedType, EmbedTypeLabels[key])));

export enum EmbedDevice {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP'
}

export const EmbedDeviceLabels = {
  MOBILE: 'Mobile & Tablet',
  DESKTOP: 'Desktop & Laptop'
};

export const EmbedDeviceKL = () =>
  (Object.keys(EmbedDevice).map(key => new KeyLabel(key as EmbedDevice, EmbedDeviceLabels[key])));
