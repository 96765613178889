import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { SortUtils } from '../../utils/sort-utils';
import { Placements, PlacementStatus, PlacementStatusColorMap } from '../../domain/placements.model';
import { PlacementsService } from '../../services/placements.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ServerErrorUtils } from '../../utils/server-error-utils';
import { FloatLabelType } from '@angular/material/form-field';
import { Publisher } from '../../domain/publisher.model';

@Component({
  selector: 'mt-placements-autocomplete',
  template: ` <mat-form-field
    [floatLabel]="floatLabel || floatLabelAlways"
    class="w-100"
    [ngClass]="formFieldClass"
    [appearance]="formFieldAppearance ? formFieldAppearance : 'outline'"
  >
    <mat-label>{{label ? label : 'Choose placement'}}</mat-label>
    <input
      matInput
      #search
      [disabled]="disabled"
      aria-label="Placement"
      [matAutocomplete]="auto"
      [formControl]="filterCtrl"
      [required]="required"
      (keydown)="!disabled"
      (click)="!disabled && openDropdown()"
    />
    <i class="far fa-spin fa-spinner text-primary" matSuffix [hidden]="!loading"></i>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="display"
    >
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ item.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="formFieldHint">{{formFieldHint ? formFieldHint : ''}}</mat-hint>
  </mat-form-field>`,
})
export class PlacementsAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('search', { static: true }) searchFilter: ElementRef;
  private readonly onDestroy = new Subject<void>();
  @Input() public endpointEntery: boolean;
  @Input() public placeholder: string;
  @Input() public floatLabel: FloatLabelType;
  /** Hold list of networkIds to filter placements only by given networks.*/
  @Input() public networkIds: string[];
  @Input() public publisherIds: string[];
  @Input() public label: string;
  @Input() public formFieldClass: string;
  @Input() public formFieldAppearance: string;
  @Input() public formFieldHint: string;
  @Input() required: boolean;
  @Input() preselectedPlacementId: Placements['id'];
  @Output() placementSelected = new EventEmitter<Placements>();
  @Input() selectedPublisher:Publisher;
  floatLabelAlways: FloatLabelType = 'always';
  @Input() filteredItems: Subject<Placements[]>  = new Subject<Placements[]>(); //List of Placements to show in dropdown filtered with 'filteredPlacementIds'
  filterCtrl = new UntypedFormControl();
  placementsList: Placements[] = []; //stores currently fetched list of placements
  selectedPlacement: Placements; //currently selected placement
  filteredPlacementIds: string[] = []; //It stores all the IDs of selected placements
  public loading = false;
  public PlacementStatusColorMap = PlacementStatusColorMap;
  @Input() disabled:boolean;

  constructor(
    private service: PlacementsService,
    private snackBarService: SnackBarService) {
    this.filterCtrl.valueChanges
      .pipe(debounceTime(800), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(
        (searchTerm) => {
          if (searchTerm && typeof searchTerm === 'string') {
            this.getPlacements(searchTerm);
          }
          else if (searchTerm == '') { //triggers when user deletes everything from input
            this.filteredItems.next(undefined);
            this.placementSelected.emit(null);
          }
        }
      );
  }

  ngOnInit(): void {
    if (this.preselectedPlacementId) {
      this.getPlacements('', this.preselectedPlacementId);
    }
  }

  /** SearchTerm used for filtering by pub name. PreselectedPlacementId used to get only one placement (preselected) at the component initialization. */
  private getPlacements(searchTerm?: string, preselectedPlacementId?: string) {
    this.loading = true;
    this.filterCtrl.disable();
    return this.service.listPlacements({
      page: 0,
      size: 1000,
      networkIds: this.networkIds,
      publishersIds: this.selectedPublisher ? this.selectedPublisher.id : [], //Should we rename this to publisherId field
      searchTerm,
      status: [PlacementStatus.ACTIVE],
      ids: [preselectedPlacementId]
    } )
      .pipe(
        finalize(() => {
          this.filterCtrl.enable();
          !preselectedPlacementId ? this.searchFilter.nativeElement.focus() : 0; //No need for focus when loading page with preselected placements
          this.loading = false;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe({
        next: (resp) => {
          this.placementsList = resp.content.sort(SortUtils.propertyComparatorString('name'));
          if (preselectedPlacementId) {
            this.selectedPlacement = this.placementsList[0]; //Naturally since the api call is filtered with one ID, selectedPlacement will actually be the first one from the response
            this.filterCtrl.setValue(this.selectedPlacement);
            this.updateFilteredPlacements([this.selectedPlacement]);
            this.emit();
          }
          if(!this.endpointEntery){
            this.filteredItems.next(this.placementsList.filter(x => !this.filteredPlacementIds.includes(x.id)));
          }else{
            let onlyStandardPlacements = this.placementsList.filter(x => !this.filteredPlacementIds.includes(x.id));
            this.filteredItems.next(onlyStandardPlacements.filter(placement => placement.type === 'STANDARD'));
          }
        },
        error: (error) => {
          const messages = ServerErrorUtils.getValidationMessages(error);
          if (messages) {
            messages.forEach((m) => this.snackBarService.error(m));
          } else {
            this.snackBarService.error('Error while fetching placements autocomplete data');
          }
        },
       });
  }

  public optionSelected($event) {
    this.selectedPlacement = $event.option.value;
    this.emit();
    this.searchFilter.nativeElement.blur(); //blur == unfocus
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty and there is no point in showing filtered list on next focus on element
  }

  /** Emits value back to parent component.
   * If wrapped inside chips autocomplete it emits value to chips component that forwards values to parent component. */
  private emit() {
    this.placementSelected.emit(this.selectedPlacement);
  }

  /** Trigger only when using chips autocomplete. Triggered when item selected through chips autocomplete to update filteredPlacementIds. */
  public updateFilteredPlacements(items) {
    this.filteredPlacementIds = items.map((item) => item.id);
  }

  /** Returns wanted object to input from whole selected item model. */
  public display(p?: Placements) {
    return p ? p.name : undefined;
  }

  /** Used only by chips autocomplete component to clear the input from selected item*/
  public resetInput() {
    this.filterCtrl.setValue(null);
    this.filteredItems.next(undefined); //Reset list since the input value of autocomplete is empty
  }

  public disable() {
    this.filterCtrl.disable();
  }

  public enable() {
    this.filterCtrl.enable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next(undefined);
  }

  openDropdown() {
      setTimeout(() => {
        this.getPlacements()
      }, 400);
  }
}
